// TODO: Extract these types to a shared package

export const DataBridgeVersion = '0.2';

export type HoursSettingsForOrderType = {
  enabled: boolean;
  effectiveHours?: {
    Monday?: DayHours;
    Tuesday?: DayHours;
    Wednesday?: DayHours;
    Thursday?: DayHours;
    Friday?: DayHours;
    Saturday?: DayHours;
    Sunday?: DayHours;
  };
};

export type DayHours = {
  active?: boolean;
  start?: string;
  end?: string;
};

export type DispensaryHoursSettings = {
  inStorePickup?: HoursSettingsForOrderType;
  curbsidePickup?: HoursSettingsForOrderType;
  driveThruPickup?: HoursSettingsForOrderType;
  delivery?: HoursSettingsForOrderType;
};

export type OrderTypeConfig = {
  enableASAPOrdering?: boolean;
  enableScheduledOrdering?: boolean;
  enableAfterHoursOrdering?: boolean;
};

export type OrderTypesConfigV2 = {
  inStorePickup?: OrderTypeConfig;
  curbsidePickup?: OrderTypeConfig;
  driveThruPickup?: OrderTypeConfig;
  delivery?: OrderTypeConfig;
  offerAnyPickupService?: boolean;
  offerDeliveryService?: boolean;
};

export type DispensaryOrderTypes = {
  pickup: boolean;
  inStorePickup: boolean;
  curbsidePickup: boolean;
  driveThruPickup: boolean;
  delivery: boolean;
  kiosk: boolean;
};

export type DispensaryAddress = {
  street1: string;
  street2: string;
  city: string;
  state: string;
  stateAbbreviation: string;
  zip: string;
};

export type Dispensary = {
  id: string;
  status: string;
  name: string;
  cname: string;
  chain: string;
  phone: string;
  email: string;
  hours: DispensaryHoursSettings;
  orderTypes: DispensaryOrderTypes;
  images: {
    logo: string;
  };
  address: DispensaryAddress;
  links: {
    website: string;
    storeFrontRoot: string;
  };
  orderTypesConfig: OrderTypesConfigV2;
};

export type User = {
  email: string;
  firstName: string;
  lastName: string;
  birthday: string;
};

export type CartItem = {
  productId: string;
  name: string;
  price: number;
  quantity: number;
};

export type Cart = {
  items: CartItem[];
  total: number;
  subtotal: number;
  tax: number;
  discount: number;
};

export type CommerceComponentsMenuContext = 'kiosk' | 'store-front';

export type CommerceComponentsDataInterface = {
  menuContext: CommerceComponentsMenuContext;
  location?: Dispensary;
  user?: User;
  cart?: Cart;
  dataLoaders: {
    getAllLocations: () => Promise<Dispensary[] | []>;
  };
  actions: {
    goToInfoPage: () => void;
    goToStoreFront: () => void;
    goToStoreLocator: () => void;
    goToStoreBrowser: () => void;
    openLogin: () => void;
    openSignUp: () => void;
  };
};
