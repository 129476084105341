import { useCallback } from 'react';

import { formatDiscountForDisplay } from 'shared/helpers/specials';
import { Product } from 'utils/helpers/product';

import {
  calcDiscountPercentage,
  getIsSpecialApplicable,
  getProductSpecialsKeys,
  SpecialRestriction,
  transformSpecialRestrictions,
} from './specials.utils';

import { formatPrice, formatDiscount } from './pricing.utils';

import { UsePriceOptionsProps, UsePriceOptionsReturn, PriceOption, Special } from './use-product-pricing.types';

type PriceOptionFilterProps = Pick<
  Product,
  'limitsPerCustomer' | 'manualInventory' | 'optionsBelowKioskThreshold' | 'optionsBelowThreshold'
> & {
  isKiosk: boolean;
};

type PriceOptionFilter = (option: PriceOption) => boolean;

type TransformOptionsProps = {
  options: Product['Options'];
  prices: Product['medicalPrices'] | Product['recPrices'];
  product: Product;
  specialPrices: Product['medicalSpecialPrices'] | Product['recSpecialPrices'];
  specialRestrictions: SpecialRestriction[];
  priceOptionFilter: PriceOptionFilter;
};

function isArrayOfString(value: any): value is Array<string> {
  return Array.isArray(value) && value.every((item) => typeof item === 'string');
}

function isArrayOfNumber(value: any): value is Array<number> {
  return Array.isArray(value) && value.every((item) => typeof item === 'number');
}

function getLimitPerCustomerPerOption(
  limitsPerCustomer: Product['limitsPerCustomer'],
  optionValue: string
): number | null {
  if (limitsPerCustomer && Array.isArray(limitsPerCustomer)) {
    const optionLimit = limitsPerCustomer.find((item) => item?.key === optionValue);

    if (optionLimit?.value && optionLimit.value !== 'Unlimited') {
      return parseInt(optionLimit.value, 10);
    }
  }

  return null;
}

function getSpecial(specialPrice: number, price: number): Special {
  const discount = calcDiscountPercentage(specialPrice, price);

  return {
    price: specialPrice,
    formattedPrice: formatPrice(specialPrice),
    discount,
    formattedDiscount: formatDiscount(discount),
  };
}

function transformOptions({
  options,
  prices,
  product,
  specialPrices,
  specialRestrictions,
  priceOptionFilter,
}: TransformOptionsProps): PriceOption[] {
  if (!isArrayOfString(options) || !isArrayOfNumber(prices) || !isArrayOfNumber(specialPrices)) {
    return [];
  }

  const uniqPriceOptions: Record<string, PriceOption> = {};

  options.forEach((value, idx) => {
    const price = prices[idx];
    const specialPrice = specialPrices[idx];

    const label = value.replace(`oz`, ` oz`).replace(`gC`, `g`);
    const uniqKey = value.replace('C', '');

    uniqPriceOptions[uniqKey] = {
      value,
      label,
      price,
      formattedDiscount: formatDiscountForDisplay(price, specialPrice, product, null, value),
      formattedPrice: formatPrice(price),
      special:
        getIsSpecialApplicable(value, specialRestrictions) && specialPrice ? getSpecial(specialPrice, price) : null,
    };
  });

  return Object.values(uniqPriceOptions).filter(priceOptionFilter);
}

function getPriceOptionFilter({
  isKiosk,
  limitsPerCustomer,
  optionsBelowThreshold,
  optionsBelowKioskThreshold,
  manualInventory,
}: PriceOptionFilterProps): PriceOptionFilter {
  return ({ value }) => {
    if (getLimitPerCustomerPerOption(limitsPerCustomer, value) === 0) {
      return false;
    }

    if (!isKiosk && optionsBelowThreshold?.includes(value)) {
      return false;
    }

    if (isKiosk && optionsBelowKioskThreshold?.includes(value)) {
      return false;
    }

    if (manualInventory?.length) {
      const inventoryOption = manualInventory.find((item) => item?.option === value);

      if (inventoryOption?.inventory) {
        return inventoryOption.inventory > 0;
      }
    }

    return true;
  };
}

/*
  This is a refactor of:
    - src/utils/helpers/product.js: groupOptionsWithPrices
    - shared/helpers/products.js: formatWeightOptions

  TODO:
   - implement `showQualifyingOptions` when needed
   - implement `specialId` when needed
   - maybe memoaize results
*/
export function usePriceOptions({ menuType, isKiosk }: UsePriceOptionsProps): UsePriceOptionsReturn {
  const getPriceOptions = useCallback(
    (product: Product) => {
      const {
        limitsPerCustomer,
        manualInventory,
        medicalPrices,
        medicalSpecialPrices,
        Options: options,
        optionsBelowKioskThreshold,
        optionsBelowThreshold,
        recPrices,
        recSpecialPrices,
        specialData,
      } = product;

      const isMed = menuType === 'med';

      const prices = isMed ? medicalPrices : recPrices;
      const specialPrices = isMed ? medicalSpecialPrices : recSpecialPrices;

      const productSpecialsKeys = getProductSpecialsKeys(product);
      const specialRestrictions = transformSpecialRestrictions({ menuType, specialData, productSpecialsKeys });

      const priceOptionFilter = getPriceOptionFilter({
        isKiosk,
        limitsPerCustomer,
        manualInventory,
        optionsBelowKioskThreshold,
        optionsBelowThreshold,
      });

      const priceOptions = transformOptions({
        options,
        prices,
        product,
        specialPrices,
        specialRestrictions,
        priceOptionFilter,
      });

      return priceOptions;
    },
    [menuType, isKiosk]
  );

  const getPriceOption = useCallback(
    (product: Product, optionValue: string): PriceOption | null =>
      getPriceOptions(product).find(({ value }) => value === optionValue) ?? null,
    [getPriceOptions]
  );

  return {
    getPriceOption,
    getPriceOptions,
  };
}
