import React from 'react';

import { InstoreEnrollmentStatus } from 'src/state/instore-enrollment';
import { InstoreEnrollmentLayout } from '../instore-enrollment-layout';
import { Login } from '../login';
import { useInstoreEnrollmentPage } from './use-instore-enrollment-page';
import { ConnectBank } from '../connect-bank';
import { PayByBankEnrollmentPage } from '../pay-by-bank-enrollment-page';
import { Success } from '../success';
import { Loyalty } from '../loyalty';

export function InstoreEnrollmentPage(): JSX.Element {
  const { currentStatus } = useInstoreEnrollmentPage();

  return (
    <InstoreEnrollmentLayout>
      {currentStatus === InstoreEnrollmentStatus.ecommLogin && <Login />}
      {currentStatus === InstoreEnrollmentStatus.loyaltyEnrollment && <Loyalty />}
      {currentStatus === InstoreEnrollmentStatus.payByBankEnrollment && <PayByBankEnrollmentPage />}
      {currentStatus === InstoreEnrollmentStatus.connectBankAccount && <ConnectBank />}
      {currentStatus === InstoreEnrollmentStatus.finishedEnrollment && <Success />}
    </InstoreEnrollmentLayout>
  );
}
