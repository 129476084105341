import { useEffect, useReducer, useState } from 'react';
import { useRouter } from 'next/router';
import { map } from 'lodash';

import {
  afterHoursEnabledForDelivery,
  afterHoursEnabledForPickup,
  scheduledOrderingEnabledForDelivery,
  getDeliveryMinimum,
  getPickupMinimum,
} from 'shared/helpers/dispensaries';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import useErnie from 'shared/hooks/use-ernie';
import useCart from 'hooks/use-cart';
import useUI from 'hooks/use-ui';
import useGoToCheckout from 'hooks/use-go-to-checkout';
import useCustomerState from 'hooks/use-customer-state';
import { useCartViewedMonitor } from 'src/utils/analytics/monitors/use-cart-viewed-monitor';

import { cartItemId } from 'utils/helpers/product';
import useAppliedOffers from 'hooks/use-applied-offers';
import useProductDemand from './product-demand/use-product-demand';

function dropdownReducer(state, action) {
  if (state.openDropdown === action.openDropdown) {
    return { openDropdown: null };
  }
  return { openDropdown: action.openDropdown };
}

function useOrderTypeStateHandlers() {
  // TODO: concider simplifing by using useState
  const [{ openDropdown }, dispatch] = useReducer(dropdownReducer, {});

  const handleOpenOrderTypeDropdown = () => {
    dispatch({ openDropdown: 'orderType' });
  };

  const handleCloseOrderTypeDropdown = () => {
    dispatch({ openDropdown: null });
  };

  const isOrderTypeDropdownOpen = openDropdown === 'orderType';

  return {
    handleOpenOrderTypeDropdown,
    handleCloseOrderTypeDropdown,
    isOrderTypeDropdownOpen,
  };
}

export function useCartDropdown({ onClose }) {
  const {
    dispensary,
    durationEstimates,
    isDelivery,
    isPickup,
    itemCount,
    items,
    menuType,
    simplifiedOrderType,
    subtotal,
    taxInclusionNote,
    changeItemWeight,
    changeItemQuantity,
    removeItem,
    isEmpty,
    isCalculating,
  } = useCart();

  const { isKiosk, showShop, isEmbedded, previewMode } = useUI();

  const showErnie = useErnie();
  const goToCheckout = useGoToCheckout(`in-cart`);

  const {
    handleOpenOrderTypeDropdown,
    handleCloseOrderTypeDropdown,
    isOrderTypeDropdownOpen,
  } = useOrderTypeStateHandlers();

  const customerState = useCustomerState();
  const router = useRouter();

  const { visibleProductDemandCounts, handleProductDemandChange } = useProductDemand({
    productIds: map(items, 'id'),
  });

  useCartViewedMonitor();

  const handleGoToCheckout = () => {
    if (goToCheckout()) {
      onClose();
    }
  };

  const openInfo = openInfoForDispensary(dispensary, { previewMode });
  const offerAnyPickupService = dispensary?.orderTypesConfigV2?.offerAnyPickupService;
  const offerDelivery = dispensary?.orderTypesConfigV2?.offerDeliveryService;
  const afterHoursOrderingForPickup = afterHoursEnabledForPickup(dispensary);
  const afterHoursOrderingForDelivery = afterHoursEnabledForDelivery(dispensary);
  const scheduledOrderingForDelivery = scheduledOrderingEnabledForDelivery(dispensary);

  const { discountToCartApplied, appliedOffers } = useAppliedOffers();

  const { inStorePickup, curbsidePickup, driveThruPickup, delivery } = openInfo;

  const pickupIsOpen = inStorePickup.isOpen || curbsidePickup.isOpen || driveThruPickup.isOpen;
  const deliveryIsOpen = delivery.isOpen;

  let displayOrderTypeSwitch;

  if (isKiosk) {
    displayOrderTypeSwitch = false;
  } else if (dispensary) {
    if (isDelivery) {
      displayOrderTypeSwitch = offerAnyPickupService && (pickupIsOpen || afterHoursOrderingForPickup);
    } else if (isPickup) {
      displayOrderTypeSwitch =
        dispensary.deliveryInfo?.withinBounds &&
        offerDelivery &&
        (deliveryIsOpen || (afterHoursOrderingForDelivery && scheduledOrderingForDelivery));
    }
  }

  const showEstimate =
    dispensary && offerDelivery && !dispensary.featureFlags?.hideDeliveryEstimate && !scheduledOrderingForDelivery;

  const handleWeightChange = (item) => (e) => {
    const { value } = e.target;
    changeItemWeight(item, value, showErnie, customerState);
  };

  const handleQuantityChange = (item) => (event) => {
    const id = cartItemId(item);
    const value = Number(event.target.value);
    changeItemQuantity(id, value, showErnie, customerState);
  };

  const handleRemoveItem = (item) => () => {
    removeItem(item.key);
  };

  let hasNotMetMinimum = false;
  let minimum = 0;

  if (!isEmpty) {
    minimum = isDelivery ? getDeliveryMinimum(dispensary, true) : getPickupMinimum(dispensary, `inStorePickup`);
    hasNotMetMinimum = minimum > subtotal;
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', onClose);

    return () => {
      router.events.off('routeChangeComplete', onClose);
    };
  }, [router, onClose]);

  return {
    appliedOffers,
    discountToCartApplied,
    dispensary,
    displayOrderTypeSwitch,
    durationEstimates,
    handleCloseOrderTypeDropdown,
    handleGoToCheckout,
    handleOpenOrderTypeDropdown,
    handleQuantityChange,
    handleRemoveItem,
    handleWeightChange,
    hasNotMetMinimum,
    isCalculating,
    isEmbedded,
    isKiosk,
    isOrderTypeDropdownOpen,
    itemCount,
    items,
    menuType,
    minimum,
    showEstimate,
    showShop,
    simplifiedOrderType,
    subtotal,
    taxInclusionNote,
    visibleProductDemandCounts,
    handleProductDemandChange,
  };
}
