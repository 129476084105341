import { useEffect, useRef, useState } from 'react';
/**
 * @returns {[import('react').MutableRefObject<null>, boolean, (open: boolean) => void]}
 */
export default function useClickOut() {
  var _useState = useState(false),
    open = _useState[0],
    setOpen = _useState[1];
  var ref = useRef(null);
  var handleClick = function handleClick(e) {
    if (ref && ref.current && !ref.current.contains(e.target)) {
      setOpen(false);
    }
  };
  var handleEscape = function handleEscape(e) {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  };
  useEffect(function () {
    var node = ref.current;
    if (node) {
      document.addEventListener('click', handleClick);
      document.addEventListener('keydown', handleEscape);
      return function () {
        document.removeEventListener('click', handleClick);
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [ref.current]);
  return [ref, open, setOpen];
}