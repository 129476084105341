import React from 'react';

type SmallGreenPhoneTapProps = React.SVGProps<SVGSVGElement>;

export function SmallGreenPhoneTap({ width = 24, height = 25, ...props }: SmallGreenPhoneTapProps): JSX.Element {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 25' fill='none' {...props}>
      <g clipPath='url(#clip0_2710_31460)'>
        <path
          d='M7.38755 17.6089C7.01956 17.6089 6.66664 17.4627 6.40644 17.2025C6.14623 16.9423 6.00005 16.5893 6.00005 16.2214L6 2.38799C6 2.02 6.14618 1.66708 6.40639 1.40688C6.66659 1.14667 7.01952 1.00049 7.3875 1.00049H17.2875C17.6554 1.00049 18.0084 1.14667 18.2686 1.40688C18.5288 1.66708 18.675 2.02 18.675 2.38799L18.675 15.0005'
          stroke='#00624A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.7764 23.0005L9.34836 20.2961C8.84333 19.7469 8.89587 18.8617 9.45105 18.3731C10.0124 17.8791 10.9 17.9358 11.3925 18.497L13.094 20.3918V14.3048C13.094 13.5944 13.694 13.0006 14.4117 13.0005C15.1294 13.0006 15.7294 13.5944 15.7294 14.3048V18.4353H17.047C18.0954 18.4353 19.1009 18.8476 19.8422 19.5814C20.5835 20.3153 21 21.3106 21 22.3483V23.0005'
          stroke='#00624A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2710_31460'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.184082)' />
        </clipPath>
      </defs>
    </svg>
  );
}
