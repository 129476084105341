import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["useDartDiscountsPath"];
/* eslint-disable import/no-extraneous-dependencies */
import { calculateProductDiscountsPOS } from './pos';
import { calculateProductDiscountsEcomm } from './ecomm';
export var calculateProductDiscounts = function calculateProductDiscounts(_ref) {
  var useDartDiscountsPath = _ref.useDartDiscountsPath,
    args = _objectWithoutProperties(_ref, _excluded);
  if (useDartDiscountsPath) {
    return calculateProductDiscountsPOS(args);
  }
  return calculateProductDiscountsEcomm(args);
};