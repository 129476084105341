import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _toConsumableArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) { n[e] = r[e]; } return n; }
import _ from 'lodash';
import { checkLimits } from 'shared/helpers/check-limits';
import { getLimitPerCustomer, optionValMap, categorySingularize } from 'shared/helpers/products';
export var ERR_OVER_PURCHASE_LIMIT = 6;
export var ERR_OVER_CATEGORY_LIMIT = 7;
function checkCategoryLimits(_ref) {
  var cart = _ref.cart,
    dispensary = _ref.dispensary;
  var categoryLimitsEnabled = dispensary.categoryLimitsEnabled,
    categoryLimits = dispensary.categoryLimits;
  var productTypes = _.map(_.keys(cart), function (k) {
    return cart[k].product.type;
  });
  var quantities = _.map(_.keys(cart), function (k) {
    return cart[k].quantity;
  });
  var options = _.map(_.keys(cart), function (k) {
    return cart[k].option;
  });
  if (categoryLimitsEnabled) {
    /* eslint-disable */
    var _iterator = _createForOfIteratorHelper(_.uniq(productTypes)),
      _step;
    try {
      var _loop = function _loop() {
        var type = _step.value;
        var indexes = _.reduce(productTypes, function (acc, val, index) {
          return val === type ? [].concat(_toConsumableArray(acc), [index]) : acc;
        }, []);
        var _$filter = _.filter(categoryLimits, function (c) {
            return c.name === type;
          }),
          _$filter2 = _slicedToArray(_$filter, 1),
          _$filter2$ = _$filter2[0];
        _$filter2$ = _$filter2$ === void 0 ? {} : _$filter2$;
        var _$filter2$$value = _$filter2$.value,
          limitForType = _$filter2$$value === void 0 ? false : _$filter2$$value;
        var amnts = _.map(indexes, function (i) {
          if (type === 'Flower') {
            return _.get(optionValMap, options[i], 0.0) * parseInt(quantities[i], 10);
          }
          return parseInt(quantities[i], 10);
        });
        if (limitForType && parseFloat(limitForType) < _.sum(amnts)) {
          var singularType = _.lowerCase(categorySingularize(type));
          var message = "Sorry, we only allow the purchase of ".concat(limitForType, "g of ").concat(singularType, " per customer.");
          if (type !== 'Flower') {
            message = "Sorry, we only allow the purchase of ".concat(limitForType, " ").concat(singularType, " items per customer.");
          }
          return {
            v: {
              withinLimits: false,
              targetTypes: type,
              message: message
            }
          };
        }
      };
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _ret = _loop();
        if (typeof _ret === "object") return _ret.v;
      }
      /* eslint-enable */
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
  return {
    withinLimits: true
  };
}
function checkLimitPerCustomer(_ref2) {
  var newItemId = _ref2.newItemId,
    cart = _ref2.cart;
  var _cart$newItemId = cart[newItemId],
    product = _cart$newItemId.product,
    option = _cart$newItemId.option,
    quantity = _cart$newItemId.quantity;
  var limitPerCustomer = getLimitPerCustomer(product, option);
  if (_.isFinite(limitPerCustomer) && limitPerCustomer < quantity) {
    return {
      withinLimits: false,
      message: "We're sorry, this item has a limit of ".concat(limitPerCustomer, " per customer")
    };
  }
  return {
    withinLimits: true
  };
}
export function checkAllLimits(_ref3) {
  var newItemId = _ref3.newItemId,
    itemBeingAdded = _ref3.itemBeingAdded,
    _ref3$itemBeingRemove = _ref3.itemBeingRemoved,
    itemBeingRemoved = _ref3$itemBeingRemove === void 0 ? null : _ref3$itemBeingRemove,
    existingItems = _ref3.existingItems,
    medical = _ref3.medical,
    dispensary = _ref3.dispensary,
    customerState = _ref3.customerState,
    _ref3$changingQuantit = _ref3.changingQuantity,
    changingQuantity = _ref3$changingQuantit === void 0 ? false : _ref3$changingQuantit,
    _ref3$complianceOnly = _ref3.complianceOnly,
    complianceOnly = _ref3$complianceOnly === void 0 ? false : _ref3$complianceOnly;
  var updatedCart = _objectSpread({}, existingItems);
  if (newItemId && itemBeingAdded) {
    var itemsInCart = _.values(existingItems || {});
    var matchingProduct = _.find(itemsInCart, {
      id: itemBeingAdded.product.id,
      option: itemBeingAdded.option
    });
    var newQuantity = Number(itemBeingAdded.quantity);
    if (matchingProduct && !changingQuantity) {
      newQuantity += Number(matchingProduct.quantity);
    }
    updatedCart = _objectSpread(_objectSpread({}, existingItems), {}, _defineProperty({}, newItemId, _objectSpread(_objectSpread({}, itemBeingAdded), {}, {
      quantity: newQuantity
    })));
  }

  // if we're simultaneously removing an item (ie. changing weights)
  // make sure we factor that in before we check limits
  if (itemBeingRemoved) {
    delete updatedCart[itemBeingRemoved];
  }
  var withinDispensaryCategoryLimits = checkCategoryLimits({
    cart: updatedCart,
    dispensary: dispensary
  });
  if (!withinDispensaryCategoryLimits.withinLimits) {
    return _objectSpread(_objectSpread({}, withinDispensaryCategoryLimits), {}, {
      code: ERR_OVER_CATEGORY_LIMIT
    });
  }
  var withinLegalPurchasingLimits = checkLimits({
    medical: medical,
    state: dispensary.location.state,
    cart: updatedCart,
    purchaseState: customerState,
    dispensary: dispensary
  });
  if (!dispensary.storeSettings.disablePurchaseLimits && !withinLegalPurchasingLimits.withinLimits) {
    return _objectSpread(_objectSpread({}, withinLegalPurchasingLimits), {}, {
      code: ERR_OVER_PURCHASE_LIMIT
    });
  }
  if (complianceOnly) {
    return {
      withinLimits: true
    };
  }
  if (newItemId) {
    var withinDispensaryItemLimits = checkLimitPerCustomer({
      newItemId: newItemId,
      cart: updatedCart
    });
    if (!withinDispensaryItemLimits.withinLimits) {
      return withinDispensaryItemLimits;
    }
  }
  return {
    withinLimits: true
  };
}