var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';

/**
 * @param {{
 *   className?: string;
 *   html?: string;
 * }}
 */
export default function RichTextHtmlWrapper(_ref) {
  var className = _ref.className,
    html = _ref.html;
  return __jsx(Wrapper, {
    "data-testid": "rich-text-wrapper",
    className: className,
    dangerouslySetInnerHTML: {
      __html: html
    }
  });
}
var Wrapper = styled.div.withConfig({
  displayName: "rich-text-html-wrapper__Wrapper",
  componentId: "sc-1wildp2-0"
})(["strong{font-weight:bold;}em{font-style:italic;}ul,ol{margin-top:0;padding-left:0.5rem;list-style-position:inside;}"]);