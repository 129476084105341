var __jsx = React.createElement;
import _ from 'lodash';
import React from 'react';
import { removeTypename } from 'shared/helpers/utils';
import { getAcceptedPaymentOptions, getPaymentFeeForMethod } from 'shared/helpers/dispensaries';
import { orderTypesToDisplayNames, paymentMethodsToDisplayNames } from 'shared/constants';
// eslint-disable-next-line import/no-unresolved
import { MobileEcommLinxInfoLink } from 'src/mobile-ecomm/components/linx-info-link';
import useCart from './use-cart';
import useUI from './use-ui';

// helpers
export var getFeeLabel = function getFeeLabel(paymentType, paymentFees) {
  var customFeeText = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var feeInfo = getPaymentFeeForMethod(paymentType, paymentFees) || {};
  var feeType = feeInfo.feeType,
    fee = feeInfo.fee;
  return fee ? ('sublabel', feeType === 'cents' ? "($".concat((fee / 100).toFixed(2), " ").concat(customFeeText || 'fee', ")") : "(".concat(fee, "% fee)")) : '';
};
var getValidOrderTypesList = function getValidOrderTypesList(orderTypesConfigV2, paymentTypeKey) {
  var validOrderTypes = [];
  _.forEach(removeTypename(orderTypesConfigV2), function (orderTypeConfig, orderTypeKey) {
    var orderTypeIsEnabled = orderTypeConfig.enableASAPOrdering || orderTypeConfig.enableScheduledOrdering;
    if (orderTypeIsEnabled && orderTypeKey !== 'kiosk' && orderTypeConfig.paymentTypes[paymentTypeKey]) {
      // Stripping out 'Pickup' string below since the V2 order types are longer/more numerous
      validOrderTypes.push(orderTypesToDisplayNames[orderTypeKey].replace(' Pickup', ''));
    }
  });
  return validOrderTypes;
};
var getPaymentMethodLabel = function getPaymentMethodLabel(paymentTypeKey) {
  return paymentMethodsToDisplayNames[paymentTypeKey];
};
var getPaymentMethodValue = function getPaymentMethodValue(paymentTypeKey) {
  // handles conversion of credit/debit types to appropriate value, if applicable
  if (paymentTypeKey === 'creditCardAtDoor') {
    return 'creditCard';
  }
  if (paymentTypeKey === 'debit') {
    return 'debitCard';
  }
  return paymentTypeKey;
};
export default function useDispensaryPaymentMethods(dispensaryProp) {
  var Cart = useCart();
  var UI = useUI();
  var dispensary;
  if (UI !== null && UI !== void 0 && UI.isEmbedded && dispensaryProp) {
    dispensary = dispensaryProp === null || dispensaryProp === void 0 ? void 0 : dispensaryProp.dispensary;
  } else {
    var _Cart$order;
    dispensary = Cart === null || Cart === void 0 ? void 0 : (_Cart$order = Cart.order) === null || _Cart$order === void 0 ? void 0 : _Cart$order.dispensary;
  }
  if (!dispensary) {
    return null;
  }
  var _dispensary = dispensary,
    paymentFees = _dispensary.paymentFees;
  var v2OrderType = !(Cart !== null && Cart !== void 0 && Cart.orderType) || (Cart === null || Cart === void 0 ? void 0 : Cart.orderType) === 'pickup' ? 'inStorePickup' : Cart === null || Cart === void 0 ? void 0 : Cart.orderType;
  var orderTypesConfig = _.omit(dispensary.orderTypesConfigV2, ['offerAnyPickupService', 'offerDeliveryService']);
  var paymentTypeKeys = ['dutchiePay', 'rethinkPay', 'cash', 'check', 'creditCardAtDoor', 'creditCardByPhone', 'debit', 'alt36', 'linx', 'canPay', 'payOnlineHypur', 'paytender', 'aeropay', 'payOnlineMerrco', 'payOnlineMoneris', 'payOnlineChase'];
  var paymentMethodsToBeDisplayed = _.filter(paymentTypeKeys, function (paymentTypeKey) {
    // determine list of payment types that should be shown at checkout based on current order type.
    // For regular checkout, ALL dispo payment types for pickups/delivery should be visible, and
    // enabled/disabled as appropriate. For kiosk, only kiosk payment types should show.

    if (Cart !== null && Cart !== void 0 && Cart.isKioskOrder) {
      return dispensary.orderTypesConfig.kiosk.paymentTypes[paymentTypeKey];
    }
    // it's regular checkout; match against payment type list with kiosk-only types excluded.
    // We don't want to show a payment type at regular checkout if it's not usable for any pickup/delivery option.
    return getAcceptedPaymentOptions(dispensary)[paymentTypeKey];
  });
  var decoratedPaymentMethods = _.map(paymentMethodsToBeDisplayed, function (paymentTypeKey) {
    // add fee labelling
    var feeLabel = getFeeLabel(paymentTypeKey, paymentFees);
    var sublabel = feeLabel;

    // check if payment type is valid for currently-selected order type
    var orderType = v2OrderType;
    var validPaymentTypes = orderTypesConfig[Cart !== null && Cart !== void 0 && Cart.isKioskOrder ? 'kiosk' : orderType].paymentTypes;
    var disabledForSelectedOrderType = !validPaymentTypes[paymentTypeKey];

    // if payment method is not valid for currently-selected order type, add explanatory sublabel
    // telling user which order types it IS valid for
    if (disabledForSelectedOrderType) {
      var validOrderTypes = getValidOrderTypesList(orderTypesConfig, paymentTypeKey);
      sublabel = "(".concat(validOrderTypes.join(', ').replace(/,(?!.*,)/gim, ' & '), " Only)");
    }
    var option = {
      label: getPaymentMethodLabel(paymentTypeKey),
      sublabel: sublabel,
      value: getPaymentMethodValue(paymentTypeKey),
      disabled: disabledForSelectedOrderType
    };
    if (paymentTypeKey === 'rethinkPay') {
      option.sublabel = getFeeLabel(paymentTypeKey, paymentFees, 'Convenience Fee');
    }
    if (paymentTypeKey === 'linx') {
      option.label =
      // eslint-disable-next-line react/jsx-filename-extension
      __jsx("span", {
        key: "linx"
      }, "Linx", ' ', UI !== null && UI !== void 0 && UI.isMobileEcommApp ? __jsx(MobileEcommLinxInfoLink, null) : __jsx("a", {
        href: "https://linx.us/",
        target: "_blank",
        rel: "noopener noreferrer",
        key: "linx-a"
      }, "?"));
    }
    return option;
  });
  return decoratedPaymentMethods;
}