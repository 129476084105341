import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
function satisfiesCondition(operator, total, target) {
  if (operator === 'equalTo' && total === target) {
    return true;
  }
  if (operator === 'greaterThan' && total > target) {
    return true;
  }
  return operator === 'greaterThanEqualTo' && total >= target;
}
var getTotalProductCombinations = function getTotalProductCombinations(_ref) {
  var availableEligibleItems = _ref.availableEligibleItems,
    comparisonOperator = _ref.comparisonOperator,
    numericTarget = _ref.numericTarget,
    _ref$totalQuantity = _ref.totalQuantity,
    totalQuantity = _ref$totalQuantity === void 0 ? {} : _ref$totalQuantity,
    _ref$propertyName = _ref.propertyName,
    propertyName = _ref$propertyName === void 0 ? 'weightInMg' : _ref$propertyName,
    _ref$accumulateToMaxi = _ref.accumulateToMaximum,
    accumulateToMaximum = _ref$accumulateToMaxi === void 0 ? false : _ref$accumulateToMaxi;
  var _totalQuantity$enable = totalQuantity.enabled,
    totalQuantityEnabled = _totalQuantity$enable === void 0 ? false : _totalQuantity$enable,
    _totalQuantity$quanti = totalQuantity.quantity,
    targetQuantity = _totalQuantity$quanti === void 0 ? 0 : _totalQuantity$quanti,
    _totalQuantity$quanti2 = totalQuantity.quantityOperator,
    quantityOperator = _totalQuantity$quanti2 === void 0 ? 'equalTo' : _totalQuantity$quanti2;
  var combinations = [];
  var fork = function fork() {
    var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var quantity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
    var total = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var combo = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
      items: {},
      totalQuantity: 0
    };
    var item = availableEligibleItems[index];
    var forkSatisfied = satisfiesCondition(comparisonOperator, total, numericTarget);
    if (forkSatisfied) {
      combinations.push(combo);
      // If weight is equalTo and total quantity is not enabled, we can stop going down this branch here
      if (comparisonOperator === 'equalTo' && !totalQuantityEnabled) {
        return;
      }
    }
    if (!item) {
      return;
    }
    var newTotal = total + item[propertyName] * quantity;
    if (comparisonOperator === 'greaterThanEqualTo' || comparisonOperator === 'greaterThan' || newTotal <= numericTarget) {
      if (item.quantity > quantity) {
        fork(index, quantity + 1, total, combo);
      }
      fork(index + 1, 1, newTotal, {
        items: _objectSpread(_objectSpread({}, combo.items), {}, _defineProperty({}, item.key, _objectSpread(_objectSpread({}, item), {}, {
          quantity: quantity
        }))),
        totalQuantity: combo.totalQuantity + quantity
      });
    }
    if (quantity < 2) {
      fork(index + 1, 1, total, combo);
    }
  };
  fork();

  // Filter all combinations that don't match the allowed totalQuantity parameters
  if (totalQuantityEnabled) {
    combinations = _.filter(combinations, function (combo) {
      return quantityOperator === 'greaterThan' ? combo.totalQuantity > targetQuantity : combo.totalQuantity === targetQuantity;
    });
  }

  // If totalWeight is greaterThan on it's own or totalWeight is greaterThan combined with greaterThan totalQuantity,
  // sort combinations by least number of items required to greatest
  if ((comparisonOperator === 'greaterThan' || comparisonOperator === 'greaterThanEqualTo') && (!totalQuantityEnabled || quantityOperator !== 'equalTo')) {
    var sortOrder = accumulateToMaximum ? 'desc' : 'asc';
    combinations = _.uniq(_.orderBy(combinations, 'totalQuantity', sortOrder));
  }
  return _.uniq(combinations);
};
export default getTotalProductCombinations;