// NOTE: Please check with #eng-all before adding anything here! Do not add any private keys or information!
export var whitelistedEnvVars = {
  adminSegmentWriteKey: process.env.ADMIN_SEGMENT_WRITE_KEY,
  adminUrl: process.env.ADMIN_URL,
  amplitudeApiKey: process.env.AMPLITUDE_API_KEY,
  apiUrl: process.env.API_URL,
  appEnv: process.env.APP_ENV,
  basisTheoryKey: process.env.BASIS_THEORY_KEY,
  consumerUrl: process.env.CONSUMER_URL,
  currentVersion: process.env.CURRENT_VERSION,
  dutchiePlusPlaygroundKey: process.env.DUTCHIE_PLUS_PLAYGROUND_KEY,
  dutchiePlusPlaygroundUrl: process.env.DUTCHIE_PLUS_PLAYGROUND_URL,
  googleAnalyticsID: 'UA-101536475-1',
  gtmContainerKey: process.env.GTM_CONTAINER_KEY,
  launchDarklyClientId: process.env.LAUNCH_DARKLY_CLIENT_ID,
  logrocketIdAdmin: process.env.LOGROCKET_ID_ADMIN,
  logrocketIdCheckout: process.env.LOGROCKET_ID_CHECKOUT,
  logrocketIdMarketplace: process.env.LOGROCKET_ID_MARKETPLACE,
  paysafeEnvironment: process.env.PAYSAFE_ENVIRONMENT,
  plaidEnv: process.env.PLAID_ENV,
  plaidKey: process.env.PLAID_KEY,
  pusherKey: process.env.PUSHER_KEY,
  siftBeaconKey: process.env.SIFT_BEACON_KEY,
  streamKey: process.env.STREAM_MESSAGING_KEY_ID,
  stripeKey: process.env.STRIPE_PKEY,
  turnstileSiteKey: process.env.CF_TURNSTILE_SITE_KEY,
  zendeskAdminKey: process.env.ZENDESK_ADMIN_KEY || '8d311820-8a25-4ab5-b80d-9e859f0911dc',
  zendeskMarketplaceKey: process.env.ZENDESK_MARKETPLACE_KEY
};