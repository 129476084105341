import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import { Receipt } from 'shared/order/receipt';
var calculateDiscountsToCart = function calculateDiscountsToCart(_ref) {
  var _detailItem$bogoSavin, _detailItem$bogoSavin2, _detailItem$bogoSavin3, _detailItem$bogoSavin4;
  var detailItem = _ref.detailItem,
    mappedRewards = _ref.mappedRewards,
    receiptProduct = _ref.receiptProduct,
    runningPrice = _ref.runningPrice;
  var quantity = detailItem.quantity;
  var totalAdditionalCredit = Big(0);
  var discountedPrice = runningPrice;
  var bogoSavings = {
    individual: (_detailItem$bogoSavin = detailItem === null || detailItem === void 0 ? void 0 : (_detailItem$bogoSavin2 = detailItem.bogoSavings) === null || _detailItem$bogoSavin2 === void 0 ? void 0 : _detailItem$bogoSavin2.individual) !== null && _detailItem$bogoSavin !== void 0 ? _detailItem$bogoSavin : [],
    total: (_detailItem$bogoSavin3 = detailItem === null || detailItem === void 0 ? void 0 : (_detailItem$bogoSavin4 = detailItem.bogoSavings) === null || _detailItem$bogoSavin4 === void 0 ? void 0 : _detailItem$bogoSavin4.total) !== null && _detailItem$bogoSavin3 !== void 0 ? _detailItem$bogoSavin3 : Big(0)
  };
  _.forEach(_.keys(mappedRewards), function (rewardId) {
    var reward = mappedRewards[rewardId];
    var conditionQuantity = reward.conditionQuantity,
      discountPercentage = reward.discountPercentage,
      redemptionMultiplier = reward.redemptionMultiplier,
      specialId = reward.specialId,
      specialName = reward.specialName;
    var discountMultiplier = Big(+discountPercentage).div(Big(100));

    /**
     * Compounding interest formula:
     *
     * A = P(1 + r/n)^nt
     *
     * A  =  final amount (amount after all redemptions are applied)
     * P  =  initial principal balance (price before applying the discounts)
     * r  =  interest rate.  (Because we are giving a discount rather than adding interest, we want to subtract from our
     *       initial value.  Therefore, we need the discount multiplier to be negative here.)
     * n  =  number of times interest applied per time period.  (Only apply the redemption once per redemption.)
     * t  =  number of time periods elapsed.  (One period = one redemption)
     */
    var n = Big(1);
    var t = redemptionMultiplier;
    var r = discountMultiplier.times(Big(-1));
    var nt = +n.times(t); // Big.pow doesn't accept Big numbers I guess? So we need to convert to a number here.
    var parenValue = Big(1).add(r.div(n)); // (1 + r/n)
    var newDiscountedPrice = discountedPrice.times(parenValue.pow(nt)); // bring it all together
    var totalDiscountValue = discountedPrice.minus(newDiscountedPrice);
    discountedPrice = newDiscountedPrice;
    var individualDiscountValue = totalDiscountValue.div(quantity);
    var additionalCredit = individualDiscountValue.times(quantity);

    // add a discount to this receipt item
    receiptProduct.addDiscount({
      type: "bogo",
      value: individualDiscountValue,
      id: specialId,
      name: specialName,
      additionalAttributes: {
        satisfiedBy: []
      }
    });
    // add the discount to the item
    bogoSavings.individual.push({
      conditionQuantity: conditionQuantity,
      discountAmount: individualDiscountValue,
      dollarDiscount: +individualDiscountValue.div(100),
      isDiscountToCartReward: true,
      // since this function is used for SECPO DTC, this can always be true
      displayAsPercentDiscount: true,
      maxApplicable: quantity,
      specialId: specialId,
      stackingBehavior: 'compounding'
    });
    bogoSavings.total = bogoSavings.total.add(additionalCredit);
    totalAdditionalCredit = totalAdditionalCredit.add(additionalCredit);
  });
  if (bogoSavings.individual.length > 0) {
    detailItem.bogoSavings = bogoSavings;
  }
  return {
    runningPrice: discountedPrice,
    productCredit: totalAdditionalCredit
  };
};
export var calculateTaxes = function calculateTaxes(_ref2) {
  var bottleDepositTaxCents = _ref2.bottleDepositTaxCents,
    cannabisTax = _ref2.cannabisTax,
    isVapeTaxed = _ref2.isVapeTaxed,
    itemSubtotal = _ref2.itemSubtotal,
    receiptProduct = _ref2.receiptProduct,
    runningPrice = _ref2.runningPrice,
    taxConfig = _ref2.taxConfig,
    salesTax = _ref2.salesTax,
    wholesaleSubtotal = _ref2.wholesaleSubtotal,
    cartProduct = _ref2.cartProduct;
  var priceToTax = Big(0);

  // when tax config is "both," then we want to tax off of the subtotal of the item (base price * quantity)
  if (taxConfig.both) {
    priceToTax = itemSubtotal;
  }

  // When the tax config is "discounts first," we want to tax off of the discounted price
  if (taxConfig.discountsFirst) {
    priceToTax = runningPrice;
  }
  delete receiptProduct.taxes;
  var cannabisTaxCents = Big(cannabisTax(priceToTax.div(100), wholesaleSubtotal, cartProduct === null || cartProduct === void 0 ? void 0 : cartProduct.product)).times(100);
  var salesTaxCents = Big(salesTax(priceToTax, wholesaleSubtotal, cartProduct === null || cartProduct === void 0 ? void 0 : cartProduct.product));
  try {
    if (receiptProduct) {
      receiptProduct.addTax({
        type: 'bottleDeposit',
        value: bottleDepositTaxCents
      });
      receiptProduct.addTax({
        type: 'cannabis',
        value: cannabisTaxCents
      });
      receiptProduct.addTax({
        type: isVapeTaxed ? 'vape' : 'sales',
        value: salesTaxCents
      });
    }
  } catch (err) {
    // ain't no thing about adding tax to the receipt product if we're calling this from the POS side
  }
  return {
    bottleDepositTaxCents: bottleDepositTaxCents,
    cannabisTaxCents: cannabisTaxCents,
    salesTaxCents: salesTaxCents
  };
};
var getTaxConfig = function getTaxConfig(discountTaxOrder) {
  return {
    both: discountTaxOrder === 'both',
    discountsFirst: discountTaxOrder === 'discountsFirst',
    taxesFirst: discountTaxOrder === 'taxesFirst'
  };
};

// applicableRewards contains information for whether or not a special is a stacking compounding percent off DTC
// lineItems has the discountToCart percentage value
var mapDtcRewards = function mapDtcRewards() {
  var lineItems = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var applicableRewards = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var mappedRewards = {};
  var flatRewards = _.uniqBy(_.flatMap(applicableRewards), 'rewardId');
  var specialsHash = _.keyBy(_.flatMap(lineItems, function (_ref3) {
    var _specialData$bogoSpec;
    var specialData = _ref3.specialData;
    return (_specialData$bogoSpec = specialData === null || specialData === void 0 ? void 0 : specialData.bogoSpecials) !== null && _specialData$bogoSpec !== void 0 ? _specialData$bogoSpec : [];
  }), 'specialId');
  _.forEach(flatRewards, function (reward) {
    var conditionQuantity = reward.conditionQuantity,
      isSecpoDtc = reward.isSecpoDtc,
      redemptionMultiplier = reward.redemptionMultiplier,
      rewardId = reward.rewardId,
      specialId = reward.specialId,
      dollarDiscount = reward.dollarDiscount;
    if (!isSecpoDtc) {
      return;
    }
    var special = specialsHash === null || specialsHash === void 0 ? void 0 : specialsHash[specialId];
    var discountPercentage = special === null || special === void 0 ? void 0 : special.discountToCart.value;
    var specialName = special.specialName;
    mappedRewards[rewardId] = {
      discountPercentage: discountPercentage,
      redemptionMultiplier: redemptionMultiplier,
      specialId: specialId,
      specialName: specialName,
      conditionQuantity: conditionQuantity,
      dollarDiscount: dollarDiscount
    };
  });
  return mappedRewards;
};
export var prepareProductData = function prepareProductData(_ref4) {
  var cartProduct = _ref4.cartProduct,
    detailItem = _ref4.detailItem,
    receiptProduct = _ref4.receiptProduct,
    taxConfig = _ref4.taxConfig;
  var basePrice = receiptProduct.basePrice,
    _receiptProduct$disco = receiptProduct.discounts,
    discounts = _receiptProduct$disco === void 0 ? {} : _receiptProduct$disco,
    mixAndMatch = receiptProduct.mixAndMatch,
    quantity = receiptProduct.quantity,
    taxes = receiptProduct.taxes;
  var bottleDepositTaxCents = detailItem.bottleDepositTaxCents,
    cannabisTax = detailItem.cannabisTax,
    salesTax = detailItem.salesTax,
    wholesalePrice = detailItem.wholesalePrice;
  var nonArmsLength = cartProduct.product.nonArmsLength;
  var itemSubtotal = Big((mixAndMatch === null || mixAndMatch === void 0 ? void 0 : mixAndMatch.adjustedBasePrice) || basePrice).times(quantity);
  var wholesaleSubtotal = wholesalePrice.times(quantity);
  var totalTaxes = Receipt.sumSection(taxes);
  var totalDiscounts = Receipt.sumValueSection(discounts);
  var runningPrice = Big(0);
  // when taxes are first, we want to add them to running price before we apply DTC
  if (taxConfig.taxesFirst) {
    runningPrice = itemSubtotal.add(totalTaxes).minus(totalDiscounts);
  }

  // when taxes are not first, we don't want to add any taxes until after we apply DTC, so only subtract the discounts
  if (taxConfig.both || taxConfig.discountsFirst) {
    runningPrice = itemSubtotal.minus(totalDiscounts);
  }
  return {
    bottleDepositTaxCents: bottleDepositTaxCents,
    cannabisTax: cannabisTax,
    itemSubtotal: itemSubtotal,
    nonArmsLength: nonArmsLength,
    runningPrice: runningPrice,
    salesTax: salesTax,
    wholesaleSubtotal: wholesaleSubtotal
  };
};

/**
 * SECPO = Stacking Enabled Compounding Percentage Off
 *
 * DTC = discount to cart
 */
export var calculateStackingCompoundingPercentDtc = function calculateStackingCompoundingPercentDtc(_ref5) {
  var applicableRewards = _ref5.applicableRewards,
    cartProducts = _ref5.cartProducts,
    credit = _ref5.credit,
    detailItems = _ref5.detailItems,
    discountTaxOrder = _ref5.discountTaxOrder,
    receipt = _ref5.receipt,
    bottleDepositTaxCentsTotal = _ref5.bottleDepositTaxCentsTotal,
    cannabisTaxTotal = _ref5.cannabisTaxTotal,
    salesTaxTotal = _ref5.salesTaxTotal;
  var receiptProducts = receipt.details.products;
  var taxConfig = getTaxConfig(discountTaxOrder);
  var mappedRewards = mapDtcRewards(detailItems, applicableRewards);
  var totalAdditionalCredit = Big(0);
  var newTaxesCents = {
    bottleDeposit: Big(0),
    cannabis: Big(0),
    sales: Big(0)
  };
  _.forEach(detailItems, function (detailItem) {
    var cartProduct = _.find(cartProducts, ['product.id', detailItem.id]);
    var receiptProduct = _.find(receiptProducts, ['id', detailItem.id]);
    if (!cartProduct || !receiptProduct) {
      return;
    }

    // get price before applying DTC
    var productData = prepareProductData({
      cartProduct: cartProduct,
      detailItem: detailItem,
      receiptProduct: receiptProduct,
      taxConfig: taxConfig
    });
    var runningPrice = productData.runningPrice;
    var productCredit = Big(0);
    // If taxes are discounts first or both we want to recalc the taxes after applying the DTC,
    // we also don't need to recalc the taxes if the productCredit is 0, meaning there is no DTC rewards
    var _calculateDiscountsTo = calculateDiscountsToCart({
      detailItem: detailItem,
      mappedRewards: mappedRewards,
      receiptProduct: receiptProduct,
      runningPrice: runningPrice
    });
    runningPrice = _calculateDiscountsTo.runningPrice;
    productCredit = _calculateDiscountsTo.productCredit;
    if (!taxConfig.taxesFirst && productCredit.gt(0)) {
      var cannabisTaxCents;
      var salesTaxCents;
      var bottleDepositTaxCents;
      var _calculateTaxes = calculateTaxes(_objectSpread(_objectSpread({}, productData), {}, {
        isVapeTaxed: detailItem.isVapeTaxed,
        receiptProduct: receiptProduct,
        runningPrice: runningPrice,
        taxConfig: taxConfig,
        cartProduct: cartProduct,
        cannabisTax: detailItem.cannabisTax,
        salesTax: detailItem.salesTax
      }));
      bottleDepositTaxCents = _calculateTaxes.bottleDepositTaxCents;
      cannabisTaxCents = _calculateTaxes.cannabisTaxCents;
      salesTaxCents = _calculateTaxes.salesTaxCents;
      newTaxesCents.cannabis = newTaxesCents.cannabis.add(cannabisTaxCents);
      newTaxesCents.sales = newTaxesCents.sales.add(salesTaxCents);
      newTaxesCents.bottleDeposit = newTaxesCents.bottleDeposit.add(bottleDepositTaxCents);
    }
    totalAdditionalCredit = totalAdditionalCredit.add(productCredit);
  });
  var useNewTaxes = !taxConfig.taxesFirst && totalAdditionalCredit.gt(0);
  return {
    credit: credit.add(totalAdditionalCredit.div(100)),
    cannabisTaxTotal: useNewTaxes ? newTaxesCents.cannabis.div(100) : cannabisTaxTotal,
    salesTaxTotal: useNewTaxes ? newTaxesCents.sales.div(100) : salesTaxTotal,
    bottleDepositTaxCentsTotal: useNewTaxes ? newTaxesCents.bottleDeposit.div(100) : bottleDepositTaxCentsTotal
  };
};