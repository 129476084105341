import { useState } from 'react';
import { yup } from 'shared/core/yup';
import { useApolloClient } from '@apollo/react-hooks';

import { updateUser } from 'src/state/actions/users';
import { ERNIE_TYPES } from 'shared/constants';
import useErnie from 'shared/hooks/use-ernie';
import useUser from 'src/hooks/use-user';
import { ExtendedUseTranslationResponse } from 'src/hooks/use-translation';
import { birthdayRegEx } from 'src/components/forms/form-utils';
import { useCreateFraudAccountMutation } from 'types/graphql';

type CompleteAccountFormFields = {
  firstName: string;
  lastName: string;
  phone: string;
  birthday: string;
};

const completeAccountValidation = (t: ExtendedUseTranslationResponse['t']): yup.SchemaOf<CompleteAccountFormFields> =>
  yup.object().shape({
    firstName: yup.string().required(t('form.firstNameRequired', 'first name is required')),
    lastName: yup.string().required(t('form.lastNameRequired', 'last name is required')),
    phone: yup
      .string()
      .required(t('form.phoneNumberRequired', 'phone number is required'))
      .phone(t('form.validPhoneNumber', 'please enter a valid phone number')),
    birthday: yup
      .string()
      .required(t('form.birthdayRequired', 'birthday is required'))
      .isLegalAge(`MM/DD/YYYY`, t('form.isLegalAge', 'You must be at least 18 years old to use dutchie'))
      .matches(birthdayRegEx, t('form.validBirthday', 'please enter a valid birthday')),
  });

type UseCompleteAccountProps = {
  close: () => void;
};

type UseCompleteAccountReturn = {
  handleSubmit: (values: CompleteAccountFormFields) => Promise<void>;
  isLoading: boolean;
  completeAccountValidation: yup.SchemaOf<CompleteAccountFormFields>;
};

export const useCompleteAccount = ({ close }: UseCompleteAccountProps): UseCompleteAccountReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const User = useUser();
  const apolloClient = useApolloClient();
  const showErnie = useErnie();
  const [createFraudMutation] = useCreateFraudAccountMutation();

  const handleSubmit = async ({ firstName, lastName, phone, birthday }): Promise<void> => {
    setIsLoading(true);

    const updatedProfile = {
      ...User.profile,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phone,
      birthday,
    };

    const { success } = await updateUser(apolloClient, {
      profile: updatedProfile,
    });

    if (success) {
      // Call Payments service to fire create_account event in Sift once a new user has been created.
      // This call is async and does not affect the user experience, so we don't need to wait for it to complete.
      if (User.id) {
        createFraudMutation({
          variables: {
            payload: {
              userId: User.id,
              userEmail: User.email,
              name: `${String(firstName)} ${String(lastName)}`,
              phone,
              paymentMethods: [],
            },
          },
        }).catch((error) => {
          console.error('Error creating fraud account', error);
        });
      }

      User.refetch();
      showErnie('Account update successfully.', ERNIE_TYPES.SUCCESS);
      close();
    } else {
      showErnie('We were unable to save your information. Please try again.', ERNIE_TYPES.DANGER);
    }

    setIsLoading(false);
  };

  return {
    handleSubmit,
    isLoading,
    completeAccountValidation,
  };
};
