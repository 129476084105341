export const PRODUCT_GROUP_SIZES = {
  // Duplicated breakpoints for now to simplify flag/non flag logic
  grid: {
    sm: {
      breakpoint: 960,
      rowSize: 3,
      adPosition: 6,
    },
    md: {
      breakpoint: 960,
      rowSize: 3,
      adPosition: 6,
    },
    lg: {
      breakpoint: 1045,
      rowSize: 4,
      adPosition: 8,
    },
    xl: {
      breakpoint: 1240,
      rowSize: 5,
      adPosition: 10,
    },
    xxl: {
      breakpoint: 1240,
      rowSize: 5,
      adPosition: 10,
    },
  },
};

export const PRODUCT_GROUP_SIZES_V2 = {
  // Duplicated breakpoints for now to simplify flag/non flag logic
  grid: {
    sm: {
      breakpoint: 960,
      rowSize: 3,
      adPosition: 6,
    },
    md: {
      breakpoint: 960,
      rowSize: 3,
      adPosition: 6,
    },
    lg: {
      breakpoint: 1110,
      rowSize: 4,
      adPosition: 8,
    },
    xl: {
      breakpoint: 1110,
      rowSize: 4,
      adPosition: 8,
    },
    xxl: {
      breakpoint: 1110,
      rowSize: 4,
      adPosition: 8,
    },
  },
};

export const PRODUCT_GROUP_SIZES_V3 = {
  grid: {
    sm: {
      breakpoint: 364,
      rowSize: 2,
      adPosition: 4,
    },
    md: {
      breakpoint: 536,
      rowSize: 3,
      adPosition: 6,
    },
    lg: {
      breakpoint: 868,
      rowSize: 4,
      adPosition: 8,
    },
    xl: {
      breakpoint: 960,
      rowSize: 3,
      adPosition: 6,
    },
    xxl: {
      breakpoint: 1105,
      rowSize: 4,
      adPosition: 8,
    },
  },
};

export const PRODUCT_GRID_TEST_ID = 'product-grid';
