import styled from 'styled-components';
import { Typography } from 'src/typography';
import { Button } from 'src/components/core';

export const MainContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 20px;
`;

export const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Heading = styled(Typography.Heading)`
  font-weight: 600;
  text-align: center;
`;

export const GreenHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.primaryGreen};
`;

export const SubHeading = styled(Typography.Heading)`
  text-align: center;
`;

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Image = styled.img`
  height: auto;
  object-fit: contain;
  width: 100%;
`;

export const SubHeadingContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LeftColumnContainer = styled.div`
  display: flex;
`;

export const RightColumnContainer = styled.div`
  display: flex;
`;

export const IconContainer = styled.div`
  display: flex;
  margin-left: 8px;
  margin-right: 8px;
`;

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const SubheadingContentText = styled(Typography.Body)`
  color: #747677;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const LinkBankButton = styled(Button)`
  background-color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  width: 100%;
`;

export const DeclineText = styled(Typography.Body)`
  color: #646d72;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
`;
