export enum Statuses {
  none = 'none',
  loading = 'loading',
  signup = 'signup',
  terms = 'terms',
  pin = 'pin',
  incorrectPin = 'incorrectPin',
  success = 'success',
}

export enum Integrations {
  none = 'none',
  alpineiq = 'alpineiq',
  springbig = 'springbig',
  dutchiepos = 'dutchiepos',
}

export type ShowErnie = (
  msg?: string,
  type?: 'danger' | 'error' | 'info' | 'success',
  timeout?: number,
  reasonCode?: string
) => void;

export type UseLoyaltyProps = {
  initialStatus: Statuses;
  integration: Integrations;
};

export type UseLoyaltyReturn = {
  createUserAndPin: () => Promise<void>;
  status: Statuses;
  setStatus: (status: Statuses) => void;
  programName: string;
  programDescription: string;
  processPin: (pin: string) => Promise<void>;
  resendPin: () => Promise<void>;
};
