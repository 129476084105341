import React, { useEffect, useRef, ReactNode } from 'react';
import Truncate from 'react-truncate';
import styled, { css } from 'styled-components';
import TinyColor from 'tinycolor2';
import Link from 'next/link';

import { Product } from 'types/graphql-overrides';
import { ProductCardButtonOption } from 'types/graphql';

import useUI from 'src/hooks/use-ui';
import useViewportVisibility from 'src/hooks/use-viewport-visibility';
import { isWeightedProduct } from 'shared/helpers/products';

import { DiscountTag } from 'src/assets/discount-tag';
import { StarCheck } from 'src/assets/star-check';
import { EmbeddedExternalLink } from 'src/components/core';
import ProductImage from 'src/components/product-image';
import { useCard } from './use-card';

type CardProps = {
  product: Product;
  productIndex: number;
  hideSponsoredTag?: boolean;
  onClick: (product: Product, index: number) => void;
  onQuickAddClick: (product: Product, index: number) => void;
  onView?: (product: Product, index: number) => void;
  'data-testid'?: string;
};

export const Card = ({
  product,
  productIndex,
  hideSponsoredTag = false,
  onClick,
  onQuickAddClick,
  onView,
  'data-testid': testId,
}: CardProps): JSX.Element => {
  const UI = useUI();
  const { isEmbeddedCarousel } = UI;
  const { ref, hasBeenVisible } = useViewportVisibility();
  const {
    href,
    isStaffPick,
    isSponsored,
    brandName,
    strainType,
    potency,
    isSpecialOffer,
    hideDiscount,
    priceOptions,
    collectionCardBadge,
  } = useCard({
    product,
  });

  const onViewRef = useRef(onView);

  useEffect(() => {
    if (hasBeenVisible) {
      onViewRef.current?.(product, productIndex);
    }
  }, [product, productIndex, hasBeenVisible]);

  const { formattedPrice, formattedDiscount, special } = priceOptions[0];
  const displayPrice = special ? special.formattedPrice : formattedPrice;
  const standardPrice = special ? formattedPrice : null;
  const discount = special && !hideDiscount ? formattedDiscount : null;

  const numberOfOptions = product.Options?.length ?? 0;
  const buttonCopy = isWeightedProduct(product) && numberOfOptions > 1 ? `Select weight` : `Add to cart`;

  const handleLinkClick = (): void => {
    onClick(product, productIndex);
  };

  const handleAddToCart = (): void => {
    onQuickAddClick(product, productIndex);
  };

  return (
    <Wrapper ref={ref} data-testid={testId}>
      <LinkComponent href={href} handleLinkClick={handleLinkClick} isEmbeddedCarousel={isEmbeddedCarousel}>
        <div>
          <ImageWrapper>
            <ProductImage product={product} height={188} width={188} />

            {isStaffPick && (
              <StaffPick>
                <StarCheck />
                Staff Pick
              </StaffPick>
            )}
          </ImageWrapper>

          {isSponsored && !hideSponsoredTag && <Sponsored>Sponsored</Sponsored>}

          <Name>
            <Truncate lines={2}>{product.name}</Truncate>
          </Name>

          {brandName && (
            <Brand>
              <Truncate>{brandName}</Truncate>
            </Brand>
          )}
        </div>
      </LinkComponent>

      <div>
        {(strainType || potency.length > 0) && (
          <Details>
            {strainType && <Strain>{strainType}</Strain>}
            {potency.length > 0 && <Potency $isMissingStrain={!strainType}>{potency.join(' · ')}</Potency>}
          </Details>
        )}

        {(isSpecialOffer || collectionCardBadge) && (
          <Tags>
            {isSpecialOffer && (
              <SpecialOffer>
                <DiscountTag width={12} />
                Special Offer
              </SpecialOffer>
            )}

            {collectionCardBadge && (
              <CollectionBadge $color={collectionCardBadge.color}>{collectionCardBadge.title}</CollectionBadge>
            )}
          </Tags>
        )}

        <Price>
          <ActivePrice>{displayPrice}</ActivePrice>

          {discount && (
            <DiscountedPrice>
              <OriginalPrice>{standardPrice}</OriginalPrice>
              <Discount>{discount}</Discount>
            </DiscountedPrice>
          )}
        </Price>

        {isEmbeddedCarousel ? (
          <EmbeddedButton href={href} data-testid='card-button'>
            View Item
          </EmbeddedButton>
        ) : (
          <Button onClick={handleAddToCart} data-testid='card-button'>
            {buttonCopy}
          </Button>
        )}
      </div>
    </Wrapper>
  );
};

type LinkComponentProps = {
  children: ReactNode;
  isEmbeddedCarousel: boolean;
  href: string;
  handleLinkClick: () => void;
};

const LinkComponent = ({ children, isEmbeddedCarousel, href, handleLinkClick }: LinkComponentProps): JSX.Element => {
  const testId = 'card-link';

  if (isEmbeddedCarousel) {
    return (
      <EmbeddedCarouselAnchor href={href} data-testid={testId}>
        {children}
      </EmbeddedCarouselAnchor>
    );
  }

  return (
    <Link href={href}>
      <Anchor href={href} onClick={handleLinkClick} data-testid={testId}>
        {children}
      </Anchor>
    </Link>
  );
};

const Wrapper = styled.div`
  background: #fff;
  border: 1px solid #e3e7e9;
  border-radius: ${({ theme }) => theme.customized.radius.lg};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  transition: all 0.2s;
  width: 100%;

  &:hover {
    border-color: #d2d7da;
    box-shadow: 0 4px 10px 0 #0000000d;
  }
`;

const anchorStyles = css`
  display: block;

  /* Increase click area to entire card */
  &::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const Anchor = styled.a`
  ${anchorStyles}
`;

const EmbeddedCarouselAnchor = styled(EmbeddedExternalLink)`
  ${anchorStyles}
`;

const ImageWrapper = styled.div`
  aspect-ratio: 1 / 1;
  margin-bottom: 8px;
  position: relative;

  img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
  }
`;

const StaffPick = styled.div`
  align-items: center;
  background: #fff;
  border-bottom-right-radius: ${({ theme }) => theme.customized.radius.xs};
  color: ${({ theme }) => theme.customized.colors.staffPickTag};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  gap: 4px;
  left: 0;
  line-height: ${13 / 12};
  padding: 4px;
  position: absolute;
  text-transform: ${({ theme }) => theme.customized.textTransforms.tags};
  top: 0;
`;

const Sponsored = styled.div`
  color: #828a8f;
  font-size: 10px;
  line-height: 1;
  margin-bottom: 6px;
`;

const Name = styled.div`
  color: #121516;
  font-size: 14px;
  font-weight: 600;
  line-height: ${16 / 14};
`;

const Brand = styled.div`
  color: #646d72;
  font: 12px/1 ${({ theme }) => theme.customized.fonts.secondary};
  margin-top: 4px;
`;

const Details = styled.div`
  min-height: 42px;
  margin-top: 16px;
`;

const Strain = styled.div`
  background: #e3e7e9;
  border-radius: 12px;
  color: #2c3236;
  display: inline-block;
  font: 500 12px/1 ${({ theme }) => theme.customized.fonts.secondary};
  padding: 6px 8px;
`;

const Potency = styled.div`
  color: #646d72;
  font: 12px/1 ${({ theme }) => theme.customized.fonts.secondary};
  margin-top: 6px;

  ${({ $isMissingStrain }) =>
    $isMissingStrain &&
    css`
      padding-top: 24px;
    `}
`;

const Tags = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  gap: 4px;
  line-height: ${13 / 12};
  margin-top: 16px;
  text-transform: ${({ theme }) => theme.customized.textTransforms.tags};
`;

const Tag = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  width: 100%;
`;

const SpecialOffer = styled(Tag)`
  color: ${({ theme }) => theme.colors.green[40]};
`;

const CollectionBadge = styled(Tag)<{ $color: string }>`
  color: ${({ $color }) => $color};

  &::before {
    background: currentColor;
    content: '';
    height: 6px;
    margin: 0 3px;
    transform: rotate(45deg);
    width: 6px;
  }
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  margin: 16px 0 8px;
  width: 100%;
`;

const ActivePrice = styled.div`
  color: #2c3236;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
`;

const DiscountedPrice = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;
`;

const OriginalPrice = styled.div`
  color: #485055;
  font-size: 14px;
  line-height: 1;
  text-decoration: line-through;
`;

const Discount = styled.div`
  background: ${({ theme }) => theme.customized.colors.discountTag};
  border-radius: ${({ theme }) => theme.customized.radius.tags};
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.5px;
  line-height: 1;
  margin-left: 2px;
  padding: 2px 6px;
  white-space: nowrap;
`;

const tintedButtonStyles = css`
  /* Hex alpha 10% */
  background: ${({ theme }) => theme.customized.colors.buttonsLinks}1a;
  color: ${({ theme }) => TinyColor.mix(theme.customized.colors.buttonsLinks, '#000', 20).toString()};
`;

const solidButtonStyles = css`
  background: ${({ theme }) => theme.customized.colors.buttonsLinks};
  color: #fff;
`;

const solidButtonHoverStyles = css`
  background: ${({ theme }) => TinyColor.mix(theme.customized.colors.buttonsLinks, '#000', 20).toString()};
  color: #fff;
`;

const buttonStyles = css`
  appearance: none;
  border: none;
  border-radius: ${({ theme }) => theme.customized.radius.buttons};
  cursor: pointer;
  display: block;
  font-size: 13px;
  font-weight: 600;
  line-height: ${20 / 13};
  padding: 8px;
  position: relative;
  text-align: center;
  text-transform: ${({ theme }) => theme.customized.textTransforms.buttons};
  transition: all 0.2s;
  width: 100%;
  z-index: 1;

  ${({ theme }) =>
    theme.customized.colors.productCardButton === ProductCardButtonOption.tinted
      ? tintedButtonStyles
      : solidButtonStyles}

  &:hover {
    ${({ theme }) =>
      theme.customized.colors.productCardButton === ProductCardButtonOption.tinted
        ? solidButtonStyles
        : solidButtonHoverStyles}
  }
`;

const Button = styled.button`
  ${buttonStyles}
`;

const EmbeddedButton = styled(EmbeddedExternalLink)`
  ${buttonStyles}
`;
