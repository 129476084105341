import React, { forwardRef } from 'react';
import { useObserver } from 'mobx-react-lite';

import useUser from 'hooks/use-user';
import useTranslation from 'hooks/use-translation';

import { ModalContainer, ModalExitIcon } from 'components/core';
import { ModalContent } from 'components/modals/components';
import { ModalObserverRenderProps } from 'components/modals/modal-observer';
import {
  StyledHeading,
  Message,
  StyledButton,
  StyledLink,
  HorizontalDivider,
} from './account-change-verification.styles';
import { EmailSent } from './email-sent';
import { DeviceNotice } from './device-notice';

type AccountChangeVerificationModalProps = ModalObserverRenderProps & {
  email: string;
  onResend: () => Promise<void>;
};

export const AccountChangeVerificationModal = forwardRef<HTMLElement, AccountChangeVerificationModalProps>(
  ({ containerProps, onClose, email, onResend }, ref) => {
    const { t } = useTranslation();
    const User = useUser();
    const isVerificationTokenSent = useObserver(() => User.isVerificationTokenSent);

    return (
      <ModalContainer ref={ref} width={420} padding={0} {...containerProps}>
        <ModalExitIcon onClick={onClose} />
        <ModalContent pt={24} pb={37} pl={32} pr={32}>
          <StyledHeading mb={24}>{t('account.verificationRequired', 'Email Verification Required')}</StyledHeading>
          <Message size='medium'>
            {t(
              'account.verificationMessage',
              'Account changes require email verification to complete. Please check your inbox for a verification link and follow the directions in the email.'
            )}
          </Message>
          <Message size='medium'>
            Email sent to:
            <br />
            <strong>{email}</strong>
          </Message>
          <DeviceNotice />
          <HorizontalDivider />
          <Message size='medium'>
            Can't find the email? Check your spam folder or
            <br />
            <StyledLink onClick={onResend}>resend verification email</StyledLink>.
            <EmailSent isVisible={isVerificationTokenSent} />
          </Message>
          <StyledButton variant='primary' fullWidth onClick={onClose}>
            {t('common.ok', 'OK')}
          </StyledButton>
        </ModalContent>
      </ModalContainer>
    );
  }
);
