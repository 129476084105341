/* eslint-disable @typescript-eslint/naming-convention */
import SHARED_ROUTES from 'shared/routes';

export enum MARKETING_ROUTES {
  ABOUT = '/about',
  BLOG = 'https://business.dutchie.com/blog',
  BLOG_CATEGORY = 'https://business.dutchie.com/blog/category',
  BLOG_CATEGORY_SLUG = 'https://business.dutchie.com/blog/[category]/[slug]',
  BLOG_SEARCH = 'https://business.dutchie.com/blog/search',

  DISPENSARY_B2B = 'https://business.dutchie.com',
  DISPENSARY_ECOMMERCE = 'https://business.dutchie.com/ecommerce',
  DISPENSARY_FEATURES = 'https://business.dutchie.com/features',
  DISPENSARY_INTEGRATIONS = 'https://business.dutchie.com/integrations',
  DISPENSARY_PAYMENTS = 'https://business.dutchie.com/payments',
  DISPENSARY_POINT_OF_SALE = 'https://business.dutchie.com/pos',
  INTEGRATIONS = 'https://business.dutchie.com/integrations',

  MULTIPLE_LOCATIONS = 'https://business.dutchie.com/multiple-locations',
  MULTIPLE_STATE_OPERATOR = 'https://business.dutchie.com/mso',

  PLUS = '/plus',
  PLUS_DEMO = '/plus/demo',
  PLUS_DOCS = '/dutchie-plus/',

  VERTICALLY_INTEGRAGED = 'https://business.dutchie.com/vertically-integrated',
  SINGLE_DISPENSARY = 'https://business.dutchie.com/single-dispensary',

  DUTCHIE_PAY_SIGNUP = '/pay/signup',
}

export enum SHOPPING_ROUTES {
  BROWSE = '/browse',
  CATEGORIES = '/categories',
  CITY = '/city',
  CITY_CITY_KEY = '/city/[city]',
  COUNTRY_STATE_CITY = '/[countryCode]/dispensaries/[stateCodeAndCityName]',
  DISPENSARIES = '/dispensaries',
  DISPENSARY_CNAME = '/dispensary/[cName]',
  LOCATIONS = '/locations',
  PRODUCTS_CATEGORY = '/products/[category]',
  SPECIAL_ID = '/[specialId]',
  SHOP = '/shop',
  SHOP_DISPENSARY = '/shop/dispensary',
  SHOP_PRODUCTS = '/shop/products',
  SHOP_PRODUCTS_CATEGORY = '/shop/products/[category]',
}

export enum USER_ROUTES {
  ACCOUNT = '/account',
  BANK_ACCOUNT = '/account/bank-account',
  MESSAGING = '/messaging/[cName]/[orderId]/[token]',
  ORDER = '/account/orders/[orderId]',
  ORDERS = '/account/orders',
  USER = '/user',
  OAUTH_AUTO_LOGIN = '/oauth/auto-login',
}

export enum LEGAL_ROUTES {
  ACCESSIBILITY = '/accessibility',
  DISPOTERMS = '/dispensary-terms',
  TERMS = '/terms',
}

export enum MARKETPLACE_ROUTES {
  CAREERS = '/careers',
  NEWS = '/news',

  HOME = '/',

  PREFERENCES = `/account/preferences`,

  PRIVACY = '/privacy',

  ROOT = '/',
  WHOOPS = '/whoops',
}

const routes: Record<string, string> = {
  ...SHARED_ROUTES,
  ...MARKETING_ROUTES,
  ...SHOPPING_ROUTES,
  ...USER_ROUTES,
  ...LEGAL_ROUTES,
  ...MARKETPLACE_ROUTES,
};

export default routes;
