import React from 'react';
import styled from 'styled-components';

import useStyledTheme from 'shared/hooks/use-styled-theme';
import GenericCheckmark from 'assets/generic-checkmark';

type EmailSentProps = {
  isVisible: boolean;
};

export const EmailSent = ({ isVisible }: EmailSentProps): JSX.Element => {
  const theme = useStyledTheme();
  const { buttonsLinks } = theme.customized.colors;
  return (
    <EmailSentContainer $isVisible={isVisible} data-testid='sent-email-indicator'>
      <GenericCheckmark width='16.25' height='13' color={buttonsLinks} />
      <EmailSentText size='medium'>Email sent</EmailSentText>
    </EmailSentContainer>
  );
};

export const EmailSentContainer = styled.span<{ $isVisible: boolean }>`
  display: inline-flex;
  align-items: baseline;
  margin-left: 12px;
  gap: 8px;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

export const EmailSentText = styled.span`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;
