import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { ArrowImage, ArrowCss } from './select-with-label';
function isValidNewOption(inputValue, _selectValue, selectOptions) {
  var hasCaseInsensitiveExactMatch = _.some(selectOptions, function (option) {
    return _.trim(option.label.toLowerCase()) === _.trim(inputValue.toLowerCase());
  });
  return inputValue.length > 0 && selectOptions.length <= 3 && !hasCaseInsensitiveExactMatch;
}

// This is actually 3 components wrapped into 1:
// if isCreatable, return <CreatableSelect>
// else if isAsync, return <AsyncSelect>
// default return is <Select>
var ReactSelect = function ReactSelect(/** @type any */_ref) {
  var defaultValue = _ref.defaultValue,
    _ref$height = _ref.height,
    height = _ref$height === void 0 ? 45 : _ref$height,
    _ref$isAsyncCreatable = _ref.isAsyncCreatable,
    isAsyncCreatable = _ref$isAsyncCreatable === void 0 ? false : _ref$isAsyncCreatable,
    _ref$isAsyncMulti = _ref.isAsyncMulti,
    isAsyncMulti = _ref$isAsyncMulti === void 0 ? false : _ref$isAsyncMulti,
    _ref$isAsync = _ref.isAsync,
    isAsync = _ref$isAsync === void 0 ? false : _ref$isAsync,
    _ref$isLoading = _ref.isLoading,
    isLoading = _ref$isLoading === void 0 ? false : _ref$isLoading,
    _ref$loadOptions = _ref.loadOptions,
    loadOptions = _ref$loadOptions === void 0 ? _.noop : _ref$loadOptions,
    _ref$isCreatable = _ref.isCreatable,
    isCreatable = _ref$isCreatable === void 0 ? false : _ref$isCreatable,
    _ref$isMulti = _ref.isMulti,
    isMulti = _ref$isMulti === void 0 ? false : _ref$isMulti,
    _ref$isSearchable = _ref.isSearchable,
    isSearchable = _ref$isSearchable === void 0 ? true : _ref$isSearchable,
    _ref$isClearable = _ref.isClearable,
    isClearable = _ref$isClearable === void 0 ? false : _ref$isClearable,
    _ref$id = _ref.id,
    id = _ref$id === void 0 ? 'react-select' : _ref$id,
    formatCreateLabel = _ref.formatCreateLabel,
    formatOptionLabel = _ref.formatOptionLabel,
    _ref$noOptionsMessage = _ref.noOptionsMessage,
    noOptionsMessage = _ref$noOptionsMessage === void 0 ? function () {
      return 'No options';
    } : _ref$noOptionsMessage,
    onChange = _ref.onChange,
    onCreateOption = _ref.onCreateOption,
    _ref$options = _ref.options,
    options = _ref$options === void 0 ? [] : _ref$options,
    _ref$placeholder = _ref.placeholder,
    placeholder = _ref$placeholder === void 0 ? 'Select...' : _ref$placeholder,
    value = _ref.value,
    components = _ref.components,
    _ref$textAlign = _ref.textAlign,
    textAlign = _ref$textAlign === void 0 ? 'inherit' : _ref$textAlign,
    _ref$width = _ref.width,
    width = _ref$width === void 0 ? 194 : _ref$width,
    _ref$borderRadius = _ref.borderRadius,
    borderRadius = _ref$borderRadius === void 0 ? '2px' : _ref$borderRadius,
    _ref$containerStyles = _ref.containerStyles,
    containerStyles = _ref$containerStyles === void 0 ? {} : _ref$containerStyles,
    _ref$optionStyles = _ref.optionStyles,
    optionStyles = _ref$optionStyles === void 0 ? {} : _ref$optionStyles,
    _ref$customSelectedBa = _ref.customSelectedBackgroundColor,
    customSelectedBackgroundColor = _ref$customSelectedBa === void 0 ? null : _ref$customSelectedBa,
    _ref$customSelectedCo = _ref.customSelectedColor,
    customSelectedColor = _ref$customSelectedCo === void 0 ? null : _ref$customSelectedCo,
    _ref$hasError = _ref.hasError,
    hasError = _ref$hasError === void 0 ? false : _ref$hasError,
    _ref$tagPrefix = _ref.tagPrefix,
    tagPrefix = _ref$tagPrefix === void 0 ? '' : _ref$tagPrefix;
  var getOptionBgColor = function getOptionBgColor(isFocused, isSelected, isDisabled) {
    if (isSelected && customSelectedBackgroundColor) {
      return customSelectedBackgroundColor;
    }
    if (isDisabled) {
      return '#FFF';
    }
    if (isSelected) {
      return '#7A9ED5';
    }
    if (isFocused) {
      return '#F6F6F6';
    }
    return '#FFF';
  };
  var getOptionColor = function getOptionColor(isSelected, isDisabled) {
    if (isDisabled) {
      return '#C6C6C6';
    }
    if (isSelected && customSelectedColor) {
      return customSelectedColor;
    }
    if (isSelected) {
      return '#FFF';
    }
    return '#6d747b';
  };
  var styleOverrides = {
    container: function container(styles) {
      return _objectSpread(_objectSpread({}, styles), containerStyles);
    },
    control: function control(styles) {
      return _objectSpread(_objectSpread({}, styles), {}, {
        cursor: 'pointer',
        width: width,
        minHeight: height,
        boxShadow: 'none',
        borderRadius: borderRadius,
        borderColor: hasError ? '#e25241' : '#d1d5da',
        ':hover': {
          borderColor: hasError ? '#e25241' : '#d1d5da'
        },
        fontSize: '13px',
        color: '#6d747b',
        backgroundColor: '#fcfdfe',
        paddingLeft: isMulti ? '13px' : '11px'
      });
    },
    menu: function menu(styles) {
      return _objectSpread(_objectSpread({}, styles), {}, {
        zIndex: 100
      });
    },
    option: function option(styles, _ref2) {
      var isFocused = _ref2.isFocused,
        isSelected = _ref2.isSelected,
        isDisabled = _ref2.isDisabled;
      return _objectSpread(_objectSpread(_objectSpread({}, styles), optionStyles), {}, {
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        fontSize: '13px',
        textAlign: textAlign,
        backgroundColor: getOptionBgColor(isFocused, isSelected, isDisabled),
        color: getOptionColor(isSelected, isDisabled),
        ':active': _objectSpread(_objectSpread({}, styles[':active']), {}, {
          color: customSelectedColor !== null && customSelectedColor !== void 0 ? customSelectedColor : '#fff',
          backgroundColor: customSelectedBackgroundColor !== null && customSelectedBackgroundColor !== void 0 ? customSelectedBackgroundColor : '#7A9ED5'
        })
      });
    },
    singleValue: function singleValue(styles) {
      return _objectSpread(_objectSpread({}, styles), {}, {
        color: '#707478',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
        top: '1px',
        transform: 'none'
      });
    },
    multiValue: function multiValue(styles) {
      return _objectSpread(_objectSpread({}, styles), {}, {
        borderRadius: '3px',
        border: 'solid 1px #b7c2d0',
        backgroundColor: '#eaeff4',
        margin: '4px',
        ':first-of-type': {
          marginLeft: 0
        }
      });
    },
    multiValueLabel: function multiValueLabel(styles) {
      return _objectSpread(_objectSpread({}, styles), {}, {
        color: '#606f7f',
        fontSize: '13px',
        fontWeight: 'bold'
      });
    },
    multiValueRemove: function multiValueRemove(styles) {
      return _objectSpread(_objectSpread({}, styles), {}, {
        color: ' #9aa8b6',
        ':hover': {}
      });
    }
  };
  var sharedProps = {
    id: id,
    onChange: onChange,
    options: options,
    placeholder: placeholder,
    styles: styleOverrides,
    value: value
  };
  if (isCreatable) {
    var _customizedComponents = _objectSpread({
      DropdownIndicator: null
    }, components);
    return __jsx(CreatableSelect, _extends({}, sharedProps, {
      components: _customizedComponents,
      isClearable: false,
      isMulti: true
    }));
  }
  var showClearable = isClearable && !_.isEmpty(value === null || value === void 0 ? void 0 : value.value);
  var customizedComponents = isMulti ? _objectSpread({
    DropdownIndicator: null
  }, components) : _objectSpread({
    IndicatorsContainer: showClearable ? ClearIndicatorWithArrow : ArrowImage
  }, components);
  if (isAsyncCreatable) {
    return __jsx(AsyncCreatableSelect, _extends({}, sharedProps, {
      components: customizedComponents,
      createOptionPosition: "last",
      formatCreateLabel: formatCreateLabel,
      isClearable: isClearable,
      isLoading: isLoading,
      isValidNewOption: isValidNewOption,
      loadOptions: loadOptions,
      onCreateOption: onCreateOption
    }));
  }
  if (isAsyncMulti) {
    return __jsx(AsyncSelect, _extends({}, sharedProps, {
      isMulti: true,
      noOptionsMessage: noOptionsMessage,
      components: customizedComponents,
      loadOptions: loadOptions,
      isClearable: isClearable
    }));
  }
  if (isAsync) {
    return __jsx(AsyncSelect, _extends({}, sharedProps, {
      noOptionsMessage: noOptionsMessage,
      components: customizedComponents,
      loadOptions: loadOptions,
      isClearable: isClearable
    }));
  }
  return __jsx(Select, _extends({}, sharedProps, {
    components: customizedComponents,
    defaultValue: defaultValue,
    isClearable: isClearable,
    isMulti: isMulti,
    isSearchable: isSearchable,
    noOptionsMessage: noOptionsMessage,
    formatOptionLabel: formatOptionLabel,
    classNamePrefix: tagPrefix
  }));
};
export default ReactSelect;
var ClearIndicatorWithArrow = function ClearIndicatorWithArrow(_ref3) {
  var clearValue = _ref3.clearValue;
  return __jsx(ClearContainer, null, __jsx(Clear, {
    onClick: clearValue
  }, __jsx("svg", {
    viewBox: "0 0 14 14",
    xmlns: "http://www.w3.org/2000/svg",
    height: "10px",
    width: "10px",
    fill: "#797e83"
  }, __jsx("path", {
    d: "m13.71 11.55-4.55-4.55 4.55-4.55c.6-.6.17-1.13-.43-1.73s-1.13-1-1.73-.43l-4.55 4.54-4.55-4.54c-.6-.6-1.13-.17-1.73.43s-1 1.13-.43 1.73l4.54 4.55-4.54 4.55c-.6.6-.17 1.13.43 1.73s1.13 1 1.73.43l4.55-4.55 4.55 4.55c.6.6 1.13.16 1.73-.43s1.03-1.14.43-1.73z"
  }))), __jsx(Divider, null, "|"), __jsx(Arrow, null));
};
var ClearContainer = styled.div.withConfig({
  displayName: "react-select__ClearContainer",
  componentId: "sc-1bfy1uq-0"
})(["display:flex;align-items:center;justify-content:space-between;width:40px;margin-right:26px;padding-top:2px;color:#797e83;"]);
var Clear = styled.div.withConfig({
  displayName: "react-select__Clear",
  componentId: "sc-1bfy1uq-1"
})(["padding-top:1px;"]);
var Divider = styled.div.withConfig({
  displayName: "react-select__Divider",
  componentId: "sc-1bfy1uq-2"
})(["font-size:20px;"]);
var Arrow = styled.div.withConfig({
  displayName: "react-select__Arrow",
  componentId: "sc-1bfy1uq-3"
})(["&:after{", " transform:translateY(-3px);}"], ArrowCss);