import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
import { encodeQueryParams, useQueryParams } from 'use-query-params';

// See README.md for usage & API
export default function useQueryParamsHookFactory(config, defaults) {
  var _useQueryParams = useQueryParams(_objectSpread({}, config)),
    _useQueryParams2 = _slicedToArray(_useQueryParams, 2),
    allQueryParams = _useQueryParams2[0],
    setAllQueryParams = _useQueryParams2[1];
  var keys = _.keys(_objectSpread({}, config));
  var scopedQueryParams = _.pick(allQueryParams, keys);

  // Update query params scoped to this hook factory and set them to new values
  // or clear the key (i.e. undefined) if it's being set to the default
  function handleSetScopedQueryParams(queryParamsToSet) {
    var newQueryParams = _.mapValues(_.pick(queryParamsToSet, keys), function (value, key) {
      return value === defaults[key] ? undefined : value;
    });
    setAllQueryParams(newQueryParams);
  }
  function pickParamsMinusDefaults(map) {
    return _.pickBy(map, function (_value, key) {
      return !_.isEqual(map[key], defaults[key]);
    });
  }

  // Take all query params, change one of them, remove anything that's set to its default value,
  // encode for usage with <Link>
  function handleGenerateScopedQueryParams(newKey, newValue) {
    if (_.includes(keys, newKey)) {
      var queryParamsToSet = _objectSpread(_objectSpread({}, scopedQueryParams), {}, _defineProperty({}, newKey, newValue));
      var queryParamsToSetMinusDefaults = pickParamsMinusDefaults(queryParamsToSet);
      return encodeQueryParams(_objectSpread({}, config), queryParamsToSetMinusDefaults);
    }
    console.error("handleGenerateScopedQueryParams on incorrect key ".concat(newKey, ", value: ").concat(newValue));
    return {};
  }
  function handleResetScopedQueryParams() {
    handleSetScopedQueryParams(defaults);
  }
  return {
    generateQueryParams: handleGenerateScopedQueryParams,
    queryParams: scopedQueryParams,
    queryParamsMinusDefaults: pickParamsMinusDefaults(scopedQueryParams),
    resetQueryParams: handleResetScopedQueryParams,
    setQueryParams: handleSetScopedQueryParams,
    defaults: defaults
  };
}