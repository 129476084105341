import { BANK_DIGITS_MISSING, UNKNOWN_BANK } from '../constants';
import { EnrollmentBankAccount, PlaidBankAccount } from '../hooks';

type BankDetailProps = EnrollmentBankAccount | PlaidBankAccount;
export type BankDetailResponse = {
  bankName: string;
  lastDigits: string;
  bankLogo: string;
};

export const getBankDetails = (bank: BankDetailProps): BankDetailResponse => {
  const bankName = bank.bankName || UNKNOWN_BANK;
  const lastDigits = bank.lastDigits || BANK_DIGITS_MISSING;
  const bankLogo =
    'financialInstitutionLogo' in bank && !!bank.financialInstitutionLogo ? bank.financialInstitutionLogo : '';

  return {
    bankName,
    lastDigits,
    bankLogo,
  };
};
