import useCart from 'hooks/use-cart';
import { usePriceCartCalcs } from 'hooks/use-price-cart-calcs';
import { UseCartPricingReturns } from 'hooks/use-cart-pricing/use-cart-pricing.types';
import { useCartItemPricing } from './use-cart-item-pricing';

export function useCartPricing(): UseCartPricingReturns {
  const { isCalculating } = useCart();
  const priceCartEnabled = usePriceCartCalcs();
  const priceCartPricing = !isCalculating && priceCartEnabled;

  return {
    ...useCartItemPricing({ priceCartPricing }),
  };
}
