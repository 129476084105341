import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _toConsumableArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) { n[e] = r[e]; } return n; }
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import Big from 'big.js';
import { getSalesType, specialsLogger } from 'shared/helpers/specials';
import { getProductWeight } from 'shared/helpers/products';

// this function used to live in /bogo/common.js but that caused a circular dependency reference
export var getBogoIndividualDiscount = function getBogoIndividualDiscount(reward, individualPrice) {
  if (reward !== null && reward !== void 0 && reward.dollarDiscount) {
    var dollarDiscount = Big(reward.dollarDiscount || 0);
    // If the dollar discount would exceed the price of the item, return the price of the item as the discount instead
    return individualPrice.minus(dollarDiscount).gt(0) ? dollarDiscount : individualPrice;
  }
  if (reward !== null && reward !== void 0 && reward.targetPrice && Big(reward.targetPrice).lt(individualPrice)) {
    var discount = individualPrice.minus(Big(reward.targetPrice || 0));
    return discount.gt(0) ? discount : Big(0);
  }
  return individualPrice.times(Big((reward === null || reward === void 0 ? void 0 : reward.percentDiscount) || 0).div(100));
};

/*
  anytime we have a discount that is not percentage based, we want to spread
  that discount evenly over each of the products in the cart. The leftOver value
  represents the fractional cents left over from floating point math and
  we want to roll that into the next product so we don't lose them
 */
export var amortizeDollarDiscount = function amortizeDollarDiscount(couponOrReward, leftOver, applicableSubtotal, compositePrice) {
  // Take the proportional amount.  Roll fractional cents forward trying to find a home for them.
  var dollarDiscountApplicable = Big((couponOrReward === null || couponOrReward === void 0 ? void 0 : couponOrReward.dollar) || 0).times(compositePrice).div(applicableSubtotal).plus(leftOver);
  var dollarDiscount = dollarDiscountApplicable.round(2);
  return {
    amortizedDiscount: dollarDiscount,
    leftOver: dollarDiscountApplicable.minus(dollarDiscount)
  };
};

// Works for our case of a non 0 length array containing positive numbers.
var findMaxKey = function findMaxKey(hash) {
  var currentMax = Big(0),
    currentMaxIndex = -1;
  var keys = _.keys(hash);
  _.forEach(keys, function (key, ind) {
    var val = Big(hash[key]);
    if (Big(val, 'Find Max Key').gte(currentMax)) {
      currentMax = val;
      currentMaxIndex = ind;
    }
  });
  return keys[currentMaxIndex];
};
export function getSpecialSettings(bogoSpecial, globalSpecialSettings) {
  var specialSettings = globalSpecialSettings || {};
  if (bogoSpecial !== null && bogoSpecial !== void 0 && bogoSpecial.stackingBehavior) {
    specialSettings.stackingBehavior = bogoSpecial.stackingBehavior;
    specialSettings.discountStacking = bogoSpecial.discountStacking;
    specialSettings.nonStackingBehavior = bogoSpecial.nonStackingBehavior;
  }
  if (bogoSpecial !== null && bogoSpecial !== void 0 && bogoSpecial.discountPrecedence) {
    specialSettings.discountPrecedence = bogoSpecial.discountPrecedence;
  }
  return specialSettings;
}
export var prepareProductTotal = function prepareProductTotal(details) {
  var _$reduce = _.reduce(details, function (accumulator, detail) {
      var _detail$mixAndMatch;
      var productPrice = Big(((_detail$mixAndMatch = detail.mixAndMatch) === null || _detail$mixAndMatch === void 0 ? void 0 : _detail$mixAndMatch.adjustedBasePrice) || detail.basePrice).times(detail.quantity);
      accumulator.productTotal = accumulator.productTotal.add(productPrice.round(2, 1));
      accumulator.productTotalRaw = accumulator.productTotalRaw.add(productPrice);
      return accumulator;
    }, {
      productTotal: Big(0),
      productTotalRaw: Big(0)
    }),
    productTotal = _$reduce.productTotal,
    productTotalRaw = _$reduce.productTotalRaw;
  return {
    productTotal: productTotal,
    residualPrice: productTotalRaw.minus(productTotal)
  };
};

// TODO: this should probably move to ./taxes.js
/*
 * For products where it is obvious what the total is we have to make certain that the pennies
 * line up.  There is not right rounding that makes this work as the following example shows.
 * $2 product containing 37% cannabis tax and 9.9% sales tax with all of this in the POS.
 * So at checkout we compute
 * pre-tax price = 2/(1.37 + .0990) = 1.36147
 * sales-tax = (2/(1.37 + .0990))*1.37 = .50374
 * cannabis-tax = (2/(1.37 + .0990))*0.0990 = .13478
 * 1.36 + .50 + .13 = 1.99
 * A penny shy of our $2 and really obvious to the person who pulled the $2 item off the shelf.
 *
 * If we end up more then 1/2 a cent to redistribute then add the extra cents in unit
 * quantities where there is the largest residual (left over after .01).
 */
export var redistributePennies = function redistributePennies() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$cannabisTax = _ref.cannabisTax,
    cannabisTax = _ref$cannabisTax === void 0 ? 0 : _ref$cannabisTax,
    _ref$salesTax = _ref.salesTax,
    salesTax = _ref$salesTax === void 0 ? 0 : _ref$salesTax,
    _ref$price = _ref.price,
    price = _ref$price === void 0 ? 0 : _ref$price;
  cannabisTax = Big(cannabisTax, 'Redistribute Pennies');
  salesTax = Big(salesTax, 'Redistribute Pennies');
  price = Big(price, 'Redistribute Pennies');
  var residuals = {
    price: price.minus(price.round(2, 0))
  };
  if (!cannabisTax.eq(0)) {
    residuals.cannabis = cannabisTax.minus(cannabisTax.round(2, 0));
  }
  if (!salesTax.eq(0)) {
    residuals.sales = salesTax.minus(salesTax.round(2, 0));
  }
  var residualTotal = _.reduce(_.values(residuals), function (accum, val) {
    return accum.add(val);
  }, Big(0));
  var accountableResidual = residualTotal.round(2, 1);
  var pennies = accountableResidual.times(100);
  delete residuals.price; // We want to count the residual from price but not modify the price.
  var residualRemaining = _.reduce(residuals, function (accum, val) {
    return accum.add(val);
  }, Big(0)).minus(accountableResidual);
  cannabisTax = cannabisTax.round(2, 0);
  salesTax = salesTax.round(2, 0);
  while (pennies.gt(0) && _.keys(residuals).length !== 0) {
    var key = findMaxKey(residuals);
    // Divide them across the types of taxes we have available.
    var thisPass = pennies.div(_.keys(residuals).length).round(0, 3);
    // eslint-disable-next-line default-case
    switch (key) {
      case 'cannabis':
        cannabisTax = cannabisTax.add(thisPass.times(0.01));
        break;
      case 'sales':
        salesTax = salesTax.add(thisPass.times(0.01));
        break;
    }
    residuals[key] = Big(0);
    pennies = pennies.minus(thisPass);
  }
  // we've consumed the extra pennies through this process.
  return {
    cannabisTax: cannabisTax,
    salesTax: salesTax,
    residual: residualRemaining
  };
};

// this function used to live in ./sales.js but that caused a circular dependency reference
export var getSaleAdjustmentForMenuItem = function getSaleAdjustmentForMenuItem(detail) {
  if (!_.isEmpty(detail === null || detail === void 0 ? void 0 : detail.saleAdjustments)) {
    var adjustmentTotal = Big(0);
    _.forEach(detail.saleAdjustments, function (adjustment) {
      var _adjustment$discount;
      var _ref2 = (adjustment === null || adjustment === void 0 ? void 0 : (_adjustment$discount = adjustment.discount) === null || _adjustment$discount === void 0 ? void 0 : _adjustment$discount.discountData) || {},
        discount = _ref2.discount,
        percentDiscount = _ref2.percentDiscount,
        targetPrice = _ref2.targetPrice;
      // How much is this item discounted
      var individualDiscount = Big(0);
      if (targetPrice) {
        // Difference between base price and target price
        individualDiscount = Big(detail.menuBasePrice).minus(Big(discount || 0));
      } else if (percentDiscount) {
        // Discount is divided by 100 because the discount is a percentage
        individualDiscount = Big(detail.menuBasePrice).times(Big(discount || 0).div(100));
      } else {
        // Dollar discount means the discount is the discount
        individualDiscount = Big(discount || 0);
      }
      adjustmentTotal = adjustmentTotal.plus(individualDiscount.times(adjustment.count));
    });
    return adjustmentTotal;
  }
  return null;
};

// this function used to live in /bogo/common.js but that caused a circular dependency reference
var getBogoDiscountForMenuItem = function getBogoDiscountForMenuItem(_ref3) {
  var _detail$bogoSavings;
  var detail = _ref3.detail,
    specialsSettings = _ref3.specialsSettings,
    individualMenuSaleAdjustment = _ref3.individualMenuSaleAdjustment;
  var globalStackingBehavior = specialsSettings.stackingBehavior,
    globalDiscountStacking = specialsSettings.discountStacking;
  var _$reduce2 = _.reduce((detail === null || detail === void 0 ? void 0 : (_detail$bogoSavings = detail.bogoSavings) === null || _detail$bogoSavings === void 0 ? void 0 : _detail$bogoSavings.individual) || [], function (_ref4, savingsObj) {
      var _ref5;
      var discountTotal = _ref4.discountTotal,
        itemPrice = _ref4.itemPrice;
      var conditionQuantity = savingsObj.conditionQuantity,
        isDiscountToCartReward = savingsObj.isDiscountToCartReward,
        displayAsPercentDiscount = savingsObj.displayAsPercentDiscount,
        maxApplicable = savingsObj.maxApplicable,
        individualStackingBehavior = savingsObj.stackingBehavior,
        individualStackingEnabled = savingsObj.discountStacking;
      var discountStackingEnabled = (_ref5 = individualStackingEnabled !== null && individualStackingEnabled !== void 0 ? individualStackingEnabled : globalDiscountStacking) !== null && _ref5 !== void 0 ? _ref5 : false;
      var rewardStackingBehaviorIsCumulative = isCumulative(individualStackingBehavior, globalStackingBehavior);
      var totalItemsAddedPastCondition = Math.max(0, detail.quantity - conditionQuantity);
      var discountQuantity = isDiscountToCartReward ? maxApplicable : Math.min(maxApplicable, totalItemsAddedPastCondition);
      var priceToDiscountFrom = Big(detail.menuBasePrice);
      if (discountStackingEnabled && !rewardStackingBehaviorIsCumulative) {
        priceToDiscountFrom = itemPrice;
      }
      var bogoIndividualDiscount = getBogoIndividualDiscount(savingsObj, priceToDiscountFrom);
      var discountAmount = bogoIndividualDiscount.times(discountQuantity);
      if (isDiscountToCartReward) {
        _.set(detail, "bogoSavings.isDiscountToCartReward", true);
        if (displayAsPercentDiscount) {
          _.set(detail, "bogoSavings.displayAsPercentDiscount", true);
        }
      }
      return {
        discountTotal: discountTotal.add(discountAmount),
        itemPrice: itemPrice.minus(discountAmount.div(detail.quantity))
      };
    }, {
      discountTotal: Big(0),
      itemPrice: Big(detail.menuIndividualPrice).add(individualMenuSaleAdjustment)
    }),
    bogoDiscount = _$reduce2.discountTotal;
  return !_.isNil(bogoDiscount) && bogoDiscount.gt(0) ? bogoDiscount.toFixed(2) : null;
};
export var calculateMenuTotal = function calculateMenuTotal(details, specialsSettings) {
  return _.reduce(details, function (accum, detail) {
    var menuSaleAdjustment = getSaleAdjustmentForMenuItem(detail);
    if (!_.isNil(menuSaleAdjustment)) {
      _.set(detail, "menuSaleAdjustment", menuSaleAdjustment);
    }
    var individualMenuSaleAdjustment = Big(menuSaleAdjustment || 0).div(detail.quantity);
    var menuBogoDiscount = getBogoDiscountForMenuItem({
      detail: detail,
      specialsSettings: specialsSettings,
      individualMenuSaleAdjustment: individualMenuSaleAdjustment
    });
    if (!_.isNil(menuBogoDiscount)) {
      _.set(detail, "bogoSavings.menuBogoDiscount", menuBogoDiscount);
    }
    return accum.add(Big(detail.menuPrice || 0).add(menuSaleAdjustment || 0).minus(menuBogoDiscount || 0));
  }, Big(0));
};
export var getOfferRewardDiscountType = function getOfferRewardDiscountType(offerReward) {
  if (offerReward.dollarDiscount) {
    return 'dollarDiscount';
  }
  if (offerReward.targetPrice) {
    return 'targetPrice';
  }
  // default to percent discount
  return 'percentDiscount';
};
export var getSaleDiscountType = function getSaleDiscountType(saleSpecial, product) {
  var saleDiscount = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var discountType;

  // 3.5 specials should have saleDiscount and discountType defined
  if (saleDiscount !== null && saleDiscount !== void 0 && saleDiscount.discountType && !_.isNil(saleDiscount.discountType)) {
    var discountTypeHash = {
      dollarDiscount: 'dollarDiscount',
      targetPrice: 'targetPrice',
      percentDiscount: 'percentDiscount',
      // i think these are just early enum typos?
      // AMOUNTOFF: 'dollarDiscount',
      // DISCOUNTEDPRICE: 'targetPrice',
      // PERCENTDISCOUNT: 'percentDiscount',
      // these are defined over on the dart calcs side
      AMOUNT_OFF: 'dollarDiscount',
      DISCOUNTED_PRICE: 'targetPrice',
      PERCENT_OFF: 'percentDiscount'
    };
    return discountTypeHash[saleDiscount.discountType];
  }

  // < 3.5 specials - Check for blended sale discount types
  if (product && !_.isEmpty(saleSpecial.specialRestrictions)) {
    var salesType = getSalesType(product);
    // eslint-disable-next-line consistent-return
    _.forEach(saleSpecial.specialRestrictions, function (restriction, key) {
      var _key$split;
      var enterpriseKey = key !== null && key !== void 0 && key.startsWith('EPID_') ? (_key$split = key.split('EPID_')) === null || _key$split === void 0 ? void 0 : _key$split[1] : key;
      if (_.includes(salesType, key) || _.includes(salesType, enterpriseKey)) {
        discountType = restriction === null || restriction === void 0 ? void 0 : restriction.discountType;
        return false; // break early
      }
    });
  }
  if (!discountType) {
    discountType = 'percentDiscount';
    if (saleSpecial.dollarDiscount) {
      discountType = 'dollarDiscount';
    } else if (saleSpecial.targetPrice) {
      discountType = 'targetPrice';
    }
  }
  return discountType;
};
export var sortSpecials = function sortSpecials(specials, product) {
  return _.sortBy(specials, function (special) {
    var specialKey = special.sourceId || special.specialId || special._id;
    var specialTypeKey = special.specialType === 'bogo' ? 1 : 0;
    var stackingKey = (special === null || special === void 0 ? void 0 : special.stackingMode) === 'stacking' || special.discountStacking === true ? 1 : 0;
    var saleDiscount = _.find(special.saleDiscounts, function (sale) {
      return _.includes(sale.productIds, product._id);
    });
    var discountType = specialTypeKey === 1 ? getOfferRewardDiscountType(special) : getSaleDiscountType(special, product, saleDiscount);
    var discountKey = 1; // percent discounts
    if (discountType === 'dollarDiscount') {
      discountKey = 0;
    } else if (discountType === 'targetPrice') {
      discountKey = 2;
    }
    return "".concat(specialTypeKey, "_").concat(stackingKey, "_").concat(discountKey, "_").concat(specialKey);
  });
};
export var findAPlaceForResidualPennies = function findAPlaceForResidualPennies(avoidTaxes, productTotal, residualPrice, cannabisTaxTotal, salesTaxTotal, receipt) {
  /*
   * Since we do taxes on a product by product basis we end up with bits of pennies between
   * products that might add up in the end.  If we didn't try to break out taxes and have
   * products which can be on special and have those taxes taken at various points then we
   * could just compute a total and take any odd pennies not directly associated with a product
   * and apply them.  But with our varying treatment of when taxes are shown and how specials are
   * dealt with we just keep track of the residuals and add them back here.
   *
   * Note we should probably really distribute whatever extra pennies we have proportionally between
   * sales and excise tax.  There really shouldn't be more then +/- 1 so just tack it onto the cannabis
   * tax which is likely to be the largest one anyways.
   */
  if (avoidTaxes) {
    // If we are in the odd taxesFirst and we have taxes on the menu case then we don't
    // have separate taxes.  Just add them to the product total.
    productTotal = productTotal.add(residualPrice.round(2, 1));
  } else {
    // Don't create a tax category by adding a bit of residual to 0.
    // eslint-disable-next-line no-lonely-if
    if (cannabisTaxTotal.gt(0)) {
      cannabisTaxTotal = cannabisTaxTotal.add(residualPrice.round(2, 1));
    } else if (salesTaxTotal.gt(0)) {
      salesTaxTotal = salesTaxTotal.add(residualPrice.round(2, 1));
    }
    // If we couldn't find a place for it in taxes then stacking it on top of the
    // product total can only create something odd like a product you thought
    // was 48 but came out as 47.99 because of underflow on a discount calculation.
  }

  // Find some taxes to put the residual cents again.  Do this by sorting the
  // taxes and then applying cents from the largest downwards.
  var cents = residualPrice.times(100).round(0);
  if (!cents.eq(0)) {
    var taxValues = _.reverse(_.sortBy(_.filter(_.compact(_.flatMap(receipt.details.products, function (detail) {
      var _detail$taxes, _detail$taxes2;
      return [(_detail$taxes = detail.taxes) === null || _detail$taxes === void 0 ? void 0 : _detail$taxes.sales, (_detail$taxes2 = detail.taxes) === null || _detail$taxes2 === void 0 ? void 0 : _detail$taxes2.cannabis];
    })), function (val) {
      return !val.eq(0);
    }), function (val) {
      return Number(val);
    }));
    if (taxValues.length > 0) {
      var increment = Number(cents.div(taxValues.length).round(0, 3));
      cents = Number(cents);
      while (cents !== 0) {
        var value = taxValues.shift();
        var found = false;
        // eslint-disable-next-line no-restricted-syntax
        var _iterator = _createForOfIteratorHelper(receipt.details.products),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var product = _step.value;
            // eslint-disable-next-line no-restricted-syntax
            for (var _i = 0, _arr = ["cannabis", "sales"]; _i < _arr.length; _i++) {
              var tax = _arr[_i];
              product.taxes = product.taxes || {};
              if (product.taxes[tax] === value) {
                var amount = Math.min(increment, cents);
                product.taxes[tax] = product.taxes[tax].plus(amount);
                cents -= amount;
                found = true;
                break;
              }
            }
            if (found) {
              break;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    }
    // Same as above.  If we couldn't find a place for this cent in the taxes then there
    // really isn't a reasonable place to put it.
  }
  return {
    productTotal: productTotal,
    cannabisTaxTotal: cannabisTaxTotal,
    salesTaxTotal: salesTaxTotal
  };
};
export var calculateManualDiscounts = function calculateManualDiscounts(manualDiscount, total, receipt) {
  var details = receipt.details,
    taxConfig = receipt.taxConfig;
  var discountTaxOrder = taxConfig.discountTaxOrder;
  var _prepareProductTotal = prepareProductTotal(_toConsumableArray(details.products)),
    productTotal = _prepareProductTotal.productTotal;
  var productTotalInDollars = Big(productTotal, "product total").div(100);
  var newCredit;
  if (manualDiscount.fixedDiscountInCents) {
    var discount = Big(manualDiscount.fixedDiscountInCents, "fixed discount").div(100);
    newCredit = discount.gt(total) ? total : discount;
  } else if (discountTaxOrder !== "discountsFirst" && discountTaxOrder !== "both") {
    newCredit = total.times(Big(manualDiscount.percentDiscount, "manual percent discount").div(100)).round(2, 2);
  } else {
    newCredit = Big(productTotalInDollars).times(Big(manualDiscount.percentDiscount, "manual percent discount").div(100)).round(2, 2);
  }
  receipt.addDiscount({
    type: "manual",
    value: newCredit.times(100)
  });
  return {
    newCredit: newCredit
  };
};
export var calculateTip = function calculateTip(shouldApplyTip, tipValue, negativePosttaxSubtotal, altBasisForZeroedSubtotal, posttaxSubtotal) {
  var tip = Big(0);
  if (shouldApplyTip) {
    if (_.isArray(tipValue)) {
      var _tipValue = tipValue;
      var _tipValue2 = _slicedToArray(_tipValue, 1);
      tipValue = _tipValue2[0];
    }
    if (tipValue.percent) {
      // Use product total instead of subtotal, if a discount has brought the subtotal to a negative
      // value and we zeroed it above.
      tip = Big(tipValue.percent, 'Tip Value Percent').times(negativePosttaxSubtotal ? altBasisForZeroedSubtotal : posttaxSubtotal);
    } else if (tipValue.dollar) {
      tip = Big(tipValue.dollar, 'Tip Value Dollar');
    }
  }
  return tip;
};
export var calculatePostTaxSubtotal = function calculatePostTaxSubtotal(productTotal, salesTaxTotal, cannabisTaxTotal, bottleDepositTaxCentsTotal, credit) {
  var posttaxSubtotal = productTotal.plus(salesTaxTotal).plus(cannabisTaxTotal).plus(bottleDepositTaxCentsTotal.div(100)).minus(credit);

  // handle edge case where a coupon discounts that exceed the subtotal results in a negative subtotal
  var negativePosttaxSubtotal = posttaxSubtotal.lt(0);
  if (negativePosttaxSubtotal) {
    posttaxSubtotal = new Big(0);
  }
  return {
    posttaxSubtotal: posttaxSubtotal,
    negativePosttaxSubtotal: negativePosttaxSubtotal
  };
};
export var orderProductsForDiscountBehavior = function orderProductsForDiscountBehavior() {
  var products = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var specialsSettings = arguments.length > 1 ? arguments[1] : undefined;
  var discountStacking = specialsSettings.discountStacking,
    stackingBehavior = specialsSettings.stackingBehavior,
    nonStackingBehavior = specialsSettings.nonStackingBehavior;
  var discountBehavior = discountStacking ? stackingBehavior : nonStackingBehavior;
  if (!discountBehavior) {
    // this will be hit when an individual special has no discount behavior
    // in that case we want to preserve the order for later calcs
    return products;
  }
  // the order of the products will determine which is discounted so if favor customer
  // is enabled we want the most expensive products first and so the larger discount will
  // be applied
  return _.sortBy(products, function (_ref6) {
    var _ref6$menuIndividualP = _ref6.menuIndividualPrice,
      menuIndividualPrice = _ref6$menuIndividualP === void 0 ? 0 : _ref6$menuIndividualP;
    return discountBehavior === 'favorCustomer' ? -Number(menuIndividualPrice) : Number(menuIndividualPrice);
  });
};
export var orderProductsForDiscountPrecedence = function orderProductsForDiscountPrecedence() {
  var products = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var specialsSettings = arguments.length > 1 ? arguments[1] : undefined;
  var context = arguments.length > 2 ? arguments[2] : undefined;
  var discountPrecedence = specialsSettings.discountPrecedence;
  if (!discountPrecedence) {
    // this will be hit when an individual special has no discount precedence
    // in that case we want to preserve the order for later calcs
    return products;
  }
  if (context === 'rewards') {
    return _.sortBy(products, function (_ref7) {
      var _ref7$menuIndividualP = _ref7.menuIndividualPrice,
        menuIndividualPrice = _ref7$menuIndividualP === void 0 ? 0 : _ref7$menuIndividualP;
      return discountPrecedence === 'highToLow' || discountPrecedence === 'mostExpensiveItem' ? -Number(menuIndividualPrice) : Number(menuIndividualPrice);
    });
  }

  // context === 'conditions'
  return _.sortBy(products, function (_ref8) {
    var _ref8$menuIndividualP = _ref8.menuIndividualPrice,
      menuIndividualPrice = _ref8$menuIndividualP === void 0 ? 0 : _ref8$menuIndividualP;
    return discountPrecedence === 'highToLow' || discountPrecedence === 'mostExpensiveItem' ? Number(menuIndividualPrice) : -Number(menuIndividualPrice);
  });
};
export var getGlobalSpecialsSettings = function getGlobalSpecialsSettings(dispensary) {
  if (_.isNil(dispensary.specialsSettings)) {
    return {
      discountBehavior: 'compounding',
      // 10/21/2022 This is deprecated and will be removed in the future
      discountStacking: true,
      discountPrecedence: 'highToLow',
      stackingBehavior: 'compounding',
      nonStackingBehavior: 'favorCustomer'
    };
  }
  var specialsSettings = dispensary.specialsSettings;
  var stackingBehavior = specialsSettings.discountStacking === true ? specialsSettings.stackingBehavior || specialsSettings.discountBehavior : 'cumulative'; // 'cumulative' is the default when discount stacking is disabled

  var nonStackingBehavior = dispensary.specialsSettings.discountStacking === false ? specialsSettings.nonStackingBehavior || specialsSettings.discountBehavior : specialsSettings.nonStackingBehavior || 'favorCustomer'; // 'favorCustomer' is the default

  var discountPrecedence = specialsSettings.discountPrecedence || (nonStackingBehavior === 'favorCustomer' ? 'highToLow' : 'lowToHigh');
  return {
    discountBehavior: specialsSettings.discountBehavior,
    stackingBehavior: stackingBehavior,
    nonStackingBehavior: nonStackingBehavior,
    discountStacking: specialsSettings.discountStacking,
    discountPrecedence: discountPrecedence
  };
};
export var isCumulative = function isCumulative(individualStackingBehavior, globalStackingBehavior) {
  if (individualStackingBehavior) {
    return individualStackingBehavior === 'cumulative';
  }
  return globalStackingBehavior === 'cumulative';
};
export var getRemainingRewardsQuantity = function getRemainingRewardsQuantity(bogoRewards) {
  return _.reduce(bogoRewards, function (obj, curr) {
    var _curr$quantity, _curr$quantity2;
    if (!obj.total) {
      obj.total = 0;
    }
    obj.total += (_curr$quantity = curr.quantity) !== null && _curr$quantity !== void 0 ? _curr$quantity : 0;
    if (!obj[curr._id]) {
      obj[curr._id] = 0;
    }
    obj[curr._id] += (_curr$quantity2 = curr.quantity) !== null && _curr$quantity2 !== void 0 ? _curr$quantity2 : 0;
    return obj;
  }, {});
};
export var compareRewards = function compareRewards(satisfiers, inProgress) {
  var fullySatisfiedReward = null; // reward_id/key
  var newSatisfiers = _.cloneDeep(satisfiers);
  _.forEach(inProgress, function (reward, rewardId) {
    if (reward.satisfied && !fullySatisfiedReward) {
      fullySatisfiedReward = rewardId;
    }
  });
  if (!fullySatisfiedReward && !_.isEmpty(inProgress)) {
    // pick satisfiers of first product. (already ordered for precedence)
    var _$keys = _.keys(inProgress);
    var _$keys2 = _slicedToArray(_$keys, 1);
    fullySatisfiedReward = _$keys2[0];
  }
  // We have the ID of the winning reward,
  // We will loop through the satisfiers/items.rewards for that key and remove others,
  // if the rewards object is empty then I remove the satisfier
  _.forEach(newSatisfiers, function (item, itemId) {
    newSatisfiers[itemId] = _objectSpread(_objectSpread({}, newSatisfiers[itemId]), {}, {
      rewards: _objectSpread({}, _.pick(newSatisfiers[itemId].rewards, fullySatisfiedReward))
    });
    if (_.isEmpty(newSatisfiers[itemId].rewards)) {
      delete newSatisfiers[itemId];
    }
  });
  return newSatisfiers;
};
export var logWholesaleDiscount = function logWholesaleDiscount(_ref9) {
  var calculationsLogger = _ref9.calculationsLogger,
    credit = _ref9.credit;
  var wholesaleDiscount = Number(credit);
  if (wholesaleDiscount > 0) {
    var logMessage = "Wholesale discount from ".concat(calculationsLogger ? 'server' : 'client');
    var logData = {
      wholesaleDiscount: wholesaleDiscount
    };
    specialsLogger({
      logger: calculationsLogger,
      data: {
        message: logMessage,
        data: logData
      }
    });
  }
};

/**
 *
 * @param {MapperLineItem} cart
 * @param {string} deliveryOption
 * @param {boolean} medicalOrder
 * @param userPosId
 * @param dispensary_id
 * @returns {{customerTypeId: (number), isDelivery: boolean, cart: *[]}}
 */
export var mapInputsForPriceCartAPI = function mapInputsForPriceCartAPI(_ref10) {
  var cart = _ref10.cart,
    _ref10$address = _ref10.address,
    address = _ref10$address === void 0 ? {} : _ref10$address,
    deliveryOption = _ref10.deliveryOption,
    medicalOrder = _ref10.medicalOrder,
    userPosId = _ref10.userPosId,
    dispensaryId = _ref10.dispensaryId,
    paymentMethod = _ref10.paymentMethod,
    couponCode = _ref10.couponCode,
    appliedRewards = _ref10.appliedRewards;
  var cartItems = [];
  var rewardRedemptions = _.compact(_.map(appliedRewards, function (reward) {
    return reward === null || reward === void 0 ? void 0 : reward.discountId;
  }));
  var mappedPaymentId = PAYMENT_METHOD_MAPPING[paymentMethod];
  var paymentMethods = mappedPaymentId ? [mappedPaymentId] : [];
  _.forEach(cart, function (item) {
    var _item$product, _item$product$POSMeta, _childProduct$canonic, _childProduct$package;
    var cartItem = {};
    // We have to check for the equivalent option in product.POSMetaData.children due to the parent/child issue that
    // results as a side effect of MenuConnector's combined ecomm product mapping for multiple weighted POS products
    var childProduct = _.find(((_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$POSMeta = _item$product.POSMetaData) === null || _item$product$POSMeta === void 0 ? void 0 : _item$product$POSMeta.children) || [], {
      option: item.option
    });
    cartItem.productId = Number((_childProduct$canonic = childProduct === null || childProduct === void 0 ? void 0 : childProduct.canonicalID) !== null && _childProduct$canonic !== void 0 ? _childProduct$canonic : item === null || item === void 0 ? void 0 : item.POSMetaData.canonicalID);
    // packageQuantity means pos packages for that given weight ie: product A: 1g, 2g, 7g would have packageQuantity each of 1, 2, 7 (because 1g is the base weight/package)
    // We check for packageQuantity because that shows it's on a pricing tier, so we multiply the quantity by the package quantity
    cartItem.quantity = childProduct !== null && childProduct !== void 0 && childProduct.packageQuantity ? (childProduct === null || childProduct === void 0 ? void 0 : childProduct.packageQuantity) * item.quantity : item.quantity;
    cartItem.unitPrice = item.basePrice;
    cartItem.unitWeightGrams = getProductWeight(item.option, 0, 1) / ((_childProduct$package = childProduct === null || childProduct === void 0 ? void 0 : childProduct.packageQuantity) !== null && _childProduct$package !== void 0 ? _childProduct$package : 1);
    cartItems.push(cartItem);
  });
  return {
    cart: cartItems,
    isDelivery: deliveryOption === 'delivery',
    deliveryState: address === null || address === void 0 ? void 0 : address.state,
    deliveryCity: address === null || address === void 0 ? void 0 : address.city,
    deliveryPostalCode: (address === null || address === void 0 ? void 0 : address.zipcode) || (address === null || address === void 0 ? void 0 : address.zip),
    deliveryStreet: (address === null || address === void 0 ? void 0 : address.ln1) || (address === null || address === void 0 ? void 0 : address.street1),
    deliveryStreet2: (address === null || address === void 0 ? void 0 : address.ln2) || (address === null || address === void 0 ? void 0 : address.street2),
    customerId: userPosId,
    customerTypeId: medicalOrder ? 1 : 2,
    dispensaryId: dispensaryId,
    rewardRedemptions: rewardRedemptions,
    paymentMethods: paymentMethods,
    codeRedemptions: couponCode ? [couponCode] : []
  };
};
var getMatchingCartItem = function getMatchingCartItem(_ref11) {
  var _item$product2, _item$product2$POSMet;
  var priceCartOutput = _ref11.priceCartOutput,
    item = _ref11.item,
    logger = _ref11.logger;
  var childProduct = _.find((item === null || item === void 0 ? void 0 : (_item$product2 = item.product) === null || _item$product2 === void 0 ? void 0 : (_item$product2$POSMet = _item$product2.POSMetaData) === null || _item$product2$POSMet === void 0 ? void 0 : _item$product2$POSMet.children) || [], {
    option: item.option
  });
  if (!childProduct) {
    logger.error("No child product found for product ".concat(item.product.id));
    return; // Skip this item
  }
  var matchingCartItems = _.filter(priceCartOutput.cartItemPrices, function (cartItem) {
    return cartItem.productId === Number(childProduct === null || childProduct === void 0 ? void 0 : childProduct.canonicalID);
  });
  if (_.isEmpty(matchingCartItems)) {
    logger.error("No matching cart items found for product ".concat(childProduct === null || childProduct === void 0 ? void 0 : childProduct.canonicalID));
    return; // Skip this item
  }
  if (matchingCartItems.length === 1) {
    return matchingCartItems[0];
  }
  var expectedWeight = getProductWeight(item === null || item === void 0 ? void 0 : item.option, 0, 1);

  // Try to find exact weight match first
  var priceCartOutputItem = _.find(matchingCartItems, function (cartItem) {
    var _childProduct$package2;
    var packageQuantity = (_childProduct$package2 = childProduct === null || childProduct === void 0 ? void 0 : childProduct.packageQuantity) !== null && _childProduct$package2 !== void 0 ? _childProduct$package2 : 1;
    if (cartItem.productType === 'Quantity') {
      return Big(cartItem.unitWeightGrams * packageQuantity || 0).eq(Big(expectedWeight || 0));
    } else {
      return Big(cartItem.unitWeightGrams || 0).eq(Big(expectedWeight || 0));
    }
  });

  // If still no match or weight is 0/undefined, use first item as fallback
  if (!priceCartOutputItem) {
    var _priceCartOutputItem;
    priceCartOutputItem = matchingCartItems[0];
    logger.error("Defaulting to first matching item for product ".concat(childProduct === null || childProduct === void 0 ? void 0 : childProduct.canonicalID, ". ") + "Expected weight: ".concat(expectedWeight, "g, using weight: ").concat((_priceCartOutputItem = priceCartOutputItem) === null || _priceCartOutputItem === void 0 ? void 0 : _priceCartOutputItem.unitWeightGrams, "g"));
  }
  return priceCartOutputItem;
};
export var updateReceiptFromPriceCartAPI = function updateReceiptFromPriceCartAPI(_ref12) {
  var _tipValue$dollar;
  var priceCartOutput = _ref12.priceCartOutput,
    receipt = _ref12.receipt,
    cartItems = _ref12.cartItems,
    tipValue = _ref12.tipValue,
    atCheckout = _ref12.atCheckout,
    logger = _ref12.logger,
    details = _ref12.details;
  var pricingTierTotalPerOrder = Big(0);
  var feeTotals = {
    delivery: Big(0),
    payment: Big(0),
    other: Big(0)
  };
  var discountIncentives = [];
  var rewardDiscount = Big(0);
  var couponOutput = null;
  var credit = Big(0);
  var includeSalesTax = false;
  var includeCannabisTax = false;
  var includeBottleDepositTax = false;
  _.forEach(cartItems, function (item) {
    var _priceCartOutputItem$;
    var priceCartOutputItem = getMatchingCartItem({
      priceCartOutput: priceCartOutput,
      item: item,
      logger: logger
    });
    if (!priceCartOutputItem) {
      throw new Error("Price-cart product mismatch: ".concat(item.product.id));
    }
    // details keys have an underscore while the item key has a hyphen, idk why man
    var detailItem = _.find(details, function (d) {
      return "".concat(d.id, "-").concat(d.option) === item.key;
    });
    var discounts = priceCartOutputItem.discounts,
      summaryPrice = priceCartOutputItem.summaryPrice,
      menuPrice = priceCartOutputItem.menuPrice;
    var quantity = item.quantity;
    var price = atCheckout ? Big(summaryPrice, "No Summary Price: ".concat(priceCartOutputItem.productId)) : Big(menuPrice, "No Menu Price: ".concat(priceCartOutputItem.productId));
    var basePrice = price.div(quantity !== null && quantity !== void 0 ? quantity : 1);
    var pricingTierTotal = Big((_priceCartOutputItem$ = priceCartOutputItem === null || priceCartOutputItem === void 0 ? void 0 : priceCartOutputItem.pricingTierAdjustmentTotal) !== null && _priceCartOutputItem$ !== void 0 ? _priceCartOutputItem$ : 0);
    var originalBasePrice = basePrice.plus(pricingTierTotal.div(quantity !== null && quantity !== void 0 ? quantity : 1));

    // add products to receipt
    var receiptDetail = receipt.addProduct(item.product.id, item.quantity, basePrice, item.option);

    // add pricing tier adjustments to receipt
    pricingTierTotalPerOrder = pricingTierTotalPerOrder.plus(pricingTierTotal);
    receiptDetail.addMixAndMatch({
      type: "originalBasePrice",
      value: originalBasePrice.times(quantity)
    });
    receiptDetail.addMixAndMatch({
      type: "adjustedBasePrice",
      value: basePrice.times(quantity)
    });
    receiptDetail.addMixAndMatch({
      type: "total",
      value: pricingTierTotal
    });

    // add discounts to receipt
    var menuBogoDiscount = Big(0);
    var individual = [];
    var includesBogo = false;
    var reducedDiscounts = _.reduce(discounts, function (accum, discount) {
      if (discount !== null && discount !== void 0 && discount.isPaymentIncentive) {
        discountIncentives.push(discount);
      } else if (!accum[discount.discountId]) {
        accum[discount.discountId] = discount;
      } else {
        var amount = Big(accum[discount.discountId].amount).add(discount.amount).toFixed(2);
        accum[discount.discountId] = _objectSpread(_objectSpread({}, accum[discount.discountId]), {}, {
          amount: amount
        });
      }
      return accum;
    }, {});
    _.forEach(reducedDiscounts, function (discount) {
      var discountType = getDiscountType({
        discount: discount,
        specialData: item.product.specialData
      });
      if (discountType === 'unknown') {
        logger.error("Unknown Discount Type for ".concat(discount.discountId, "."));
        return;
      }
      if (discountType === 'reward') {
        rewardDiscount = rewardDiscount.plus(Big(discount.amount));
        receiptDetail.addReward({
          type: _.toLower(discount.applicationMethod),
          id: discount.discountId,
          name: discount.name,
          value: Big(discount.amount)
        });
      } else {
        credit = credit.plus(discount.amount);
        receiptDetail.addDiscount({
          type: discountType,
          value: Big(discount.amount),
          id: discount.discountId,
          name: discount.name
        });
        if (discountType === 'bogo') {
          includesBogo = true;
          menuBogoDiscount = menuBogoDiscount.add(discount.amount);
          individual.push({
            specialId: discount.discountId,
            displayAsPercentDiscount: false,
            dollarDiscount: true,
            discountAmount: discount.amount
          });
        } else if (discountType === 'coupon') {
          couponOutput = discount;
        }
      }
    });
    if (includesBogo) {
      _.set(detailItem, "bogoSavings.menuBogoDiscount", Number(menuBogoDiscount));
      _.set(detailItem, "bogoSavings.individual", individual);
      _.set(detailItem, "bogoSavings.total", Number(menuBogoDiscount));
    }
  });

  // add fees to receipt
  _.forEach(priceCartOutput.fees, function (fee) {
    var feeAmount = Big(fee.feeAmount, "No Fee Amount for: ".concat(fee.feeName));
    if (fee !== null && fee !== void 0 && fee.deliveryFee) {
      receipt.addFee({
        type: 'delivery',
        value: feeAmount
      });
      feeTotals.delivery = feeTotals.delivery.plus(feeAmount);
    } else if (fee !== null && fee !== void 0 && fee.paymentFee) {
      // feePaymentMethod is an id that maps to a payment method 1:dutchie pay, 2:PIN Debit, 3:Credit Card
      receipt.addFee({
        type: 'payment',
        value: feeAmount
      });
      feeTotals.payment = feeTotals.payment.plus(feeAmount);
    } else {
      receipt.addFee({
        type: 'other',
        value: feeAmount
      });
      feeTotals.other = feeTotals.other.plus(feeAmount);
    }
  });

  // add tip to receipt
  receipt.addFee({
    type: 'tip',
    value: Big((_tipValue$dollar = tipValue === null || tipValue === void 0 ? void 0 : tipValue.dollar) !== null && _tipValue$dollar !== void 0 ? _tipValue$dollar : 0).times(100)
  });

  // add taxes to receipt
  _.forEach(priceCartOutput.itemTaxes, function (tax) {
    if (!includeTaxInCalculations(tax, atCheckout)) {
      return;
    }
    if (tax.taxType === 'sales') {
      includeSalesTax = true;
    } else if (tax.taxType === 'cannabis') {
      includeCannabisTax = true;
    } else if (tax.taxType === 'bottleDeposit') {
      includeBottleDepositTax = true;
    }
  });
  receipt.addTax({
    type: 'sales',
    value: Big(priceCartOutput.totalSalesTax, "No totalSalesTax")
  });
  receipt.addTax({
    type: 'cannabis',
    value: Big(priceCartOutput.totalCannabisTax, "No totalCannabisTax")
  });
  receipt.addTax({
    type: 'bottleDeposit',
    value: Big(priceCartOutput.totalBottleDepositTax, "No totalBottleDepositTax")
  });
  return {
    pricingTierTotalPerOrder: pricingTierTotalPerOrder,
    feeTotals: feeTotals,
    discountIncentives: discountIncentives,
    rewardDiscount: rewardDiscount,
    couponOutput: couponOutput,
    credit: credit,
    taxInclusions: {
      includeSalesTax: includeSalesTax,
      includeCannabisTax: includeCannabisTax,
      includeBottleDepositTax: includeBottleDepositTax
    }
  };
};
var includeTaxInCalculations = function includeTaxInCalculations(tax, atCheckout) {
  if (tax.showOnCheckout && tax.showOnMenu) {
    return true;
  }
  if (tax.showOnCheckout && atCheckout) {
    return true;
  }
  if (tax.showOnMenu && !atCheckout) {
    return true;
  }
  return false;
};
var getDiscountType = function getDiscountType(_ref13) {
  var _specialData$saleSpec, _specialData$bogoSpec;
  var discount = _ref13.discount,
    specialData = _ref13.specialData;
  if ((discount === null || discount === void 0 ? void 0 : discount.applicationMethod) === 'Code') return 'coupon';
  if (discount !== null && discount !== void 0 && discount.isLoyaltyReward) return 'reward';
  var saleIds = _.map((_specialData$saleSpec = specialData === null || specialData === void 0 ? void 0 : specialData.saleSpecials) !== null && _specialData$saleSpec !== void 0 ? _specialData$saleSpec : [], 'sourceId');
  var bogoIds = _.map((_specialData$bogoSpec = specialData === null || specialData === void 0 ? void 0 : specialData.bogoSpecials) !== null && _specialData$bogoSpec !== void 0 ? _specialData$bogoSpec : [], 'sourceId');
  if (_.includes(saleIds, discount === null || discount === void 0 ? void 0 : discount.discountId.toString())) {
    return 'sale';
  } else if (_.includes(bogoIds, discount === null || discount === void 0 ? void 0 : discount.discountId.toString())) {
    return 'bogo';
  }
  return 'unknown';
};
var PAYMENT_METHOD_MAPPING = {
  'Pay by Bank': 1,
  dutchiePay: 1,
  debitCard: 2,
  creditCard: 3
};

// this compares the costBreakdown of each set of calcs to gather the diffs so we can log it
export var calcDifference = function calcDifference() {
  var ecommClacs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var priceCartCalcs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var diffs = {};
  var ecommKeys = _.keys(ecommClacs);
  var priceCartKeys = _.keys(priceCartCalcs);
  var allKeys = _.union(ecommKeys, priceCartKeys);
  _.forEach(allKeys, function (key) {
    var ecommValue = ecommClacs[key];
    var priceCartValue = priceCartCalcs[key];
    // we want this value to be able to be negative so we can see where the difference is at a glance
    // if totalDiff is negative, ecomm value is higher, if positive, priceCart value is higher
    var totalDiff = priceCartValue - ecommValue;
    diffs[key] = {
      ecommValue: ecommValue,
      priceCartValue: priceCartValue,
      totalDiff: totalDiff
    };
  });
  return diffs;
};