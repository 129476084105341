import React, { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useInstoreEnrollment } from 'src/hooks/use-instore-enrollment';
import { SmallGreenQrCode } from 'src/assets/small-green-qr-code';
import { SmallGreenPhoneTap } from 'src/assets/small-green-phone-tap';
import { SmallGreenDiamond } from 'src/assets/small-green-diamond';
import { InstoreEnrollmentStatus } from 'src/state/instore-enrollment';
import { useCreatePointsBoostMutation, useUpdateProfileMutation } from 'types/graphql';
import useUser from 'src/hooks/use-user';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLoyalty } from '../loyalty/use-loyalty';

import {
  MainContainer,
  ContentContainer,
  HeadingContainer,
  Heading,
  FooterContainer,
  Image,
  ImageContainer,
  TextContentContainer,
  TextContentHeading,
  GreenText,
  LineItemContainer,
  LineItem,
  LineItemText,
  BackButton,
  OuterContainer,
  CloseText,
} from './success.styles';

export function Success(): JSX.Element {
  const instoreEnrollment = useInstoreEnrollment();
  const declinedPayByBank = useObserver(() => instoreEnrollment.declinedPayByBank);
  const isFullyEnrolled = useObserver(() => instoreEnrollment.fullyEnrolled);
  const user = useUser();
  const { dispensary } = useDispensary();
  const [updateProfile] = useUpdateProfileMutation();
  const [createPointsBoost] = useCreatePointsBoostMutation();
  const { pointsBoostValue, rewardsProgram } = useLoyalty();
  const flags = useFlags();
  const pointsBoostEnabled = flags['growth.ecomm.loyalty-and-pbb-points-boost'];

  useEffect(() => {
    if (isFullyEnrolled && !user.profile?.inStoreEnrollmentCompleted && pointsBoostEnabled) {
      createPointsBoost({
        variables: {
          input: {
            dispensaryId: dispensary.id,
            signedUpForLoyalty: true,
            signedUpForPbb: !declinedPayByBank,
            userId: user.id,
            program: rewardsProgram,
          },
        },
      });

      updateProfile({
        variables: {
          profile: {
            _id: user.id,
            firstName: user.profile?.firstName,
            lastName: user.profile?.lastName,
            email: user.profile?.email,
            phone: user.profile?.phone,
            birthday: user.profile?.birthday,
            emailNotifications: user.profile?.emailNotifications,
            emailOptIn: user.profile?.emailOptIn,
            textNotifications: user.profile?.textNotifications,
            photoId: user.profile?.photoId,
            inStoreEnrollmentCompleted: true,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullyEnrolled, updateProfile, user.id, user.profile]);

  const imageSource = declinedPayByBank
    ? 'https://images.dutchie.com/pay/loyalty-only-enrollment-success.png'
    : 'https://images.dutchie.com/pay/pay-by-bank-enrollment-success.png';

  const handleGoBackToPayByBankEnrollment = (): void => {
    instoreEnrollment.setCurrentStatus(InstoreEnrollmentStatus.payByBankEnrollment);
  };

  return (
    <OuterContainer data-testid='instore-enrollment-success-page'>
      <MainContainer>
        <HeadingContainer>
          <Heading size='large' tag='h1'>
            {declinedPayByBank ? (
              <>
                You can now earn points with
                <br />
                every purchase!
              </>
            ) : (
              <>
                {isFullyEnrolled && user.profile?.inStoreEnrollmentCompleted
                  ? "You're already earning points!"
                  : "You're all set!"}
              </>
            )}
          </Heading>
          <CloseText size='small' tag='h2'>
            Feel free to close this window.
          </CloseText>
        </HeadingContainer>
        <ContentContainer>
          <ImageContainer>
            <Image
              src={imageSource}
              alt={declinedPayByBank ? 'loyalty only enrollment success' : 'pay by bank enrollment success'}
            />
          </ImageContainer>
          {!declinedPayByBank && (
            <TextContentContainer>
              <TextContentHeading size='large' tag='h2'>
                Ask to <GreenText>Pay by Bank</GreenText> <br /> at the register
              </TextContentHeading>
              <LineItemContainer>
                <LineItem>
                  <SmallGreenQrCode />
                  <LineItemText size='small' tag='p'>
                    Scan QR code at checkout
                  </LineItemText>
                </LineItem>
                <LineItem>
                  <SmallGreenPhoneTap />
                  <LineItemText size='small' tag='p'>
                    Confirm payment
                  </LineItemText>
                </LineItem>
                <LineItem>
                  <SmallGreenDiamond />
                  <LineItemText size='small' tag='p'>
                    Earn points & rewards
                  </LineItemText>
                </LineItem>
              </LineItemContainer>
            </TextContentContainer>
          )}
        </ContentContainer>
        <FooterContainer>
          {declinedPayByBank && (
            <BackButton size='large' onClick={handleGoBackToPayByBankEnrollment}>
              Get {pointsBoostValue} bonus points!
            </BackButton>
          )}
        </FooterContainer>
      </MainContainer>
    </OuterContainer>
  );
}
