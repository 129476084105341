import React from 'react';
import styled, { css } from 'styled-components';
import { space, width } from 'styled-system';

import useTranslation from 'src/hooks/use-translation';
import useUser from 'src/hooks/use-user';

import { FormTextInput, FormFormattedInput } from 'src/components/form-inputs';
import Form from 'src/components/forms/form';
import { DutchiePayButton, DutchiePayHeader, DutchiePayTextBody, EmailDisplay } from 'src/payments/components';
import { useCompleteAccountForm } from './use-complete-account-form';

type CompleteAccountFormProps = {
  onSuccess: () => void;
};

export const CompleteAccountForm = ({ onSuccess }: CompleteAccountFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { email, firstName, lastName, birthday, formatedBirthdate, phone } = useUser();

  const { handleSubmit, validationSchema } = useCompleteAccountForm({ onSuccess });

  const formValues = {
    firstName,
    lastName,
    birthday: birthday ? formatedBirthdate : null,
    phone,
  };

  return (
    <>
      <DutchiePayHeader>Complete your account</DutchiePayHeader>
      <DutchiePayTextBody>Enter the required info below to complete your account.</DutchiePayTextBody>

      <Form defaultValues={formValues} onSubmit={handleSubmit} mode='onSubmit' validationSchema={validationSchema}>
        <FormContainer>
          <EmailDisplay>{email}</EmailDisplay>
          <InlineContainer>
            <InputContainer width='50%' mr='8px'>
              <FormTextInput
                autoFocus
                name='firstName'
                label={t('signUp.firstName', 'First name')}
                variant='filled'
                autoComplete='given-name'
                required
              />
            </InputContainer>

            <InputContainer width='50%' ml='8px'>
              <FormTextInput
                name='lastName'
                label={t('signUp.lastName', 'Last name')}
                variant='filled'
                autoComplete='family-name'
                required
              />
            </InputContainer>
          </InlineContainer>

          <InputContainer mt={16}>
            <FormFormattedInput
              format='date'
              name='birthday'
              label={t('signUp.birthday', 'Date of birth')}
              variant='filled'
              autoComplete='bday'
              required
            />
          </InputContainer>

          <InputContainer mt={16}>
            <FormFormattedInput
              format='phone'
              name='phone'
              label={t('signUp.phone', 'Mobile phone number')}
              variant='filled'
              autoComplete='tel'
              required
            />
          </InputContainer>
        </FormContainer>

        <DutchiePayButton>{t('common.continue', 'Continue')}</DutchiePayButton>
      </Form>
    </>
  );
};

const InlineContainer = styled.div`
  display: flex;

  ${space}
`;

const FormContainer = styled.div`
  ${({ theme: { spaces } }) => css`
    margin: ${spaces[5]} 0;
  `}
`;

const InputContainer = styled.div`
  ${width}
  ${space}
`;
