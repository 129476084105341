import React, { memo, useEffect, useRef } from 'react';
import Truncate from 'react-truncate';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import usePaths from 'hooks/use-paths';
import useUI from 'hooks/use-ui';
import useCart from 'src/hooks/use-cart';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import { Link } from 'components/core';
import { getCategoryForProduct, isOutOfStock } from 'shared/helpers/products';
import { groupOptionsWithPrices } from 'utils/helpers/product';
import useViewportVisibility from 'src/hooks/use-viewport-visibility';

import { SponsoredTag } from 'utils/ads/ui';
import { focusStyles } from 'src/theme';
import { Price } from './price';
import { StrainDetails } from './strain-details';
import { QuickAddButton } from './quick-add-button';
import { Brand, Container, Content, Details, Name, ProductImage, StyledLink } from './product-card.components';
import { ProductCardProduct } from './product-card.types';
import { getBrandName } from './product-card.utils';
import { AddToCartButton } from './add-to-cart-button';

export const TEST_ID_LIST_VIEW_PRODUCT_CARD = 'list-view-product-card-anchor';

export type ListViewProductCardProps = {
  product: ProductCardProduct;
  productIndex: number;
  onClick?: (product: ProductCardProduct, index: number) => void;
  onQuickAddClick?: (product: ProductCardProduct, index: number) => void;
  href?: string;
  route?: string;
  onView?: (product: ProductCardProduct, index: number) => void;
};

type UseProductCardPathParams = Pick<ListViewProductCardProps, 'href' | 'product'>;

type UseProductCardPathReturn = {
  href: string;
  route: string | null;
};

function useProductCardPath({ product, href }: UseProductCardPathParams): UseProductCardPathReturn {
  const paths = usePaths({
    category: getCategoryForProduct(product),
    product: { id: product.id },
  });

  return {
    href: href ?? paths.href,
    route: href ? null : paths.href,
  };
}

export const ListViewProductCard = memo(
  ({
    product,
    productIndex,
    onQuickAddClick,
    onClick,
    onView,
    href: hrefOverride,
  }: ListViewProductCardProps): JSX.Element | null => {
    const UI = useUI();
    const { href, route } = useProductCardPath({ product, href: hrefOverride });
    const { menuType } = useCart();
    const { isKiosk } = UI;
    const router = useRouter();

    const flags = useFlags();
    const isAddToCartCTAEnabled = flags['growth.ecomm.add-to-cart-cta.experiment'] ?? false;

    const viewingOffersPage = router.route.includes('specials/offer');
    const { specialId } = router.query;

    const parsedOptions = groupOptionsWithPrices({
      product,
      menuType,
      isKiosk,
      offerSpecial: viewingOffersPage,
      specialId,
      saleSpecial: undefined,
    });

    const brandName = getBrandName(product);

    const handleClick = (): void => {
      onClick?.(product, productIndex);
    };

    const handleClickQuickAddButton = (): void => {
      onQuickAddClick?.(product, productIndex);
    };

    const handleAddToCart = (): void => {
      onQuickAddClick?.(product, productIndex);
    };

    const comingSoonProductsFlagEnabled = useDispensaryFlag(`rollout.coming-soon-products`, product.DispensaryID);
    const showOutOfStockUIFlagEnabled = useDispensaryFlag(
      `ecomm.menu.show-out-of-stock-ui.rollout`,
      product.DispensaryID
    );

    const isComingSoon = !!(comingSoonProductsFlagEnabled && 'comingSoon' in product && product.comingSoon);

    const showOutOfStock = showOutOfStockUIFlagEnabled && isOutOfStock(product, parsedOptions);
    const displayAddToCartVariation = !isComingSoon && !showOutOfStock;
    const displayQuickAddButton = !isAddToCartCTAEnabled && displayAddToCartVariation;
    const displayAddToCartButton = isAddToCartCTAEnabled && displayAddToCartVariation;

    const isSponsored = !!product.adTrackers;

    const { ref, hasBeenVisible } = useViewportVisibility();
    const onViewRef = useRef(onView);

    useEffect(() => {
      if (hasBeenVisible) {
        onViewRef.current?.(product, productIndex);
      }
    }, [product, productIndex, hasBeenVisible]);

    return (
      <StyledContainer data-testid='product-grid-item' ref={ref}>
        <Content>
          {displayQuickAddButton && <QuickAddButton onClick={handleClickQuickAddButton} productName={product.name} />}
          <Link href={href} route={route}>
            <StyledLink href={href} onClick={handleClick} data-testid={TEST_ID_LIST_VIEW_PRODUCT_CARD}>
              <StyledProductImage product={product} width={160} height={160} />

              <Details>
                <SponsoredTag isSponsored={isSponsored} />

                <Price product={product} isComingSoon={isComingSoon} />

                <Name>
                  <Truncate lines={3}>{product.name}</Truncate>
                </Name>

                {brandName && <Brand>{brandName}</Brand>}

                <StrainDetails product={product} />
              </Details>
              {displayAddToCartButton && <AddToCartButton onClick={handleAddToCart} product={product} />}
            </StyledLink>
          </Link>
        </Content>
      </StyledContainer>
    );
  }
);

const StyledContainer = styled(Container)`
  .focus-visible {
    ${focusStyles}
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `}
`;

export const listViewProductCardImageStyles = css`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 100%;
    /* Total horizontal margin space === 87px */
    /* This results in the same value as the width to get a square image */
    height: calc((100vw - 87px) / 2);
  }
`;

const StyledProductImage = styled(ProductImage)`
  ${listViewProductCardImageStyles}
`;
