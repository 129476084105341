import React, { useCallback } from 'react';
import Router from 'next/router';

import ROUTES from 'src/routes';

import useUI from 'hooks/use-ui';
import useUser from 'hooks/use-user';
import useCart from 'hooks/use-cart';
import { removePersistedValue } from 'shared/utils/persisted-values';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { DialogWrapper } from 'shared/modals/v2-dialog-wrapper';
import { DesktopOnly, MobileOnly } from 'shared/components';

import { ModalObserver } from '../modal-observer';
import { OrderStatusModal } from './order-status-modal';

function useOrderStatus({ onClose }) {
  const user = useUser();
  const { isDutchieMain } = useUI();
  const cart = useCart();

  const handleClose = useCallback(() => {
    user.viewOrderId = '';
    cart.stashedOrder = null;
    removePersistedValue('viewOrderId', true);
    removePersistedValue('stashedOrder', true);

    if (isDutchieMain) {
      Router.replace(ROUTES.ORDERS);
    } else {
      onClose();
    }
  }, [isDutchieMain, onClose, user, cart]);

  return {
    handleClose,
  };
}

function OrderStatusWrapper({ open, onClose, containerProps }) {
  const { handleClose } = useOrderStatus({ onClose });

  return (
    <>
      <MobileOnly>
        <DialogWrapper open={open} onClose={handleClose}>
          <OrderStatusModal containerProps={containerProps} onClose={handleClose} />
        </DialogWrapper>
      </MobileOnly>

      <DesktopOnly>
        <ModalWrapper open={open} onClose={handleClose}>
          <OrderStatusModal containerProps={containerProps} onClose={handleClose} />
        </ModalWrapper>
      </DesktopOnly>
    </>
  );
}

export const OrderStatusContoller = ({ name }) => (
  <ModalObserver name={name}>{(props) => <OrderStatusWrapper {...props} />}</ModalObserver>
);
