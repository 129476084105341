import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { v4 } from 'uuid';
import _ from 'lodash';
/* eslint-disable no-unused-vars */
import { mapApplicationRules, mapExclusions, mapInclusions, mapThresholds, mapWeightThresholds } from './other-mappers';
import { shouldUseWeightThreshold } from './helpers';
/* eslint-enable no-unused-vars */

export var createDiscountBundleReward = function createDiscountBundleReward(_ref) {
  var amountOff = _ref.amountOff,
    discountedPrice = _ref.discountedPrice,
    discountType = _ref.discountType,
    id = _ref.id,
    percentDiscount = _ref.percentDiscount,
    rewardType = _ref.rewardType;
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, defaultReward()), {}, {
    id: id
  }, amountOff ? {
    amountOff: amountOff
  } : {}), discountedPrice ? {
    discountedPrice: discountedPrice
  } : {}), {}, {
    discountType: discountType
  }, percentDiscount ? {
    percentDiscount: percentDiscount
  } : {}), {}, {
    type: rewardType
  });
};
export var createItemsForADiscountReward = function createItemsForADiscountReward(_ref2) {
  var _discountBundle$_id;
  var discountBundle = _ref2.discountBundle;
  return createDiscountBundleReward(_objectSpread({
    id: (_discountBundle$_id = discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle._id) !== null && _discountBundle$_id !== void 0 ? _discountBundle$_id : v4(),
    rewardType: (discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.applyTo) === 'EACH' ? 'ITEM' : 'BUNDLE'
  }, mapDiscountType(discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.discountType, discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.value)));
};
export var createItemsForAPriceReward = function createItemsForAPriceReward(_ref3) {
  var _itemsForAPrice$_id, _itemsForAPrice$disco;
  var itemsForAPrice = _ref3.itemsForAPrice;
  return createDiscountBundleReward(_objectSpread({
    id: (_itemsForAPrice$_id = itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice._id) !== null && _itemsForAPrice$_id !== void 0 ? _itemsForAPrice$_id : v4(),
    rewardType: 'BUNDLE'
  }, mapDiscountType((_itemsForAPrice$disco = itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice.discountType) !== null && _itemsForAPrice$disco !== void 0 ? _itemsForAPrice$disco : 'targetPrice', itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice.value)));
};
var createDiscountToCartReward = function createDiscountToCartReward(special) {
  var _special$discountToCa = special.discountToCart,
    discountType = _special$discountToCa.discountType,
    value = _special$discountToCa.value,
    _id = _special$discountToCa._id;
  return _objectSpread(_objectSpread({}, defaultReward()), {}, {
    id: _id !== null && _id !== void 0 ? _id : v4(),
    type: 'CART'
  }, mapDiscountType(discountType, value));
};
export var defaultReward = function defaultReward() {
  return {
    id: v4(),
    discountType: 'PERCENT_OFF',
    exclusions: [],
    inclusions: [],
    rangeBehavior: 'EVERY_X',
    thresholds: [],
    type: 'ITEM'
  };
};
var mapDiscountRewardType = function mapDiscountRewardType(_ref4) {
  var discountBundle = _ref4.discountBundle,
    discountToCart = _ref4.discountToCart,
    itemsForAPrice = _ref4.itemsForAPrice;
  if (discountToCart !== null && discountToCart !== void 0 && discountToCart.enabled) {
    return 'CART';
  }
  if (discountBundle !== null && discountBundle !== void 0 && discountBundle.enabled && (discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.applyTo) === 'ALL' || itemsForAPrice !== null && itemsForAPrice !== void 0 && itemsForAPrice.enabled) {
    return 'BUNDLE';
  }
  return 'ITEM';
};
export var mapDiscountType = function mapDiscountType(discountType, value) {
  switch (discountType) {
    case 'percentDiscount':
      return {
        discountType: 'PERCENT_OFF',
        percentDiscount: parseFloat(value)
      };
    case 'dollarDiscount':
      return {
        discountType: 'AMOUNT_OFF',
        amountOff: parseFloat(value) * 100
      };
    case 'targetPrice':
      return {
        discountType: 'DISCOUNTED_PRICE',
        discountedPrice: parseFloat(value) * 100
      };
    default:
      console.error('unable to determine a discount type for discount to cart');
      return {
        discountType: 'PERCENT_OFF',
        discountedPrice: parseFloat(value)
      };
  }
};

// TODO: implement this function
// eslint-disable-next-line no-unused-vars
export var mapGlobalRewardThresholds = function mapGlobalRewardThresholds(special) {
  return [];
};
export var mapAdvancedRewards = function mapAdvancedRewards(special) {
  var _special$itemsForAPri, _special$discountBund, _special$discountToCa2;
  if ((_special$itemsForAPri = special.itemsForAPrice) !== null && _special$itemsForAPri !== void 0 && _special$itemsForAPri.enabled) {
    return [createItemsForAPriceReward(special)];
  }
  if ((_special$discountBund = special.discountBundle) !== null && _special$discountBund !== void 0 && _special$discountBund.enabled) {
    return [createItemsForADiscountReward(special)];
  }
  if ((_special$discountToCa2 = special.discountToCart) !== null && _special$discountToCa2 !== void 0 && _special$discountToCa2.enabled) {
    return [createDiscountToCartReward(special)];
  }
  return mapRewards(special, 'bogoRewards');
};
export var mapRangeBehaviorFromTotalQuantity = function mapRangeBehaviorFromTotalQuantity(_ref5) {
  var totalQuantity = _ref5.totalQuantity;
  if (totalQuantity !== null && totalQuantity !== void 0 && totalQuantity.enabled && (totalQuantity === null || totalQuantity === void 0 ? void 0 : totalQuantity.quantityOperator) === 'between') {
    return 'FROM_X_TO_Y';
  }
  return 'EVERY_X';
};
var mapRangeBehavior = function mapRangeBehavior(rangeBehavior) {
  switch (rangeBehavior) {
    case 'betweenXAndY':
    case 'BETWEEN_X_AND_Y':
      return 'BETWEEN_X_AND_Y';
    case 'everyX':
    case 'EVERY_X':
      return 'EVERY_X';
    case 'fromXToY':
    case 'FROM_X_TO_Y':
      return 'FROM_X_TO_Y';
    default:
      console.error('invalid rangeBehavior provided');
  }
  return rangeBehavior;
};
export var mapReward = function mapReward(special, reward) {
  var _reward$discountAmoun;
  return _objectSpread(_objectSpread(_objectSpread({}, defaultReward()), {}, {
    id: reward._id
  }, mapDiscountType(reward.discountType, (_reward$discountAmoun = reward.discountAmount) === null || _reward$discountAmoun === void 0 ? void 0 : _reward$discountAmoun.toString())), {}, {
    applicationRules: mapApplicationRules(reward),
    exclusions: mapExclusions(reward),
    inclusions: mapInclusions(reward),
    rangeBehavior: mapRangeBehaviorFromTotalQuantity(reward),
    requiresSingleSku: reward.requiresSingleSku,
    thresholds: mapRewardThresholds(reward),
    type: mapDiscountRewardType(special)
  });
};

/**
 *
 * @param special
 * @param rewardsKey - either 'saleDiscounts' or 'bogoRewards'
 * @returns {{thresholds: [], applicationRules: *|{applicationType: string|*, quantity: {amount: *}, comparisonOperator: string}[], discountType: (string), exclusions: *|unknown[], id: *, type: string, rangeBehavior: string, inclusions: *|unknown[]}[]}
 */
export var mapRewards = function mapRewards(special, rewardsKey) {
  return _.map(special[rewardsKey], function (reward) {
    return mapReward(special, reward);
  });
};
var mapRewardThresholds = function mapRewardThresholds(reward) {
  return mapThresholds(reward);
};