import { useApolloClient } from '@apollo/react-hooks';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { space, width } from 'styled-system';
import { object, string } from 'yup';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useTranslation from 'hooks/use-translation';
import useUser from 'hooks/use-user';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { consumerLoginV2 } from 'src/state/actions/users';
import useErnie from 'shared/hooks/use-ernie';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import ShowEnrollment from 'src/payments/graphql/queries/show-enrollment.gql';

import { useModals, ModalNames } from 'components/modals';
import { TextButton, Button } from 'components/core';
import { subparagraph } from 'components/core/typography';
import { useTurnstile } from 'src/components/turnstile';
import { TURNSTILE_ACTIONS } from 'shared/constants/turnstile';
import SvgChevronIcon from 'src/assets/chevron-icon';

import useUI from 'src/hooks/use-ui';
import { tracker } from 'utils/analytics';
import { FormTextInput } from '../../form-inputs';
import Form from '../form';
import { emailRegEx } from '../form-utils';

const loginFormValidation = (t) =>
  object().shape({
    email: string()
      .required(t('form.emailRequired', 'email is required'))
      .matches(emailRegEx, t('form.validEmail', 'please enter a valid email address')),
    password: string().required(t('form.passwordRequired', 'password is required')),
  });

const trackAccountLogin = (dispensary, userId, analyticsEventLabel) => {
  tracker.setContext({ activeDispensary: dispensary });
  tracker.accountLogin({ newAccount: false, customerId: userId, analyticsEventLabel });
};

/**
 * @param {{ analyticsEventLabel?: null | string, onClose?: function() | null, onLoginSuccess?: function() | null, onBack?: function() | null }}
 */
const LoginForm = ({ onClose, analyticsEventLabel = null, onLoginSuccess, onBack }) => {
  const [loading, setLoading] = useState(false);
  const flags = useFlags();

  const apolloClient = useApolloClient();
  const { dispensary } = useDispensary();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const { t } = useTranslation();
  const { openModal } = useModals();
  const { isMobileEcommApp, isEmbedded } = useUI();
  const user = useUser();
  const showErnie = useErnie();

  const { TurnstileComponent, executeAndWaitForToken } = useTurnstile({
    size: 'flexible',
    action: TURNSTILE_ACTIONS.LOGIN,
  });

  // eslint-disable-next-line consistent-return
  const handleLogin = async ({ email, password }) => {
    try {
      setLoading(true);

      let turnstileToken = '';
      if (flags['growth.ecomm.login-turnstile-enable.rollout']) {
        try {
          turnstileToken = await executeAndWaitForToken({ successDelayMs: 1000, keepMounted: true });
        } catch (error) {
          showErnie('Error validating your request. Please try again.', 'danger');
          setLoading(false);
          return;
        }
      }

      const result = await consumerLoginV2({
        apolloClient,
        User: user,
        email,
        password,
        turnstileToken,
        DutchiePayEnrollment,
        showEnrollmentQuery: ShowEnrollment,
        isEmbedded,
      });

      // This is an SSO login so will redirect
      if (result.success && result?.sso) {
        return null;
      }

      if (result.success) {
        trackAccountLogin(dispensary, user.id, analyticsEventLabel);
        const loginSuccessMessage = t('login.success', `You've been successfully logged in`);

        showErnie(loginSuccessMessage, `success`);
        onLoginSuccess?.();
      } else {
        const message = _.get(
          result,
          `error.graphQLErrors[0].extensions.errors[0].detail`,
          t('login.fail', "You've entered an incorrect email or password. Please try again.")
        );
        showErnie(message, `danger`);
      }

      // Only close if not explicitly told to keep open
      if (!result.keepModalOpen) {
        onClose?.(result.success);
      }
    } catch (err) {
      console.error(err);
      showErnie(err, `danger`);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenForgotPasswordModal = () => {
    if (!isMobileEcommApp) {
      onClose?.();
    }
    openModal(ModalNames.resetPassword);
  };

  return (
    <Form onSubmit={handleLogin} validationSchema={loginFormValidation(t)}>
      <FormTextInput
        data-cy='login-form-email-input'
        data-test='login-form-email-input'
        data-testid='login-form-email-input'
        name='email'
        label={t('loginModal.emailLabel', 'Email')}
        variant='filled'
        autoComplete='email'
        type='email'
        required
      />

      <InputContainer mt={15}>
        <FormTextInput
          data-cy='login-form-password-input'
          data-test='login-form-password-input'
          data-testid='login-form-password-input'
          name='password'
          type='password'
          label='Password'
          variant='filled'
          autoComplete='current-password'
          required
        />
      </InputContainer>

      {flags['growth.ecomm.login-turnstile-enable.rollout'] && <TurnstileComponent />}

      <LoginOptionsContainer>
        <ForgotPasswordText
          type='button'
          data-testid='login-form-forgot-password-link'
          onClick={handleOpenForgotPasswordModal}
          px={0}
          $displayBelow={onBack}
        >
          {t('loginModal.forgotYourPassword', 'Forgot your password?')}
        </ForgotPasswordText>

        {onBack && (
          <BackButton type='button' onClick={onBack}>
            <StyledSvgChevronIcon height={9} width={15} />
            Back
          </BackButton>
        )}

        <StyledButton
          variant='flora'
          loading={loading}
          disabled={loading}
          type='submit'
          size='medium'
          data-cy='login-form-submit-button'
          data-testid='login-form-submit-button'
        >
          {t('loginModal.login', 'Log in')}
        </StyledButton>
      </LoginOptionsContainer>
    </Form>
  );
};

export default LoginForm;

const LoginOptionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
`;

const ForgotPasswordText = styled(TextButton)`
  color: #485055;
  font-size: ${subparagraph};
  font-weight: bold;

  ${({ $displayBelow }) =>
    $displayBelow &&
    css`
      margin-top: 24px;
      order: 3;
      width: 100%;
    `}

  &:hover {
    text-decoration: none;
  }
`;

const BackButton = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: #485055;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  gap: 8px;
  padding: 0;
`;

const StyledSvgChevronIcon = styled(SvgChevronIcon)`
  transform: rotate(90deg);
`;

const StyledButton = styled(Button)`
  width: 95px;
  background-color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;

const InputContainer = styled.div`
  ${width}
  ${space}
`;
