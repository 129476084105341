/* eslint-disable max-len */
import React from 'react';
import { v4 } from 'uuid';

function MarkerIcon(props) {
  const instanceId = `instance-id-${v4()}`;

  return (
    <svg width='26px' height='37px' viewBox='0 0 26 37' {...props}>
      <g id={`Page-1-${instanceId}`} stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id={`marker-11-${instanceId}`} transform='translate(-2.000000, -3.000000)' fill='#000000'>
          <g id={`path4133-${instanceId}`}>
            <path d='M15,3.27466667 C9.04466667,3.27466667 2.09633333,6.914 2.09633333,16.1783333 C2.09633333,22.4643333 12.0223333,36.3606667 15,40 C17.647,36.3606667 27.9033333,22.7953333 27.9033333,16.178 C27.9033333,6.914 20.9553333,3.27466667 15,3.27466667 L15,3.27466667 Z' />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MarkerIcon;
