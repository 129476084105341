/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

export const Shield = (props: SVGAttributes<unknown>): JSX.Element => (
  <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.5885 17.9875L9.80775 18.2875C9.54778 18.3875 9.27389 18.4375 9 18.4375V1.56253C11.2914 1.54127 13.5614 2.00412 15.6615 2.92078C15.8569 3.01023 16.0223 3.15422 16.1378 3.33544C16.2533 3.51666 16.3139 3.72739 16.3125 3.94228V9.65278C16.3125 11.4578 15.7655 13.2204 14.7437 14.7083C13.7219 16.1962 12.2731 17.3395 10.5885 17.9875Z'
      fill='#C1D3F1'
    />
    <path
      d='M1.68752 3.94228V9.65278C1.68754 11.4578 2.23451 13.2204 3.25634 14.7083C4.27817 16.1962 5.72689 17.3395 7.41153 17.9875L8.19228 18.2875C8.71221 18.4875 9.28784 18.4875 9.80777 18.2875L10.5885 17.9875C12.2732 17.3395 13.7219 16.1962 14.7437 14.7083C15.7655 13.2204 16.3125 11.4578 16.3125 9.65278V3.94228C16.314 3.72739 16.2533 3.51666 16.1378 3.33544C16.0223 3.15422 15.8569 3.01023 15.6615 2.92078C13.5614 2.00412 11.2914 1.54127 9.00003 1.56253C6.70867 1.54127 4.43865 2.00412 2.33852 2.92078C2.14313 3.01023 1.97775 3.15422 1.86225 3.33544C1.74676 3.51666 1.68609 3.72739 1.68752 3.94228Z'
      stroke='#0075E0'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.44193 14.9111L9.86901 14.7333C10.7905 14.3493 11.583 13.6718 12.1419 12.7902C12.7009 11.9085 13.0001 10.864 13.0001 9.79444V6.41058C13.0009 6.28324 12.9677 6.15837 12.9045 6.05099C12.8413 5.9436 12.7509 5.85828 12.644 5.80527C11.4952 5.26209 10.2535 4.98782 9.00009 5.00041C7.7467 4.98782 6.50498 5.26209 5.3562 5.80527'
      stroke='#0075E0'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
