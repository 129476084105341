/**
 * A hook for lazy loading remote modules using Module Federation.
 *
 * This hook allows you to dynamically load components or modules from other applications
 * that are exposed through Module Federation. It handles loading states and errors
 * by providing a fallback value while loading or if the remote module fails to load.
 *
 * @example
 * // Loading a React component
 * const RemoteButton = useLazyRemoteComponent({
 *   manifestURL: 'https://remote-app.example.com/remoteEntry.json',
 *   module: './Button',
 *   scope: 'remote_app',
 *   fallback: { default: DefaultButton }
 * });
 *
 * // Loading a utility module
 * const utils = useLazyRemoteComponent({
 *   manifestURL: 'https://remote-app.example.com/remoteEntry.json',
 *   module: './utils',
 *   scope: 'remote_app',
 *   fallback: { default: defaultUtils }
 * });
 *
 * @param options
 * @param options.manifestURL - The URL to the remote app's Module Federation manifest JSON
 * @param options.module - The path to the module as defined in the remote app's Module Federation config
 * @param options.scope - The name of the remote app as defined in its Module Federation config
 * @param options.fallback - The value to use while loading or if loading fails. Should be an object with a 'default' key to match module format
 * @param options.skip - Optional flag to skip loading the remote module
 *
 * @returns The loaded remote module if successful, otherwise the fallback value
 *
 * @note
 * - The remote app must expose the module through its Module Federation config
 * - The fallback should be an object with a 'default' key to match the module format
 * - The hook will automatically handle reloading if the manifest URL, module, or scope changes
 * - If loading fails or returns 'fallback', the fallback value will be used
 * - The hook requires Module Federation to be initialized before use
 */

import { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-extraneous-dependencies
import { loadRemote } from '@module-federation/runtime';
import { ensureRemoteInitialized } from '../runtime-init';

type UseLazyRemoteComponentProps<T> = {
  manifestURL: string;
  module: string;
  scope: string;
  fallback: T;
  skip?: boolean;
};

function useLazyRemoteComponent<T>({ manifestURL, module, scope, fallback, skip }: UseLazyRemoteComponentProps<T>): T {
  const [remoteModule, setRemoteModule] = useState<T>(fallback);

  useEffect(() => {
    if (skip) {
      return;
    }
    if (!window.__MF_INITIALIZED) {
      console.log('MF not initialized');
      return;
    }
    const initialized = ensureRemoteInitialized({
      scope,
      manifestURL,
    });
    if (!initialized) {
      return;
    }
    loadRemote(`${scope}/${module}`)
      .then((result: T | 'fallback') => {
        if (result !== 'fallback') {
          setRemoteModule(result);
        }
      })
      .catch(() => {
        console.log('error loading remote module', module, scope);
      });
  }, [manifestURL, module, scope, skip]);

  return remoteModule;
}

export default useLazyRemoteComponent;
