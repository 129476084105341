import { useObserver } from 'mobx-react';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Product } from 'types/graphql-overrides';
import { Maybe, GqlCollectionCardBadge } from 'types/graphql';

import useCart from 'src/hooks/use-cart';
import usePaths from 'src/hooks/use-paths';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';
import { getPotencyStrings } from 'src/utils/helpers/product';
import { productIsPartOfActiveSpecial, isNotSolitaryMinimumSpendOffer } from 'shared/helpers/specials';
import { useProductPricing } from 'src/hooks/use-product-pricing';

type UseCardProps = {
  product: Product;
};

type UseCardReturn = {
  href: string;
  isStaffPick: boolean;
  isSponsored: boolean;
  brandName: string;
  strainType: Maybe<string> | null | undefined;
  potency: string[];
  isSpecialOffer: boolean;
  hideDiscount: boolean;
  priceOptions: ReturnType<ReturnType<typeof useProductPricing>['getPriceOptions']>;
  collectionCardBadge: GqlCollectionCardBadge | null;
};

export const useCard = ({ product }: UseCardProps): UseCardReturn => {
  const { href } = usePaths({
    product,
  });

  const isStaffPick = !!product.featured?.current;
  const isSponsored = !!product.adTrackers;
  const brandName = product.brand.name;
  const strainType = product.strainType !== 'N/A' ? product.strainType : null;

  const productForPotencyHelpers = product.thcContent
    ? { ...product, THCContent: product.thcContent, CBDContent: product.cbdContent }
    : product;
  const { THC: THCString, CBD: CBDString, TAC: TACString } = getPotencyStrings(productForPotencyHelpers);
  const showTAC = !!product.cannabinoidsV2?.length;
  const potency: string[] = [];

  if (showTAC && typeof TACString === 'string') {
    potency.push(`TAC: ${TACString}`);
  }

  if (typeof THCString === 'string') {
    potency.push(`THC: ${THCString}`);
  }

  if (typeof CBDString === 'string') {
    potency.push(`CBD: ${CBDString}`);
  }

  const { query: routerQuery } = useRouter();
  const Cart = useCart();
  const menuType = useObserver(() => Cart.menuType);
  const isInBogo = productIsPartOfActiveSpecial(product, menuType);
  const isSpecialOffer =
    !routerQuery.specialId && isInBogo && isNotSolitaryMinimumSpendOffer(product.specialData?.bogoSpecials);

  const { dispensary } = useDispensary();
  const hideDiscount = useHideDiscountFromConsumer(dispensary);

  const { getPriceOptions } = useProductPricing();
  const priceOptions = getPriceOptions(product);

  const flags = useFlags();
  const isCollectionsEnabled = flags['growth.ecomm.product-collections.rollout'] ?? false;
  const collectionCardBadge = (isCollectionsEnabled && product.collectionCardBadge) ?? null;

  return {
    href,
    isStaffPick,
    isSponsored,
    brandName,
    strainType,
    potency,
    isSpecialOffer,
    hideDiscount,
    priceOptions,
    collectionCardBadge,
  };
};
