/* eslint-disable react-hooks/exhaustive-deps */
/* ENG-51943: ignoring temporarily until we find a better path forward */

import { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { toJS, transaction } from 'mobx';
import { useObserver } from 'mobx-react-lite';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { consumerDispensaries } from 'shared/graphql/dispensary/queries';
import { isMedOnly, isRecOnly, openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import { durationEstimatesForOrder } from 'shared/helpers/order-estimates';
import {
  defaultDurationEstimates,
  LOCAL_STORAGE_STORED_CART_KEY,
  MENU_TYPE_MED,
  MENU_TYPE_REC,
} from 'shared/constants';
import {
  applyCouponCode,
  getStoredCartKey,
  normalizeAndUpdateStoredCart,
  persistCartData,
} from 'src/state/actions/cart';
import useCart from 'hooks/use-cart';
import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUser from 'hooks/use-user';
import usePersistedValue from 'hooks/use-persisted-value';
import useProductQueryParams from 'hooks/use-product-query-params';
import useMobxDataBind from 'hooks/use-mobx-data-bind';
import useSwitchMenuType from 'hooks/use-switch-menu-type';
import { useUTM } from 'hooks/use-utm';
import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';
import { useCustomerFromQueryParams } from 'shared/hooks/use-customer-from-query-params';
import { isBrowser } from 'utils/misc-utils';
import { useGetAddressBasedDispensaryDataQuery } from 'types/graphql';
import { isDutchiePlusKioskOrder } from 'shared/helpers/checkout';

export function usePersistedCartValues({ Cart }) {
  const { queryParams } = useProductQueryParams();
  const switchMenuType = useSwitchMenuType();
  const { isMobileEcommApp } = useUI();

  // See this document for explanation of the overrides below:
  // https://dutchie.atlassian.net/wiki/spaces/EN/pages/19894796312/Webviews+as+Browser+Tabs+Synchronization+w+Local+Session+Storage
  usePersistedValue(Cart, `orderType`, `orderType`, !isMobileEcommApp);
  usePersistedValue(Cart, `address`, `address`, !isMobileEcommApp);
  usePersistedValue(Cart, `isMedical`, !isMobileEcommApp);

  useEffect(() => {
    const type = queryParams.menuType;
    if (type && type !== Cart.menuType) {
      switchMenuType(type);
    }
  }, [queryParams.menuType]);
}

export function useClearCart({ UI, Cart, dispensary, viewingDispensary }) {
  const isEmbedded = useObserver(() => UI.isEmbedded);

  useEffect(() => {
    const cartDispensaryCName = _.get(dispensary, `cName`, false);
    const viewingDispensaryCName = _.get(viewingDispensary, `cName`, false);
    // if we dont have either cName we aren't vewing a menu
    if (!isEmbedded || !cartDispensaryCName || !viewingDispensaryCName) {
      return;
    }
    if (cartDispensaryCName !== viewingDispensaryCName) {
      Cart.clearOrder();
    }
  }, [isEmbedded, dispensary, viewingDispensary]);
}

export function useDefaultOrderType({ Cart, UI, dispensary }) {
  const orderOptions = useObserver(() => Cart.orderOptions);

  useEffect(() => {
    if (orderOptions.length === 1 || (!!orderOptions.length && UI.isDutchieMain && !Cart.orderType)) {
      const isDutchiePlusKiosk = isDutchiePlusKioskOrder(Cart.orderType, UI.isPlus);

      if (!isDutchiePlusKiosk) {
        Cart.setOrderType(orderOptions[0]?.value);
      }
    }
  }, [UI.isDutchieMain, Cart, dispensary, orderOptions, orderOptions.length, UI.isPlus]);
}

export function useOpenInfoForDispensary({ Cart, dispensary, UI }) {
  const userTimezone = useObserver(() => UI.timezone);
  const previewMode = useObserver(() => UI.previewMode);
  useEffect(() => {
    if (!dispensary) {
      return;
    }
    Cart.openInfoForDispensary = openInfoForDispensary(dispensary, {
      userTimezone,
      previewMode,
    });
  }, [dispensary, userTimezone, previewMode]);
}

const persistCart = _.debounce(({ apolloClient, Cart, UI, user, flags }) => {
  if (!Cart.order.dispensary?.id) {
    return;
  }

  persistCartData(apolloClient, Cart, UI, user, flags);
}, 2.5 * 1000);

export function loadCart({ storedDispensaryCart, Cart }) {
  transaction(() => {
    _.forEach(storedDispensaryCart.items, (item) => {
      Cart.addItem(storedDispensaryCart.dispensary, item);
    });
    Cart.mostRecentProduct = null;
    Cart.isMedical = storedDispensaryCart.isMedical;
    Cart.utmData = storedDispensaryCart.utmData || Cart.utmData;
    // or is so that *old* saved carts still work
    // otherwise it would load the undefined token and use that.
    Cart.checkoutToken = storedDispensaryCart.checkoutToken;
  });
}

export function useCartSaveLoad({ apolloClient, Cart, UI, viewingDispensaryId }) {
  const flags = useFlags();
  const isDutchieMain = useObserver(() => UI.isDutchieMain);
  const currentStoredCartKey = getStoredCartKey(viewingDispensaryId, isDutchieMain);
  const itemQuantities = useObserver(() => _.map(_.values(Cart.items), ({ quantity }) => quantity));
  const itemIds = useObserver(() => _.map(_.values(Cart.items), ({ product }) => product.id));
  const itemOptions = useObserver(() => _.map(_.values(Cart.items), ({ option }) => option));
  const otherCartValues = useObserver(() => [
    Cart.isMedical,
    Cart.dispensary,
    Cart.orderType,
    Cart.utmData,
    Cart.address,
  ]);
  const user = useUser();
  const persistCartValues = useObserver(() => [
    user.email,
    user.phone,
    user.firstName,
    user.lastName,
    user.fullName,
    user.id,
    Cart.order.guestCustomer?.email,
    Cart.order.guestCustomer?.phone,
    Cart.order.guestCustomer?.firstName,
    Cart.order.guestCustomer?.lastName,
    Cart.order.guestCustomer?.fullName,
  ]);

  useEffect(() => {
    if (!currentStoredCartKey) {
      return;
    }

    const storedCart = normalizeAndUpdateStoredCart(
      getPersistedValue(LOCAL_STORAGE_STORED_CART_KEY, {}),
      isDutchieMain
    );

    if (storedCart[currentStoredCartKey] && Cart.itemCount === 0) {
      const storedDispensaryCart = storedCart[currentStoredCartKey];

      loadCart({ storedDispensaryCart, Cart });
    }

    Cart.isReady = true;
  }, [Cart, currentStoredCartKey, isDutchieMain]);

  useEffect(() => {
    if (Cart.dispensary && currentStoredCartKey) {
      const storedCart = getPersistedValue(LOCAL_STORAGE_STORED_CART_KEY, {});

      storedCart[currentStoredCartKey] = {
        isMedical: Cart.isMedical,
        dispensary: Cart.dispensary,
        checkoutToken: Cart.checkoutToken,
        items: _.values(Cart.items),
        orderType: Cart.orderType,
        utmData: Cart.utmData,
        address: Cart.address,
      };
      setPersistedValue(LOCAL_STORAGE_STORED_CART_KEY, storedCart);
    }
  }, [itemQuantities.join(','), itemIds.join(','), itemOptions.join(','), ...otherCartValues]);

  useEffect(() => {
    if (Cart.itemCount > 0) {
      persistCart({ apolloClient, Cart, UI, user, flags });
    }
  }, [itemQuantities.join(','), itemIds.join(','), itemOptions.join(','), ...otherCartValues, ...persistCartValues]);
}

export function useCartGuestCustomer({ Cart }) {
  const user = useUser();
  const isLoggedIn = useObserver(() => user.isLoggedIn);
  useEffect(() => {
    Cart.guestCustomer = user.isLoggedIn ? false : {};
  }, [isLoggedIn]);
}

export function useCartMedicalCard({ Cart }) {
  const user = useUser();
  const isLoggedIn = useObserver(() => user.isLoggedIn);
  const { number, expirationDate, state, photo } = user?.profile?.medicalCard || {};

  useEffect(() => {
    if (isLoggedIn) {
      transaction(() => {
        Cart.setMedicalCardNumber(number);
        Cart.setMedicalCardExpiration(expirationDate);
        Cart.setMedicalCardState(state);
        Cart.setMedicalCardPhoto(photo);
      });
    } else {
      Cart.clearMedicalCard();
    }
  }, [isLoggedIn]);
}

export function useReapplyCurrentCoupon({ apolloClient, Cart, UI }) {
  const items = useObserver(() => Cart.items);
  const totalItemQuantity = _.reduce(_.values(items || {}), (total, item) => total + (item.quantity || 0), 0);

  useEffect(() => {
    const reapplyCoupon = async () => {
      const coupon = { ...Cart.order.coupon };
      Cart.removeCoupon();
      await applyCouponCode(apolloClient, Cart, UI, coupon.code, Cart.order.dispensary?.id, true);
    };
    if (Cart.order?.coupon) {
      reapplyCoupon();
    }
  }, [totalItemQuantity]);
}

export function useShowShoppingWithModal({ dispensary, UI, viewingDispensary }) {
  const [previousDispensary, setPreviousDispensary] = useState(dispensary);

  useEffect(() => {
    // if we're not already in a dispensary
    // and there either isn't a dispensary in the URL or the dispensary in the url matches the one chosen
    // that way it doesn't ask you if you want to shop there if you already are
    if (!previousDispensary && dispensary && (!viewingDispensary || viewingDispensary.id !== dispensary.id)) {
      UI.shoppingWithModal = dispensary;
    }
    setPreviousDispensary(dispensary);
  }, [dispensary]);
}

export function useUpdateDispensaryDeliveryInfo({ dispensary, address, Cart }) {
  const dispensaryId = useObserver(() => dispensary?.id);
  const skip = !dispensaryId;
  const deliveryInfoResult = useGetAddressBasedDispensaryDataQuery({
    variables: {
      input: {
        dispensaryId,
        city: address?.city,
        state: address?.state,
        zipcode: address?.zipcode,
        lat: address?.lat,
        lng: address?.lng,
      },
    },
    skip,
  });

  const { data, loading } = useQuery(consumerDispensaries, {
    skip,
    variables: {
      dispensaryFilter: {
        cNameOrID: dispensaryId,
      },
    },
  });

  useEffect(() => {
    const updatedDispensary = _.get(data, `filteredDispensaries[0]`);
    const updatedDeliveryInfo = _.get(deliveryInfoResult, `data.getAddressBasedDispensaryData`);
    let newDispensary = Cart.dispensary;
    if (!loading && updatedDispensary) {
      newDispensary = updatedDispensary;
    }
    if (updatedDeliveryInfo) {
      const destinationTax = _.find(updatedDeliveryInfo.taxes, (elem) => elem.deliveryPolicy == 'destinationBased');
      if (!destinationTax || (destinationTax && destinationTax.id.endsWith(address?.zipcode))) {
        Cart.dispensary = {
          ...newDispensary,
          deliveryInfo: updatedDeliveryInfo.deliveryInfo,
          taxConfig: {
            ...newDispensary.taxConfig,
            taxes: updatedDeliveryInfo.taxes,
          },
        };
      }
    }
  }, [data, loading, deliveryInfoResult.loading, deliveryInfoResult.data]);
}

export function useSetDurationEstimatesForDispensary({ dispensary, Cart }) {
  useEffect(() => {
    async function setDurationEstimates() {
      try {
        const durationEstimates = await durationEstimatesForOrder(dispensary);

        Cart.durationEstimates = durationEstimates;
      } catch (err) {
        Cart.durationEstimates = { ...defaultDurationEstimates };
        console.error(err);
      }
    }
    if (dispensary) {
      setDurationEstimates();
    }
  }, [dispensary]);
}

export function useSetCartDispensary({ viewingDispensary, Cart, UI }) {
  const isDutchieMain = useObserver(() => UI.isDutchieMain);

  useEffect(() => {
    if (viewingDispensary && Cart.dispensary) {
      const switchedToNewDispoOnEmbedded = !isDutchieMain && viewingDispensary.id !== Cart.dispensary?.id;
      if (switchedToNewDispoOnEmbedded) {
        if (Cart.itemCount && Cart.itemCount !== 0) {
          Cart.clearOrder();
        }

        Cart.setDispensary(viewingDispensary);
      }
    }
  }, [Cart, isDutchieMain, viewingDispensary]);
}

export function useSetBasicCartData({ Cart, dispensary, viewingDispensary }) {
  const activeDispensary = dispensary || viewingDispensary;
  useEffect(() => {
    if (activeDispensary) {
      // update pricing type
      const medOnly = isMedOnly(activeDispensary);
      const recOnly = isRecOnly(activeDispensary);

      if (medOnly && !Cart.isMedical) {
        Cart.changePricingType(MENU_TYPE_MED);
      } else if (recOnly && Cart.isMedical) {
        Cart.changePricingType(MENU_TYPE_REC);
      }
    }
  }, [activeDispensary?.id]);
}

export function useStorageListener({ Cart, UI, viewingDispensary }) {
  const viewingDispensaryId = useObserver(() => viewingDispensary?.id);
  const { isDutchieMain, isMobileEcommApp } = UI;
  const currentStoredCartKey = getStoredCartKey(viewingDispensaryId, isDutchieMain);

  const cartItemsRef = useRef(null);
  // listen for Cart changes in other browser tabs
  useEffect(() => {
    if (!isBrowser()) {
      // ssr
      return;
    }

    if (!isMobileEcommApp) {
      return;
    }

    function handleCartStorageEvent(event) {
      if (event.key !== 'stored-cart') {
        return;
      }

      const newValue = JSON.parse(event.newValue);

      if (!newValue || !currentStoredCartKey) {
        return;
      }

      const updatedCart = newValue[currentStoredCartKey];
      const updatedItems = updatedCart?.items || [];

      transaction(() => {
        // We check for "undefined" because this is coming from localStorage which wipes the stored Cart values
        // on order submission. We don't want to override what's stored in the Cart object itself with
        // "undefined", so we skip in this case, effectively keeping "isMedical" set to the correct value on
        // all browser tabs
        if (!_.isUndefined(updatedCart?.isMedical) && updatedCart?.isMedical !== Cart.isMedical) {
          Cart.setIsMedical(updatedCart?.isMedical);
        }

        if (Cart.orderType !== updatedCart?.orderType) {
          Cart.setOrderType(updatedCart?.orderType);
        }

        if (!_.isEqual(updatedCart?.address, toJS(Cart.address))) {
          Cart.setAddress(updatedCart?.address);
        }

        if (!_.isEqual(updatedItems, cartItemsRef.current)) {
          cartItemsRef.current = updatedItems;

          // transaction() won't update observers or views until these next lines are done
          Cart.items = {};
          _.forEach(updatedItems, (item) => Cart.addItem(viewingDispensary, item));
          Cart.clearMostRecentProduct();
        }
      });
    }

    window.addEventListener('storage', handleCartStorageEvent);

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('storage', handleCartStorageEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewingDispensaryId]);
}

export async function usePerformCalculations({ Cart, UI, apolloClient, userDetailsFromExternalSource }) {
  const flags = useFlags();
  const isCheckoutPage = useObserver(() => UI.isCheckoutPage);
  const isAsyncComputerEnabled = flags?.['core.cats.enable-async-ecomm-calculations.rollout'];
  // totalItemQuantity accounts for overall qty changes
  const totalItemQuantity = useObserver(() => Cart.itemCount);
  // cartItemKeys accounts for weight changes
  const cartItemKeys = useObserver(() => Cart.cartItemKeys);
  const isMedical = useObserver(() => Cart.isMedical);
  const couponCode = useObserver(() => Cart.coupon?.code);
  const appliedRewards = useObserver(() => Cart.appliedRewards);
  const tipValue = useObserver(() => Cart.tipValue);
  const orderType = useObserver(() => Cart.orderType);
  const address = useObserver(() => Cart.address);
  const destinationTaxRateSum = useObserver(() => Cart.destinationTaxRateSum);
  const userPosId = useObserver(() => userDetailsFromExternalSource?.externalUserId);
  const paymentMethod = useObserver(() => Cart.paymentMethod);

  const priceCartFlagState = flags['core.cats-ecomm.price-cart-calcs-state.rollout'] ?? 'DISABLED';

  const dependencies = [
    totalItemQuantity,
    cartItemKeys,
    isMedical,
    couponCode,
    appliedRewards.length,
    isAsyncComputerEnabled,
    tipValue,
    orderType,
    address?.zipcode,
    address?.ln1,
    address?.state,
    destinationTaxRateSum,
    paymentMethod,
    priceCartFlagState,
    isCheckoutPage,
  ];

  useEffect(() => {
    const calculateCart = async () => {
      Cart.setIsCalculating(true);

      await Cart.performCalculations({
        apolloClient,
        isCheckoutPage,
        userPosId,
        priceCartFlagState,
      });
    };
    if (isAsyncComputerEnabled) {
      calculateCart();
    }
  }, dependencies);
}

export default function useCartController() {
  const apolloClient = useApolloClient();
  const Cart = useCart();
  const UI = useUI();
  const { isLoggedIn: isUserLoggedIn, userDetailsFromExternalSource } = useUser();
  const { dispensary: viewingDispensary } = useDispensary();
  const viewingDispensaryId = viewingDispensary?.id;
  const dispensary = useObserver(() => Cart.dispensary);
  const address = useObserver(() => Cart.address);
  const router = useRouter();
  const queryParams = router.query;

  useMobxDataBind(UI, Cart, `previewMode`);

  useUTM({ viewingDispensary, Cart });
  useSetCartDispensary({ viewingDispensary, Cart, UI });
  useDefaultOrderType({ Cart, dispensary, UI });
  useClearCart({ Cart, dispensary, UI, viewingDispensary });
  useOpenInfoForDispensary({ Cart, dispensary, UI });
  useCartSaveLoad({ apolloClient, Cart, UI, viewingDispensaryId });
  useCartGuestCustomer({ Cart });
  useCartMedicalCard({ Cart });
  useShowShoppingWithModal({ dispensary, UI, viewingDispensary });
  useSetDurationEstimatesForDispensary({ dispensary, address, Cart });
  useUpdateDispensaryDeliveryInfo({ dispensary, address, Cart });
  usePersistedCartValues({ Cart });
  useReapplyCurrentCoupon({ apolloClient, Cart, UI });
  useCustomerFromQueryParams({ Cart, queryParams, isUserLoggedIn });
  useSetBasicCartData({ Cart, dispensary, viewingDispensary });
  useStorageListener({ Cart, UI, viewingDispensary });
  usePerformCalculations({ Cart, UI, apolloClient, userDetailsFromExternalSource });

  return Cart;
}
