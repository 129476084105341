import _ from 'lodash';
import SHARED_ROUTES from '../routes';
function buildBaseRoute() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    isDemo = _ref.isDemo,
    isKiosk = _ref.isKiosk,
    isEmbedded = _ref.isEmbedded,
    isStoreFront = _ref.isStoreFront,
    isMobileEcommApp = _ref.isMobileEcommApp,
    dispensary = _ref.dispensary;
  var href, route;
  if (isDemo) {
    href = SHARED_ROUTES.DEMO;
    route = SHARED_ROUTES.DEMO;
  } else if (isKiosk) {
    href = SHARED_ROUTES.KIOSKS;
    route = SHARED_ROUTES.KIOSKS;
  } else if (isEmbedded) {
    href = SHARED_ROUTES.EMBEDDED_MENU;
    route = SHARED_ROUTES.EMBEDDED_MENU;
  } else if (isMobileEcommApp) {
    href = SHARED_ROUTES.MOBILE_ECOMM;
    route = SHARED_ROUTES.MOBILE_ECOMM;
  } else if (isStoreFront) {
    href = SHARED_ROUTES.STORES;
    route = SHARED_ROUTES.STORES;
  } else if (dispensary) {
    href = SHARED_ROUTES.DISPENSARY;
    route = SHARED_ROUTES.DISPENSARY;
  } else {
    href = SHARED_ROUTES.SHOP;
    route = SHARED_ROUTES.SHOP;
  }
  return [href, route];
}
function addDispensaryRoutes() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    href = _ref2.href,
    route = _ref2.route,
    dispensary = _ref2.dispensary,
    info = _ref2.info;
  if (dispensary && info) {
    href += "/".concat(dispensary.cName).concat(SHARED_ROUTES.INFO);
    route += SHARED_ROUTES.CNAME_INFO;
  } else if (dispensary) {
    href += "/".concat(dispensary.cName);
    route += SHARED_ROUTES.CNAME;
  }
  return [href, route];
}
function addProductRoutes(_ref3) {
  var _ref4, _product$cName;
  var href = _ref3.href,
    route = _ref3.route,
    product = _ref3.product;
  href += "".concat(SHARED_ROUTES.PRODUCT).concat((_ref4 = (_product$cName = product.cName) !== null && _product$cName !== void 0 ? _product$cName : product.id) !== null && _ref4 !== void 0 ? _ref4 : product._id);
  route += SHARED_ROUTES.PRODUCT_ID;
  return [href, route];
}
function addMenuSectionRoutes(_ref5) {
  var href = _ref5.href,
    route = _ref5.route,
    menuSection = _ref5.menuSection;
  href += "".concat(SHARED_ROUTES.MENU_SECTION).concat(menuSection.id);
  route += SHARED_ROUTES.MENU_SECTION_ID;
  return [href, route];
}
function addSpecialsRoutes(_ref6) {
  var href = _ref6.href,
    route = _ref6.route,
    id = _ref6.id,
    currentRoute = _ref6.currentRoute,
    specialId = _ref6.specialId;
  href += SHARED_ROUTES.SPECIALS;
  route += SHARED_ROUTES.SPECIALS;
  var generatedOfferSubroute = _.includes(id, 'offer');
  var generatedSaleSubroute = _.includes(id, 'sale');
  if (!generatedOfferSubroute && !generatedSaleSubroute && id) {
    href += "/".concat(id || specialId);
    route += SHARED_ROUTES.OFFER_SPECIAL_ID;
  }
  if (generatedOfferSubroute) {
    href += "/".concat(id || specialId);
    route += SHARED_ROUTES.OFFER_SPECIAL_ID;
  } else if (generatedSaleSubroute) {
    href += "/".concat(id || specialId);
    route += SHARED_ROUTES.SALE_SPECIAL_ID;
  }
  if (!id) {
    if (_.includes(currentRoute, 'offer')) {
      href += "".concat(SHARED_ROUTES.OFFER, "/").concat(specialId);
      route += SHARED_ROUTES.OFFER_SPECIAL_ID;
    } else if (_.includes(currentRoute, 'sale')) {
      href += "".concat(SHARED_ROUTES.SALE, "/").concat(specialId);
      route += SHARED_ROUTES.SALE_SPECIAL_ID;
    }
  }
  return [href, route];
}
function buildExternalTemplateRoute(_ref7) {
  var routeRoot = _ref7.routeRoot,
    brands = _ref7.brands,
    category = _ref7.category,
    product = _ref7.product,
    subcategory = _ref7.subcategory,
    specials = _ref7.specials;
  var href = routeRoot;
  if (brands) {
    href += "?dtche[path]=brands&dtche[brands]=".concat(encodeURIComponent(brands));
  } else if (specials) {
    href += "?dtche[path]=specials";
  } else if (product) {
    href += "?dtche[product]=".concat(encodeURIComponent(product.cName || product.id));
  } else if (category && category.key) {
    href += "?dtche[category]=".concat(encodeURIComponent(category.key));
    if (subcategory) {
      href += "?dtche[subcategory]=".concat(encodeURIComponent(subcategory));
    }
  }
  return {
    href: href
  };
}
function buildStoreFrontRoute(_ref8) {
  var routeRoot = _ref8.routeRoot,
    brands = _ref8.brands,
    category = _ref8.category,
    product = _ref8.product,
    subcategory = _ref8.subcategory,
    specials = _ref8.specials;
  var href = routeRoot;
  if (brands) {
    href += "".concat(SHARED_ROUTES.BRANDS, "?brands=").concat(encodeURIComponent(brands));
  } else if (specials) {
    href += SHARED_ROUTES.SPECIALS;
  } else if (product) {
    href += SHARED_ROUTES.PRODUCT + encodeURIComponent(product.cName || product.id);
  } else if (category && category.key) {
    href += SHARED_ROUTES.PRODUCTS + encodeURIComponent(category.key);
    if (subcategory) {
      href += "/".concat(encodeURIComponent(subcategory));
    }
  }
  return {
    href: href
  };
}
function isStoreFrontRouteRoot(routeRoot) {
  if (!routeRoot) {
    return false;
  }
  var splitRoute = _.split(routeRoot, '/');
  // Storefront routes  have one of two patterns:
  // domain/store/{cName} || domain/stores/{cName}
  return splitRoute.length > 1 && _.includes(['stores', 'store'], splitRoute[splitRoute.length - 2]);
}
function buildCheckoutRoute(_ref9) {
  var dispensary = _ref9.dispensary,
    isDemo = _ref9.isDemo,
    isStoreFront = _ref9.isStoreFront,
    isEmbedded = _ref9.isEmbedded,
    isMobileEcommApp = _ref9.isMobileEcommApp;
  var href, route;
  if (isDemo) {
    href = SHARED_ROUTES.DEMO;
    route = SHARED_ROUTES.DEMO;
  } else if (isEmbedded && dispensary) {
    // embedded-menu has its own checkout route
    href = "".concat(SHARED_ROUTES.EMBEDDED_MENU, "/").concat(dispensary.cName);
    route = SHARED_ROUTES.EMBEDDED_MENU_CNAME;
  } else if (isStoreFront && dispensary) {
    // storefront has its own checkout route
    href = "".concat(SHARED_ROUTES.STORES, "/").concat(dispensary.cName);
    route = SHARED_ROUTES.STORES_CNAME;
  } else if (isMobileEcommApp && dispensary) {
    // mobile ecomm has its own checkout route
    href = "".concat(SHARED_ROUTES.MOBILE_ECOMM, "/").concat(dispensary.cName);
    route = SHARED_ROUTES.MOBILE_ECOMM;
  } else {
    href = '';
    route = '';
  }
  href += SHARED_ROUTES.CHECKOUT;
  route += SHARED_ROUTES.CHECKOUT;
  return [href, route];
}
function buildHomeRoute() {
  return ["/", "/"];
}
function buildDispensariesRoute() {
  return [SHARED_ROUTES.DISPENSARIES, SHARED_ROUTES.DISPENSARIES];
}
export { addDispensaryRoutes, addMenuSectionRoutes, addProductRoutes, addSpecialsRoutes, buildBaseRoute, buildCheckoutRoute, buildDispensariesRoute, buildExternalTemplateRoute, buildHomeRoute, buildStoreFrontRoute, isStoreFrontRouteRoot };