import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapApplyRewardsToConditions, mapConsumerTypes, mapDirectCopyFields, mapEnumFields, mapGlobalApplicationRules, mapSchedule, mapSpecialId, mapStackingBehavior } from './other-mappers';
import { FIELD_DEFAULTS } from './constants';
import { mapGlobalRewardThresholds, mapRewards } from './reward-mappers';
import { mapConditions, mapGlobalConditionThresholds } from './condition-mappers';
export var toSchemaV4 = function toSchemaV4(special) {
  var _ref, _directCopyFields$nam, _special$paymentRestr;
  var dispensary = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var conditions = null;
  var rewards = null;
  var globalApplicationRules = null;
  var globalConditionThresholds = null;
  var globalRewardThresholds = null;
  var _dispensary$dispensar = dispensary.dispensaryTimezone,
    dispensaryTimezone = _dispensary$dispensar === void 0 ? 'America/Los_Angeles' : _dispensary$dispensar;
  var directCopyFields = mapDirectCopyFields(special);
  var consumerTypes = mapConsumerTypes(special.menuType);
  var enumFields = mapEnumFields(special);
  var id = mapSpecialId(special);
  var schedule = mapSchedule(special, dispensaryTimezone);
  var stackingBehavior = mapStackingBehavior(special);
  if (special.specialType === 'sale') {
    rewards = mapRewards(special, 'saleDiscounts');
  }
  if (special.specialType === 'bogo') {
    // TODO: Dear super cool engineer in the future, do we want to differentiate POS and ecomm paths?
    // rewards = special.source === 'POS' ? mapRewards(special, 'bogoRewards') : mapAdvancedRewards(special);
    rewards = mapRewards(special, 'bogoRewards');
    conditions = mapConditions(special);
    globalApplicationRules = mapGlobalApplicationRules(special);
    globalConditionThresholds = mapGlobalConditionThresholds(special);
    globalRewardThresholds = mapGlobalRewardThresholds(special);
  }
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, FIELD_DEFAULTS), directCopyFields), enumFields), {}, {
    applyRewardToConditions: mapApplyRewardsToConditions(special, rewards),
    conditions: conditions,
    consumerTypes: consumerTypes,
    directPOSProductIdMapping: (special === null || special === void 0 ? void 0 : special.directPOSProductIdMapping) || false,
    rawPOSWeightMapping: (special === null || special === void 0 ? void 0 : special.rawPOSWeightMapping) || false,
    useActiveBatchTagOfWeightOption: (special === null || special === void 0 ? void 0 : special.useActiveBatchTagOfWeightOption) || false,
    id: id,
    globalApplicationRules: globalApplicationRules,
    globalConditionThresholds: globalConditionThresholds,
    globalRewardThresholds: globalRewardThresholds,
    name: (_ref = (_directCopyFields$nam = directCopyFields.name) !== null && _directCopyFields$nam !== void 0 ? _directCopyFields$nam : special === null || special === void 0 ? void 0 : special.specialName) !== null && _ref !== void 0 ? _ref : special.name,
    // To account for both naming conventions
    paymentRestrictions: (_special$paymentRestr = special === null || special === void 0 ? void 0 : special.paymentRestrictions) !== null && _special$paymentRestr !== void 0 ? _special$paymentRestr : {
      payByBankSignupIncentive: false
    },
    rewards: rewards,
    schedule: schedule,
    stackingBehavior: stackingBehavior
  });
};