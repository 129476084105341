import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';

import { useGetCollectionsNavigationQuery } from 'types/graphql';
import ROUTES from 'src/routes';
import usePaths from 'src/hooks/use-paths';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import { tracker } from 'src/utils/analytics';
import { LoginCta } from 'src/components/ctas';

type ShopTabProps = {
  onClose: () => void;
};

export const ShopTab = ({ onClose }: ShopTabProps): JSX.Element => {
  const { dispensary } = useDispensary();
  const { categories } = useDispensaryCategories();
  const { href: baseHref } = usePaths();
  const { PRODUCTS, COLLECTIONS } = ROUTES;

  const handleLinkClick = (label: string): void => {
    tracker.gaGTMClickEvent({
      eventCategory: `navigation click`,
      eventAction: `main menu`,
      eventLabel: label,
    });

    onClose();
  };

  const { data } = useGetCollectionsNavigationQuery({
    variables: {
      dispensaryId: dispensary.id,
    },
  });

  const collections = data?.getCollectionsNavigation ?? [];

  return (
    <Container>
      <Nav>
        <div>
          <NavHeading>Categories</NavHeading>
          <NavList>
            {categories.map(({ key, label }) => (
              <li key={key}>
                <Link href={`${baseHref}${PRODUCTS}${key}`}>
                  <NavLink
                    href={`${baseHref}${PRODUCTS}${key}`}
                    onClick={() => handleLinkClick(`categories > ${label}`)}
                    data-testid='menu-drawer-category-link'
                  >
                    {label}
                  </NavLink>
                </Link>
              </li>
            ))}
          </NavList>
        </div>

        {collections.length > 0 && (
          <div>
            <NavHeading>Collections</NavHeading>
            <NavList>
              {collections.map(({ slug, navigationTitle, title }) => (
                <li key={slug}>
                  <Link href={`${baseHref}${COLLECTIONS}${slug}`}>
                    <NavLink
                      href={`${baseHref}${COLLECTIONS}${slug}`}
                      onClick={() => handleLinkClick(`collections > ${navigationTitle ?? title}`)}
                    >
                      {navigationTitle ?? title}
                    </NavLink>
                  </Link>
                </li>
              ))}
            </NavList>
          </div>
        )}
      </Nav>

      <LoginCta />
    </Container>
  );
};

const Container = styled.div`
  padding: 16px 32px;
  max-height: 100%;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

const NavHeading = styled.h2`
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  margin: 16px 0;
`;

const NavList = styled.ul`
  font-size: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavLink = styled.a`
  color: #646d72;
  display: block;
  font-size: 20px;
  line-height: 1;
  padding: 16px 0;
`;
