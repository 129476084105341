import {
  RadiusOption,
  TextTransformOption,
  CategoriesTypeOption,
  CategoriesMobileDisplayOption,
  ProductCardDefaultViewOption,
} from 'types/graphql';

export const textTransforms = [
  {
    label: 'UPPERCASE',
    value: TextTransformOption.upper,
    style: 'uppercase',
  },
  {
    label: 'Title Case',
    value: TextTransformOption.title,
    style: 'capitalize',
  },
  {
    label: 'Sentence case',
    value: TextTransformOption.sentence,
    style: 'none',
  },
  {
    label: 'lowercase',
    value: TextTransformOption.lower,
    style: 'lowercase',
  },
];

export const radius = [
  {
    label: 'Rounded',
    value: RadiusOption.rounded,
    style: {
      xs: '2px',
      sm: '4px',
      md: '8px',
      lg: '12px',
      xl: '16px',
      tags: '30px',
      cards: '16px',
      buttons: '9999px',
    },
  },
  {
    label: 'Rounded Rectangle',
    value: RadiusOption.roundedRectangle,
    style: {
      xs: '2px',
      sm: '4px',
      md: '8px',
      lg: '12px',
      xl: '16px',
      tags: '4px',
      cards: '12px',
      buttons: `8px`,
    },
  },
  {
    label: 'Square',
    value: RadiusOption.square,
    style: {
      xs: '0',
      sm: '0',
      md: '0',
      lg: '0',
      xl: '0',
      tags: '0',
      cards: '0',
      buttons: '0',
    },
  },
];

export const categoriesTypes = [
  {
    label: 'Large',
    value: CategoriesTypeOption.large,
  },
  {
    label: 'Compact',
    value: CategoriesTypeOption.compact,
  },
  {
    label: 'Stacked',
    value: CategoriesTypeOption.stacked,
  },
  {
    label: 'Text',
    value: CategoriesTypeOption.text,
  },
];

export const categoriesMobileDisplays = [
  {
    label: 'Grid',
    value: CategoriesMobileDisplayOption.grid,
  },
  {
    label: 'Row',
    value: CategoriesMobileDisplayOption.row,
  },
];

export const productCardDefaultViews = [
  {
    label: 'Card',
    value: ProductCardDefaultViewOption.card,
  },
  {
    label: 'List',
    value: ProductCardDefaultViewOption.list,
  },
];
