import React, { SVGProps } from 'react';

type GreyBoltProps = SVGProps<SVGSVGElement> & { color?: string };

export function GreyBolt({ color = '#5d666d', ...props }: GreyBoltProps): JSX.Element {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Flash-1--Streamline-Ultimate' clipPath='url(#clip0_2132_11097)'>
        <path
          id='Vector'
          d='M14.4674 9.46971C14.5403 9.35132 14.5802 9.21564 14.5831 9.07667C14.586 8.9377 14.5518 8.80047 14.484 8.67912C14.4162 8.55778 14.3173 8.45671 14.1974 8.38634C14.0775 8.31598 13.9411 8.27885 13.8021 8.27881H11.146V1.40381L5.53298 10.5262C5.46009 10.6445 5.42011 10.7801 5.41717 10.9191C5.41423 11.058 5.44843 11.1953 5.51625 11.3166C5.58407 11.4379 5.68304 11.5389 5.80295 11.6092C5.92285 11.6794 6.05934 11.7164 6.19832 11.7163H8.85437V18.5913L14.4674 9.46971Z'
          stroke={color}
          strokeWidth='1.5'
        />
      </g>
      <defs>
        <clipPath id='clip0_2132_11097'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
