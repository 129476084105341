import { isEmpty, isEqual, kebabCase } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { ApolloError } from 'apollo-client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';

import { Product } from 'types/graphql-overrides';
import {
  GqlGetSponsoredProductsInput,
  SponsoredProductsInventoryId,
  useGetSponsoredProductsLazyQuery,
} from 'types/graphql';

import useProductQueryParams from 'src/hooks/use-product-query-params';

import { getAccuratePotencyValues } from 'shared/helpers/products';
import { parseProduct, potencyWithinRange } from 'src/utils/helpers/product';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { ADS_FETCH_POLICY } from 'src/utils/ads/helpers';
import { useFiltersForSponsoredResources } from 'src/dispensary/hooks/use-filters-for-sponsored-resources';

type PrependWithSponsoredProductsArgs = Omit<GqlGetSponsoredProductsInput, 'dispensaryId'> & {
  skip?: boolean;
  products: Product[];
  loading: boolean;
  error: ApolloError | undefined;
};

type PrependWithSponsoredProducts = {
  products: Product[];
  loading: boolean;
  error: ApolloError | undefined;
};

// TODO: Can we replace https://github.com/GetDutchie/Dutchie/blob/c933e75d45482bbd154e85fcaf9c427ca72569a6/marketplace/src/dispensary/hooks/use-products-for-category-page.ts#L25 with this?
const getSponsoredProductsLimit = (inventoryId: string, flags: any): number => {
  const categoryPageLimit = flags['growth.ads.sponsored-products.increased-display-count'] ?? 1;
  const cartTopperLimit = flags['growth.ads.sponsored-products.cart-topper-increased-display-count'] ?? 1;
  const pdpSponsoredProductsLimit = flags['growth.ads.sponsored-products.pdp-increased-display-count'] ?? 1;
  const searchPageLimit = 1;

  if (inventoryId === SponsoredProductsInventoryId.searchProductAuc) {
    return searchPageLimit;
  }

  if (inventoryId === SponsoredProductsInventoryId.cartTopperProductAuc) {
    return cartTopperLimit;
  }

  if (inventoryId === SponsoredProductsInventoryId.pdpProductAuc) {
    return pdpSponsoredProductsLimit;
  }

  return categoryPageLimit;
};

// Filter sponsored products based on the current menu filters
// We will make an attempt to request sponsored products given the current filters, but prior to display we must ensure
// they are correctly filtered.
export function useSponsoredProductsFilter(
  sponsoredProductsData: Product[] | undefined,
  query: any,
  qp: any,
  defaults: any,
  category: string,
  dispensaryId?: string
): Product[] {
  const searchFilterEnabled = useDispensaryFlag(
    'growth.ads.sponsored-products-search-filter.rollout',
    dispensaryId,
    false
  );

  return useMemo(() => {
    if (!sponsoredProductsData) {
      return [];
    }

    return sponsoredProductsData.filter((product) => {
      // Ideally this would filter by brand ID instead of name, because the product denormalized brand name is unreliable.
      // but there are two problems:
      // 1. The brand ID is not present in the query params, only the slugified name
      // 2. The brand filter list deduplicates by name, potentially causing the correct ID to be unselectable
      // Therefore this approach may cause a sponsored product from a similarly named brand to appear in the filtered list.
      const productBrandNameSlug = kebabCase(product.brandName ?? '');

      const viewingSubcategory = query.subcategory ?? qp.subcategories[0];
      const potencyRanges = getAccuratePotencyValues(product);

      const filters = [
        () => isEmpty(category) || product.type === category,
        () => isEmpty(viewingSubcategory) || viewingSubcategory === product.subcategory,
        // Show the product anyway if the product denormalized brand name is not present, see note above.
        () => !qp.brands.length || !productBrandNameSlug || qp.brands.includes(productBrandNameSlug),
        () => !qp.straintypes.length || qp.straintypes.includes(product.strainType),
        // Need to use category from query params to match default potency ranges:
        () =>
          !qp.potencythc ||
          isEqual(qp.potencythc, defaults.potencythc) ||
          potencyWithinRange(potencyRanges.THC, qp.potencythc),
        () =>
          !qp.potencycbd ||
          isEqual(qp.potencycbd, defaults.potencycbd) ||
          potencyWithinRange(potencyRanges.CBD, qp.potencycbd),
        () => !qp.search || !searchFilterEnabled || product.Name?.toLowerCase().includes(qp.search.toLowerCase()),
        // product.effects does not seem to be present here, due to the field being absent in consumerBaseProductFragment?
        () => isEqual(qp.effects, defaults.effects), // || qp.effects.every(e => product.effects.includes(e)),
      ];

      return filters.every((filter) => filter());
    });
  }, [sponsoredProductsData, query, qp, defaults, category, searchFilterEnabled]);
}

export function usePrependWithSponsoredProducts({
  skip = true,
  products,
  loading: productsLoading,
  error: productsError,
  limit,
  inventoryId,
  category,
  searchQuery,
  productId,
}: PrependWithSponsoredProductsArgs): PrependWithSponsoredProducts {
  const { dispensary } = useDispensary();
  const dispensaryId = dispensary?.id;
  const safeCategory = category ?? undefined;
  const flags = useFlags();
  const sponsoredProductsLimit = getSponsoredProductsLimit(inventoryId, flags);
  const { filters } = useFiltersForSponsoredResources();
  const [
    getSponsoredProducts,
    { data, loading: sponsoredProductsLoading, error: sponsoredProductsError, called },
  ] = useGetSponsoredProductsLazyQuery({
    fetchPolicy: ADS_FETCH_POLICY,
    variables: {
      input: {
        limit,
        dispensaryId,
        inventoryId,
        category: safeCategory,
        searchQuery,
        productId,
        filters,
      },
    },
  });

  useEffect(() => {
    if (skip || called || !dispensaryId || productsError || sponsoredProductsLimit === 0) {
      return;
    }

    getSponsoredProducts();
  }, [getSponsoredProducts, skip, called, dispensaryId, productsError, sponsoredProductsLimit]);

  const unfilteredSponsoredProductsData: Product[] = data?.getSponsoredProducts as Product[];
  const { query } = useRouter();
  const { queryParams: qp, defaults } = useProductQueryParams();

  // Remove sponsored products which do not match the current menu filters
  const sponsoredProductsData = useSponsoredProductsFilter(
    unfilteredSponsoredProductsData,
    query,
    qp,
    defaults,
    category ?? '',
    dispensaryId
  );

  const serializedProducts = useMemo(() => {
    if (productsLoading || sponsoredProductsLoading || productsError) {
      return [];
    }

    if (skip || !sponsoredProductsData.length || sponsoredProductsError) {
      return products;
    }

    const sponsoredProducts = sponsoredProductsData.slice(0, sponsoredProductsLimit).map(parseProduct);

    const sponsoredProductsIds = new Set(sponsoredProducts.map((item) => item.id));

    return sponsoredProducts.concat(products.filter((item) => !sponsoredProductsIds.has(item.id)));
  }, [
    skip,
    products,
    productsLoading,
    productsError,
    sponsoredProductsData,
    sponsoredProductsLoading,
    sponsoredProductsError,
    sponsoredProductsLimit,
  ]);

  return {
    products: serializedProducts,
    loading: productsLoading || sponsoredProductsLoading,
    error: productsError,
  };
}
