import React, { useState } from 'react';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Typography } from 'src/typography';
import LoginForm from 'components/forms/login-form';
import SignUpForm from 'components/forms/sign-up-form';
import { GoogleLogin, AppleLogin } from 'src/components/social-login';
import { Divider } from 'src/components/divider';
import { INSTORE_ENROLLMENT_SOURCE } from 'src/components/instore-enrollment';

export function Login(): JSX.Element {
  const [displaySignup, setDisplaySignup] = useState(false);
  const flags = useFlags();
  const isAppleLoginEnabled = flags[`growth.ecomm.sign-in-apple.rollout`] ?? false;

  return (
    <MainContainer data-testid='login-main-container'>
      {!displaySignup && (
        <ContentContainer data-testid='login-content-container'>
          <HeadingContainer>
            <Typography.Heading size='medium'>Log in to your account</Typography.Heading>
          </HeadingContainer>
          <SocialLogins>
            <GoogleLogin redirectUrl={window.location.href} />
            {isAppleLoginEnabled && <AppleLogin redirectUrl={window.location.href} />}
          </SocialLogins>
          <Divider text='or login with your email' />
          <LoginForm analyticsEventLabel={INSTORE_ENROLLMENT_SOURCE} />
          <SignupTextContainer>
            <BodyText size='small'>Don't have an account?</BodyText>
            <SignupLink data-testid='signup-link' size='small' onClick={() => setDisplaySignup(true)}>
              Sign Up
            </SignupLink>
          </SignupTextContainer>
        </ContentContainer>
      )}
      {displaySignup && (
        <ContentContainer data-testid='signup-content-container'>
          <HeadingContainer>
            <Typography.Heading size='medium'>Create an account</Typography.Heading>
          </HeadingContainer>
          <SocialLogins>
            <GoogleLogin redirectUrl={window.location.href} />
            {isAppleLoginEnabled && <AppleLogin redirectUrl={window.location.href} />}
          </SocialLogins>
          <Divider text='or enter your information' />

          <SignUpForm analyticsEventLabel={INSTORE_ENROLLMENT_SOURCE} displayLoginLink={false} />
          <LoginTextContainer>
            <BodyText size='small'>Already have an account?</BodyText>
            <SignupLink data-testid='login-link' size='small' onClick={() => setDisplaySignup(false)}>
              Log In
            </SignupLink>
          </LoginTextContainer>
        </ContentContainer>
      )}
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentContainer = styled.div`
  padding: 40px;
`;

const BodyText = styled(Typography.Body)`
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grey[45]};
`;

const SocialLogins = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SignupTextContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces[1]};
  justify-content: center;
  margin-top: 25px;
`;

const SignupLink = styled(Typography.Body)`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
`;

const LoginTextContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-top: -29px;
`;

const HeadingContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
`;
