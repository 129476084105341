import React from 'react';
import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { ModalObserver } from '../modal-observer';
import { ChainLocationBrowserContainer } from './chain-location-browser-container';
import { ModalNames } from '../modals.types';

type Props = {
  name: ModalNames;
};

export const ChainLocationBrowserModal = ({ name }: Props): JSX.Element => (
  <ModalObserver name={name}>
    {(props) => (
      <ModalWrapper {...props}>
        <ChainLocationBrowserContainer {...props} />
      </ModalWrapper>
    )}
  </ModalObserver>
);
