import { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useObserver } from 'mobx-react-lite';
import { GqlDispensaries } from 'types/graphql';
import useCart from 'src/hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUser from 'src/hooks/use-user';
import useErnie from 'shared/hooks/use-ernie';
import { Statuses, Integrations, UseLoyaltyProps, UseLoyaltyReturn } from './types';
import { createAlpineLoyalty } from './use-alpine-loyalty';
import { createSpringbigLoyalty } from './use-springbig-loyalty';
import { createNoLoyalty } from './no-loyalty';

export { Statuses, Integrations };

export const useLoyalty = ({ initialStatus, integration }: UseLoyaltyProps): UseLoyaltyReturn => {
  const [status, setStatus] = useState(initialStatus);
  const { dispensary } = useDispensary();
  const Cart = useCart();
  const User = useUser();
  const apolloClient = useApolloClient();
  const showErnie = useErnie();

  const phone = useObserver(() => User.phone);
  const birthday = useObserver(() => User.birthday);
  const email = useObserver(() => User.email);
  const firstName = useObserver(() => User.firstName);
  const lastName = useObserver(() => User.lastName);

  const alpineDeps = {
    dispensary,
    cart: Cart,
    user: {
      phone,
      birthday,
      email,
      firstName,
      lastName,
      setLoyaltyPin: User.setLoyaltyPin,
      hasLoyaltyAccount: User.hasLoyaltyAccount,
    },
    apolloClient,
    showErnie,
  };

  const springbigDeps = {
    dispensary,
    cart: Cart,
    user: {
      id: User.id,
      phone,
      birthday,
    },
    apolloClient,
    showErnie,
  };

  switch (integration) {
    case Integrations.alpineiq:
      return createAlpineLoyalty({ status, setStatus, deps: alpineDeps });
    case Integrations.dutchiepos:
      return createAlpineLoyalty({ status, setStatus, deps: alpineDeps });
    case Integrations.springbig:
      return createSpringbigLoyalty({ status, setStatus, deps: springbigDeps });
    case Integrations.none:
      return createNoLoyalty({ status, setStatus });
  }
  return createNoLoyalty({ status, setStatus });
};

export const detectLoyaltyIntegration = (dispensary: GqlDispensaries | undefined): Integrations => {
  if (!dispensary) {
    return Integrations.none;
  }

  const alpineEnabled = dispensary.consumerDispensaryIntegrations?.alpineiq?.enabled;
  const springbigEnabled = dispensary.consumerDispensaryIntegrations?.springbig?.enabled;
  const dutchieLoyaltyEnable = dispensary.consumerDispensaryIntegrations?.dutchieposloyalty?.enabled;

  if (alpineEnabled) {
    return Integrations.alpineiq;
  }
  if (springbigEnabled) {
    return Integrations.springbig;
  }
  if (dutchieLoyaltyEnable) {
    return Integrations.dutchiepos;
  }

  return Integrations.none;
};
