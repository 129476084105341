import useUI from 'src/hooks/use-ui';
import { GqlProductDemandCount } from 'types/graphql';
import { useState, useCallback, useMemo, useRef } from 'react';

// Minumum count of products in a cart to be displayed. Only products with a count of N or
// more will display the tag.
const minProductDemandCount = 3;

// Maximum number of items to return. Only N number of products in the cart will display the tag.
const maxProductDemandCountItems = 3;

// Number of product IDs to group by. For every N product IDs, 1 tag will be displayed.
const groupSize = 3;

/**
 * Sorts and filters product demand counts to determine which products should display demand tags.
 * Products are filtered based on minimum count thresholds and whether they've been seen before.
 * The function maintains state of which products have been seen or filtered out via Sets.
 * Products are grouped together (e.g. 1 tag per N products) and limited to a maximum number of items.
 * Once a demand tag is shown for a product, it will continue to be shown. Similarly, if a product
 * did not receive a demand tag initially, it will not show one in the future.
 *
 * @param productDemandCounts Array of product demand count objects
 * @param productIds Array of product IDs to process
 * @param seenProductDemandCounts Set of previously seen product IDs
 * @param notSeenProductDemandIds Set of product IDs that have been filtered out
 * @returns Array of product IDs that should display demand tags
 */
function sortAndFilterProductDemandCounts(
  productDemandCounts: GqlProductDemandCount[],
  productIds: string[],
  seenProductDemandCounts: Set<string>,
  notSeenProductDemandIds: Set<string>
): string[] {
  // Create a Set of productIds for O(1) lookups
  const productIdSet = new Set(productIds);

  // Single pass to filter and partition counts
  const { validSeenCounts, validNewCounts } = productDemandCounts.reduce(
    (acc, productDemandCount) => {
      if (notSeenProductDemandIds.has(productDemandCount.id) || !productIdSet.has(productDemandCount.id)) {
        return acc;
      }

      const countValue = productDemandCount.count;
      const isSeen = seenProductDemandCounts.has(productDemandCount.id);

      if (countValue >= minProductDemandCount || isSeen) {
        if (isSeen) {
          acc.validSeenCounts.push(productDemandCount);
        } else {
          acc.validNewCounts.push(productDemandCount);
        }
      }
      return acc;
    },
    {
      validSeenCounts: [] as GqlProductDemandCount[],
      validNewCounts: [] as GqlProductDemandCount[],
    }
  );

  // Sort new counts by count value (seen counts don't need sorting)
  validNewCounts.sort((a, b) => (b.count || 0) - (a.count || 0));

  const numToReturn = Math.ceil(productIds.length / groupSize);
  const newCountsToInclude = validNewCounts.slice(
    0,
    Math.min(numToReturn - validSeenCounts.length, maxProductDemandCountItems - validSeenCounts.length)
  );

  const result = [...validSeenCounts, ...newCountsToInclude].map((count) => count.id);

  // Update seen and filtered IDs in a single pass
  if (result.length) {
    result.forEach((id) => seenProductDemandCounts.add(id));
  }

  const resultIds = new Set(result);

  // Only process productIds that aren't in the result and aren't already filtered
  productIds.forEach((id) => {
    if (!resultIds.has(id) && !notSeenProductDemandIds.has(id)) {
      notSeenProductDemandIds.add(id);
    }
  });

  return result;
}

type UseProductDemandParams = {
  productIds: string[];
};

type UseProductDemandReturn = {
  visibleProductDemandCounts: string[];
  handleProductDemandChange: (productId: string, productDemandCount: GqlProductDemandCount | undefined) => void;
};

export default function useProductDemand({ productIds }: UseProductDemandParams): UseProductDemandReturn {
  const UI = useUI();
  const productDemandCounts = useRef<Record<string, GqlProductDemandCount | undefined>>({});
  const [isLoaded, setIsLoaded] = useState(false);

  const handleProductDemandChange = useCallback(
    (productId: string, productDemandCount: GqlProductDemandCount | undefined): void => {
      productDemandCounts.current = { ...productDemandCounts.current, [productId]: productDemandCount };
      const loadedIds = Object.keys(productDemandCounts.current);
      if (loadedIds.length === productIds.length) {
        setIsLoaded(true);
      }
    },
    [productIds]
  );

  const visibleProductDemandCounts = useMemo(() => {
    if (!isLoaded) {
      return [];
    }

    return sortAndFilterProductDemandCounts(
      Object.values(productDemandCounts.current).filter((count): count is GqlProductDemandCount => !!count),
      productIds,
      UI.productDemand.seenIds,
      UI.productDemand.notSeenIds
    );
  }, [productDemandCounts, isLoaded, productIds, UI.productDemand.seenIds, UI.productDemand.notSeenIds]);

  return {
    visibleProductDemandCounts,
    handleProductDemandChange,
  };
}
