export var integrationsByCategory = {
  pos: {
    leaflogix: 'Dutchie Point of Sale',
    greenbitsV2: 'Green Bits',
    flowhub: 'Flowhub',
    biotrack: 'Biotrack',
    treezV2: 'Treez',
    cova: 'COVA',
    mjfreeway: 'MJ Freeway',
    dauntless: 'Dauntless',
    adilas: 'Adilas',
    blaze: 'Blaze',
    d365: 'D365',
    proteus: 'Proteus',
    weave: 'Weave',
    naturesMedicine: "Nature's Medicines",
    klickTrack: 'KlickTrack',
    meadow: 'Meadow',
    growflow: 'GrowFlow',
    greenline: 'Greenline',
    vend: 'Vend',
    shopify: 'Shopify',
    posabit: 'POSaBIT',
    techpos: 'TechPOS',
    anthea: 'Anthea',
    sndl: 'SNDL',
    domo: 'DOMO'
  },
  fleetManagement: {
    onfleet: 'onfleet',
    cannveya: 'Cannveya',
    pineappleExpress: 'Pineapple Express Delivery',
    bluedot: 'Bluedot'
    // cancurrent: 'Cancurrent', - Disabled until launch planning is completed. CO, 1/6/20
  },
  crm: {
    hubspot: 'Hubspot'
  },
  analytics: {
    'google-analytics': 'Google Analytics',
    'google-tag-manager': 'Google Tag Manager'
  },
  payments: {
    merrco: 'Merrco',
    hypur: 'Hypur',
    moneris: 'Moneris',
    chase: 'Chase Paymentech'
  },
  rewards: {
    alpineiq: 'Alpineiq',
    fyllo: 'Fyllo',
    springbig: 'Springbig',
    dutchieposloyalty: 'Dutchie POS Loyalty'
  },
  menuImport: {
    menuImport: 'Menu Import'
  }
};
export var defaultFlowerThresholds = {
  '1g': 5,
  '2g': 5,
  '1/8oz': 5,
  '1/4oz': 3,
  '1/2oz': 1,
  '1oz': 0
};