/* eslint-disable @typescript-eslint/no-empty-function */

import { USStatesMap } from 'shared/constants/cities-geography';
import { GqlDispensaries } from 'types/graphql';
import {
  Dispensary,
  CommerceComponentsDataInterface,
  DispensaryHoursSettings,
  OrderTypesConfigV2,
  Cart,
  CommerceComponentsMenuContext,
  User,
} from './types';

const mapDispensaryToRemote = (dispensary: GqlDispensaries): Dispensary => {
  const enabledOrderTypes = dispensary.enabledOrderTypes ?? {};

  return {
    id: dispensary.id,
    status: dispensary.status ?? '',
    name: dispensary.name ?? '',
    cname: dispensary.cName ?? '',
    chain: dispensary.chain ?? '',
    phone: dispensary.phone ?? '',
    email: dispensary.email ?? '',
    hours: (dispensary.hoursSettings ?? {}) as DispensaryHoursSettings,
    orderTypes: {
      pickup: !!enabledOrderTypes.pickup,
      inStorePickup: !!enabledOrderTypes.inStorePickup,
      curbsidePickup: !!enabledOrderTypes.curbsidePickup,
      driveThruPickup: !!enabledOrderTypes.driveThruPickup,
      delivery: !!enabledOrderTypes.delivery,
      kiosk: !!enabledOrderTypes.kiosk,
    },
    images: {
      logo: dispensary.logoImage ?? '',
    },
    address: {
      street1: dispensary.location?.ln1 ?? '',
      street2: dispensary.location?.ln2 ?? '',
      city: dispensary.location?.city ?? '',
      state: dispensary.location?.state ? USStatesMap[dispensary.location.state] : undefined,
      stateAbbreviation: dispensary.location?.state ?? '',
      zip: dispensary.location?.zipcode ?? '',
    },
    links: {
      website: dispensary.menuUrl ?? '',
      storeFrontRoot: `/stores/${String(dispensary.cName ?? '')}`,
    },
    orderTypesConfig: dispensary.orderTypesConfigV2 as OrderTypesConfigV2,
  };
};

type PrepareDataLoaderProps = {
  menuContext: CommerceComponentsMenuContext;
  location?: GqlDispensaries;
  user?: User;
  cart?: Cart;
  dataLoaders: {
    getAllLocations: () => Promise<GqlDispensaries[]>;
  };
  actions: {
    goToInfoPage: () => void;
    goToStoreFront: () => void;
    goToStoreLocator: () => void;
    goToStoreBrowser: () => void;
    openLogin: () => void;
    openSignUp: () => void;
  };
};

const prepareDataForRemote = ({
  menuContext,
  location,
  dataLoaders: { getAllLocations },
  actions: { goToInfoPage, goToStoreFront, goToStoreLocator, goToStoreBrowser, openLogin, openSignUp },
}: PrepareDataLoaderProps): CommerceComponentsDataInterface => ({
  location: location ? mapDispensaryToRemote(location) : undefined,
  menuContext,
  dataLoaders: {
    getAllLocations: async () => {
      const dispensaryChainLocations = await getAllLocations();
      return dispensaryChainLocations.map(mapDispensaryToRemote);
    },
  },
  actions: {
    goToInfoPage,
    goToStoreFront,
    goToStoreLocator,
    goToStoreBrowser,
    openLogin,
    openSignUp,
  },
});

export { prepareDataForRemote };
