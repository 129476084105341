import useDispensary from 'src/dispensary/hooks/use-dispensary';
import {
  useGetChainDispensariesQuery,
  GqlGetChainDispensariesQueryVariables,
  DispensarySortByType,
} from 'types/graphql';
import { DispensaryChainReturn } from './types';

export function useDispensaryChains({
  address,
  deliversToAddress = false,
  activeOnly = false,
  includeStealthMode = false,
  skip = false,
}): DispensaryChainReturn {
  const { dispensary } = useDispensary();

  const variables: GqlGetChainDispensariesQueryVariables = {
    dispensaryFilter: {
      type: `Dispensary`,
      chain: dispensary?.chain,
      nearLat: address?.lat,
      nearLng: address?.lng,
      destinationTaxState: address?.state,
      destinationTaxZipcode: address?.zipcode,
      distance: address ? 1000.0 : null,
      deliversToAddress,
      activeOnly,
      includeStealthMode,
      sortBy: DispensarySortByType.distance,
    },
  };

  const { data, ...apolloValues } = useGetChainDispensariesQuery({
    variables,
    skip: !dispensary?.id || skip,
  });

  return { dispensaryChainLocations: data?.filteredDispensaries, ...apolloValues };
}
