import { GqlDispensaries as Dispensary } from 'types/graphql';
import { EmptyObject } from 'shared/utils/type-utils';

type MenuVariant = 'default' | 'embedded' | 'kiosk' | 'store-front';
type PersonalizationKey =
  | 'enablePersonalizationForDefault'
  | 'enablePersonalizationForEmbedded'
  | 'enablePersonalizationForKiosk'
  | 'enablePersonalizationForStoreFront';

type DispensaryInput = Dispensary | EmptyObject | null | undefined;

export function getMenuVariantEnabledForPersonalization(dispensary: DispensaryInput, variant: MenuVariant): boolean {
  if (!dispensary) {
    return false;
  }

  const { personalizationSettings } = dispensary;

  const personalizationSettingsMenuVariantMap: Record<MenuVariant, PersonalizationKey> = {
    default: 'enablePersonalizationForDefault',
    embedded: 'enablePersonalizationForEmbedded',
    kiosk: 'enablePersonalizationForKiosk',
    'store-front': 'enablePersonalizationForStoreFront',
  };

  const currentMenu = personalizationSettingsMenuVariantMap[variant];

  return personalizationSettings?.[currentMenu] ?? false;
}

export function getDispensaryEnabledForPersonalization(dispensary: DispensaryInput): boolean {
  if (!dispensary) {
    return false;
  }

  const { personalizationSettings } = dispensary;

  return (
    !!personalizationSettings?.enablePersonalizationForDefault ||
    !!personalizationSettings?.enablePersonalizationForEmbedded ||
    !!personalizationSettings?.enablePersonalizationForKiosk ||
    !!personalizationSettings?.enablePersonalizationForStoreFront
  );
}
