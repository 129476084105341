import React from 'react';

import { Heading, GreenHeading } from './pay-by-bank-enrollment-page.styles';

type PageHeadingProps = {
  bonusPoints?: number;
};

export function PageHeading({ bonusPoints }: PageHeadingProps): JSX.Element {
  if (!bonusPoints) {
    return (
      <Heading size='large' tag='h2'>
        <GreenHeading size='large'>You're in!</GreenHeading>
        Link your bank to checkout faster & avoid ATM + card fees
      </Heading>
    );
  }

  return (
    <Heading size='large' tag='h2'>
      You're in!
      <br />
      Complete your profile to get a<GreenHeading size='large'>{bonusPoints}pt bonus</GreenHeading>
    </Heading>
  );
}
