import React from 'react';

type SmallGreenQrCodeProps = React.SVGProps<SVGSVGElement>;

export function SmallGreenQrCode({ width = 24, height = 25, ...props }: SmallGreenQrCodeProps): JSX.Element {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 25' fill='none' {...props}>
      <path
        d='M5.75635 5.94043H8.53135V8.71543H5.75635V5.94043Z'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.75635 15.6528H8.53135V18.4278H5.75635V15.6528Z'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.4688 5.94043H18.2438V8.71543H15.4688V5.94043Z'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.75635 12.8779H11.3063V14.2654'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.0813 14.2651V18.4276H18.2438V14.2651H16.8563'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.3062 17.0405V18.428'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.3062 5.94043V10.1029H12.6937'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.4688 11.4902H18.2438'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.59375 6.63408V3.16533C1.59375 2.79734 1.73993 2.44443 2.00014 2.18422C2.26035 1.92401 2.61326 1.77783 2.98125 1.77783H6.45'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.55 1.77783H21.0188C21.3868 1.77783 21.7397 1.92401 21.9999 2.18422C22.2601 2.44443 22.4063 2.79734 22.4063 3.16533V6.63408'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.4063 17.7339V21.2026C22.4063 21.5706 22.2601 21.9235 21.9999 22.1837C21.7397 22.444 21.3868 22.5901 21.0188 22.5901H17.55'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.45 22.5901H2.98125C2.61326 22.5901 2.26035 22.444 2.00014 22.1837C1.73993 21.9235 1.59375 21.5706 1.59375 21.2026V17.7339'
        stroke='#00624A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
