import React, { useState } from 'react';
import styled from 'styled-components';
import { space, width } from 'styled-system';
import { useRouter } from 'next/router';

import useErnie from 'shared/hooks/use-ernie';
import useTranslation from 'hooks/use-translation';

import { useResetPasswordV2Mutation } from 'types/graphql';
import { FIELD_NAMES, ERNIE_TYPES } from 'shared/constants';
import { useChangePassword } from 'src/state/actions/users';
import { Button } from 'components/core';
import { userFormSchema } from 'components/forms/form-utils';
import { FormTextInput } from '../../form-inputs';
import Form from '../form';

const ChangePasswordForm = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const updatePassword = useChangePassword();
  const [resetPasswordV2Mutation] = useResetPasswordV2Mutation();

  const router = useRouter();
  const { resetToken } = router.query;
  const { t } = useTranslation();
  const showErnie = useErnie();

  const formValidationFields = {
    oldPassword: !resetToken,
    newPassword: true,
    confirmPassword: true,
  };

  async function changePassword(data) {
    if (resetToken) {
      try {
        const result = await resetPasswordV2Mutation({
          variables: {
            input: {
              token: resetToken,
              password: data.newPassword,
            },
          },
        });
        return result?.data?.resetPasswordV2;
      } catch (_e) {
        return { success: false };
      }
    }
    return updatePassword(data);
  }

  const onSubmit = async (data) => {
    setIsLoading(true);

    const { success, message } = await changePassword(data);

    if (success) {
      showErnie(
        t('changePasswordModal.passwordUpdated', 'Your password has been successfully updated!'),
        ERNIE_TYPES.SUCCESS
      );
      onClose();
    } else {
      const errorMessage = message || t('changePasswordModal.unknownError', 'Unable to update password at this time.');
      showErnie(errorMessage, ERNIE_TYPES.DANGER);
    }

    setIsLoading(false);
  };

  return (
    <Form onSubmit={onSubmit} validationSchema={userFormSchema(t, formValidationFields)}>
      <InputContainer>
        {!resetToken && (
          <FormTextInput
            label={t('changePasswordModal.oldPassword', 'Old Password')}
            variant='filled'
            name={FIELD_NAMES.OLD_PASSWORD}
            type='password'
          />
        )}
      </InputContainer>
      <InputContainer mt={15}>
        <FormTextInput
          label={t('changePasswordModal.newPassword', 'New Password')}
          variant='filled'
          name={FIELD_NAMES.NEW_PASSWORD}
          type='password'
        />
      </InputContainer>
      <InputContainer mt={15}>
        <FormTextInput
          label={t('changePasswordModal.confirmPassword', 'Confirm Password')}
          variant='filled'
          name={FIELD_NAMES.CONFIRM_PASSWORD}
          type='password'
        />
      </InputContainer>

      <ButtonContainer>
        <StyledButton type='submit' loading={isLoading}>
          {t('common.update', 'Update')}
        </StyledButton>
      </ButtonContainer>
    </Form>
  );
};

export default ChangePasswordForm;

export const InputContainer = styled.div`
  ${width}
  ${space}
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const StyledButton = styled(Button)`
  width: 95px;
`;
