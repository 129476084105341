import React, { useRef } from 'react';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import styled from 'styled-components';

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import useTranslation from 'src/hooks/use-translation';
import SearchClearIcon from 'assets/search-clear';
import SearchIcon from 'assets/search-icon';
import { colors } from 'shared/themes';

export enum SearchInputShapeVariant {
  pill = 'pill',
  marshmallow = 'marshmallow',
}

export type SearchInputProps = LayoutProps &
  SpaceProps & {
    autoFocus?: boolean;
    className?: string;
    onBlur?: () => void;
    onClear?: () => void;
    onClick?: () => void;
    onFocus?: () => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    searchIconColor?: string;
    shapeVariant?: SearchInputShapeVariant;
    value: string;
    withClearButton?: boolean;
  };

export function SearchInput(props: SearchInputProps): JSX.Element {
  const {
    autoFocus,
    className,
    mx,
    mt,
    onBlur,
    onChange,
    onClear,
    onClick,
    onFocus,
    onKeyPress,
    placeholder,
    searchIconColor = colors.grey[70],
    shapeVariant,
    value,
    width,
    withClearButton,
  } = props;

  const { t } = useTranslation();

  const defaultPlaceholder = t('search-placeholder', 'Search...');
  const showClearButton = value.length > 0 && withClearButton;
  const inputRef = useRef<HTMLInputElement>();

  function handleClearButtonClick(e): void {
    e.stopPropagation();
    onClear?.();
  }

  function StartAdornment(): JSX.Element {
    return (
      <InputAdornment position='start'>
        <SearchIcon id='search-icon' alt='' fill={searchIconColor} />
      </InputAdornment>
    );
  }

  function EndAdornment(): JSX.Element {
    return (
      <InputAdornment position='end'>
        <SearchClearIcon
          alt={t('clear-search-input-value', 'Clear search input value')}
          id='clear-icon'
          onClick={handleClearButtonClick}
          onMouseDown={(e) => e.preventDefault()}
        />
      </InputAdornment>
    );
  }

  return (
    <StyledFormControl
      _shapeVariant={shapeVariant ?? SearchInputShapeVariant.pill}
      className={className}
      tabIndex={0}
      width={width}
      mx={mx}
      mt={mt}
      onClick={() => inputRef.current?.focus()}
    >
      <InputField
        inputRef={inputRef}
        autoComplete='off'
        autoFocus={autoFocus}
        disableUnderline
        endAdornment={showClearButton ? <EndAdornment /> : undefined}
        id='search-input'
        inputProps={{ autoCapitalize: 'none', 'data-testid': 'search-input' }}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        placeholder={placeholder ?? defaultPlaceholder}
        startAdornment={<StartAdornment />}
        value={value}
      />
    </StyledFormControl>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledFormControl = styled(({ _shapeVariant, ...props }) => <FormControl {...props} />)<
  LayoutProps &
    SpaceProps & {
      _shapeVariant: SearchInputShapeVariant;
      width: number | string;
    }
>`
  &&& {
    width: 100%;
    justify-content: center;
    cursor: text;
    padding: 0 15px;
    height: ${({ _shapeVariant }) => (_shapeVariant === SearchInputShapeVariant.pill ? `40px` : `45px`)};
    border-radius: ${({ _shapeVariant }) => (_shapeVariant === SearchInputShapeVariant.pill ? `100px` : `5px`)};
    border: 1px solid ${({ theme }) => theme.colors.blueGrey[80]};
    background-color: ${({ theme }) => theme.colors.white};
    input {
      text-overflow: ellipsis;
    }
    .MuiInputAdornment-root-positionStart {
      margin-right: 9px;
    }
    #search-icon {
      fill: ${({ searchIconColor }) => searchIconColor};
      width: 18px;
      height: 17px;
    }
    #clear-icon {
      fill: ${({ theme }) => theme.colors.grey[70]};
      width: 18px;
      height: 18px;
      :hover {
        fill: ${({ theme }) => theme.colors.grey[45]};
        cursor: pointer;
      }
    }
    ${layout}
    ${space}
  }
`;

const InputField = styled(Input)`
  &&& input {
    padding: 0;
    line-height: 13px;
    font-size: 16px;
    ${({ theme }) => theme.breakpoints.up('md')} {
      font-size: 14px;
    }
    color: ${({ theme }) => theme.colors.grey[35]};
    ::placeholder {
      color: ${({ theme }) => theme.colors.grey[45]};
      opacity: 1;
    }
  }
  &&& .focus-visible {
    box-shadow: none;
  }
`;
