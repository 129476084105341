/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { object } from 'yup';
import _ from 'lodash';
import { useApolloClient } from '@apollo/react-hooks';

import { yup } from 'shared/core/yup';
import { addCustomerToOrder } from 'shared/graphql/order/mutations';
import { useConsumerSignup } from 'src/state/actions/users';
import useUI from 'hooks/use-ui';
import useErnie from 'shared/hooks/use-ernie';
import useCart from 'hooks/use-cart';
import useUser from 'hooks/use-user';
import useTranslation from 'hooks/use-translation';
import { ResetButton } from 'shared/components';

import { useModals, ModalNames } from 'components/modals';
import { emailRegEx, birthdayRegEx } from 'components/forms/form-utils';
import { FormTextInput, FormFormattedInput } from 'components/form-inputs';
import { mediaQueries } from 'shared/styles';
import { Link, Button } from 'components/core';
import Form from 'components/forms/form';
import { MobileEcommTermsLink } from 'src/mobile-ecomm/components/terms-link';

import ROUTES from 'src/routes';

export default function GuestCheckoutSuccessSignupForm({ onClose }) {
  const { t } = useTranslation();
  const { openModal } = useModals();
  const UI = useUI();
  const user = useUser();
  const showErnie = useErnie();
  const apolloClient = useApolloClient();
  const consumerSignup = useConsumerSignup();
  const [loading, setLoading] = useState(false);
  const { isDutchieMain } = useUI();

  const cart = useCart();
  const stashedOrder = cart?.stashedOrder;
  const guestInfoToSave = stashedOrder?.guestInfoToSave;
  const dispensary = stashedOrder?.dispensary;
  const termsCopy = t('guest-checkout-modal.terms', 'Terms');

  const createAccount = async ({ email, password, birthday }) => {
    // start loading and assign values to profile
    setLoading(true);

    const stashedCustomer = guestInfoToSave.customer;

    const userData = {
      profile: {
        birthday,
        ...stashedCustomer.profile,
      },
      email,
      password,
    };

    const userCreationData = {
      dispensaryId: dispensary.id,
      embedded: UI.isVariant([`embedded`, `store-front`]),
    };

    const result = await consumerSignup(userData, userCreationData);

    if (result.error) {
      showErnie(result.error.message);
      setLoading(false);
      return;
    }

    const { data: updateOrderData } = await apolloClient.mutate({
      mutation: addCustomerToOrder,
      variables: {
        input: { orderId: stashedOrder?.id },
      },
    });

    if (!updateOrderData?.addCustomerToOrder.updated) {
      showErnie(
        t(
          'guest-order-success.error-adding-user-to-account',
          'There was an error adding this order to your new account. Please contact support'
        )
      );
      setLoading(false);
      UI.viewingGuestOrderSuccess = false;
      return;
    }

    // send sign in and send to orders page
    try {
      setLoading(false);
      if (UI.isVariant(`default`)) {
        // @TODO: route to account page
      }
      showErnie(t('guest-order-success.account-created', 'Success! Your account has been created.'), `success`);
      UI.viewingGuestOrderSuccess = false;
      user.viewOrderId = stashedOrder?.orderId;
      openModal(ModalNames.orderStatus);
    } catch (e) {
      console.log(e);
      showErnie(t('guest-order-success.error-logging-in', 'There was an error logging you in. Please contact support'));
      setLoading(false);
    }
  };

  const showDOB = _.get(dispensary, `storeSettings.disableGuestDOB`, false);

  const birthdayValidation = showDOB
    ? {
        birthday: yup
          .string()
          .required(t('form.birthdayRequired', 'birthday is required'))
          .isLegalAge(`MM/DD/YYYY`, t('form.isLegalAge', 'You must be at least 18 to use dutchie'))
          .matches(birthdayRegEx, t('form.validBirthday', 'please enter a valid birthday')),
      }
    : {};

  const validationSchema = object().shape({
    email: yup
      .string()
      .required(t('form.emailRequired', 'email is required'))
      .matches(emailRegEx, t('form.validEmail', 'please enter a valid email address')),
    password: yup.string().required(t('form.passwordRequired', 'password is required')).min(8),
    ...birthdayValidation,
  });
  return (
    <Container p={showDOB ? `33px 105px 0` : `33px 76px 0`} isDutchieMain={isDutchieMain}>
      <SaveYourInfo data-testid='save-your-info-header'>
        {t('guest-checkout-modal.save-your-info', 'Save your Info')}
      </SaveYourInfo>
      <MainText>
        {t('guest-checkout-modal.create-an-account', 'Create an account for 1-click ordering next time')}
      </MainText>
      <StyledForm onSubmit={createAccount} validationSchema={validationSchema}>
        <FormWrapper>
          <InputsContainer flexDirection={showDOB ? 'column' : 'row'} showDOB={showDOB}>
            {showDOB && (
              <StyledFormFormattedInput
                name='birthday'
                format='date'
                label={t('guest-checkout-modal.birthday', 'Birthday')}
                width='100%'
                labelstyles={labelStyles}
                autoFocus={showDOB}
                data-testid='save-your-info-bday-input'
              />
            )}
            <StyledTextField
              name='email'
              label={t('guest-checkout-modal.email', 'Email Address')}
              width='100%'
              labelstyles={labelStyles}
              defaultValue={guestInfoToSave?.customer?.email}
              autoFocus={!showDOB}
              inputProps={{ maxLength: 300 }}
              data-testid='save-your-info-email-input'
            />
            <StyledTextField
              name='password'
              label={t('guest-checkout-modal.password', 'Password')}
              type='password'
              width='100%'
              labelstyles={labelStyles}
              data-testid='save-your-info-password-input'
            />
          </InputsContainer>

          <SubmitButton data-testid='save-your-info-submit-button' type='submit' loading={loading}>
            {t('guest-checkout-modal.save', 'Save')}
          </SubmitButton>
          <StyledResetButton data-testid='save-your-info-nothanks-button' onClick={onClose}>
            {t('guest-checkout-modal.no-thanks', 'No thanks')}
          </StyledResetButton>
        </FormWrapper>
      </StyledForm>

      <Disclaimer>
        {t('guest-checkout-modal.agreement', 'By creating an account, you agree to our')}{' '}
        {UI.isMobileEcommApp ? (
          <MobileEcommTermsLink text={termsCopy} />
        ) : (
          <Link href={ROUTES.TERMS}>
            <a href='terms' rel='noopener noreferrer' target='_blank'>
              {termsCopy}
            </a>
          </Link>
        )}
      </Disclaimer>
    </Container>
  );
}

const labelStyles = css`
  &.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(7px, 22px) scale(0.75) !important;
  }
  &.MuiInputLabel-outlined {
    transform: translate(8px, 32px) scale(1) !important;
    white-space: nowrap;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey[95]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ isDutchieMain }) => !isDutchieMain && `0 0 28px 28px`};
  ${space}
  @media ${mediaQueries.largePhone} {
    padding: 33px 45px 0;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;
  @media ${mediaQueries.largePhone} {
    flex-direction: column;
  }

  > :first-child {
    margin-right: ${({ showDOB }) => (showDOB ? `0` : `15px`)};
    @media ${mediaQueries.largePhone} {
      margin-right: 0;
    }
  }
`;

const inputStyles = css`
  height: 50px;
  background: ${({ theme }) => theme.colors.white};
  margin-top: 15px;

  input {
    padding-left: 20px;
    padding-top: 23px;
    font-size: 13px;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: ${({ theme }) => `0 0 0px 30px ${theme.colors.white} inset`};
    padding-top: 18px;
  }

  ${space}
`;

const StyledFormFormattedInput = styled(FormFormattedInput)`
  ${inputStyles}
`;

const StyledTextField = styled(FormTextInput)`
  ${inputStyles}
`;

const MainText = styled.span`
  margin: 0;
  min-width: 0;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[25]};
  margin-top: 13px;
  @media ${mediaQueries.largePhone} {
    max-width: 200px;
    line-height: 16px;
  }
`;

const SubmitButton = styled(Button)`
  height: 40px;
  width: 252px;
  margin: 24px auto 18px auto;
`;

const SaveYourInfo = styled.span`
  font-size: 17px;
  color: ${({ theme }) => theme.colors.grey[25]};
  font-weight: bold;
`;

const Disclaimer = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.grey[55]};
  margin-bottom: 30px;

  & a,
  & button {
    color: inherit;
    text-decoration: underline;
  }
`;

const StyledResetButton = styled(ResetButton)`
  width: 100%;
  color: #0b99e6;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 16px;
`;
