import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
function sumOriginalTotal(items, discountTaxOrder) {
  var originalTotalPrice = Big(0);
  // Iterate over products to determine originalTotalPrice
  _.forEach(items, function (_ref) {
    var basePrice = _ref.basePrice,
      basePriceMult = _ref.basePriceMult,
      mixAndMatch = _ref.mixAndMatch,
      quantity = _ref.quantity;
    var adjustedBasePrice = (mixAndMatch === null || mixAndMatch === void 0 ? void 0 : mixAndMatch.adjustedBasePrice) || basePrice || 0;
    originalTotalPrice = originalTotalPrice.add(
    // Multiply basePrice by the tax mult to account for taxes in the menu or from POS for taxesFirst setting
    adjustedBasePrice.times(discountTaxOrder === 'taxesFirst' ? basePriceMult || 1 : 1).times(quantity));
  });
  return originalTotalPrice;
}
var issueDiscountToCartRewards = function issueDiscountToCartRewards(bogoCalcsData, bogoSpecial, discountTaxOrder, items) {
  var _discountToCart$value;
  var discountToCart = bogoSpecial.discountToCart,
    specialId = bogoSpecial.specialId,
    specialName = bogoSpecial.specialName;
  var rewardId = discountToCart === null || discountToCart === void 0 ? void 0 : discountToCart._id;
  var targetIndividualDiscountPercentage = Big(0);
  var originalTotalPrice = sumOriginalTotal(items, discountTaxOrder);
  var totalDiscount = (discountToCart === null || discountToCart === void 0 ? void 0 : discountToCart.discountType) === 'percentDiscount' ? originalTotalPrice.times(Big((_discountToCart$value = discountToCart === null || discountToCart === void 0 ? void 0 : discountToCart.value) !== null && _discountToCart$value !== void 0 ? _discountToCart$value : 0).div(100)) : Big(discountToCart === null || discountToCart === void 0 ? void 0 : discountToCart.value);
  /*
    taking the total discount and dividing it by the cart total will leave
    you with the percentage of each product that needs to be discounted
   */
  if (originalTotalPrice.gt(0)) {
    targetIndividualDiscountPercentage = totalDiscount.div(originalTotalPrice);
  }
  if (targetIndividualDiscountPercentage.lt(0)) {
    return {};
  }

  // filter out products that have had their DTC reward defeated
  var itemsNotDefeatedByOtherRewards = _.filter(items, function (_ref2) {
    var _bogoCalcsData$defeat, _bogoCalcsData$defeat2;
    var key = _ref2.key;
    return !((_bogoCalcsData$defeat = bogoCalcsData.defeatedDiscountToCartRewards) !== null && _bogoCalcsData$defeat !== void 0 && (_bogoCalcsData$defeat2 = _bogoCalcsData$defeat[rewardId]) !== null && _bogoCalcsData$defeat2 !== void 0 && _bogoCalcsData$defeat2[key]);
  });

  /*
    when one or more product has it's DTC cart reward defeated, we need to
    take the same overall discount and spread it evenly across the remaining
    products so that the overall DTC value is preserved
   */
  var atleastOneProductRemainsUndefeated = itemsNotDefeatedByOtherRewards.length > 0 && itemsNotDefeatedByOtherRewards.length < items.length;
  if (atleastOneProductRemainsUndefeated) {
    var totalPriceMinusProductsWithRewards = sumOriginalTotal(itemsNotDefeatedByOtherRewards, discountTaxOrder);
    // here the targetIndividualDiscountPercentage will increase by some amount
    // so the discount will be larger for the remaining products
    if (totalPriceMinusProductsWithRewards.gt(0)) {
      targetIndividualDiscountPercentage = totalDiscount.div(totalPriceMinusProductsWithRewards);
    }
  }
  var discountToCartRewards = {};
  _.forEach(itemsNotDefeatedByOtherRewards, function (item) {
    var _item$mixAndMatch, _bogoCalcsData$discou, _bogoCalcsData$discou2, _bogoCalcsData$discou3, _bogoCalcsData$discou4, _bogoCalcsData$discou5, _bogoCalcsData$discou6, _bogoCalcsData$discou7, _bogoCalcsData$discou8;
    var adjustedBasePrice = Big(((_item$mixAndMatch = item.mixAndMatch) === null || _item$mixAndMatch === void 0 ? void 0 : _item$mixAndMatch.adjustedBasePrice) || item.basePrice || 0);
    var discount = adjustedBasePrice.times(discountTaxOrder === 'taxesFirst' ? Big(item.basePriceMult || 1) : 1).times(targetIndividualDiscountPercentage);

    /*
      if the defeated discounts end up stacking onto
      one or more products and the discount is too large
      we want to 0 out the product instead
      of over discounting a single product
    */
    if (discount.gt(adjustedBasePrice)) {
      discount = adjustedBasePrice;
    }
    var hasPreviousDTCReward = !!((_bogoCalcsData$discou = bogoCalcsData.discountToCartRewards) !== null && _bogoCalcsData$discou !== void 0 && (_bogoCalcsData$discou2 = _bogoCalcsData$discou[item.key]) !== null && _bogoCalcsData$discou2 !== void 0 && (_bogoCalcsData$discou3 = _bogoCalcsData$discou2.rewards) !== null && _bogoCalcsData$discou3 !== void 0 && _bogoCalcsData$discou3[rewardId]);
    var redemptionMultiplier = hasPreviousDTCReward ? (_bogoCalcsData$discou4 = bogoCalcsData.discountToCartRewards) === null || _bogoCalcsData$discou4 === void 0 ? void 0 : (_bogoCalcsData$discou5 = _bogoCalcsData$discou4[item.key]) === null || _bogoCalcsData$discou5 === void 0 ? void 0 : (_bogoCalcsData$discou6 = _bogoCalcsData$discou5.rewards) === null || _bogoCalcsData$discou6 === void 0 ? void 0 : (_bogoCalcsData$discou7 = _bogoCalcsData$discou6[rewardId]) === null || _bogoCalcsData$discou7 === void 0 ? void 0 : (_bogoCalcsData$discou8 = _bogoCalcsData$discou7.reward) === null || _bogoCalcsData$discou8 === void 0 ? void 0 : _bogoCalcsData$discou8.redemptionMultiplier : 0;
    discountToCartRewards[item.key] = {
      item: item,
      quantity: item.quantity,
      rewards: _defineProperty({}, rewardId, {
        quantity: item.quantity,
        reward: {
          _id: rewardId,
          dollarDiscount: Big(discount),
          discountToCart: _objectSpread({}, discountToCart),
          redemptionMultiplier: redemptionMultiplier + 1
        },
        specialId: specialId,
        specialName: specialName,
        discountStacking: bogoSpecial.discountStacking,
        discountPrecedence: bogoSpecial.discountPrecedence,
        stackingBehavior: bogoSpecial.stackingBehavior,
        nonStackingBehavior: bogoSpecial.nonStackingBehavior,
        settingsDerivedFrom: bogoSpecial.settingsDerivedFrom
      })
    };
  });
  return discountToCartRewards;
};
export default issueDiscountToCartRewards;