import React from 'react';

import useErnie from 'shared/hooks/use-ernie';
import { ERNIE_TYPES } from 'shared/constants';
import useUser from 'src/hooks/use-user';

import { CompleteAccountForm } from 'src/payments/components/complete-account-form/complete-account-form';

export const CompleteAccount = (): JSX.Element => {
  const User = useUser();
  const showErnie = useErnie();

  const onSuccess = (): void => {
    User.refetch();
    showErnie('Account updated successfully.', ERNIE_TYPES.SUCCESS);
  };

  return (
    <>
      <CompleteAccountForm onSuccess={onSuccess} />
    </>
  );
};
