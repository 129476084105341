import styled from 'styled-components';

import { Button } from 'components/core';
import { HeaderText } from 'components/modals/components';
import { Typography } from 'src/typography';

export const StyledHeading = styled(HeaderText)`
  text-align: left;
  margin-left: 0;
`;

export const Message = styled(Typography.Body)`
  margin-bottom: 24px;
  text-align: left;
`;

export const StyledButton = styled(Button)`
  display: block;
  padding: 12px 32px;
  margin: 48px 0 11px;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  text-decoration: underline;
  cursor: pointer;
`;

export const HorizontalDivider = styled.hr`
  border: 0;
  border-top: 1px solid #e3e7e9;
  margin: 32px 0;
`;
