import { useCallback } from 'react';

import { Product } from 'types/graphql-overrides';
import { tracker } from 'src/utils/analytics';
import { useFetchTracker } from 'src/utils/ads/hooks/use-fetch-tracker';
import { getLogger } from 'src/utils/datadog-logger';

const logger = getLogger('ads.sponsored-products');

type SponsoredProductHandlersInput = {
  trackerSource: string;
};

type SponsoredProductHandlersReturn = {
  handleSponsoredProductClick: (product: Product, productIndex: number) => void;
  handleSponsoredProductImpression: (product: Product) => void;
};

export function useSponsoredProductHandlers({
  trackerSource,
}: SponsoredProductHandlersInput): SponsoredProductHandlersReturn {
  const fetchTracker = useFetchTracker();

  const handleSponsoredProductImpression = useCallback(
    (product: Product) => {
      const { impressionUrl } = product.adTrackers ?? {};

      if (impressionUrl) {
        logger.info('handleSponsoredProductImpression', { impressionUrl, trackerSource });

        void fetchTracker(impressionUrl);
        tracker.sponsoredProductImpression({ product, trackerSource });
      }
    },
    [fetchTracker, trackerSource]
  );

  const handleSponsoredProductClick = useCallback(
    (product: Product, productIndex: number) => {
      const { clickUrl } = product.adTrackers ?? {};

      if (clickUrl) {
        logger.info('handleSponsoredProductClick', { clickUrl, trackerSource });

        void fetchTracker(clickUrl);
        tracker.sponsoredProductClicked({ product, trackerSource, position: productIndex });
        tracker.setContext({ activePromotion: { product, trackerSource } });
      }
    },
    [fetchTracker, trackerSource]
  );

  return {
    handleSponsoredProductImpression,
    handleSponsoredProductClick,
  };
}
