import { Statuses, UseLoyaltyReturn } from './types';

type NoLoyaltyProps = {
  status: Statuses;
  setStatus: (status: Statuses) => void;
};

export const createNoLoyalty = ({ status, setStatus }: NoLoyaltyProps): UseLoyaltyReturn => ({
  createUserAndPin: async () => Promise.resolve(),
  status,
  setStatus,
  programName: '',
  programDescription: '',
  processPin: async () => Promise.resolve(),
  resendPin: async () => Promise.resolve(),
});
