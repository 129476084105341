import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import useCart from 'hooks/use-cart';
import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useRouter } from 'next/router';

import { ModalNames } from 'components/modals';
import { useModals } from 'components/modals/use-modals';
import { useCheckResidential, validateAddressLimits } from 'src/state/actions/address';

type UseValidateDeliveryAddressReturn = () => Promise<boolean>;

export const useValidateDeliveryAddress = (): UseValidateDeliveryAddressReturn => {
  const Cart = useCart();
  const UI = useUI();
  const apolloClient = useApolloClient();
  const router = useRouter();
  const { isDutchieMain } = UI;

  const checkResidential = useCheckResidential();
  const { openModal } = useModals();
  const { dispensary } = useDispensary(router.query.cName);

  const handleGetDeliveryAddress = useCallback(async (): Promise<boolean> => openModal(ModalNames.deliveryAddress), [
    openModal,
  ]);

  const handleAddressVerification = useCallback(async (): Promise<boolean> => {
    const verificationStatus = await checkResidential(Cart.address);

    if (verificationStatus === 'VERIFIED') {
      return true;
    }

    return UI.showResidentialStatusModal();
  }, [Cart.address, UI, checkResidential]);

  return useCallback(async (): Promise<boolean> => {
    // If there's no address at all, open deliveryAddress modal
    // Modal handles checking residential status and delivery zone, so we don't need to do that here
    if (!Cart.hasAddress) {
      return handleGetDeliveryAddress();
    }

    // Validate that the delivery address isn't just a generic city/state/zip
    const validDeliveryAddress = UI.hasDeliveryAddress(Cart.address);

    // If delivery address is not valid, open deliveryAddress modal
    // Modal handles checking residential status and delivery zone, so we don't need to do that here
    if (!validDeliveryAddress) {
      return handleGetDeliveryAddress();
    }

    // Check if the delivery address is within the delivery zone of the dispensary
    const isWithinDeliveryZone = await validateAddressLimits(Cart, apolloClient, Cart.address, dispensary);

    // The address is valid, and we are not on an embedded menu but not within delivery zone - stop here
    // Only show this on Dutchie main as it doesn't make sense in any other context.
    // (Temp solution until we have better idea of the delivery address flow) - Mack H. 5/11/22
    if (!isWithinDeliveryZone.success && isDutchieMain) {
      UI.openShowOutsideDeliveryAreaModal();

      return false;
    }

    // Check whether the residential status is verified
    const isResidentialStatusVerified = Cart.address.residentialStatus === 'VERIFIED';

    // The address is valid and within the delivery zone, but residential status is not verified
    if (!isResidentialStatusVerified) {
      return handleAddressVerification();
    }

    // Address is valid, verified, and within the delivery zone
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Cart, UI, apolloClient, dispensary, handleAddressVerification, handleGetDeliveryAddress]); // FIXME: ENG-32714 fix hooks dependency
};
