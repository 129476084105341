import React from 'react';
import Head from 'next/head';

import useTranslation from 'hooks/use-translation';

import { getCityToDisplay } from 'shared/helpers/address';
import { useMetaInfo } from './use-meta-info';

import Meta from './meta';

// default title values
/* eslint-disable */
const DutchiePageTitles = {
  about: 'About Us | dutchie',
  blog: 'dutchie | Blog',
  cities: '{{retailerLabelPluralCapitalized}} by city - US & Canada | dutchie',
  city: '{{city}} {{state}} {{retailerLabelPluralCapitalized}} - order online, delivery or pickup | Dutchie',
  default: 'dutchie | Order cannabis online for pickup or delivery',
  dispensary:
    '{{dispensaryName}} - Cannabis {{retailerLabelCapitalized}}, {{dispensaryCity}} {{dispensaryState}} | Dutchie',
  dispensaryAllProducts:
    '{{dispensaryName}} - Cannabis {{retailerLabelCapitalized}}, {{dispensaryCity}} {{dispensaryState}} | Dutchie',
  dispensaryProduct: '{{productName}} | {{dispensaryName}} {{dispensaryCity}} {{dispensaryState}} | Dutchie',
  dispensarySpecials:
    '{{dispensaryName}} {{retailerLabelCapitalized}} Deals {{dispensaryCity}} {{dispensaryState}} | Dutchie',
  dispensaryBrands: '{{dispensaryName}} Cannabis Brands Sold | Dutchie',
  dispensaryBrand: '{{brandName}} - {{dispensaryName}}, {{dispensaryCity}} {{dispensaryState}} | Dutchie',
  dispensaryInfo: '{{dispensaryName}} Hours & Info - {{dispensaryCity}} {{dispensaryState}} | Dutchie',
  dispensaryProductCategories:
    '{{dispensaryName}} Products - {{dispensaryCity}} {{dispensaryState}} {{retailerLabelCapitalized}} | Dutchie',
  dispensaryProductCategory:
    '{{productCategory}} at {{dispensaryName}} {{retailerLabelCapitalized}} {{dispensaryCity}} {{dispensaryState}} | Dutchie',
  dispensaryProductSubcategory:
    '{{productSubcategory}} at {{dispensaryName}} {{retailerLabelCapitalized}} {{dispensaryCity}} {{dispensaryState}} | Dutchie',
  careers: 'Careers | dutchie',
  help: 'Get Help | dutchie',
  home: 'Dutchie | Order cannabis online from {{retailerLabelPluralCapitalized}} near me',
  isolatedCheckout: '{{dispensaryName}} Checkout',
  news: 'News | dutchie',
  privacy: 'Privacy Policy | dutchie',
  dispoTerms: '{{retailerLabelCapitalized}} Terms and Conditions | dutchie',
  terms: 'Terms of Service | dutchie',
  accessibility: 'Accessibility | dutchie',
  messaging: 'Order Messaging',
  curbsideArrivals: 'Curbside Arrival',
  plus: 'Dutchie Plus | Cannabis E-commerce for the Enterprise',
  whoops: '404 | Page Not Found | Dutchie.com',
};

const EmbeddedAndStoreFrontTitle = {
  ...DutchiePageTitles,
  dispensary:
    '{{dispensaryName}} Menu - a Cannabis {{retailerLabelCapitalized}} in {{dispensaryCity}}, {{dispensaryState}}',
  dispensaryProduct: '{{productName}} | {{dispensaryName}}',
  dispensarySpecials: 'Specials, Deals, & Sales | {{dispensaryName}}',
  dispensarySpecial: '{{specialName}} | {{dispensaryName}}',
  dispensaryBrands: 'Brands Sold | {{dispensaryName}}',
  dispensaryBrand: '{{brandName}} | {{dispensaryName}}',
  dispensaryAllProducts: 'Cannabis Products  | {{dispensaryName}}',
  dispensaryProductCategory: 'Cannabis {{productCategory}} | {{dispensaryName}}',
  dispensaryProductSubcategory: 'Cannabis {{productSubcategory}} | {{dispensaryName}}',
  dispensarySearch: '{{searchTerm}} search result | {{dispensaryName}}',
};
/* eslint-enable */

export function useTitle(variant, location, isEmbeddedOrStoreFront) {
  const { t } = useTranslation();
  const metaInfo = useMetaInfo();
  const PageTitles = isEmbeddedOrStoreFront ? EmbeddedAndStoreFrontTitle : DutchiePageTitles;

  if (!PageTitles[variant]) {
    variant = `default`;
  }

  const data = {
    defaultValue: PageTitles[variant],
    ...metaInfo,
  };

  if (location) {
    data.city = getCityToDisplay(location);
    data.state = location.state;
  }

  /* i18next-extract-disable-next-line */
  return t(`head.title.${variant}`, data);
}

export default function PageTitle(props) {
  const { variant, location, isEmbeddedOrStoreFront } = props;
  const title = useTitle(variant, location, isEmbeddedOrStoreFront);

  return (
    <>
      <Head>
        <title key='title'>{title}</title>
      </Head>
      <Meta property='og:title' content={title} />
    </>
  );
}
