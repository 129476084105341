import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import { sortSpecials, isCumulative } from 'shared/order/utilities';
import { productSatisfiesSaleWeight } from 'shared/helpers/specials';
export var calculateSaleDiscounts = function calculateSaleDiscounts(_ref) {
  var applyStowedDiscounts = _ref.applyStowedDiscounts,
    compositePrice = _ref.compositePrice,
    credit = _ref.credit,
    discountTaxOrder = _ref.discountTaxOrder,
    detail = _ref.detail,
    originalCompositePrice = _ref.originalCompositePrice,
    medicalOrder = _ref.medicalOrder,
    productTotal = _ref.productTotal,
    receiptDetail = _ref.receiptDetail,
    specialData = _ref.specialData,
    globalSpecialsSettings = _ref.globalSpecialsSettings,
    stowDiscounts = _ref.stowDiscounts,
    stowedDiscounts = _ref.stowedDiscounts;
  // Gather required product attributes for productSatisfiesSaleWeight
  var product = {
    brandId: detail.productBrandId,
    brandName: detail.productBrand,
    type: detail.productCategory,
    subcategory: detail.productSubcategory,
    _id: detail.id,
    enterpriseProductId: detail.enterpriseProductId,
    option: detail.option
  };
  var saleSpecials;

  // if we don't have any special restrictions, don't worry about weights, they all apply then
  var hasApplicableSpecial = specialData !== null && specialData !== void 0 && specialData.saleSpecials ? productSatisfiesSaleWeight(product, specialData.saleSpecials, detail.option) : true;
  if (hasApplicableSpecial) {
    if (applyStowedDiscounts) {
      saleSpecials = _.reduce(stowedDiscounts, function (saleDiscounts, discount) {
        if (discount.type === 'sale') {
          saleDiscounts.push(_objectSpread(_objectSpread({}, discount.discountData), {}, {
            _quantityToApply: discount.quantityToApply || 0
          }));
        }
        return saleDiscounts;
      }, []);
    } else {
      saleSpecials = (specialData === null || specialData === void 0 ? void 0 : specialData.saleSpecials) || [];

      // Legacy special data support
      if (saleSpecials.length < 1 && (!_.isNil(specialData === null || specialData === void 0 ? void 0 : specialData.discount) || !_.isNil(specialData === null || specialData === void 0 ? void 0 : specialData.productDiscounts))) {
        var _specialData$productD;
        var inProductDiscounts = !_.isNil(specialData === null || specialData === void 0 ? void 0 : (_specialData$productD = specialData.productDiscounts) === null || _specialData$productD === void 0 ? void 0 : _specialData$productD[detail.id]);
        saleSpecials.push({
          discount: inProductDiscounts ? specialData.productDiscounts[detail.id] : specialData === null || specialData === void 0 ? void 0 : specialData.discount,
          menuType: "both",
          percentDiscount: !_.isNil(specialData === null || specialData === void 0 ? void 0 : specialData.percentDiscount) ? specialData.percentDiscount : inProductDiscounts
        });
      }
    }
  }
  saleSpecials = sortSpecials(saleSpecials, {
    // Partial product object here from the detail data
    brandId: detail.productBrandId,
    brandName: detail.productBrand,
    _id: detail.id,
    subcategory: detail.productSubcategory,
    type: detail.productCategory
  });

  // credit has not been modified at this point
  _.forEach(saleSpecials, function (saleSpecial) {
    var stackingBehaviorIsCumulative = isCumulative(saleSpecial.stackingBehavior, globalSpecialsSettings.stackingBehavior);
    var startingPrice = stackingBehaviorIsCumulative ? originalCompositePrice : compositePrice;
    var individualPrice = startingPrice.div(detail.quantity);
    var specialMenuType = saleSpecial.menuType || "both";
    // should we apply this special to the product?
    var specialAppliesToWeight = saleSpecial && productSatisfiesSaleWeight(product, [saleSpecial], detail.option);
    if (specialAppliesToWeight && (specialMenuType === "both" || specialMenuType === "medical" && medicalOrder || specialMenuType === "recreational" && !medicalOrder)) {
      var quantityToApply = !_.isNil(saleSpecial === null || saleSpecial === void 0 ? void 0 : saleSpecial._quantityToApply) ? saleSpecial._quantityToApply : detail.quantity || 0;
      var additionalCredit = Big(0);
      var individualDiscount;
      if (saleSpecial !== null && saleSpecial !== void 0 && saleSpecial.percentDiscount) {
        individualDiscount = individualPrice.times(Big((saleSpecial === null || saleSpecial === void 0 ? void 0 : saleSpecial.discount) || 0).div(100));
      } else if (saleSpecial !== null && saleSpecial !== void 0 && saleSpecial.targetPrice) {
        individualDiscount = individualPrice.minus(Big((saleSpecial === null || saleSpecial === void 0 ? void 0 : saleSpecial.discount) || 0));
      } else {
        individualDiscount = Big((saleSpecial === null || saleSpecial === void 0 ? void 0 : saleSpecial.discount) || 0);
      }
      additionalCredit = additionalCredit.add(individualDiscount.times(quantityToApply));
      if (stowDiscounts) {
        stowedDiscounts.push({
          applicableQuantity: detail.quantity,
          discountData: saleSpecial,
          individualDiscount: individualDiscount,
          key: saleSpecial.specialId,
          type: "sale"
        });
      } else {
        compositePrice = compositePrice.minus(additionalCredit.round(2, 1));

        // Depending on tax config, add the credit into the total. This is to make the subtotal on the checkout ticket
        // look more like the menu.
        if (discountTaxOrder === 'taxesFirst' || Big(detail.basePriceMult, "Base Price Mult").eq(1)) {
          credit = credit.plus(additionalCredit.round(2, 1));
        } else {
          productTotal = productTotal.minus(additionalCredit.round(2, 1));
        }
        var specialSettings = {
          discountStacking: saleSpecial.discountStacking,
          stackingBehavior: saleSpecial.stackingBehavior,
          nonStackingBehavior: saleSpecial.nonStackingBehavior,
          settingsDerivedFrom: saleSpecial.settingsDerivedFrom
        };
        if (receiptDetail && additionalCredit.gt(0)) {
          receiptDetail.addDiscount({
            type: "sale",
            value: additionalCredit.times(100),
            id: saleSpecial.specialId,
            name: saleSpecial.specialName,
            additionalAttributes: {
              specialSettings: specialSettings
            }
          });
        }
      }
    }
  });
  return {
    compositePrice: compositePrice,
    credit: credit,
    productTotal: productTotal,
    stowedDiscounts: stowedDiscounts
  };
};