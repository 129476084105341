import React from 'react';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { ModalObserver } from '../modal-observer';
import { AccountChangeVerificationResultModal } from './account-change-verification-result-modal';

type ModalObserverRenderProps = {
  email?: string;
  result: 'failure' | 'success';
  onResend?: () => Promise<void>;
  onClose?: () => void;
};

export const AccountChangeVerificationResult = ({ name }: { name: string }): JSX.Element => (
  <ModalObserver name={name}>
    {(props) => {
      const { data } = props;
      const { email, result, onResend, onClose } = data as ModalObserverRenderProps;

      return (
        <ModalWrapper {...props}>
          <AccountChangeVerificationResultModal
            {...props}
            email={email}
            result={result}
            onResend={onResend}
            customOnClose={onClose}
          />
        </ModalWrapper>
      );
    }}
  </ModalObserver>
);
