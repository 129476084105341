import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import { tracker } from 'src/utils/analytics';
import { LoginCta } from 'src/components/ctas';
import { CategoryLink } from './category-link';

export default function CategoriesList(props) {
  const { onClose = _.noop } = props;
  const { categories } = useDispensaryCategories();

  const handleLinkSelection = (category) => {
    tracker.gaGTMClickEvent({
      eventCategory: `navigation click`,
      eventAction: `main menu`,
      eventLabel: `categories > ${category.key}`,
    });

    onClose();
  };
  return (
    <Container>
      <CategoryList>
        {_.map(categories, (category) => (
          <CategoryLink key={category.key} category={category} onSelect={() => handleLinkSelection(category)} />
        ))}
      </CategoryList>

      <LoginCta />
    </Container>
  );
}

const Container = styled.div`
  padding: 16px 32px;
  max-height: 100%;
`;

const CategoryList = styled.div`
  display: flex;
  flex-direction: column;

  // We want this box to always scroll even when the container is high enough.
  // Otherwise, when using embedded, the parent frame would start scrolling and the user would lose focus on iframe.
  padding-bottom: 25px;
  min-height: 100%;
  li {
    cursor: pointer;
    padding: 15px 0px;
  }
`;
