import { useFlags } from 'launchdarkly-react-client-sdk';
import useDispensary from 'dispensary/hooks/use-dispensary';

export function usePriceCartCalcs(): boolean {
  const flags = useFlags();
  const { dispensary } = useDispensary();

  const discountSyncEnabled = dispensary?.storeSettings?.enableLLxSaleDiscountSync;
  const priceCartFlagEnabled = flags['core.cats-ecomm.price-cart-calcs-state.rollout'] === 'ENABLED';

  return discountSyncEnabled && priceCartFlagEnabled;
}
