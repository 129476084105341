import _toConsumableArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
var getAllBogoSpecialsForCart = function getAllBogoSpecialsForCart(items, medicalOrder, globalSpecialsSettings) {
  var bogoSpecialsHash = _.reduce(_.flatMap(items, function (item) {
    var _item$specialData;
    return (item === null || item === void 0 ? void 0 : (_item$specialData = item.specialData) === null || _item$specialData === void 0 ? void 0 : _item$specialData.bogoSpecials) || [];
  }), function (hash, bogoSpecial) {
    var bogoRewards = bogoSpecial.bogoRewards,
      discountToCart = bogoSpecial.discountToCart,
      discountBundle = bogoSpecial.discountBundle,
      itemsForAPrice = bogoSpecial.itemsForAPrice,
      menuType = bogoSpecial.menuType,
      specialId = bogoSpecial.specialId;
    if (menuType === 'medical' && !medicalOrder || menuType === 'recreational' && medicalOrder || (bogoRewards || []).length < 1 && !(itemsForAPrice !== null && itemsForAPrice !== void 0 && itemsForAPrice.enabled) && !(discountToCart !== null && discountToCart !== void 0 && discountToCart.enabled) && !(discountBundle !== null && discountBundle !== void 0 && discountBundle.enabled)) {
      return hash;
    }
    var existingSpecial = hash[bogoSpecial.specialId];
    if (!existingSpecial) {
      hash[specialId] = _objectSpread(_objectSpread({}, bogoSpecial), {}, {
        discountPrecedence: bogoSpecial.discountPrecedence || globalSpecialsSettings.discountPrecedence
      });
    } else {
      hash[specialId] = _objectSpread(_objectSpread({}, existingSpecial), {}, {
        discountPrecedence: bogoSpecial.discountPrecedence || globalSpecialsSettings.discountPrecedence,
        bogoRewards: _.uniqBy([].concat(_toConsumableArray((existingSpecial === null || existingSpecial === void 0 ? void 0 : existingSpecial.bogoRewards) || []), _toConsumableArray(bogoRewards || [])), '_id')
      });
    }
    return hash;
  }, {});

  // 3.5 data format compatibility for the rest of ecomm calcs
  _.forEach(bogoSpecialsHash, function (special) {
    _.forEach(special.bogoRewards, function (reward) {
      if (!_.isNil(reward.discountAmount) && !_.isNil(reward.discountType)) {
        reward[reward.discountType] = reward.discountAmount;
      }
    });
  });
  return _.sortBy(_.values(bogoSpecialsHash), function (special) {
    if (special.isRecurring) {
      return special.recurringEndDate ? Number(special.recurringEndDate) : Infinity;
    }
    return special.endStamp ? Number(special.endStamp) : Infinity;
  });
};
export default getAllBogoSpecialsForCart;