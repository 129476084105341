import _ from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import Big from 'big.js';
import { redistributePennies } from '../utilities';
import { calculateBogoDiscounts } from './index';
export function redistributeDiscountToCart(_ref) {
  var keysToApplyDiscount = _ref.keysToApplyDiscount,
    detailsSorted = _ref.detailsSorted,
    bogoCalcsData = _ref.bogoCalcsData,
    products = _ref.products,
    discountTaxOrder = _ref.discountTaxOrder,
    bottleDepositTaxCentsTotal = _ref.bottleDepositTaxCentsTotal,
    cannabisTaxTotal = _ref.cannabisTaxTotal,
    salesTaxTotal = _ref.salesTaxTotal,
    productTotal = _ref.productTotal,
    avoidTaxes = _ref.avoidTaxes,
    residualPrice = _ref.residualPrice,
    credit = _ref.credit,
    bogoRewardsApplied = _ref.bogoRewardsApplied,
    compositePrice = _ref.compositePrice,
    stowedDiscounts = _ref.stowedDiscounts,
    receiptDetail = _ref.receiptDetail,
    globalSpecialsSettings = _ref.globalSpecialsSettings;
  _.forEach(keysToApplyDiscount, function (key) {
    var _bogoCalcsData$applic, _detailToDiscount$mix, _products$detailToDis, _detailToDiscount$bog;
    var detailToDiscount = _.find(detailsSorted, function (_ref2) {
      var detailKey = _ref2.key;
      return detailKey === key;
    });
    var applicableDiscountToCartReward = (bogoCalcsData === null || bogoCalcsData === void 0 ? void 0 : (_bogoCalcsData$applic = bogoCalcsData.applicableRewards) === null || _bogoCalcsData$applic === void 0 ? void 0 : _bogoCalcsData$applic[detailToDiscount.key]) || [];
    var itemBasePrice = ((_detailToDiscount$mix = detailToDiscount.mixAndMatch) === null || _detailToDiscount$mix === void 0 ? void 0 : _detailToDiscount$mix.adjustedBasePrice) || detailToDiscount.basePrice;
    var itemPrice = itemBasePrice.times(detailToDiscount.quantity);
    var itemQtyWholesalePrice = detailToDiscount.wholesalePrice.times(detailToDiscount.quantity);
    var itemWhPrice = itemQtyWholesalePrice.round(2, 1);
    var product = (_products$detailToDis = products[detailToDiscount === null || detailToDiscount === void 0 ? void 0 : detailToDiscount.key]) === null || _products$detailToDis === void 0 ? void 0 : _products$detailToDis.product;
    if (!product) {
      console.warn("can't find product ".concat(key, " in redistributeDiscountToCart"));
      return;
    }
    var itemBottleDepositTaxCents = Big(0);
    var itemCannabisTax = Big(0);
    var itemSalesTax = Big(0);
    var itemResidual = Big(0);
    if (discountTaxOrder === 'both' || discountTaxOrder === 'taxesFirst') {
      itemBottleDepositTaxCents = detailToDiscount.bottleDepositTaxCents.times(detailToDiscount.quantity);
      itemCannabisTax = detailToDiscount.cannabisTax(itemPrice, itemWhPrice, product) || Big(0);
      itemSalesTax = detailToDiscount.salesTax(itemPrice, itemWhPrice, product) || Big(0);
      var _redistributePennies = redistributePennies({
        itemCannabisTax: itemCannabisTax,
        itemPrice: itemPrice,
        itemSalesTax: itemSalesTax
      });
      itemCannabisTax = _redistributePennies.cannabisTax;
      itemSalesTax = _redistributePennies.salesTax;
      itemResidual = _redistributePennies.residual;
    }
    if (discountTaxOrder === 'taxesFirst') {
      // If the tax is taken before the discount then add it into the subtotal much
      // as we do above with the discount.
      if (Big(detailToDiscount.basePriceMult, 'Base Price Mult').eq(1)) {
        bottleDepositTaxCentsTotal = bottleDepositTaxCentsTotal.add(itemBottleDepositTaxCents);
        cannabisTaxTotal = cannabisTaxTotal.add(itemCannabisTax);
        salesTaxTotal = salesTaxTotal.add(itemSalesTax);
      } else {
        // Unclear how to deal with residual taxes in this case.
        productTotal = productTotal.add(itemCannabisTax).add(itemSalesTax).add(itemBottleDepositTaxCents.div(100));
        avoidTaxes = true;
      }
      residualPrice = residualPrice.add(itemResidual);
    }
    var itemPostTaxPrice = itemPrice.plus(itemCannabisTax).plus(itemSalesTax).plus(itemBottleDepositTaxCents.div(100));
    var itemCompositePrice = discountTaxOrder === 'taxesFirst' ? Big(itemPostTaxPrice) : Big(itemPrice);
    var itemIndividualPrice = discountTaxOrder === 'taxesFirst' ? itemPostTaxPrice.div(detailToDiscount.quantity) : Big(itemBasePrice);

    // we need to remove the previously added credit from the discount to cart
    credit = credit.minus(((_detailToDiscount$bog = detailToDiscount.bogoSavings) === null || _detailToDiscount$bog === void 0 ? void 0 : _detailToDiscount$bog.total) || Big(0));
    var _calculateBogoDiscoun = calculateBogoDiscounts({
      applicableBogoRewards: applicableDiscountToCartReward,
      applyStowedDiscounts: false,
      bogoRewardsApplied: bogoRewardsApplied,
      compositePrice: itemCompositePrice,
      credit: credit,
      detail: detailToDiscount,
      discountTaxOrder: discountTaxOrder,
      dtcIndividualPrice: itemIndividualPrice,
      productTotal: productTotal,
      receiptDetail: receiptDetail,
      globalSpecialsSettings: globalSpecialsSettings,
      stowDiscounts: false,
      stowedDiscounts: []
    });
    bogoRewardsApplied = _calculateBogoDiscoun.bogoRewardsApplied;
    compositePrice = _calculateBogoDiscoun.compositePrice;
    credit = _calculateBogoDiscoun.credit;
    productTotal = _calculateBogoDiscoun.productTotal;
    stowedDiscounts = _calculateBogoDiscoun.stowedDiscounts;
  });
  return {
    bottleDepositTaxCentsTotal: bottleDepositTaxCentsTotal,
    cannabisTaxTotal: cannabisTaxTotal,
    salesTaxTotal: salesTaxTotal,
    productTotal: productTotal,
    avoidTaxes: avoidTaxes,
    residualPrice: residualPrice,
    credit: credit,
    bogoRewardsApplied: bogoRewardsApplied,
    compositePrice: compositePrice,
    stowedDiscounts: stowedDiscounts
  };
}