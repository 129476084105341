import React from 'react';
import styled, { css } from 'styled-components';

import useColors from 'hooks/use-colors';

import { ContentWrapper } from 'components/core';
import { LocationSettingsPopover, AddressTextButton } from 'components/location-settings-popover';
import MyAccountLink from './my-account-link';
import CartButtonMobile from './cart-button-mobile';
import { HeaderLogo } from './header-logo';

export const GlobalHeaderMobile = ({
  addressButtonRef,
  anchorEl,
  href,
  route,
  itemCount,
  globalHeaderRef,
  address,
  orderType,
  isDispensaryList,
  toggleCartView,
  badgeVariant,
  onSaveLocationSettings,
  onAddressTextButtonClick,
}) => {
  const colors = useColors();

  return (
    <Wrapper id='global-header' isDispensaryList={isDispensaryList}>
      <ContentWrapper bg={colors.opal04} flexGrow={0}>
        <Container ref={globalHeaderRef}>
          <ContentGroup>
            <HeaderLogo href={href} route={route} />
          </ContentGroup>

          <ContentGroup>
            <DropdownContainer>
              <MyAccountLink />
            </DropdownContainer>

            <DropdownContainer>
              <CartButtonMobile
                onButtonClick={toggleCartView}
                itemCount={itemCount}
                badgeVariant={badgeVariant}
                variant='white'
                showCountColor
              />
            </DropdownContainer>
          </ContentGroup>
        </Container>
      </ContentWrapper>

      <DropdownContainer ref={addressButtonRef} data-testid='dropdown-mobile'>
        <AddressTextButton onClick={onAddressTextButtonClick} address={address} orderType={orderType} />
        <LocationSettingsPopover onSave={onSaveLocationSettings} anchorEl={anchorEl} />
      </DropdownContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.0579928);
  width: 100%;
  z-index: 1200;

  ${({ isDispensaryList }) =>
    isDispensaryList &&
    css`
      position: fixed;
      width: 100%;
    `}
`;

const Container = styled.header`
  padding: 0 20px;
  position: relative;
  z-index: 1200;
  background-color: ${({ theme }) => theme.colors.opal04};
  display: flex;
  height: 55px;
  width: 100%;
  justify-content: space-between;
`;

const ContentGroup = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownContainer = styled.div`
  align-items: center;
  position: relative;

  div[class*='dropdown'] {
    left: -50%;
    top: 54px;
  }
`;
