import { useQuery } from '@apollo/react-hooks';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _isEqual from 'lodash/isEqual';
import _kebabCase from 'lodash/kebabCase';
import _find from 'lodash/find';
import _isString from 'lodash/isString';
import { useObserver } from 'mobx-react-lite';

import { parseProduct } from 'utils/helpers/product';
import { queryParamWeightToGqlFilterValue } from 'utils/helpers/product/weight-filter-converters';

import { CategoryPotencyRanges, CategoryPotencyUnits, POTENCY_UNITS, StrainTypeFilterOptions } from 'shared/constants';
import filteredProducts from 'shared/graphql/product/filtered-products.gql';

import { getCategory, useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'hooks/use-ui';
import useProductQueryParams from 'hooks/use-product-query-params';
import useMenuFilterOptions from 'hooks/use-menu-filter-options';
import useCart from 'hooks/use-cart';
import { useHidePotencies } from 'hooks/use-hide-potencies';
import { useDispensarySubcategory } from 'hooks/use-dispensary-subcategory';
import { useFilterProductsQueryByEffects } from 'src/hooks/use-filter-products-query-by-effects';
import { useSortProductsByCustomBrandsort } from 'src/hooks/use-sort-products-by-custom-brandsort';
import useFeatureFlags from 'hooks/use-feature-flags';

const sortQueryParamToFields = (param) => {
  const formattedParam = _isString(param) ? param.toLowerCase() : param;

  switch (formattedParam) {
    case 'pricehightolow':
      return { sortDirection: -1, sortBy: 'price' };
    case 'pricelowtohigh':
      return { sortDirection: 1, sortBy: 'price' };
    case 'potencyhightolow':
      return { sortDirection: -1, sortBy: 'potency' };
    case 'potencylowtohigh':
      return { sortDirection: 1, sortBy: 'potency' };
    case 'custom':
      return { sortDirection: 1, sortBy: 'weight' };
    case 'category':
      return { sortDirection: 1, sortBy: 'type' };
    default:
      return { sortDirection: 1, sortBy: param };
  }
};

export function getPotencyContentForFilter(range, category) {
  const unit = CategoryPotencyUnits[category?.key] === `%` ? POTENCY_UNITS.PERCENTAGE : POTENCY_UNITS.MILLIGRAMS;
  if (range?.length === 2 && category?.key) {
    // If this is the default range for the category then don't apply any filtering
    if (_isEqual(CategoryPotencyRanges[category.key], range)) {
      return undefined;
    }
    // If the upper query param value is the max limit for the category,
    // include products with potencies that surpass the maximum.
    // e.g. if a pre-roll's potency is 60%, it should still show if the selected
    // potency filter range is [20, 50] even though the max range for pre-rolls is 50%.
    if (range[1] === CategoryPotencyRanges[category.key]?.[1]) {
      return { range: [range[0], Number.MAX_SAFE_INTEGER], unit };
    }
    return { range, unit };
  }
  return undefined;
}

/**
 * @param {Object} props
 * @param {Object} [props.filters]
 * @param {boolean} [props.skip]
 * @param {number} [props.perPage]
 * @returns {Object}
 */
export default function useDispensaryProducts(props) {
  const { filters = {}, perPage = 50, page } = props || {};
  let { skip = false } = props || {};
  const { queryParams: productQueryParams } = useProductQueryParams();
  const { brands: loadedBrands, loading: menuFilterOptionsLoading } = useMenuFilterOptions({
    skipCurrentFilters: true,
    location: 'use-dispensary-products.js',
  });
  const category = useDispensaryCategory();
  const { subcategory, loading: subcategoryLoading } = useDispensarySubcategory();
  const { dispensary } = useDispensary();
  const cart = useCart();
  const { isKiosk } = useUI();
  const menuType = useObserver(() => cart.menuType);
  const { menuHidePotencies } = useHidePotencies();
  const featureFlags = useFeatureFlags();
  const shouldDisableSearch = featureFlags?.flags['ecomm.configuration.disable-product-search'];

  if (shouldDisableSearch) {
    delete productQueryParams.search;
  }

  const {
    search: query,
    weight,
    brands: selectedBrands,
    page: pageFromQueryParams,
    straintypes,
    subcategories: subcategoriesFromQueryParams,
    category: categoryFromParams,
    effects: effectsFromParams,
  } = productQueryParams;

  const option = _isString(weight) ? queryParamWeightToGqlFilterValue(weight) : weight;

  const strainTypesForFilter = _map(straintypes, (queryValue) => _find(StrainTypeFilterOptions, { queryValue })?.value);

  const thcContentForFilter = getPotencyContentForFilter(productQueryParams.potencythc, category);
  const cbdContentForFilter = getPotencyContentForFilter(productQueryParams.potencycbd, category);

  let types = [];

  if (filters?.category) {
    types = [filters.category];
  } else {
    types = query || !category || (category && category.key === 'all') ? [] : [category.value];
  }

  if (filters?.types?.length > 0) {
    types = filters.types;
  }

  if (categoryFromParams) {
    types = [getCategory(categoryFromParams).value];
  }

  let brandIds = filters?.brandIds;
  const filterableBrands = filters?.brands || selectedBrands;

  if (filterableBrands?.length) {
    // get all brand objects that match brand names from filter, accounts for duplicate brands
    const matchingBrands = _filter(loadedBrands, (brand) => _includes(filterableBrands, _kebabCase(brand.name)));

    skip = !loadedBrands?.length;
    brandIds = _map(matchingBrands, (brand) => brand?.id);
  }

  // When no sorting option is selected, but a query is made we should sort by match score
  // Otherwise, default to the dispensary's default sort order
  let qpSortBy = productQueryParams.sortby;
  const isDefaultSort = qpSortBy === 'default';

  if (isDefaultSort) {
    if (query) {
      qpSortBy = null;
    } else if (dispensary?.menuOrder) {
      qpSortBy = dispensary.menuOrder;
    }
  }

  const { sortBy, sortDirection } = sortQueryParamToFields(filters.sortBy ?? qpSortBy);

  const productsFilter = {
    brandIds,
    productIds: filters?.productIds,
    staffPicks: filters?.staffPicks,
    dispensaryId: dispensary?.id,
    option,
    pricingType: menuType,
    strainTypes: filters?.strainTypes || strainTypesForFilter,
    subcategories: filters?.subcategories || subcategory || subcategoriesFromQueryParams,
    Status: `Active`,
    CBDContent: cbdContentForFilter,
    THCContent: thcContentForFilter,
    isOnSpecial: filters?.isOnSpecial,
    specialId: filters?.specialId,
    specialType: filters?.specialType,
    types,
    query,
    useCache: filters?.useCache || false,
    isDefaultSort,
    sortBy,
    sortDirection,
    bypassOnlineThresholds: isKiosk,
    isKioskMenu: isKiosk,
    removeProductsBelowOptionThresholds: true,
    collectionId: filters?.collectionId,
  };

  const variables = {
    productsFilter,
    includeCannabinoids: !menuHidePotencies,
    page: (page || pageFromQueryParams || 1) - 1,
    perPage: perPage > 0 ? perPage : undefined,
  };

  const { data, loading: filteredProductsLoading, ...apolloValues } = useQuery(filteredProducts, {
    skip: !dispensary || skip,
    variables,
  });
  const loading = menuFilterOptionsLoading || subcategoryLoading || filteredProductsLoading;

  const parsedProducts = _map(_get(data, 'filteredProducts.products', []), parseProduct);
  const totalCountFromQuery = _get(data, 'filteredProducts.queryInfo.totalCount', 0);
  const totalPagesFromQuery = _get(data, 'filteredProducts.queryInfo.totalPages');

  const { products: filteredByEffectsProducts, totalCount, totalPages } = useFilterProductsQueryByEffects({
    effectsFromParams,
    perPage,
    products: parsedProducts,
    totalCount: totalCountFromQuery,
    totalPages: totalPagesFromQuery,
  });

  const { products } = useSortProductsByCustomBrandsort({
    products: filteredByEffectsProducts,
    dispensary,
    isDefaultSort,
    types,
  });

  return {
    products,
    totalCount,
    totalPages,
    data,
    loading,
    ...apolloValues,
  };
}
