import React, { useState, useRef, useCallback } from 'react';
import { Turnstile as CFTurnstile } from '@marsidev/react-turnstile';
import styled from 'styled-components';

// This can be used to import the test keys
// To test the turnstile in a interactive way
// Set siteKey={TURNSTILE_TEST_SITE_KEYS.FORCES_INTERACTIVE}
// import { TURNSTILE_TEST_SITE_KEYS } from 'shared/constants/turnstile';
import type { TurnstileAction } from 'shared/constants/turnstile';

import PublicEnv from 'shared/utils/public-env';

export type TurnstileProps = {
  onSuccess?: (token: string) => void;
  onError?: (errorMsg: string) => void;
  size?: 'compact' | 'flexible' | 'normal';
  action: TurnstileAction;
  appearance?: 'always' | 'interaction-only';
};

const Turnstile: React.FC<TurnstileProps> = ({
  onSuccess,
  onError,
  action,
  appearance = 'always',
  size = 'normal',
}): JSX.Element => {
  const [turnstileIsInteractive, setturnstileIsInteractive] = useState<boolean>(appearance !== 'interaction-only');
  return (
    <TurnstileContainer $turnstileIsInteractive={turnstileIsInteractive}>
      <CFTurnstile
        siteKey={PublicEnv.turnstileSiteKey}
        // siteKey={TURNSTILE_TEST_SITE_KEYS.FORCES_INTERACTIVE}
        onBeforeInteractive={() => setturnstileIsInteractive(true)}
        onSuccess={(token) => {
          onSuccess?.(token);
        }}
        onError={(errorMsg) => {
          console.error('Turnstile error', errorMsg);
          onError?.(errorMsg);
        }}
        options={{
          action,
          size,
          appearance,
          theme: 'light',
          feedbackEnabled: false,
        }}
      />
    </TurnstileContainer>
  );
};

Turnstile.displayName = 'Turnstile';

/**
 * Custom hook that provides a Turnstile component instance along with a method to execute and wait for token.
 * This implementation only renders the Turnstile component when executeAndWaitForToken is called.
 * @param props The props to pass to the Turnstile component
 * @returns An object containing executeAndWaitForToken method
 */
export const useTurnstile = (
  props: TurnstileProps
): {
  executeAndWaitForToken: (options?: { keepMounted?: boolean; successDelayMs?: number }) => Promise<string>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  TurnstileComponent: () => JSX.Element | null;
} => {
  const [shouldRender, setShouldRender] = useState(false);
  const tokenPromiseRef = useRef<{
    resolve: (token: string) => void;
    reject: (error: string) => void;
    active: boolean;
    keepMounted: boolean;
    successDelayMs?: number;
  } | null>(null);

  // Success callback wrapper
  const successCallback = useCallback(
    (token: string) => {
      // Call the original callback
      props.onSuccess?.(token);

      // Resolve the promise if it exists
      if (tokenPromiseRef.current?.active) {
        const delayMs = tokenPromiseRef.current.successDelayMs ?? 1000;

        // Use setTimeout for both immediate and delayed resolution
        setTimeout(() => {
          if (tokenPromiseRef.current?.active) {
            tokenPromiseRef.current.resolve(token);
            tokenPromiseRef.current.active = false;

            // Unmount the component after getting the token only if keepMounted is false
            if (!tokenPromiseRef.current.keepMounted) {
              setShouldRender(false);
            }
          }
        }, delayMs);
      }
    },
    [props]
  );

  // Error callback wrapper
  const errorCallback = useCallback(
    (errorMsg: string) => {
      // Call the original callback
      props.onError?.(errorMsg);

      // Reject the promise if it exists
      if (tokenPromiseRef.current?.active) {
        tokenPromiseRef.current.reject(errorMsg);
        tokenPromiseRef.current.active = false;

        // Unmount the component after getting an error
        setShouldRender(false);
      }
    },
    [props]
  );

  // Method to execute the challenge and return a promise
  const executeAndWaitForToken = useCallback(
    (options?: { keepMounted?: boolean; successDelayMs?: number }): Promise<string> =>
      new Promise<string>((resolve, reject) => {
        // Store the promise handlers
        tokenPromiseRef.current = {
          resolve,
          reject,
          active: true,
          keepMounted: options?.keepMounted ?? false,
          successDelayMs: options?.successDelayMs,
        };

        // Render the component first
        setShouldRender(true);
      }),
    []
  );

  // Render the Turnstile component only when needed
  const TurnstileComponent = useCallback((): JSX.Element | null => {
    if (!shouldRender) {
      return null;
    }

    return <Turnstile {...props} onSuccess={successCallback} onError={errorCallback} />;
  }, [props, successCallback, errorCallback, shouldRender]);

  return {
    executeAndWaitForToken,
    TurnstileComponent,
  };
};

export default Turnstile;

const TurnstileContainer = styled.div<{ $turnstileIsInteractive?: boolean }>`
  margin: ${(props) => (props.$turnstileIsInteractive ? '1rem 0' : '0')};
  visibility: ${(props) => (props.$turnstileIsInteractive ? 'visible' : 'hidden')};
  display: ${(props) => (props.$turnstileIsInteractive ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;
