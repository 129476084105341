import { ageRestrictions } from 'shared/constants';
export function getAgeRestriction(geoInfo) {
  var _ref = geoInfo || {},
    country = _ref.country,
    region = _ref.region;
  if (!ageRestrictions[country]) {
    return ageRestrictions["default"];
  }
  if (!ageRestrictions[country][region]) {
    return ageRestrictions[country]["default"];
  }
  return ageRestrictions[country][region];
}