import { GqlGetRewardsV2Query } from 'types/graphql';
import Cart from 'src/hooks/use-cart';
import User from 'src/hooks/use-user';

export const getTermsOfUse = (
  dispensaryName: string,
  programName: string
): string => `I consent to signup for the ${dispensaryName} ${programName} program where I will receive marketing
        communications. By doing so, I understand that I am allowing ${dispensaryName} and its technology provider(s) to
        retain my personal contact details/engagement history for use in personalized marketing. Opt out at any time by
        replying "STOP" to messages. Standard message and calling rates may apply.`;

type SetLoyaltyDataProps = {
  user: ReturnType<typeof User>;
  cart: ReturnType<typeof Cart>;
  data: GqlGetRewardsV2Query;
  dispensaryId: string;
  pin?: string;
};

export const setLoyaltyData = ({ user, cart, data, dispensaryId, pin }: SetLoyaltyDataProps): void => {
  const { getRewardsV2 } = data;

  if (getRewardsV2?.auth?.pinConfirmed && pin) {
    user.setLoyaltyPin(dispensaryId, pin);
  }

  user.hasLoyaltyAccount = true;

  cart.availableRewards = getRewardsV2?.rewards.map((reward) => ({
    ...reward,
    brand: getRewardsV2.rewardBrand,
  }));
  cart.hasRewardsWallet = getRewardsV2?.userHasWallet;
  cart.rewardsBalance = getRewardsV2?.balance;
  cart.rewardBrandName = getRewardsV2?.rewardBrand;
  cart.rewardAuth = getRewardsV2?.auth;
  cart.hasCheckedForRewards = true;
};
