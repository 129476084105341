import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useObserver } from 'mobx-react-lite';
import Lottie from 'lottie-react';

import { useInstoreEnrollment } from 'src/hooks/use-instore-enrollment';

import confetti from 'src/assets/confetti.json';
import { FullPageLoader } from 'shared/components';
import { EmbeddedAccountIcon } from 'src/assets/embedded-account-icon';
import { Typography } from 'src/typography';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUser from 'src/hooks/use-user';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { WhoopsContent, WhoopsReason } from 'src/components/whoops';
import { InstoreEnrollmentStatus } from 'src/state/instore-enrollment';
import { useLoyalty } from '../loyalty/use-loyalty';

type InstoreEnrollmentLayoutProps = {
  children: React.ReactNode;
};

const LottieOverlay = styled.div`
  position: fixed;
  left: -30px;
  width: 100vh;
  height: 101vh;
  pointer-events: none;
  z-index: 999;
  display: flex;
`;

const progressBarWidth = {
  [InstoreEnrollmentStatus.loyaltyEnrollment]: '33.33%',
  [InstoreEnrollmentStatus.payByBankEnrollment]: '66.66%',
  [InstoreEnrollmentStatus.connectBankAccount]: '66.66%',
  [InstoreEnrollmentStatus.finishedEnrollment]: '100%',
  [InstoreEnrollmentStatus.ecommLogin]: '0%',
};

const tasksText = {
  [InstoreEnrollmentStatus.loyaltyEnrollment]: '0/2 Tasks',
  [InstoreEnrollmentStatus.payByBankEnrollment]: '1/2 Tasks',
  [InstoreEnrollmentStatus.connectBankAccount]: '1/2 Tasks',
  [InstoreEnrollmentStatus.finishedEnrollment]: '2/2 Tasks',
  [InstoreEnrollmentStatus.ecommLogin]: '0/2 Tasks',
};

export function InstoreEnrollmentLayout({ children }: InstoreEnrollmentLayoutProps): JSX.Element | null {
  const { dispensary, loading: dispensaryLoading } = useDispensary();
  const user = useUser();
  const flags = useFlags();
  const userIsLoggedIn = useObserver(() => user.isLoggedIn);
  const userFirstName = useObserver(() => user.profile?.firstName);
  const userIsLoading = useObserver(() => user.loading);
  const loading = dispensaryLoading || userIsLoading;
  const isInstoreEnrollmentEnabled = flags['growth.ecomm.in-store-incentive-enrollment.rollout'] ?? false;
  const pointsBoostEnabled = flags['growth.ecomm.loyalty-and-pbb-points-boost'];
  const showInStoreEnrollment = !!dispensary && isInstoreEnrollmentEnabled;
  const instoreEnrollment = useInstoreEnrollment();
  const hasSeenSuccess = useObserver(() => instoreEnrollment.hasSeenSuccess);
  const [isConfettiPlaying, setIsConfettiPlaying] = useState(false);
  const { pointsBoostValue } = useLoyalty();

  useEffect(() => {
    if (instoreEnrollment.currentStatus === InstoreEnrollmentStatus.finishedEnrollment && !hasSeenSuccess) {
      setIsConfettiPlaying(true);
      instoreEnrollment.setHasSeenSuccess();
    }
  }, [instoreEnrollment, instoreEnrollment.currentStatus, hasSeenSuccess]);

  if (!isInstoreEnrollmentEnabled) {
    return null;
  }

  if (loading) {
    return (
      <LoadingContainer data-testid='instore-enrollment-loading-container'>
        <FullPageLoader />
      </LoadingContainer>
    );
  }

  if (!showInStoreEnrollment) {
    return <WhoopsContent reason={WhoopsReason.pageNotFound} />;
  }

  const { logoImage, name } = dispensary;
  const dispensaryName = name ?? 'Dispensary';
  const hideProgressBar =
    instoreEnrollment.currentStatus === InstoreEnrollmentStatus.ecommLogin ||
    (instoreEnrollment.declinedPayByBank &&
      instoreEnrollment.currentStatus === InstoreEnrollmentStatus.finishedEnrollment);

  return (
    <MainContainer data-testid='instore-enrollment-layout'>
      {isConfettiPlaying && (
        <LottieOverlay data-testid='instore-enrollment-confetti-overlay'>
          <Lottie
            animationData={confetti}
            style={{
              height: '100%',
            }}
            loop={false}
          />
        </LottieOverlay>
      )}
      <HeadingContainer>
        <HeadingContent>
          <LogoImageContainer>
            <DispensaryLogoImage src={logoImage} alt={`${String(dispensaryName)} Logo`} />
          </LogoImageContainer>
          {userIsLoggedIn && (
            <UserContainer data-testid='instore-enrollment-user-container'>
              <EmbeddedAccountIcon />
              <FirstName size='small'>{userFirstName}</FirstName>
            </UserContainer>
          )}
        </HeadingContent>
      </HeadingContainer>
      {!hideProgressBar && pointsBoostEnabled && (
        <ProgressContainer>
          <div>
            <HeaderContainer>
              <ProgressText size='small'>
                {instoreEnrollment.fullyEnrolled
                  ? 'Puff! The points are yours!'
                  : 'Finalize setup to get bonus points!'}
              </ProgressText>
              <PointsBadge>+{pointsBoostValue} pts</PointsBadge>
            </HeaderContainer>
            <ProgressBarContainer>
              <TasksText size='small'>{tasksText[instoreEnrollment.currentStatus]}</TasksText>
              <ProgressBarWrapper>
                <ProgressBar width={progressBarWidth[instoreEnrollment.currentStatus]} />
              </ProgressBarWrapper>
            </ProgressBarContainer>
          </div>
        </ProgressContainer>
      )}
      {children}
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const DispensaryLogoImage = styled.img`
  object-fit: cover;
`;

const HeadingContent = styled.div`
  display: flex;
  padding: 18px 16px;
  justify-content: space-between;
  width: 100%;
`;

const LogoImageContainer = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spaces[8]};
  max-width: 154px;
`;

const HeadingContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[90]};
  display: flex;
`;

const UserContainer = styled.div`
  align-items: center;
  color: #485055;
  display: flex;
  gap: ${({ theme }) => theme.spaces[2]};
`;

const FirstName = styled(Typography.Heading)`
  font-weight: 600;
  font-size: 13px;
`;

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f1f8f7;
  padding: 16px 20px;
`;

const ProgressText = styled(Typography.Body)`
  font-size: 14px;
  font-weight: 600;
  color: #00624a;
  padding-right: 12px;
`;

const PointsBadge = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 1000px;
  background: #26a27b;
  color: #fff;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 8px;
  display: flex;
  position: relative;
  align-items: center;
`;

const ProgressBarWrapper = styled.div`
  flex: 1;
  height: 100%;
  background: #c4e5dd;
  border-radius: 4px;
`;

const ProgressBar = styled.div`
  width: ${({ width }) => width};
  height: 100%;
  background: linear-gradient(90deg, #004232 0%, #008363 50%, #004232 100%);
  opacity: 0.8;
  border-radius: 4px;
`;

const TasksText = styled(Typography.Body)`
  color: #00624a;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%;
  padding-right: 8px;
  margin-right: 8px;
  white-space: nowrap;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
`;
