import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import {
  ProductRecommendationSource,
  ProductRecommendationSection,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { ProductCarousel } from 'src/components/product-carousel';
import { ProductCarousel as ProductCarouselV2 } from 'src/components/carousel-v2/types/product-carousel';
import { useSponsoredProductHandlers } from 'src/utils/ads/hooks/sponsored-products';
import { useCartRecommendationsCarousel } from './use-cart-recommendations-carousel';

export function CartRecommendationsCarousel(): JSX.Element {
  const { t } = useTranslation();
  const carouselProps = useCartRecommendationsCarousel();
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;
  const title = 'Before You Go';

  const { handleSponsoredProductClick, handleSponsoredProductImpression } = useSponsoredProductHandlers({
    trackerSource: ProductRecommendationSource.moloco,
  });

  if (isProductCardsV2Enabled) {
    return (
      <Wrapper>
        <ProductCarouselV2
          title={title}
          products={carouselProps.products}
          isLoading={carouselProps.loading}
          source={ProductRecommendationSource.crossingMinds}
          isSmallTitle
          onProductClick={handleSponsoredProductClick}
          onProductView={handleSponsoredProductImpression}
          onQuickAddToCart={handleSponsoredProductClick}
        />
      </Wrapper>
    );
  }

  return (
    <ProductCarousel
      isMini
      heading={t(`common.beforeYouGo`, title)}
      trackerSource={ProductRecommendationSource.crossingMinds}
      trackerSection={ProductRecommendationSection.cartRecommendations}
      {...carouselProps}
    />
  );
}

const Wrapper = styled.div`
  padding: 0 20px;
`;
