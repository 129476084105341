import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable consistent-return */

// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import { eligibleProductOptionsForRestriction, isSpecialVersion3dot5 } from 'shared/helpers/specials-3-5';
import { getAvailableQuantity, isApplicableItem, mergeSatisfiers } from './common';
import { orderProductsForDiscountPrecedence, getRemainingRewardsQuantity, compareRewards } from '../utilities';
var isEligibleWeight = function isEligibleWeight(reward, item) {
  return _.isNil(reward.weight) || reward.weight === 'Any Weight' || item.option === reward.weight;
};
var issueStandardRewards = function issueStandardRewards(bogoCalcsData, bogoSpecial, conditionsManager, items) {
  var bogoRewardLogicOperator = bogoSpecial.bogoRewardLogicOperator,
    bogoRewards = bogoSpecial.bogoRewards,
    specialId = bogoSpecial.specialId,
    totalSpend = bogoSpecial.totalSpend;
  var rewardsSatisfiers = {};
  var rewardsInProgress = {};
  var sortedItems = orderProductsForDiscountPrecedence(items, conditionsManager.specialsSettings, 'rewards');
  var finishedSatisfyingRewards = false;
  var remainingSatisfiersCount = getRemainingRewardsQuantity(bogoRewards);
  _.forEach(sortedItems, function (item) {
    _.forEach(bogoRewards || [], function (reward) {
      var satisfierFieldToUse = bogoRewardLogicOperator === 'or' ? reward._id : 'total';
      var isApplicable = false;
      var isApplicableWeight = false;
      if (isSpecialVersion3dot5(bogoSpecial)) {
        var _eligibleProductOptio;
        if ((((_eligibleProductOptio = eligibleProductOptionsForRestriction(item, reward, 'inclusion', bogoSpecial)) === null || _eligibleProductOptio === void 0 ? void 0 : _eligibleProductOptio.length) || 0) > 0) {
          isApplicable = true;
          isApplicableWeight = true;
        }
      } else {
        isApplicable = isApplicableItem(reward, item, bogoSpecial, "reward");
        isApplicableWeight = isEligibleWeight(reward, item);
      }
      if (isApplicable && isApplicableWeight) {
        var _conditionsManager$sa, _conditionsManager$sa2, _conditionsManager$sa3, _bogoCalcsData$defeat, _bogoCalcsData$defeat2, _$find;
        var itemsUsedForConditions = (_conditionsManager$sa = (_conditionsManager$sa2 = conditionsManager.satisfiers) === null || _conditionsManager$sa2 === void 0 ? void 0 : (_conditionsManager$sa3 = _conditionsManager$sa2[item.key]) === null || _conditionsManager$sa3 === void 0 ? void 0 : _conditionsManager$sa3.quantity) !== null && _conditionsManager$sa !== void 0 ? _conditionsManager$sa : 0;
        // TODO: optimize how reshuffling occurs
        var attemptConditionShuffle = itemsUsedForConditions > 0 &&
        // Don't reshuffle if defeated rewards would make it impossible
        (((_bogoCalcsData$defeat = bogoCalcsData.defeatedRewardsSatisfiers) === null || _bogoCalcsData$defeat === void 0 ? void 0 : (_bogoCalcsData$defeat2 = _bogoCalcsData$defeat[item.key]) === null || _bogoCalcsData$defeat2 === void 0 ? void 0 : _bogoCalcsData$defeat2.quantity) || 0) < item.quantity;
        if (attemptConditionShuffle) {
          conditionsManager.evaluate({
            itemToConsiderLast: item,
            pendingRewardsSatisfiers: rewardsSatisfiers
          });
        }
        var availableQuantity = getAvailableQuantity({
          item: item,
          bogoCalcsData: bogoCalcsData,
          pendingConditionsSatisfiers: conditionsManager.satisfiers,
          pendingRewardsSatisfiers: rewardsSatisfiers,
          excludeDefeatedRewardsSatisfiers: true,
          totalSpendApplicable: totalSpend === null || totalSpend === void 0 ? void 0 : totalSpend.enabled
        });
        var previouslySatisfied = _.flatMap(_.values(rewardsSatisfiers), 'rewards');
        var isRewardAlreadySatisfied = !!((_$find = _.find(previouslySatisfied, reward._id)) !== null && _$find !== void 0 && _$find[reward._id].satisfied);
        if (availableQuantity > 0 && !isRewardAlreadySatisfied) {
          rewardsInProgress[reward._id] = _objectSpread(_objectSpread({}, rewardsInProgress[reward._id]), {}, {
            total: remainingSatisfiersCount[satisfierFieldToUse]
          });
          var amountAwarded = Math.min(availableQuantity, remainingSatisfiersCount[satisfierFieldToUse]);
          remainingSatisfiersCount[satisfierFieldToUse] -= amountAwarded;
          rewardsSatisfiers = mergeSatisfiers(rewardsSatisfiers, _defineProperty({}, item.key, {
            item: item,
            quantity: amountAwarded,
            rewards: _defineProperty({}, reward._id, {
              quantity: amountAwarded,
              reward: _objectSpread({}, reward),
              specialId: specialId,
              satisfied: remainingSatisfiersCount[satisfierFieldToUse] < 1,
              discountStacking: bogoSpecial.discountStacking,
              discountPrecedence: bogoSpecial.discountPrecedence,
              stackingBehavior: bogoSpecial.stackingBehavior,
              nonStackingBehavior: bogoSpecial.nonStackingBehavior,
              settingsDerivedFrom: bogoSpecial.settingsDerivedFrom
            })
          }));
          rewardsInProgress[reward._id] = _objectSpread(_objectSpread({}, rewardsInProgress[reward._id]), {}, {
            remaining: remainingSatisfiersCount[satisfierFieldToUse],
            satisfied: remainingSatisfiersCount[satisfierFieldToUse] < 1
          });
        } else if (attemptConditionShuffle) {
          conditionsManager.revert();
        }
      }
      if (remainingSatisfiersCount[satisfierFieldToUse] < 1) {
        finishedSatisfyingRewards = true;
        return false; // breaks early
      }
    });
    if (finishedSatisfyingRewards) {
      return false;
    }
  });
  // compareRewards filters out partial rewards for fully satisfied ones
  // in the case of multiple possible rewards and 'OR' specials
  return bogoRewardLogicOperator === 'or' ? compareRewards(rewardsSatisfiers, rewardsInProgress) : rewardsSatisfiers;
};
export default issueStandardRewards;