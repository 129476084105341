// Test Site Keys
export const TURNSTILE_TEST_SITE_KEYS = {
  ALWAYS_PASSES: '1x00000000000000000000AA',
  ALWAYS_FAILS: '2x00000000000000000000AB',
  FORCES_INTERACTIVE: '3x00000000000000000000FF',
  MOCK_NETWORK_ERROR: '4x00000000000000000000FF',
};

// Corresponding Test Secret Keys
export const TURNSTILE_TEST_SECRET_KEYS = {
  ALWAYS_PASSES: '1x0000000000000000000000000000000AA',
  ALWAYS_FAILS: '2x0000000000000000000000000000000AA',
  FORCES_INTERACTIVE: '3x0000000000000000000000000000000FF',
  MOCK_NETWORK_ERROR: '4x0000000000000000000000000000000FF',
};

export const TURNSTILE_ACTIONS = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  CHECKOUT: 'checkout',
} as const;

export type TurnstileAction = keyof typeof TURNSTILE_ACTIONS;
