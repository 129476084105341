import React from 'react';
import { GreyBolt } from 'src/assets/grey-bolt';
import { LockIconSimple } from 'src/assets/lock-icon-simple';
import { InstoreEnrollmentStatus } from 'src/state/instore-enrollment';
import { useInstoreEnrollment } from 'src/hooks/use-instore-enrollment';
import {
  ENROLLMENT_EVENTS,
  useDutchiePayAnalytics,
} from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import { EnrollmentSteps } from 'src/payments/constants';
import {
  MainContainer,
  ContentContainer,
  SubHeading,
  ImageContainer,
  Image,
  SubHeadingContent,
  BenefitsContainer,
  LeftColumnContainer,
  RightColumnContainer,
  IconContainer,
  SubheadingContentText,
  ButtonsContainer,
  LinkBankButton,
  DeclineText,
} from './pay-by-bank-enrollment-page.styles';
import { INSTORE_ENROLLMENT_SOURCE } from '..';
import { PageHeading } from './page-heading';

type PayByBankEnrollmentPageProps = {
  bonusPoints?: number;
};

export function PayByBankEnrollmentPage({ bonusPoints }: PayByBankEnrollmentPageProps): JSX.Element {
  const instoreEnrollment = useInstoreEnrollment();
  const { dutchiePayEventTracker } = useDutchiePayAnalytics();

  const handleStartBankConnection = (): void => {
    instoreEnrollment.setDidNotDeclinePayByBank();
    instoreEnrollment.setCurrentStatus(InstoreEnrollmentStatus.connectBankAccount);
    dutchiePayEventTracker(ENROLLMENT_EVENTS.ENROLLMENT_BUTTON_CLICKED, {
      step: EnrollmentSteps.connectBank,
      buttonLocation: `${INSTORE_ENROLLMENT_SOURCE}-pay-by-bank-enrollment-page`,
    });
  };

  const handleDeclinePayByBank = (): void => {
    instoreEnrollment.setDidDeclinePayByBank();
    instoreEnrollment.setCurrentStatus(InstoreEnrollmentStatus.finishedEnrollment);
  };

  return (
    <MainContainer data-testid='pay-by-bank-enrollment-page'>
      <ContentContainer>
        <PageHeading bonusPoints={bonusPoints} />
        <ImageContainer>
          <Image
            src='https://images.dutchie.com/pay/pay-by-bank-handheld-iphone.png'
            alt='Pay by bank displayed on iPhone being held in hand'
          />
        </ImageContainer>
        <SubHeadingContent>
          <SubHeading size='small' tag='h3'>
            {bonusPoints
              ? 'Link your bank to checkout faster & avoid potential ATM + card fees'
              : 'Like Venmo, for cannabis'}
          </SubHeading>
          <BenefitsContainer>
            <LeftColumnContainer>
              <IconContainer>
                <GreyBolt />
              </IconContainer>
              <SubheadingContentText size='small' tag='p'>
                Simply login with your <br /> banking credentials in seconds.
              </SubheadingContentText>
            </LeftColumnContainer>
            <RightColumnContainer>
              <IconContainer>
                <LockIconSimple />
              </IconContainer>
              <SubheadingContentText size='small' tag='p'>
                Encrypted end-to-end, <br /> & you can unlink at any time.
              </SubheadingContentText>
            </RightColumnContainer>
          </BenefitsContainer>
        </SubHeadingContent>
        <ButtonsContainer>
          <LinkBankButton data-testid='link-bank-button' size='large' onClick={handleStartBankConnection}>
            Link Your Bank
          </LinkBankButton>
          <DeclineText data-testid='decline-pay-by-bank-button' size='small' tag='p' onClick={handleDeclinePayByBank}>
            Decline faster checkouts
          </DeclineText>
        </ButtonsContainer>
      </ContentContainer>
    </MainContainer>
  );
}
