import { yup } from 'shared/core/yup';
import { useApolloClient } from '@apollo/react-hooks';

import { updateUser } from 'src/state/actions/users';
import useErnie from 'shared/hooks/use-ernie';
import { ERNIE_TYPES } from 'shared/constants';
import { useCreateFraudAccountMutation } from 'types/graphql';

import useTranslation, { ExtendedUseTranslationResponse } from 'src/hooks/use-translation';
import useUser from 'src/hooks/use-user';
import { birthdayRegEx } from 'src/components/forms/form-utils';

type UseCompleteAccountFormProps = {
  onSuccess: () => void;
};

type UseCompleteAccountFormReturn = {
  handleSubmit: (values: CompleteAccountFormProps) => Promise<void>;
  validationSchema: yup.SchemaOf<CompleteAccountFormProps>;
};

type CompleteAccountFormProps = {
  firstName: string;
  lastName: string;
  phone: string;
  birthday: string;
};

const completeAccountFormValidation = (
  t: ExtendedUseTranslationResponse['t']
): yup.SchemaOf<CompleteAccountFormProps> =>
  yup.object().shape({
    firstName: yup.string().required(t('form.firstNameRequired', 'first name is required')),
    lastName: yup.string().required(t('form.lastNameRequired', 'last name is required')),
    phone: yup
      .string()
      .required(t('form.phoneNumberRequired', 'phone number is required'))
      .phone(t('form.validPhoneNumber', 'please enter a valid phone number')),
    birthday: yup
      .string()
      .required(t('form.birthdayRequired', 'birthday is required'))
      .isLegalAge(`MM/DD/YYYY`, t('form.isLegalAge', 'You must be at least 18 years old to use dutchie'))
      .matches(birthdayRegEx, t('form.validBirthday', 'please enter a valid birthday')),
  });

export const useCompleteAccountForm = ({ onSuccess }: UseCompleteAccountFormProps): UseCompleteAccountFormReturn => {
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const showErnie = useErnie();
  const User = useUser();
  const { profile } = User;
  const [createFraudMutation] = useCreateFraudAccountMutation();

  const handleSubmit = async ({ firstName, lastName, phone, birthday }): Promise<void> => {
    const updatedProfile = {
      ...profile,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phone,
      birthday,
    };

    const { success } = await updateUser(apolloClient, {
      profile: updatedProfile,
    });

    if (success) {
      // Call Payments service to fire create_account event in Sift once a new user has been created.
      // This call is async and does not affect the user experience, so we don't need to wait for it to complete.
      if (User.id) {
        createFraudMutation({
          variables: {
            payload: {
              userId: User.id,
              userEmail: User.email,
              name: `${String(firstName)} ${String(lastName)}`,
              phone,
              paymentMethods: [],
            },
          },
        }).catch((error) => {
          console.error('Error creating fraud account', error);
        });
      }

      onSuccess();
    } else {
      showErnie('We were unable to save your information. Please try again.', ERNIE_TYPES.DANGER);
    }
  };

  return {
    handleSubmit,
    validationSchema: completeAccountFormValidation(t),
  };
};
