import React from 'react';

type SmallGreenDiamondProps = React.SVGProps<SVGSVGElement>;

export function SmallGreenDiamond({ width = 24, height = 25, ...props }: SmallGreenDiamondProps): JSX.Element {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 25' fill='none' {...props}>
      <g clipPath='url(#clip0_2710_31469)'>
        <path
          d='M11.3618 23.0005L1.49902 11.9976L11.3618 1.00049L21.2232 11.9976L11.3618 23.0005Z'
          stroke='#00624A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.87915 12.0752L11.4703 17.6126L21.0615 12.0752'
          stroke='#00624A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.3618 1.00049L11.3618 23.0005'
          stroke='#00624A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2710_31469'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.184082)' />
        </clipPath>
      </defs>
    </svg>
  );
}
