import _ from 'lodash';
import { yup } from 'shared/core/yup';
import errors from 'shared/core/fieldValidationErrorMessages';
import { getPickupMinimum } from 'shared/helpers/dispensaries';
import { isDutchiePlusKioskOrder } from 'shared/helpers/checkout';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { getComplianceConfig } from 'shared/core/helpers/compliance';
import moment from 'moment/moment';
/*
 * This should mostly attempt to check for things not sent to the backend on the order. Or things that
 * require some sort of specific UI interaction
 * called via src/checkout/actions/on-click-submit.js 21
 */

export function checkOrderAgainstPickupMinimum(cart, showErnie) {
  const { isPickup, isKioskOrder, subtotalMinusDiscounts, order } = cart;
  const notKioskOrder = !isKioskOrder;
  if (order.deliveryOption === 'delivery') {
    return null;
  }

  const minimum = getPickupMinimum(order.dispensary, order.deliveryOption);
  const minimumInCents = minimum?.minimumInCents ?? 0;
  const minimumEnabled = minimum?.enabled ?? false;
  const subtotalLessThanMinimum = subtotalMinusDiscounts < minimumInCents / 100;

  if (isPickup && notKioskOrder && minimumEnabled && subtotalLessThanMinimum) {
    showErnie(
      `Please meet the pickup minimum of $${
        minimumInCents / 100
      }. Minimums are based on the subtotal minus any discounts.`,
      'danger'
    );
    return { proceed: false };
  }

  return null;
}

export default async function validateSubmit({
  Cart,
  UI,
  User,
  Checkout,
  showErnie,
  isEmailRequiredForCurbsideArrival,
  isExternalProviderEnabled,
}) {
  const { isVariant } = Checkout;
  const { dispensary } = Cart.order;
  if (Cart.isDelivery && Cart.subtotalMinusDiscounts < _.get(Cart.order, 'dispensary.deliveryInfo.minimum', 0) / 100) {
    showErnie(
      `Please meet the delivery minimum of $${
        dispensary.deliveryInfo.minimum / 100
      }. Minimums are based on the subtotal minus any discounts.`,
      'danger'
    );
    return { proceed: false };
  }

  const pickupMinimumCheckResult = checkOrderAgainstPickupMinimum(Cart, showErnie);

  if (pickupMinimumCheckResult) {
    return pickupMinimumCheckResult;
  }

  const isDutchiePlusKiosk = isDutchiePlusKioskOrder(Cart.orderType, UI.isPlus);

  // When guest checkout is disabled, non-logged in users get this early validation failure
  // and all login/signup fields are added to the errors array so the UI can draw the user's attention.
  const disableGuestCheckout = dispensary.storeSettings?.disableGuestCheckout;
  let requireEmailAddressForGuestCheckout =
    dispensary.storeSettings?.requireEmailAddressForGuestCheckout || isEmailRequiredForCurbsideArrival;

  /**
   * There is a setting for kiosk checkout to hideEmailField,
   * but there is also an option to `requireEmailAddressForGuestCheckout`
   * these settings can conflict and won't allow the user to checkout successfully
   */
  if ((Cart.isKioskOrder && dispensary.orderTypesConfigV2.kiosk?.hideEmailField) || Cart.isAnonymousKioskCheckout) {
    requireEmailAddressForGuestCheckout = false;
  }

  if (disableGuestCheckout && !isVariant('kiosk')) {
    _.set(Cart.order, 'inlineAuth.password', '');
  }

  const isPlusOmniauth = isExternalProviderEnabled && UI.isPlus;
  if (isPlusOmniauth) {
    Cart.saveGuestInfo = false;
  } else if (Cart.order.coupon && Cart.order.isGuestOrder) {
    Cart.saveGuestInfo = true;
  }

  if (Cart?.order?.isGuestOrder && Cart?.order?.coupon?.code && isExternalProviderEnabled) {
    Checkout.addErrors([{ card: 'customer', message: 'Please log in to use single use promo codes' }]);
    return { proceed: false };
  }

  if (!Cart.order.isGuestOrder && Checkout.customerCardOpen) {
    Checkout.addErrors([{ card: 'customer', message: 'Please save or cancel your changes', path: 'saveOrCancel' }]);
    return { proceed: false };
  }

  if (!Cart.isPickup && !Cart.isDelivery && !isDutchiePlusKiosk) {
    if (dispensary.orderTypesConfigV2.offerAnyPickupService && dispensary.enabledOrderTypes.delivery) {
      Checkout.addErrors([{ card: 'orderType', message: 'Please select an order type' }]);
      return { proceed: false };
    }

    if (dispensary.orderTypesConfigV2.offerAnyPickupService && !dispensary.enabledOrderTypes.delivery) {
      Checkout.addErrors([{ card: 'orderType', message: 'Please select a pickup type' }]);
      return { proceed: false };
    }
  }

  if (Cart.order.paymentMethod === 'cash') {
    _.unset(Cart.order, 'tipValue');
  }

  if (Cart.paymentMethod === 'rethinkPay') {
    const { isDelivery, isPickup } = Cart;
    const requirePhotoIdForDelivery = _.get(dispensary, 'requirePhotoIdForDelivery', false);
    const requirePhotoIdForPickup = _.get(dispensary, 'requirePhotoIdForPickup', false);
    const birthdayEnabled = !Cart.order.dispensary.storeSettings?.disableGuestDOB;
    const stateEnabled = Cart.requiresCustomerState;
    const showPhotoId = (isDelivery && requirePhotoIdForDelivery) || (isPickup && requirePhotoIdForPickup);

    const driversLicenseEnabled =
      (isDelivery && _.get(dispensary, `requiresDriversLicense`, false)) ||
      (!isDelivery && _.get(dispensary, `requiresDriversLicenseForPickup`, false));

    if (Cart.order.isGuestOrder) {
      if (!Cart.order.guestCustomer.firstName) {
        Checkout.addErrors([{ path: 'guestCustomer.firstName', message: errors.firstName.missing }]);
        return { proceed: false };
      }

      if (!Cart.order.guestCustomer.lastName) {
        Checkout.addErrors([{ path: 'guestCustomer.lastName', message: errors.lastName.missing }]);
        return { proceed: false };
      }

      if (!Cart.order?.guestCustomer?.phone || !isMobilePhone(Cart.order?.guestCustomer?.phone, `en-US`)) {
        Checkout.addErrors([{ path: 'guestCustomer.phone', message: errors.phone.missing }]);
        return { proceed: false };
      }
      if (birthdayEnabled && !Cart.formattedGuestCustomerBirthdate) {
        Checkout.addErrors([{ path: 'guestCustomer.birth', message: errors.birthday.missing }]);
        return { proceed: false };
      }

      if (birthdayEnabled && Cart.formattedGuestCustomerBirthdate) {
        const minAge = getComplianceConfig(
          Cart.order.dispensary.location.state,
          Cart.order.medicalOrder ? `medMinAge` : `recMinAge`
        );

        const legalBirthday = moment().subtract(minAge, `years`).startOf(`day`);
        const customerBirthday = moment({
          month: parseInt(Cart.order.guestCustomer.birthMonth, 10) - 1,
          day: Cart.order.guestCustomer.birthDay,
          year: Cart.order.guestCustomer.birthYear,
        });

        if (moment(Cart.formattedGuestCustomerBirthdate) >= moment(new Date())) {
          Checkout.addErrors([{ path: 'guestCustomer.birth', message: errors.birthday.missing }]);
          return { proceed: false };
        }

        if (legalBirthday.isBefore(customerBirthday.startOf(`day`))) {
          Checkout.addErrors([
            { path: 'guestCustomer.birth', message: 'You must be 18 years of age or older to continue' },
          ]);
          return { proceed: false };
        }
      }
      if (showPhotoId && !Cart.order.guestCustomer.photoId) {
        Checkout.addErrors([{ path: 'guestCustomer.photoId', message: errors.photoId.missing }]);
        return { proceed: false };
      }
    }
    if (stateEnabled && !Cart.order.customerState) {
      Checkout.addErrors([{ path: 'customerState', message: errors.customerState.missing }]);
      return { proceed: false };
    }

    if (driversLicenseEnabled && !Cart.driversLicense) {
      Checkout.addErrors([{ path: 'driversLicense', message: errors.driversLicense.missing }]);
      return { proceed: false };
    }

    if (driversLicenseEnabled && Cart.driversLicense) {
      const invalidDriversLicense = _.size(Cart.driversLicense) <= 4;
      if (invalidDriversLicense) {
        Checkout.addErrors([{ path: 'driversLicense', message: errors.driversLicense.invalid }]);
        return { proceed: false };
      }
    }
  }

  if (!disableGuestCheckout && Cart.order.isGuestOrder && requireEmailAddressForGuestCheckout) {
    if (!Cart.order.guestCustomer.email) {
      Checkout.addErrors([{ path: 'guestCustomer.email', message: errors.email.missing }]);
      return { proceed: false };
    }

    const validEmail = await yup.string().required().email().isValid(Cart.order.guestCustomer.email);
    if (!validEmail) {
      Checkout.addErrors([{ path: 'guestCustomer.email', message: errors.email.invalid }]);
      return { proceed: false };
    }
  }

  if (!Cart.order.isGuestOrder && User.expiredToken && !isVariant('kiosk')) {
    User.logout();
    Checkout.addErrors([{ path: 'userId', message: errors.user.loginRequired }]);
    return { proceed: false };
  }

  // dispensary displays phone confirmation modal at checkout
  if (dispensary.storeSettings.displayPhoneConfirmation && !isVariant('kiosk') && !User.hasExternalUser) {
    // we need to check for an empty phone number before showing the confirmation modal
    if (Cart.order.isGuestOrder) {
      const validPhone = await yup.string().required().phone().isValid(Cart.order?.guestCustomer?.phone);
      if (!validPhone) {
        Checkout.addErrors([{ path: 'guestCustomer.phone', message: errors.phone.missing }]);
        return { proceed: false };
      }
    }
    // delay the order from being fulfilled until the user acknowledges the modal.
    const shouldProceed = await UI.openPhoneNumberConfirmationModal({ confirmed: false });
    if (!shouldProceed) {
      return { proceed: false };
    }
  }

  return { proceed: true };
}
