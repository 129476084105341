import React from 'react';
import Link from 'next/link';
import PopupState from 'material-ui-popup-state';
import { bindHover, bindPopover, bindTrigger, PopupState as PopupStateType } from 'material-ui-popup-state/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { GqlDispensaries, useGetCollectionsNavigationQuery } from 'types/graphql';

import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';
import usePaths from 'src/hooks/use-paths';
import useMenuFilterOptions from 'src/hooks/use-menu-filter-options';
import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import { useHeader } from 'src/dispensary/core-menu/components/header/use-header';

import SvgChevronIcon from 'src/assets/chevron-icon';

import ROUTES from 'src/routes';
import { CartButton } from 'src/dispensary/core-menu/components/header/cart-button';
import { UserButton } from 'src/dispensary/core-menu/components/header/user-button';
import { Search } from 'src/dispensary/core-menu/components/header/search';
import { StoreStatus } from 'src/dispensary/core-menu/components/header/store-status';
import { MenuType } from 'src/dispensary/core-menu/components/header/menu-type';

import {
  PopoverNavLink,
  StyledHoverPopover,
  TwoColumnPopoverNavLink,
} from 'src/dispensary/core-menu/components/header/header.styles';
import {
  Divider,
  HeaderContainer,
  HeaderWrapper,
  InBetweenMq,
  Nav,
  NavButton,
  NavLink,
  ShopNav,
  ShopNavHeading,
  TwoColumnNav,
  UtilityNav,
} from './desktop-header.styles';

type HeaderProps = {
  dispensary: GqlDispensaries;
  isAccountEnabled: boolean;
};

export const DesktopHeader = ({ dispensary, isAccountEnabled }: HeaderProps): JSX.Element => {
  const { href: baseHref } = usePaths();

  const { BRANDS, SPECIALS, PRODUCTS, COLLECTIONS } = ROUTES;

  const { brands, hasProductsOnSpecial } = useMenuFilterOptions({
    skipCurrentFilters: true,
  });
  const hideDiscount = useHideDiscountFromConsumer(dispensary);

  const currentCategory = useDispensaryCategory();
  const { categories } = useDispensaryCategories();

  const { trackEvent } = useHeader();

  const handleCategoryClick = (label: string, popupState: PopupStateType): void => {
    trackEvent(`categories > ${label}`);
    popupState.close();
  };

  const handleCollectionClick = (label: string, popupState: PopupStateType): void => {
    trackEvent(`collections > ${label}`);
    popupState.close();
  };

  const flags = useFlags();
  const isCollectionsEnabled = flags['growth.ecomm.product-collections.rollout'] ?? false;

  const { data } = useGetCollectionsNavigationQuery({
    skip: !isCollectionsEnabled,
    variables: {
      dispensaryId: dispensary.id,
    },
  });

  const collections = data?.getCollectionsNavigation ?? [];

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Nav>
          <Link href={baseHref}>
            <NavLink href={baseHref} onClick={() => trackEvent('Home')}>
              Home
            </NavLink>
          </Link>

          <PopupState variant='popover' popupId='categoriesPopover'>
            {(popupState) => (
              <>
                {/* Also bindTrigger for keyboard and touch device support */}
                <NavButton {...bindHover(popupState)} {...bindTrigger(popupState)} data-testid='nav-shop-button'>
                  {isCollectionsEnabled ? 'Shop' : 'Categories'}
                  <SvgChevronIcon height='7px' width='12px' />
                </NavButton>

                <StyledHoverPopover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {isCollectionsEnabled ? (
                    <ShopNav>
                      <div>
                        <ShopNavHeading>Categories</ShopNavHeading>
                        <TwoColumnNav style={{ gridTemplateRows: `repeat(${Math.ceil(categories.length / 2)}, 1fr)` }}>
                          {categories.map(({ key, label }) => (
                            <li key={key}>
                              <Link href={`${baseHref}${PRODUCTS}${key}`}>
                                <TwoColumnPopoverNavLink
                                  href={`${baseHref}${PRODUCTS}${key}`}
                                  $isActive={currentCategory?.key === key}
                                  onClick={() => handleCategoryClick(label, popupState)}
                                >
                                  {label}
                                </TwoColumnPopoverNavLink>
                              </Link>
                            </li>
                          ))}
                        </TwoColumnNav>
                      </div>

                      {collections.length > 0 && (
                        <div>
                          <ShopNavHeading>Collections</ShopNavHeading>
                          <TwoColumnNav
                            style={{ gridTemplateRows: `repeat(${Math.ceil(collections.length / 2)}, 1fr)` }}
                          >
                            {collections.map(({ slug, navigationTitle, title }) => (
                              <li key={slug}>
                                <Link href={`${baseHref}${COLLECTIONS}${slug}`}>
                                  <TwoColumnPopoverNavLink
                                    href={`${baseHref}${COLLECTIONS}${slug}`}
                                    onClick={() => handleCollectionClick(navigationTitle ?? title, popupState)}
                                  >
                                    {navigationTitle ?? title}
                                  </TwoColumnPopoverNavLink>
                                </Link>
                              </li>
                            ))}
                          </TwoColumnNav>
                        </div>
                      )}
                    </ShopNav>
                  ) : (
                    <nav>
                      {categories.map(({ key, label }) => (
                        <Link href={`${baseHref}${PRODUCTS}${key}`} key={key}>
                          <PopoverNavLink
                            href={`${baseHref}${PRODUCTS}${key}`}
                            $isActive={currentCategory?.key === key}
                            onClick={() => handleCategoryClick(label, popupState)}
                          >
                            {label}
                          </PopoverNavLink>
                        </Link>
                      ))}
                    </nav>
                  )}
                </StyledHoverPopover>
              </>
            )}
          </PopupState>

          {brands.length > 0 && (
            <Link href={`${baseHref}${BRANDS}`}>
              <NavLink href={`${baseHref}${BRANDS}`} onClick={() => trackEvent('Brands')}>
                Brands
              </NavLink>
            </Link>
          )}

          {hasProductsOnSpecial && !hideDiscount && (
            <Link href={`${baseHref}${SPECIALS}`}>
              <NavLink href={`${baseHref}${SPECIALS}`} onClick={() => trackEvent('Specials')}>
                Specials
              </NavLink>
            </Link>
          )}

          <Search />
        </Nav>

        <UtilityNav>
          <InBetweenMq>
            <StoreStatus />

            <Divider />

            <MenuType />

            {isAccountEnabled && <Divider />}
          </InBetweenMq>

          {isAccountEnabled && <UserButton />}

          <CartButton />
        </UtilityNav>
      </HeaderContainer>
    </HeaderWrapper>
  );
};
