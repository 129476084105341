import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useUI from 'hooks/use-ui';
import useCart from 'hooks/use-cart';
import useViewport from 'hooks/use-viewport';
import { removePersistedValue } from 'shared/utils/persisted-values';
import { ModalWrapper } from 'shared/modals/v2-wrapper';
import SwipeableDrawer from 'components/material-ui-swipeable-drawer';
import { MobileOnly } from 'shared/components';

import { CartDropdown } from 'components/cart';
import { KioskQrCodeModal, PayByBankKioskOrderSuccessModal } from 'src/payments/modals';
import { DutchiePayCancelModal } from 'src/payments/modals/leave-modal';
import { DutchiePayLearnMoreModal } from 'src/payments/modals/learn-more-modal';
import { DutchiePayInstoreTaxesModal } from 'src/payments/modals/instore-cart-taxes-modal';
import { InfoBackdropComponent } from 'src/payments/modals/info-modal';
import { KioskCheckoutSuccessModalV2 } from './kiosk-checkout/kiosk-checkout-success-modal-v2';
import { ChangeEmailPasswordPrompt } from './change-email-password-prompt/change-email-password-prompt';
import MenuTypeSwitchWarningModal from './menu-type-switch-warning';
import GuestCheckoutSuccessModal from './guest-checkout-success-modal';
import PausedOrders from './paused-orders';
import Closed from './closed';
import ResidentialVerification from './residential-verification';
import KioskCheckoutModal from './kiosk-checkout';
import KioskSuccessModal from './kiosk-checkout/success';
import AgeRestrictionModal from './age-restriction';
import OutsideDeliveryAreaModal from './outside-of-delivery-area';
import { StoreTypeBottomDrawer } from './store-type-bottom-drawer';
import { OrderTypeDrawer } from './order-type-bottom-drawer/index';
import { DispensaryListFiltersBottomDrawer } from './dispensary-list-filters-bottom-drawer/index';

function GlobalModals() {
  const UI = useUI();
  const viewport = useViewport();
  const flags = useFlags();
  const cart = useCart();

  const closeModal = (name) => () => {
    UI[`show${name}Modal`] = false;
  };

  const closeGuestCheckoutModal = () => {
    cart.stashedOrder = null;
    removePersistedValue('viewOrderId', true);
    removePersistedValue('stashedOrder', true);
    UI.viewingGuestOrderSuccess = false;
  };

  if (UI.isDemo) {
    return null;
  }

  const containerProps = {
    isIframe: UI.isIframe,
    iframeOffset: viewport.offset,
    parentOffset: viewport.parentOffset,
    viewportHeight: viewport.height,
    viewportWidth: viewport.width,
    iframeHeight: UI.iframeHeight,
  };

  const kioskOrderSuccessModalV2Enabled = flags['growth.ecomm.kiosk-order-success-modal-v2'] ?? false;

  return (
    <>
      <ModalWrapper open={!!UI.showClosedModal} onClose={closeModal(`Closed`)}>
        <Closed containerProps={containerProps} onClose={closeModal(`Closed`)} />
      </ModalWrapper>

      <ModalWrapper open={!!UI.showPausedOrdersModal} onClose={closeModal(`PausedOrders`)}>
        <PausedOrders containerProps={containerProps} onClose={closeModal(`PausedOrders`)} />
      </ModalWrapper>

      <ModalWrapper open={!!UI.viewingGuestOrderSuccess} onClose={closeGuestCheckoutModal}>
        <GuestCheckoutSuccessModal containerProps={containerProps} onClose={closeGuestCheckoutModal} />
      </ModalWrapper>

      <ModalWrapper
        open={!!UI.menuTypeSwitchWarningObj.open}
        onClose={() => (UI.menuTypeSwitchWarningObj.open = false)}
      >
        <MenuTypeSwitchWarningModal
          containerProps={containerProps}
          onClose={() => (UI.menuTypeSwitchWarningObj.open = false)}
        />
      </ModalWrapper>

      <ModalWrapper open={!!UI.showResidentialVerificationModal.open}>
        <ResidentialVerification
          containerProps={containerProps}
          onClose={(yesClick) => {
            UI.showResidentialVerificationModal.resolve(yesClick);
            UI.showResidentialVerificationModal.open = false;
          }}
        />
      </ModalWrapper>

      <ModalWrapper open={!!UI.showKioskCheckoutModal} onClose={() => (UI.showKioskCheckoutModal = false)}>
        <KioskCheckoutModal containerProps={containerProps} onClose={() => (UI.showKioskCheckoutModal = false)} />
      </ModalWrapper>

      <ModalWrapper open={!!UI.showKioskOrderSuccess} onClose={() => (UI.showKioskOrderSuccess = false)}>
        {kioskOrderSuccessModalV2Enabled ? (
          <KioskCheckoutSuccessModalV2 onClose={() => (UI.showKioskOrderSuccess = false)} />
        ) : (
          <KioskSuccessModal containerProps={containerProps} onClose={() => (UI.showKioskOrderSuccess = false)} />
        )}
      </ModalWrapper>

      <ModalWrapper open={!!UI.showDutchiePayQrCodeInKiosk}>
        <KioskQrCodeModal onClose={() => (UI.showDutchiePayQrCodeInKiosk = false)} />
      </ModalWrapper>

      <ModalWrapper open={!!UI.showDutchiePayKioskOrderSuccess}>
        <PayByBankKioskOrderSuccessModal onClose={() => (UI.showDutchiePayKioskOrderSuccess = false)} />
      </ModalWrapper>

      <ModalWrapper
        open={!!UI.showOutsideDeliveryAreaModal.open}
        onClose={() => {
          UI.showOutsideDeliveryAreaModal.resolve(false);
          UI.showOutsideDeliveryAreaModal.open = false;
        }}
      >
        <OutsideDeliveryAreaModal
          containerProps={containerProps}
          onClose={() => {
            UI.showOutsideDeliveryAreaModal.resolve();
            UI.showOutsideDeliveryAreaModal.open = false;
          }}
        />
      </ModalWrapper>

      <ModalWrapper
        open={UI.showDutchiePayLeaveModal}
        onClose={() => {
          UI.closeDutchiePayLeaveModal();
        }}
      >
        <DutchiePayCancelModal onCloseModal={() => UI.closeDutchiePayLeaveModal()} />
      </ModalWrapper>

      <ModalWrapper open={UI.showDutchiePayLearnMoreModal} onClose={UI.closeDutchiePayLearnMoreModal}>
        <DutchiePayLearnMoreModal onCloseModal={() => UI.closeDutchiePayLearnMoreModal()} />
      </ModalWrapper>

      <ModalWrapper
        open={UI.showDutchiePayInstoreTaxInfo}
        BackdropComponent={InfoBackdropComponent}
        onClose={UI.closeDutchiePayInstoreTaxInfo}
      >
        <DutchiePayInstoreTaxesModal onCloseModal={UI.closeDutchiePayInstoreTaxInfo} />
      </ModalWrapper>

      <ModalWrapper
        open={!!UI.showChangeEmailPasswordPrompt.open}
        onClose={() => {
          UI.showChangeEmailPasswordPrompt.resolve(false);
          UI.showChangeEmailPasswordPrompt.open = false;
        }}
      >
        <ChangeEmailPasswordPrompt
          containerProps={containerProps}
          onClose={(proceed) => {
            UI.showChangeEmailPasswordPrompt.resolve(proceed);
            UI.showChangeEmailPasswordPrompt.open = false;
          }}
        />
      </ModalWrapper>

      {UI.isDutchieMain && (
        <MobileOnly>
          <DispensaryListFiltersBottomDrawer
            open={!!UI.showDispensariesFiltersDrawer}
            onClose={UI.closeDispensariesFiltersDrawer}
          />
          <OrderTypeDrawer open={!!UI.showOrderTypeDrawer} onClose={UI.closeOrderTypeDrawer} />
          <StoreTypeBottomDrawer open={!!UI.showStoreTypeDrawer} onClose={UI.closeStoreTypeDrawer} />
        </MobileOnly>
      )}

      <StyledDrawer anchor='right' open={UI.showCartDrawer} onOpen={UI.openCartDrawer} onClose={UI.closeCartDrawer}>
        <CartDropdown height={UI.isEmbedded ? viewport.height : `100%`} onClose={UI.closeCartDrawer} />
      </StyledDrawer>
      {UI.showAgeRestrictionModal && <AgeRestrictionModal />}
    </>
  );
}

const StyledDrawer = styled(SwipeableDrawer)`
  .MuiBackdrop-root {
    background-color: ${({ theme }) => theme.colors.whiteOverlay};
  }
`;

export default observer(GlobalModals);
