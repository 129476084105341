import React from 'react';
import styled from 'styled-components';

import { Link } from 'components/core';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import usePaths from 'hooks/use-paths';

export function CategoryLink({ category, onSelect }) {
  const selectedCategory = useDispensaryCategory();
  const { href, route } = usePaths({ category });
  const selected = category.key === selectedCategory?.key;

  return (
    <LinkContainer>
      <Link href={href} route={route}>
        {/* eslint-disable-next-line styled-components-a11y/anchor-is-valid, styled-components-a11y/click-events-have-key-events, styled-components-a11y/no-static-element-interactions */}
        <Anchor selected={selected} onClick={onSelect} data-testid='menu-drawer-category-link'>
          {category.label}
        </Anchor>
      </Link>
    </LinkContainer>
  );
}
const LinkContainer = styled.li`
  list-style: none;
`;

const Anchor = styled.a`
  font-weight: bold;
  font-size: 22px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.grey[30]};
`;
