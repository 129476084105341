import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AdInventoryId, GqlAdSettings } from 'types/graphql';
import { Product } from 'src/utils/helpers/product';
import { Category } from 'src/generics/data/generic-category/data.types';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'src/hooks/use-ui';

export const ADS_FETCH_POLICY = 'cache-and-network';
export function getMostCommonBrandSlug(products: Product[]): string {
  const result = products.reduce(
    (acc, product) => {
      const brandName = product.brandName ?? product.brand.name;

      if (brandName) {
        acc.counts[brandName] = (acc.counts[brandName] || 0) + 1;

        if (acc.counts[brandName] > acc.maxCount) {
          acc.maxCount = acc.counts[brandName];
          acc.mostCommonBrandName = brandName;
        }
      }
      return acc;
    },
    { counts: {} as Record<string, number>, maxCount: 0, mostCommonBrandName: '' }
  );

  return _.kebabCase(result.mostCommonBrandName);
}
export function useAdInventoryId(dispensaryId: string, category?: Category | null): AdInventoryId {
  const { categoryCarouselAuc, homeCarouselAuc } = AdInventoryId;

  if (category) {
    return categoryCarouselAuc;
  }

  return homeCarouselAuc;
}

export function participatingInAds(flags: Record<string, unknown>, showAdByMenuVariant: boolean): boolean {
  const isMenuVariantFlagActive = flags['growth.ads.sponsored-brand-carousel.use-ads-by-menu-variant'] ?? true;
  const isSponsoredBrandAdActive = flags['growth.ads.sponsored-brand-carousel.placement'] !== 'none';

  if (isMenuVariantFlagActive) {
    return showAdByMenuVariant;
  }

  return isSponsoredBrandAdActive;
}

export function useParticipatingInAds(): boolean {
  const flags = useFlags();
  const showAdByMenuVariant = useIsDispensaryMenuVariantEnabledForAds();

  return participatingInAds(flags, showAdByMenuVariant);
}

export function useIsDispensaryMenuVariantEnabledForAds(): boolean {
  const { dispensary } = useDispensary();
  const { variant } = useUI();

  return getDispensaryMenuVariantEnabledForAds(variant, dispensary?.adSettings);
}

export function getDispensaryMenuVariantEnabledForAds(variant: string, adSettings?: GqlAdSettings): boolean {
  const adSettingsMenuVariantMap = {
    default: 'enableAdsForDefault',
    embedded: 'enableAdsForEmbedded',
    kiosk: 'enableAdsForKiosk',
    'store-front': 'enableAdsForStoreFront',
  };

  const currentMenu = adSettingsMenuVariantMap[variant];

  return adSettings?.[currentMenu] ?? false;
}
