import React from 'react';
import styled from 'styled-components';
import { space, height } from 'styled-system';
import geodist from 'geodist';
import { useRouter } from 'next/router';

import PinIcon from 'assets/pin-icon';
import Link from 'components/core/link';

import LoadingSkeleton from 'src/components/core/loading-skeletons/loading-skeleton';

import useUI from 'hooks/use-ui';

/**
 * Only present link if not loading. We do not want to try and send user to a route
 * that does not exist.
 */
const LinkWrapper = ({ href, route, loading, children }) =>
  loading ? (
    <>{children}</>
  ) : (
    <Link href={href} route={route}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a>{children}</a>
    </Link>
  );

const DispensaryChainCard = ({ dispensary, loading, onMouseEnter, onMouseLeave, onClick, id, isHighlighted }) => {
  const { hasAddress, currentAddress, isStoreFront } = useUI();
  const router = useRouter();
  const { path } = router.query;
  // eslint-disable-next-line lodash/prefer-lodash-typecheck
  const decodedPath = typeof path === 'string' ? decodeURIComponent(path) : '/menu';
  const pathSuffix = decodedPath.startsWith('/') ? decodedPath : `/${decodedPath}`;

  const logoStyles = {
    height: 63,
    width: 78,
    mr: 27,
  };

  let pathFragment = 'embedded-menu';

  if (isStoreFront) {
    pathFragment = 'stores';
  }

  const href = `/${pathFragment}/${dispensary?.cName}${pathSuffix}`;
  const route = `/${pathFragment}/[cName]${pathSuffix}`;
  let distance;

  if (hasAddress) {
    const [fromLng, fromLat] = dispensary.location.geometry.coordinates;
    const { lat: toLat, lng: toLng } = currentAddress;
    distance = geodist(
      { lat: fromLat, lng: fromLng },
      { lat: toLat, lng: toLng },
      { unit: `miles`, exact: true }
    ).toFixed(1);
  }

  return (
    <LinkWrapper href={href} route={route} loading={loading}>
      <LocationCard
        id={id}
        loading={loading}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        onKeyPress={(e) => e.key === 'Enter' && onClick?.()}
        role='button'
        tabIndex={0}
        isHighlighted={isHighlighted}
      >
        {loading ? (
          <LoadingSkeleton height={logoStyles.height} width={logoStyles.width} mr={logoStyles.mr} />
        ) : (
          <Logo
            src={dispensary.logoImage}
            alt=''
            height={logoStyles.height}
            width={logoStyles.width}
            mr={logoStyles.mr}
          />
        )}

        <DispensaryDetails>
          {loading ? (
            <>
              <LoadingSkeleton width='60%' height='6px' />
              <LoadingSkeleton width='100%' height='6px' />
              <LoadingSkeleton width='75%' height='9px' />
            </>
          ) : (
            <>
              <DispensaryName>{dispensary.name}</DispensaryName>
              <DispensaryAddress>{dispensary.address}</DispensaryAddress>
            </>
          )}
        </DispensaryDetails>

        {distance && (
          <DistanceContainer>
            <PinIcon height={12} />
            {distance}
          </DistanceContainer>
        )}
      </LocationCard>
    </LinkWrapper>
  );
};

export default DispensaryChainCard;

const DispensaryDetails = styled.div`
  width: 270px;
`;

const DispensaryName = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 6px;
`;

const DispensaryAddress = styled.div`
  font-size: 13px;
`;

const DistanceContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  > svg {
    margin-right: 4px;
  }
`;

const LocationCard = styled.div`
  display: flex;
  width: auto;
  margin: 7px 10px 13px 10px;
  border-radius: 4px;
  padding: 18px;
  cursor: ${({ loading }) => (loading ? `auto` : `pointer`)};
  border: ${({ theme, isHighlighted }) =>
    isHighlighted ? `1px solid ${theme.customized.colors.buttonsLinks}` : `1px solid ${theme.colors.v2Border1}`};
  background-color: ${({ theme }) => theme.colors.v2Background1};
  text-align: left;
  color: ${({ theme }) => theme.colors.v2TextColor1};
  pointer-events: auto;
  flex-shrink: 0;
  max-width: 100%;
  align-items: center;
  transition: border 0.2s ease;

  &:hover {
    background-color: ${({ theme, loading }) => (loading ? theme.colors.v2Background1 : theme.colors.v2Background2)};
    border: ${({ theme, loading, isHighlighted }) =>
      isHighlighted
        ? `1px solid ${theme.customized.colors.buttonsLinks}`
        : `1px solid ${loading ? theme.colors.v2Border1 : theme.colors.v2Border2}`};
  }
`;

const Logo = styled.img`
  ${space}
  ${height}
  width: auto;
`;
