import noop from 'lodash/noop';
import { EventTracker } from '../events';
import { createSiftClient, createSiftNoopClient, SiftClient } from '../clients';

type TrackerArgs = {
  beaconKey: string;
  enabled: boolean;
};

export const createSiftTracker = (options: TrackerArgs): EventTracker => {
  let client: SiftClient;

  if (options.enabled) {
    client = createSiftClient(options.beaconKey);
  } else {
    client = createSiftNoopClient();
  }

  return {
    onContextChange: (_updates, newContext, oldContext) => {
      if (newContext.activePath?.concrete !== oldContext.activePath?.concrete) {
        client.trackPageView();
      }

      if (newContext.activeUser?.id !== oldContext.activeUser?.id && newContext.activeUser?.id) {
        client.setUserId(newContext.activeUser.id);
      }
    },

    accountLogin: noop,
    addedProductToCart: noop,
    buyItAgainImpression: noop,
    buyItAgainViewAllClicked: noop,
    checkoutStarted: noop,
    dutchiePayEnrollmentButtonClicked: noop,
    dutchiePayLearnMoreButtonClicked: noop,
    dutchiePayCTAButtonClicked: noop,
    dutchiePayEnrollmentStep: noop,
    dutchiePayConnectBankStep: noop,
    dutchiePayPlaidOnEvent: noop,
    dutchiePayInstoreAccountLinkViewed: noop,
    dutchiePayInstoreCartApprovalClicked: noop,
    dutchiePayInstoreCartApprovalFailed: noop,
    dutchiePayInstoreCartApprovalSucceeded: noop,
    dutchiePayInstoreCartViewed: noop,
    dutchiePayInstoreLoginViewed: noop,
    loyaltySignup: noop,
    paymentsError: noop,
    placedOrder: noop,
    productClicked: noop,
    privacyPreferenceUpdated: noop,
    removedProductFromCart: noop,
    searchedForProduct: noop,
    searchResults: noop,
    viewedCheckout: noop,
    checkoutSession: noop,
    viewedProduct: noop,
    cartViewed: noop,
    imageBannerClicked: noop,
    sponsoredProductImpression: noop,
    sponsoredProductClicked: noop,
    sponsoredBannerImpression: noop,
    sponsoredBannerClicked: noop,
    gaGTMClickEvent: noop,
  };
};
