import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
import moment from 'moment';
import { removeTypename } from 'shared/helpers/utils';
import { findNextOpenDay, guessTimezone } from 'shared/helpers/date-time';
import { CanadianDefaultRewardsProgramDescription, CanadianDefaultRewardsProgramEnrollment, CanadianDefaultRewardsProgramName, DefaultRewardsProgramDescription, DefaultRewardsProgramEnrollment, DefaultRewardsProgramName } from '../../constants/dispensaries';
import { getComplianceConfig } from '../compliance';
var getOpenInfo = function getOpenInfo(_ref) {
  var serviceType = _ref.serviceType,
    timezone = _ref.timezone,
    standardHours = _ref.standardHours,
    specialHours = _ref.specialHours,
    isServiceOffered = _ref.isServiceOffered;
  var defaultOpenInfo = {
    isOpen: false,
    isClosed: true,
    nextService: {
      openMoment: null,
      openString: "Time unknown",
      closeMoment: null
    }
  };
  if (!isServiceOffered) {
    return defaultOpenInfo;
  }
  timezone = timezone || guessTimezone();
  var now = moment().tz(timezone);
  var nextOpenDay = findNextOpenDay({
    serviceType: serviceType,
    standardHours: standardHours,
    specialHours: specialHours,
    timezone: timezone,
    now: now
  });
  if (!nextOpenDay || _.keys(nextOpenDay).length === 0) {
    return defaultOpenInfo;
  }
  var date = nextOpenDay.date,
    openMoment = nextOpenDay.openMoment,
    closeMoment = nextOpenDay.closeMoment;
  var nextDayString = "on ".concat(date.format('dddd'));
  if (date.isSame(now, 'day')) {
    nextDayString = 'today';
  }
  var openString = "".concat(openMoment.format('h:mmA'), " ").concat(nextDayString);
  var isOpen = now.isBetween(openMoment, closeMoment);
  return {
    isOpen: isOpen,
    isClosed: !isOpen,
    nextService: {
      openMoment: openMoment,
      closeMoment: closeMoment,
      openString: openString
    }
  };
};

/**
 * @param {Dispensary} dispensary
 * @returns {boolean}
 */
export var isRecMed = function isRecMed(_ref2) {
  var medicalDispensary = _ref2.medicalDispensary,
    recDispensary = _ref2.recDispensary;
  return !!medicalDispensary && !!recDispensary;
};
export var hasIsolatedMenus = function hasIsolatedMenus() {
  var dispensary = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var location = dispensary.location,
    storeSettings = dispensary.storeSettings;
  return (storeSettings === null || storeSettings === void 0 ? void 0 : storeSettings.isolatedMenus) || isRecMed(dispensary) && getComplianceConfig(location === null || location === void 0 ? void 0 : location.state, 'isolatedMenus');
};

/**
 * @typedef {{recDispensary?: boolean | null, medicalDispensary?: boolean | null}} Dispensary
 */
/**
 * @param {Dispensary} dispensary
 * @returns {boolean}
 */
export var isMedOnly = function isMedOnly(_ref3) {
  var medicalDispensary = _ref3.medicalDispensary,
    recDispensary = _ref3.recDispensary;
  return !!medicalDispensary && !recDispensary;
};

/**
 * @param {Dispensary} dispensary
 * @returns {boolean}
 */
export var isRecOnly = function isRecOnly(_ref4) {
  var medicalDispensary = _ref4.medicalDispensary,
    recDispensary = _ref4.recDispensary;
  return !medicalDispensary && !!recDispensary;
};
export var getDefaultRewardsProgramDisplayDescription = function getDefaultRewardsProgramDisplayDescription(_ref5) {
  var _ref5$location = _ref5.location,
    location = _ref5$location === void 0 ? {} : _ref5$location;
  return (location === null || location === void 0 ? void 0 : location.country) === 'Canada' ? CanadianDefaultRewardsProgramDescription : DefaultRewardsProgramDescription;
};
export var getDefaultRewardsProgramDisplayEnrollment = function getDefaultRewardsProgramDisplayEnrollment(_ref6) {
  var _ref6$location = _ref6.location,
    location = _ref6$location === void 0 ? {} : _ref6$location;
  return (location === null || location === void 0 ? void 0 : location.country) === 'Canada' ? CanadianDefaultRewardsProgramEnrollment : DefaultRewardsProgramEnrollment;
};
export var getDefaultRewardsProgramDisplayName = function getDefaultRewardsProgramDisplayName(_ref7) {
  var _ref7$location = _ref7.location,
    location = _ref7$location === void 0 ? {} : _ref7$location;
  return (location === null || location === void 0 ? void 0 : location.country) === 'Canada' ? CanadianDefaultRewardsProgramName : DefaultRewardsProgramName;
};
export function requiresCustomerState(dispensary) {
  var _dispensary$location;
  return !!getComplianceConfig(dispensary === null || dispensary === void 0 ? void 0 : (_dispensary$location = dispensary.location) === null || _dispensary$location === void 0 ? void 0 : _dispensary$location.state, 'outOfStateLimits');
}

/*
 * Javascript date has no internal knowledge about
 * timezone whereas moment does.  We are only going
 * to use moment in these calculations for that reason.
 */
export function openInfoForDispensary(dispensary) {
  var _ref8 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _ref8$_previewMode = _ref8._previewMode,
    _previewMode = _ref8$_previewMode === void 0 ? false : _ref8$_previewMode;
  var orderTypes = ['inStorePickup', 'curbsidePickup', 'driveThruPickup', 'delivery'];
  var openInfo = {};
  _.forEach(orderTypes, function (orderType) {
    var defaultOpenInfo = {
      isOpen: true,
      isClosed: false,
      nextService: {
        openMoment: null,
        openString: "Time unknown",
        closeMoment: null
      }
    };
    _.set(openInfo, orderType, defaultOpenInfo);
  });
  if (!dispensary) {
    return openInfo;
  }
  var timezone = dispensary.timezone,
    hoursSettings = dispensary.hoursSettings;
  var enabledOrderTypes = getEnabledV2OrderTypes(dispensary);
  _.forEach(orderTypes, function (orderType) {
    var _hoursSettings$orderT;
    var hoursSettingsForOrderType = (_hoursSettings$orderT = hoursSettings === null || hoursSettings === void 0 ? void 0 : hoursSettings[orderType]) !== null && _hoursSettings$orderT !== void 0 ? _hoursSettings$orderT : {};
    _.set(openInfo, orderType, getOpenInfo({
      serviceType: orderType,
      timezone: timezone,
      standardHours: hoursSettingsForOrderType.hours,
      specialHours: hoursSettingsForOrderType.specialHours,
      isServiceOffered: _.includes(enabledOrderTypes, orderType)
    }));
  });
  return openInfo;
}
export function checkDeliveryOpenInfo(openInfoForOrderTypes, dispensary) {
  var _dispensary$orderType, _dispensary$orderType2, _dispensary$orderType3, _dispensary$orderType4;
  var sameDayDeliveryEnabled = (_dispensary$orderType = dispensary === null || dispensary === void 0 ? void 0 : (_dispensary$orderType2 = dispensary.orderTypesConfigV2) === null || _dispensary$orderType2 === void 0 ? void 0 : (_dispensary$orderType3 = _dispensary$orderType2.delivery) === null || _dispensary$orderType3 === void 0 ? void 0 : (_dispensary$orderType4 = _dispensary$orderType3.scheduledOrderingConfigV2) === null || _dispensary$orderType4 === void 0 ? void 0 : _dispensary$orderType4.sameDayDeliveryEnabled) !== null && _dispensary$orderType !== void 0 ? _dispensary$orderType : true;
  if (openInfoForOrderTypes !== null && openInfoForOrderTypes !== void 0 && openInfoForOrderTypes.delivery && !sameDayDeliveryEnabled) {
    openInfoForOrderTypes.delivery.isOpen = false;
    openInfoForOrderTypes.delivery.isClosed = true;
  }
  return openInfoForOrderTypes;
}

/**
 * @param {Dispensary} dispensary
 * @returns {Array<string>}
 */
export function getEnabledV2OrderTypes(dispensary) {
  if (!dispensary || !dispensary.enabledOrderTypes && !dispensary.orderTypesConfig) {
    return [];
  }
  var enabledOrderTypes = dispensary.enabledOrderTypes,
    orderTypesConfig = dispensary.orderTypesConfig;

  // Helper is being called from the API and doesn't have
  // access to the serialized enabledOrderTypes field, use
  // the orderTypesConfig Mongo data instead
  if (!enabledOrderTypes) {
    var _orderTypes = _objectSpread(_objectSpread({}, _.omit(removeTypename(orderTypesConfig), ['pickup', 'kiosk', '_id']) || {}), {}, {
      inStorePickup: orderTypesConfig.pickup
    });
    return _.filter(_.keys(_orderTypes), function (orderType) {
      var _orderTypes$orderType = _orderTypes[orderType],
        enabled = _orderTypes$orderType.enabled,
        enableASAPOrdering = _orderTypes$orderType.enableASAPOrdering,
        enableScheduledOrdering = _orderTypes$orderType.enableScheduledOrdering;
      return enabled && (enableASAPOrdering || enableScheduledOrdering);
    });
  }

  // Helper is being called from the client and can use the
  // serialized enabledOrderTypes field on the dispensary
  var orderTypes = _.omit(removeTypename(enabledOrderTypes), ['pickup', 'kiosk']) || {};
  return _.filter(_.keys(orderTypes), function (orderType) {
    return orderTypes[orderType];
  });
}
export function areSpecialHoursToday(dispensary) {
  var hoursSettings = dispensary.hoursSettings,
    _dispensary$specialHo = dispensary.specialHours,
    specialHours = _dispensary$specialHo === void 0 ? {} : _dispensary$specialHo;
  var today = moment().format('YYYY-MM-DD');
  if (!hoursSettings || !specialHours) {
    return false;
  }
  var checkSpecialHours = function checkSpecialHours(specialHourArr) {
    if (specialHourArr !== null && specialHourArr !== void 0 && specialHourArr.length) {
      var isDuringSpecialHours = _.some(specialHourArr, function (specialHour) {
        var startDate = specialHour.startDate,
          endDate = specialHour.endDate;
        var startMoment = moment(startDate);
        var endMoment = moment(endDate);
        return startMoment.isSameOrBefore(today, 'day') && endMoment.isSameOrAfter(today, 'day');
      });
      return isDuringSpecialHours;
    }
    return false;
  };
  if (hoursSettings) {
    var _hoursSettings$inStor = hoursSettings.inStorePickup.specialHours,
      inStoreSpecialHours = _hoursSettings$inStor === void 0 ? {} : _hoursSettings$inStor,
      _hoursSettings$curbsi = hoursSettings.curbsidePickup.specialHours,
      curbsideSpecialHours = _hoursSettings$curbsi === void 0 ? {} : _hoursSettings$curbsi,
      _hoursSettings$driveT = hoursSettings.driveThruPickup.specialHours,
      driveThruSpecialHours = _hoursSettings$driveT === void 0 ? {} : _hoursSettings$driveT,
      _hoursSettings$delive = hoursSettings.delivery.specialHours,
      deliverySpecialHours = _hoursSettings$delive === void 0 ? {} : _hoursSettings$delive;
    var allSpecialHours = _.concat(inStoreSpecialHours, curbsideSpecialHours, driveThruSpecialHours, deliverySpecialHours);
    return checkSpecialHours(allSpecialHours);
  }
  return checkSpecialHours(specialHours);
}
export function collectScheduledData(dispensary, orderType, schedulingOption, reservationSlots) {
  var _openInfo$currentOrde, _dispensary$orderType5, _dispensary$orderType6, _openInfo$currentOrde2, _nextService$openMome, _nextService$closeMom;
  var openInfo = openInfoForDispensary(dispensary);
  var currentOrderType = orderType !== null && orderType !== void 0 ? orderType : _.findKey(openInfo, "isOpen");
  var nextService = (_openInfo$currentOrde = openInfo[currentOrderType]) === null || _openInfo$currentOrde === void 0 ? void 0 : _openInfo$currentOrde.nextService;
  var scheduledOrderingEnabled = dispensary === null || dispensary === void 0 ? void 0 : (_dispensary$orderType5 = dispensary.orderTypesConfigV2[currentOrderType]) === null || _dispensary$orderType5 === void 0 ? void 0 : _dispensary$orderType5.enableScheduledOrdering;
  var afterHoursEnabled = dispensary === null || dispensary === void 0 ? void 0 : (_dispensary$orderType6 = dispensary.orderTypesConfigV2[currentOrderType]) === null || _dispensary$orderType6 === void 0 ? void 0 : _dispensary$orderType6.enableAfterHoursOrdering;
  var afterHoursInEffect = ((_openInfo$currentOrde2 = openInfo[currentOrderType]) === null || _openInfo$currentOrde2 === void 0 ? void 0 : _openInfo$currentOrde2.isClosed) && afterHoursEnabled;
  if (currentOrderType === 'delivery') {
    afterHoursEnabled = afterHoursEnabled && scheduledOrderingEnabled;
  }
  return {
    dispensaryId: dispensary === null || dispensary === void 0 ? void 0 : dispensary.id,
    orderType: currentOrderType,
    orderTypeHours: removeTypename(dispensary === null || dispensary === void 0 ? void 0 : dispensary.hoursSettings[currentOrderType]),
    orderTypeOpenInfo: _objectSpread(_objectSpread({}, openInfo[currentOrderType]), {}, {
      nextService: _objectSpread(_objectSpread({}, nextService), {}, {
        openMoment: nextService === null || nextService === void 0 ? void 0 : (_nextService$openMome = nextService.openMoment) === null || _nextService$openMome === void 0 ? void 0 : _nextService$openMome.format(),
        closeMoment: nextService === null || nextService === void 0 ? void 0 : (_nextService$closeMom = nextService.closeMoment) === null || _nextService$closeMom === void 0 ? void 0 : _nextService$closeMom.format()
      })
    }),
    scheduledOrderingEnabled: scheduledOrderingEnabled,
    afterHoursEnabled: afterHoursEnabled,
    afterHoursInEffect: afterHoursInEffect,
    schedulingOption: schedulingOption,
    reservationSlots: reservationSlots
  };
}