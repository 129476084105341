// TODO: Move this into /marketplace/src/checkout/actions, build-order.test.js is located there
import _ from 'lodash';
import { toJS } from 'mobx';

import { getPersistedValue } from 'shared/utils/persisted-values';
import { hasSaleSpecialForMenuType } from 'shared/helpers/specials';
import { removeTypename, serializeUtmData } from 'shared/helpers/utils';
import { isDutchiePlusKioskOrder } from 'shared/helpers/checkout';
import { ChannelSources } from './constants';

/*
 * called via src/checkout/actions/onClickSubmit.js
 */
export default function buildOrder({ Cart, UI, User, Checkout, paymentId, flags = {} }) {
  const { isVariant, checkoutId } = Checkout;
  const { dispensary } = Cart.order;
  const { removeMedicalCardFieldsAtCheckout } = dispensary;
  const sendPaysafeInput = !!(dispensary.payOnlineMerrco && Cart.order.paymentMethod === 'payOnlineMerrco');
  let guestCustomerFields = null;

  if (Cart.isGuestOrder || isVariant('kiosk')) {
    guestCustomerFields = Cart.order.guestCustomer;
  }

  // if we have an external order put those values on the guestCustomer field
  // if the MSO gives us that info, lets store it.
  if (User.hasExternalUser) {
    guestCustomerFields = toJS(User.userDetailsFromExternalSource);
  }

  // If a user clicks the box to save info, then enters a password, then unchecks the box,
  // don't send the password to the server.
  if (!Cart.saveGuestInfo && guestCustomerFields) {
    delete guestCustomerFields.password;
  }

  // this serializes the utmData to ensure that it is not an array of duplicated values, but a string of that value that
  // was duplicated
  const serializedUtmData = serializeUtmData(Cart.order.utmData);
  const sessionStoredUtmData = getPersistedValue('utmSessionData', { parameters: {} });
  const sessionUtmParameters = _.get(sessionStoredUtmData, 'parameters', {});

  const sessionStorageUtmDataEnabled = flags?.['growth.ecomm.use-session-storage-utm-data.rollout'] ?? false;

  const priceCartEnabled = flags?.['core.cats-ecomm.price-cart-calcs-state.rollout'] === 'ENABLED';
  const discountSyncEnabled = dispensary?.storeSettings?.enableLLxSaleDiscountSync;
  const sendCouponCode = priceCartEnabled && discountSyncEnabled;

  const couponObject = sendCouponCode
    ? { couponCode: _.get(Cart.order, 'coupon.code') }
    : { couponId: _.get(Cart.order, 'coupon._id') };

  // if possible, dont destructure values here. Logic above the fold could be altering them.
  return {
    proceed: true,
    order: _.omitBy(
      removeTypename({
        dispensaryId: dispensary.id,
        products: _.map(_.values(Cart.order.cart), (item) =>
          _.omitBy(
            {
              id: item.product._id,
              quantity: parseInt(item.quantity, 10),
              option: item.option,
              additionalOption: item.additionalOption,
              special: hasSaleSpecialForMenuType({ product: item.product, medicalOrder: Cart.order.medicalOrder }),
              rawOption: item.rawOption,
            },
            _.isNil
          )
        ),
        tip: Cart.order.tipValue,
        ...couponObject,
        paysafeInput: sendPaysafeInput ? _.get(Cart.order, 'paysafeInput') : undefined,
        paymentMethod: Cart.order.paymentMethod,
        paymentMethodId: Cart.paymentMethodId,
        paymentId: Cart.paymentMethod === 'rethinkPay' ? paymentId : undefined,
        utmData: sessionStorageUtmDataEnabled ? sessionUtmParameters : serializedUtmData,
        embedded: isVariant(['embedded', 'store-front']),
        variantSource: Checkout.variant,
        medicalOrder: Cart.order.medicalOrder,
        medicalCard: removeMedicalCardFieldsAtCheckout ? null : _.get(Cart.order, 'medicalCard'),
        driversLicense: _.get(Cart.order, 'driversLicense'),
        photoId: _.get(Cart.order, 'photoId', User.profile.photoId),
        deliveryOption: Cart.isDelivery,
        address: UI.formattedAddress,
        location: _.pick(UI.currentAddress, [
          'ln1',
          'ln2',
          'city',
          'state',
          'zipcode',
          'lat',
          'lng',
          'streetNumber',
          'streetName',
          'country',
        ]),
        specialInstructions: _.get(Cart.order, 'specialInstructions', ''),
        isPreviewOrder: UI.previewMode,
        isKioskOrder: isVariant('kiosk') || isDutchiePlusKioskOrder(Cart.orderType, UI.isPlus),
        isGuestOrder: isVariant('kiosk') ? true : Cart.isGuestOrder,
        isExternalOrder: User.hasExternalUser,
        isAfterHoursOrder: Cart.isAfterHoursOrder,
        isCurbsidePickupOrder: Cart.isCurbsidePickup,
        isDriveThruPickupOrder: Cart.isDriveThruPickup,
        guestCustomer: guestCustomerFields,
        checkoutToken: Cart.order.checkoutToken,
        expectedTotal: Cart.costBreakdown.total,
        destinationTerminal: Cart.order.destinationTerminal,
        customerState: Cart.customerState,
        appliedRewards: _.map(Cart.order.appliedRewards, (reward) =>
          _.pick(reward, ['id', 'available', 'operator', 'value', 'brand', 'copy', 'rewardType', 'discountId'])
        ),
        reservation: _.pick(Cart.order.reservation, ['startTimeISO', 'endTimeISO', 'slotId']),
        reservationType: Cart.reservationType,
        hypurAccessToken: Cart.hypurAccessToken,
        monerisInput: _.isEmpty(Cart.monerisInput) ? null : Cart.monerisInput,
        saveGuestInfo: Cart.saveGuestInfo,
        checkoutId,
        isPTPOT: true,
        channelSource: isVariant('mobile-ecomm') ? ChannelSources.retailerMobile : null,
        schedulingData: toJS(Cart.schedulingData),
      }),
      _.isNil
    ),
  };
}
