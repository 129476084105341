/* eslint-disable consistent-return */

import _ from 'lodash';
import { getProductWeight } from 'shared/helpers/products';
import { orderProductsForDiscountPrecedence } from '../utilities';
import { getAvailableQuantity, determineWinningCombo, setSatisfiersToWinningCombo } from './common';
import getTotalProductCombinations from './get-total-product-combinations';

// TODO: perhaps make these parse in getProductWeight
var targetWeightToMg = {
  '.5g': 500,
  '.75g': 750,
  '1g': 1000,
  '1.25g': 1250,
  '1.5g': 1500,
  '1.75g': 1700,
  '2g': 2000,
  '2.25g': 2250,
  '2.5g': 2500,
  '2.75g': 2750,
  '3g': 3000,
  '3.25g': 3250,
  '3.5g': 3500,
  '3.75g': 3750,
  '4g': 4000,
  '1/8oz': 3500,
  '1/4oz': 7000,
  '1/2oz': 14000,
  '1oz': 28000,
  '1 1/8oz': 31500,
  '1 1/4oz': 35000,
  '1 1/2oz': 42000,
  '2oz': 56000
};
var checkTotalWeight = function checkTotalWeight(_ref) {
  var _targetWeightToMg$wei;
  var bogoCalcsData = _ref.bogoCalcsData,
    conditions = _ref.conditions,
    discountBundle = _ref.discountBundle,
    items = _ref.items,
    itemsForAPrice = _ref.itemsForAPrice,
    itemToConsiderLast = _ref.itemToConsiderLast,
    logicOperator = _ref.logicOperator,
    pendingRewardsSatisfiers = _ref.pendingRewardsSatisfiers,
    specialId = _ref.specialId,
    specialsSettings = _ref.specialsSettings,
    totalQuantity = _ref.totalQuantity,
    totalWeight = _ref.totalWeight;
  var weightTarget = totalWeight.weight,
    weightOperator = totalWeight.weightOperator;
  var advancedReward = (discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.enabled) || (itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice.enabled);
  var accumulateToMaximum = (totalWeight === null || totalWeight === void 0 ? void 0 : totalWeight.weightOperator) === 'greaterThan' && advancedReward;
  var satisfied = false;
  var satisfiers = {};
  var uniqueConditions = conditions.length > 0 ? _.uniqBy(_.flatMap(conditions, 'eligibleItems'), 'key') : items;
  var eligibleItems = orderProductsForDiscountPrecedence(uniqueConditions, specialsSettings, itemsForAPrice.enabled ? 'rewards' : 'conditions');
  var availableEligibleItems = _.reduceRight(eligibleItems, function (accumulator, item) {
    // If item is weighted
    if ((item === null || item === void 0 ? void 0 : item.weightInMg) > 0) {
      var availableQuantity = getAvailableQuantity({
        item: item,
        bogoCalcsData: bogoCalcsData,
        pendingConditionsSatisfiers: satisfiers,
        pendingRewardsSatisfiers: pendingRewardsSatisfiers,
        excludeDefeatedRewardsSatisfiers: itemsForAPrice === null || itemsForAPrice === void 0 ? void 0 : itemsForAPrice.enabled,
        specialId: specialId
      });
      if (availableQuantity > 0) {
        accumulator.push({
          item: item,
          key: item.key,
          quantity: availableQuantity,
          weightInMg: item.weightInMg
        });
      }
    }
    return accumulator;
  }, []);
  if (availableEligibleItems.length < 1) {
    return {
      satisfied: satisfied,
      satisfiers: satisfiers
    };
  }
  var weightTargetInMg = (_targetWeightToMg$wei = targetWeightToMg[weightTarget]) !== null && _targetWeightToMg$wei !== void 0 ? _targetWeightToMg$wei : Number(getProductWeight(weightTarget) * 1000);
  var combinations = getTotalProductCombinations({
    availableEligibleItems: availableEligibleItems,
    comparisonOperator: weightOperator,
    numericTarget: weightTargetInMg,
    totalQuantity: totalQuantity,
    accumulateToMaximum: accumulateToMaximum
  });
  if (combinations.length < 1) {
    return {
      satisfied: satisfied,
      satisfiers: satisfiers
    };
  }
  var winningCombination = determineWinningCombo(combinations, itemToConsiderLast, conditions, logicOperator);
  if (winningCombination) {
    satisfied = true;
    satisfiers = setSatisfiersToWinningCombo(winningCombination);
  }
  return {
    satisfied: satisfied,
    satisfiers: satisfiers
  };
};
export default checkTotalWeight;