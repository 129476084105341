/* eslint-disable @typescript-eslint/naming-convention,camelcase */
import { isEqual } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { usePrivacyPreferences } from 'src/utils/fides/hooks/use-privacy-preferences';
import useCart from 'src/hooks/use-cart';
import useProductQueryParams from 'src/hooks/use-product-query-params';
import { MenuSortOptions } from 'shared/constants';
import { useParticipatingInAds } from '../helpers';

function useAdsKillSwitch(): boolean {
  const flags = useFlags();
  return flags['growth.ads.queries-kill-switch.operational'] ?? false;
}

function useUserParticipatingInAds(): boolean {
  const { data_sales_and_sharing } = usePrivacyPreferences();
  return !!data_sales_and_sharing;
}

function useValidMenuType(): boolean {
  const { menuType } = useCart();
  const flags = useFlags();

  const serveMedical = flags['growth.ads.serve-medical.rollout'] ?? false;
  return menuType === 'rec' || menuType === 'both' || (menuType === 'med' && serveMedical);
}

export function useAdsEnabled(): boolean {
  const adsKillSwitch = useAdsKillSwitch();
  const dispensaryParticipatingInAds = useParticipatingInAds();
  const userParticipatingInAds = useUserParticipatingInAds();
  const validMenuType = useValidMenuType();

  return !adsKillSwitch && dispensaryParticipatingInAds && userParticipatingInAds && validMenuType;
}

const validSortOptions = new Set(
  ['default'].concat(MenuSortOptions.filter((option) => option.allowSponsored).map((option) => option.key))
);

export function useAdsEnabledForCategoryPage(): boolean {
  const adsEnabled = useAdsEnabled();
  const { queryParams: qp, defaults } = useProductQueryParams();

  return (
    adsEnabled &&
    qp.page === 1 &&
    validSortOptions.has(qp.sortby) &&
    isEqual(qp.brands, defaults.brands) &&
    isEqual(qp.subcategories, defaults.subcategories) &&
    isEqual(qp.straintypes, defaults.straintypes) &&
    isEqual(qp.potencythc, defaults.potencythc) &&
    isEqual(qp.potencycbd, defaults.potencycbd) &&
    isEqual(qp.effects, defaults.effects)
  );
}
