import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { space } from 'styled-system';

/**
 * @type {React.FC<{
 *  height?: number | string;
 *  width?: number | string;
 *  circle?: boolean;
 *  rounded?: boolean;
 *  mr?: number | string
 * }>}
 */
const Skeleton = (props) => {
  const { carouselTheme } = props;

  return (
    <div>
      <StyledSkeleton loadingColor={carouselTheme?.loadingColor} {...props}>
        &zwnj;
      </StyledSkeleton>
    </div>
  );
};

Skeleton.defaultProps = {
  width: null,
  height: null,
  circle: false,
};

export default Skeleton;

const loadingOpacityProgression = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const animations = () => css`
  animation: ${loadingOpacityProgression} 1s linear infinite;
`;

export const StyledSkeleton = styled.div`
  ${space}
  ${animations}
  background-color: ${({ theme, loadingColor }) => loadingColor ?? theme.colors.grey[95]};
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 1;
  width: 100%;
  height: 100%;

  width: ${({ width }) => width && `${width}px`};
  height: ${({ height }) => height && `${height}px`};

  border-radius: ${({ width, height, circle, rounded }) => {
    if (width && height && circle) {
      return `50%`;
    }
    if (rounded) {
      return `30px`;
    }
    return `10px`;
  }};
`;
