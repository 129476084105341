/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useMemo } from 'react';
import { PlaidLinkStableEvent, PlaidLinkOnEventMetadata } from 'react-plaid-link';
import _ from 'lodash';

import { tracker, Events } from 'utils/analytics';
import { useGetInstoreAnalyticsTokenQuery } from 'types/graphql';
import { EnrollmentSteps } from 'src/payments/constants';
import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';
import { useRouter } from 'next/router';
import { INSTORE_ENROLLMENT_ROUTE, INSTORE_ENROLLMENT_SOURCE } from 'src/components/instore-enrollment';

export type EnrollmentEvents = keyof Pick<
  Events,
  | 'dutchiePayConnectBankStep'
  | 'dutchiePayCTAButtonClicked'
  | 'dutchiePayEnrollmentButtonClicked'
  | 'dutchiePayEnrollmentStep'
  | 'dutchiePayLearnMoreButtonClicked'
  | 'dutchiePayPlaidOnEvent'
>;

export type InstoreCartEvents = keyof Pick<
  Events,
  | 'dutchiePayInstoreAccountLinkViewed'
  | 'dutchiePayInstoreCartApprovalClicked'
  | 'dutchiePayInstoreCartApprovalFailed'
  | 'dutchiePayInstoreCartApprovalSucceeded'
  | 'dutchiePayInstoreCartViewed'
  | 'dutchiePayInstoreLoginViewed'
>;

export type DutchiePayEvents = EnrollmentEvents | InstoreCartEvents;

export const ENROLLMENT_EVENTS = {
  CTA_BUTTON_CLICKED: 'dutchiePayCTAButtonClicked',
  CONNECT_BANK_STEP: 'dutchiePayConnectBankStep',
  ENROLLMENT_BUTTON_CLICKED: 'dutchiePayEnrollmentButtonClicked',
  ENROLLMENT_STEP: 'dutchiePayEnrollmentStep',
  LEARN_MORE_CLICKED: 'dutchiePayLearnMoreButtonClicked',
  PLAID_ON_EVENT: 'dutchiePayPlaidOnEvent',
} as const;

export const INSTORE_EVENTS = {
  ACCOUNT_LINK_VIEWED: 'dutchiePayInstoreAccountLinkViewed',
  CART_APPROVAL_CLICKED: 'dutchiePayInstoreCartApprovalClicked',
  CART_APPROVAL_FAILED: 'dutchiePayInstoreCartApprovalFailed',
  CART_APPROVAL_SUCCEEDED: 'dutchiePayInstoreCartApprovalSucceeded',
  CART_VIEWED: 'dutchiePayInstoreCartViewed',
  LOGIN_VIEWED: 'dutchiePayInstoreLoginViewed',
} as const;

export const EVENT_ACTIONS = {
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  SUBMITTED: 'Submitted',
  SUCCEEDED: 'Succeeded',
  VIEWED: 'Viewed',
} as const;

export type EnrollmentEventsPayload = {
  step?: string;
  action?: string;
  source?: string;
  buttonLocation?: string;
};

export type InstoreEventsPayload = {
  instoreCartId?: string;
  loginStep?: string;
  dispensary?: {
    id?: string;
    name?: string;
  };
  showBarcode?: boolean;
};

export const DPAY_SESSION_TOKEN_KEY = 'dutchiePaySessionToken';
export type AnalyticsEventPayload = EnrollmentEventsPayload | InstoreEventsPayload;

export type DutchiePayEventTracker = (methodName: DutchiePayEvents, payloadProps?: AnalyticsEventPayload) => void;
export type EnrollmentStepTracker = (step: EnrollmentSteps) => void;
export type PlaidOnEventTracker = (eventName: PlaidLinkStableEvent, metadata: PlaidLinkOnEventMetadata) => void;

export function trackEvent(token, methodName, payloadProps): void {
  if (!Object.values({ ...ENROLLMENT_EVENTS, ...INSTORE_EVENTS }).includes(methodName)) {
    console.error(`Invalid Analytics Tracking Method Name: ${String(methodName)}`);
    return;
  }

  const payload = {
    instoreCartToken: token, // Delete after data team has updated to dutchiePaySessionToken
    dutchiePaySessionToken: token,
    ...payloadProps,
  };

  const method = tracker[methodName];

  if (token) {
    method(payload);
  }
}

export type UseDutchiePayAnalyticsResponse = {
  dutchiePayEventTracker: DutchiePayEventTracker;
  trackEnrollmentStepSubmitted: EnrollmentStepTracker;
  trackEnrollmentStepViewed: EnrollmentStepTracker;
  trackPlaidOnEvent: PlaidOnEventTracker;
};
// TODO: Get dispo data here https://dutchie.atlassian.net/browse/ENG-47647
export const useDutchiePayAnalytics = (): UseDutchiePayAnalyticsResponse => {
  const router = useRouter();

  const enrollmentSource = useMemo(() => {
    const path = router.asPath;
    return path.includes(INSTORE_ENROLLMENT_ROUTE) ? INSTORE_ENROLLMENT_SOURCE : router.query.enrollmentSource;
  }, [router.asPath, router.query.enrollmentSource]);

  /*
    Check session storage for token, and if not found then request a new one and persist it
  */
  const dutchiePaySessionToken = getPersistedValue(DPAY_SESSION_TOKEN_KEY, '');

  // TODO: Rename the query to GetDutchiePaySessionToken https://dutchie.atlassian.net/browse/ENG-47643
  const { data, error } = useGetInstoreAnalyticsTokenQuery({ skip: Boolean(dutchiePaySessionToken) });
  const analyticsToken = data?.getInstoreAnalyticsToken?.instoreAnalyticsTokenId;

  if (error) {
    console.error(`Failed to fetch analytics token. Error:${error.message}`);
  }

  if (analyticsToken) {
    setPersistedValue(DPAY_SESSION_TOKEN_KEY, analyticsToken, true);
  }

  const eventToken = dutchiePaySessionToken || analyticsToken;

  /*
    Use for general DPay events
  */
  const dutchiePayEventTracker: DutchiePayEventTracker = useCallback(
    (methodName, payloadProps) => trackEvent(eventToken, methodName, payloadProps),
    [eventToken]
  );

  /*
    Use for specific enrollment step viewed events
  */
  const trackEnrollmentStepViewed = useCallback(
    (step: EnrollmentSteps): void =>
      trackEvent(eventToken, ENROLLMENT_EVENTS.ENROLLMENT_STEP, {
        step,
        action: EVENT_ACTIONS.VIEWED,
        enrollmentSource,
      }),
    [eventToken, enrollmentSource]
  );

  /*
    Use for specific enrollment step submitted events
  */
  const trackEnrollmentStepSubmitted = useCallback(
    (step: EnrollmentSteps): void =>
      trackEvent(eventToken, ENROLLMENT_EVENTS.ENROLLMENT_STEP, {
        step,
        action: EVENT_ACTIONS.SUBMITTED,
        enrollmentSource,
      }),
    [eventToken, enrollmentSource]
  );

  const trackPlaidOnEvent = useCallback(
    (eventName: PlaidLinkStableEvent, metadata: PlaidLinkOnEventMetadata) => {
      const eventMetadata = _.omitBy(metadata, (val) => val == null);

      trackEvent(eventToken, ENROLLMENT_EVENTS.PLAID_ON_EVENT, {
        eventName,
        eventMetadata,
      });
    },
    [eventToken]
  );

  return {
    dutchiePayEventTracker,
    trackEnrollmentStepSubmitted,
    trackEnrollmentStepViewed,
    trackPlaidOnEvent,
  };
};
