import React from 'react';
import styled from 'styled-components';

import { InlineBanner } from 'src/components/inline-banner';

export const DeviceNotice = (): JSX.Element => (
  <div data-testid='device-notice'>
    <InlineBanner severity='info' noBorder>
      <DeviceNoticeText>
        <DeviceNoticeEmphasis>Important:</DeviceNoticeEmphasis> Verification link must be opened on the device and browser you are currently using.
      </DeviceNoticeText>
    </InlineBanner>
  </div>
);


const DeviceNoticeText = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

const DeviceNoticeEmphasis = styled.strong`
  font-weight: 700;
  text-transform: uppercase;
`;
