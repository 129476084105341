import ApolloClient from 'apollo-client';
import Cart from 'src/hooks/use-cart';
import { ERNIE_TYPES } from 'shared/constants';
import { formatBirthdayForRewards } from 'shared/helpers/rewards';
import createAlpineUser from 'shared/graphql/reward/mutations/create-alpine-user.gql';
import getRewardsV2 from 'shared/graphql/reward/queries/get-rewards-V2.gql';
import { GqlGetRewardsV2Query, GqlDispensaries } from 'types/graphql';
import {
  getDefaultRewardsProgramDisplayName,
  getDefaultRewardsProgramDisplayDescription,
} from 'shared/core/helpers/dispensaries';
import { setLoyaltyData } from '../loyalty.helpers';
import { Statuses, UseLoyaltyReturn, ShowErnie } from './types';

type Dependencies = {
  dispensary: GqlDispensaries;
  cart: ReturnType<typeof Cart>;
  user: {
    phone: string;
    birthday: string;
    email: string;
    firstName: string;
    lastName: string;
    setLoyaltyPin: (dispensaryId: string, pin: string) => void;
    hasLoyaltyAccount: boolean;
  };
  apolloClient: ApolloClient<object>;
  showErnie: ShowErnie;
};

type AlpineLoyaltyProps = {
  status: Statuses;
  setStatus: (status: Statuses) => void;
  deps: Dependencies;
};

export const createAlpineLoyalty = ({ status, setStatus, deps }: AlpineLoyaltyProps): UseLoyaltyReturn => {
  const { dispensary, cart, user: User, apolloClient, showErnie } = deps;
  const dob = formatBirthdayForRewards(User.birthday);
  const programName =
    dispensary.storeSettings.rewardsIntegrationConfiguration?.rewardsProgramDisplayName ??
    getDefaultRewardsProgramDisplayName({ location: dispensary.location ?? {} });
  const programDescription =
    dispensary.storeSettings.rewardsIntegrationConfiguration?.rewardsProgramDisplayDescription ??
    getDefaultRewardsProgramDisplayDescription({ location: dispensary.location ?? {} });

  const generatePin = async (): Promise<GqlGetRewardsV2Query | undefined> => {
    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: getRewardsV2,
      variables: {
        dispensaryId: dispensary.id,
        phoneNumber: User.phone,
        dob,
        pin: '',
      },
    });

    return data;
  };

  const resendPin = async (): Promise<void> => {
    setStatus(Statuses.loading);

    const data = await generatePin();

    if (!data) {
      showErnie('Error connecting account, please try again.', ERNIE_TYPES.DANGER);
    }

    setStatus(Statuses.pin);
  };

  const createUserAndPin = async (): Promise<void> => {
    setStatus(Statuses.loading);

    try {
      const { data: createdUserData } = await apolloClient.mutate({
        mutation: createAlpineUser,
        variables: {
          dispensaryId: dispensary.id,
          phoneNumber: User.phone,
          firstName: User.firstName,
          lastName: User.lastName,
          email: User.email,
          favoriteStore: dispensary.name,
          program: 'alpineiq',
          acceptedTerms: true,
          address: '',
        },
      });

      if (!createdUserData) {
        showErnie('Error connecting account, please try again.', ERNIE_TYPES.DANGER);
        setStatus(Statuses.terms);
        return;
      }
    } catch (error) {
      console.error(error);

      showErnie('Error connecting account, please try again.', ERNIE_TYPES.DANGER);
      setStatus(Statuses.terms);
      return;
    }

    const data = await generatePin();

    if (!data) {
      showErnie('Error connecting account, please try again.', ERNIE_TYPES.DANGER);
      setStatus(Statuses.terms);
    } else {
      setStatus(Statuses.pin);
    }
  };

  const processPin = async (pin: string): Promise<void> => {
    setStatus(Statuses.loading);

    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: getRewardsV2,
      variables: {
        dispensaryId: dispensary.id,
        phoneNumber: User.phone,
        dob,
        pin,
      },
    });

    if (!data) {
      showErnie('Error connecting account, please try again.', ERNIE_TYPES.DANGER);
      setStatus(Statuses.pin);
    }

    const rewardsData = data?.getRewardsV2;

    if (rewardsData.auth.pinConfirmed) {
      setLoyaltyData({
        user: User,
        cart,
        data,
        dispensaryId: dispensary.id,
        pin,
      });

      setStatus(Statuses.success);
    } else if (rewardsData.auth.incorrectPinProvided) {
      setStatus(Statuses.incorrectPin);
    }
  };

  return {
    createUserAndPin,
    status,
    setStatus,
    programName,
    programDescription,
    processPin,
    resendPin,
  };
};
