import { toJS, transaction } from 'mobx';
import _ from 'lodash';

import { DUTCHIE_MAIN_STORED_CART_KEY, LOCAL_STORAGE_STORED_CART_KEY } from 'shared/constants';
import { setPersistedValue, getPersistedValue } from 'shared/utils/persisted-values';

import { consumerDispensaries, dispensaryAvailableReservations } from 'shared/graphql/dispensary/queries';
import { validateCouponQuery } from 'shared/graphql/coupon/queries';
import createAlpineUser from 'shared/graphql/reward/mutations/create-alpine-user.gql';
import createAlpineUserV2 from 'shared/graphql/reward/mutations/create-alpine-user-v2.gql';
import createSpringbigUser from 'shared/graphql/reward/mutations/create-springbig-user.gql';
import { formatAddress } from 'shared/helpers/address';
import getRewardsV2 from 'shared/graphql/reward/queries/get-rewards-V2.gql';
import useStores from 'shared/hooks/use-stores';
import { useCallback } from 'react';

import { formatBirthdayForRewards } from 'shared/helpers/rewards';
import persistCheckoutV2 from './persist-cart-v2.gql';

export const getStoredCartKey = (dispensaryId, isDutchieMain = false) =>
  isDutchieMain ? DUTCHIE_MAIN_STORED_CART_KEY : dispensaryId ?? null;

export const normalizeAndUpdateStoredCart = (storedCart, isDutchieMain = false) => {
  // Check to see if the stored cart is the old shape, ie. there is a checkoutToken property at the root
  if (storedCart.checkoutToken === undefined) {
    return storedCart;
  }

  storedCart = _.clone(storedCart);

  if (!_.isEmpty(storedCart.dispensary)) {
    const cartData = {
      checkoutToken: storedCart.checkoutToken,
      dispensary: storedCart.dispensary,
      isMedical: storedCart.isMedical,
      items: _.values(storedCart.items),
      orderType: storedCart.orderType,
      utmData: storedCart.utmData,
    };
    const currentStoredCartKey = getStoredCartKey(storedCart.dispensary.id, isDutchieMain);

    storedCart[currentStoredCartKey] = { ...cartData };
  }

  delete storedCart.checkoutToken;
  delete storedCart.dispensary;
  delete storedCart.isMedical;
  delete storedCart.items;
  delete storedCart.orderType;
  delete storedCart.utmData;

  setPersistedValue(LOCAL_STORAGE_STORED_CART_KEY, storedCart);

  return storedCart;
};

export async function persistCartData(apolloClient, Cart, UI, User, flags, preventRetry = false) {
  // don't persist empty carts
  if (Cart.itemCount === 0 || !Cart.order.dispensary?.id) {
    return;
  }

  try {
    const order = toJS(Cart.order);
    let customer;
    if (User.exists) {
      customer = _.pick(User, [`email`, `firstName`, `lastName`, `fullName`, `id`]);
      customer.phone = _.get(User, `profile.phone`);
    } else {
      customer = _.pick(order.guestCustomer, [`email`, `phone`, `firstName`, `lastName`, `fullName`]);
    }

    const sessionStorageUtmDataEnabled = flags[`growth.ecomm.use-session-storage-utm-data.rollout`] ?? false;
    const sessionStoredUtmData = getPersistedValue(`utmSessionData`, { parameters: {} });
    const sessionUtmParameters = _.get(sessionStoredUtmData, `parameters`, {});
    const clientStoredUtmData = _.get(order, `utmData`, {});
    const utmData = sessionStorageUtmDataEnabled ? sessionUtmParameters : clientStoredUtmData;

    const result = await apolloClient.mutate({
      mutation: persistCheckoutV2,
      variables: {
        order: {
          variant: UI.variant,
          ..._.pick(order, [`medicalOrder`, `paymentMethod`, `deliveryOption`]),
          customer,
          cart: _.pick(Cart.costBreakdown.receipt, [`details`, `summary`]),
          products: _.map(order.cart, (entry) => ({
            _id: entry?.product?._id,
            Name: entry?.product?.Name,
            type: entry?.product?.type,
          })),
          url: window.location.href,
          utmData,
        },
        token: order.checkoutToken,
        dispensaryId: order.dispensary.id,
      },
    });

    if (
      result.data?.persistCheckoutV2?.checkoutToken &&
      Cart.checkoutToken !== result.data.persistCheckoutV2.checkoutToken
    ) {
      Cart.checkoutToken = result.data.persistCheckoutV2.checkoutToken;
    }
  } catch (e) {
    if (Cart.checkoutToken) {
      Cart.checkoutToken = null;
      if (!preventRetry) {
        await persistCartData(apolloClient, Cart, UI, User, flags, true);
      }
    }
  }
}

export async function getAvailableReservations(apolloClient, Cart) {
  if (Cart.reservationsAvailableByOrderType) {
    return;
  }

  try {
    const { data } = await apolloClient.query({
      fetchPolicy: `no-cache`,
      query: dispensaryAvailableReservations,
      variables: {
        dispensaryFilter: {
          cNameOrID: Cart.order?.dispensary?.id || Cart.dispensary?.id,
        },
      },
    });

    if (data) {
      Cart.reservationsAvailableByOrderType = data.filteredDispensaries[0].reservationsAvailableByOrderType;
    }
  } catch (error) {
    console.error(`Error getting available reservations`, error);
  }
}
export function useGetAvailableReservations() {
  const { apolloClient, Cart } = useStores();

  return useCallback(() => _.partial(getAvailableReservations, apolloClient, Cart), [apolloClient, Cart])();
}

export async function applyCouponCode(
  apolloClient,
  Cart,
  UI,
  FeatureFlags,
  code,
  dispensaryId,
  isReapplying = false,
  rewardsLabel
) {
  const priceCartEnabled = FeatureFlags?.flags['core.cats-ecomm.price-cart-calcs-state.rollout'] === 'ENABLED';
  const discountSyncEnabled = Cart.dispensary?.storeSettings?.enableLLxSaleDiscountSync;

  if (discountSyncEnabled && priceCartEnabled) {
    // TODO: we need to short-circuit here and add the coupon code to the cart so that it can get to the new calcs
    Cart.coupon = { code };
    return;
  }

  // if we've already applied a reward, exit early
  if (Cart?.appliedRewards?.length > 0 && !Cart.combineDiscounts) {
    UI.showErnie(`Promo codes are not eligible on orders with ${rewardsLabel} discounts.`, `danger`);
    return;
  }

  try {
    const result = await apolloClient.query({
      fetchPolicy: `no-cache`,
      query: validateCouponQuery,
      variables: {
        code,
        dispensaryId,
        isReapplying,
        orderSubtotalInCents: parseInt(Cart.orderSubtotal * 100, 10),
        products: _.map(Cart.order.cart, (productObj) => ({
          id: productObj.product._id,
          category: productObj.product.type,
          subcategory: productObj.product.subcategory,
          brandId: productObj.product.brandId,
          brandName: productObj.product.brandName,
          weight: productObj.option,
        })),
      },
    });
    const { coupon, isValid, message } = result.data.validateCouponQuery;

    if (isValid) {
      Cart.applyCouponCode(coupon);
      if (!isReapplying) {
        UI.showErnie(message || `Success!`, `success`);
      }
    } else {
      UI.showErnie(message, `danger`);
    }
  } catch (err) {
    UI.showErnie(err.message, `danger`);
  }
}
export function useApplyCouponCode() {
  const { apolloClient, Cart, UI, FeatureFlags } = useStores();
  return _.partial(applyCouponCode, apolloClient, Cart, UI, FeatureFlags);
}

export async function createSpringbigMember({ userId, dispensaryId, apolloClient }) {
  try {
    await apolloClient.mutate({
      mutation: createSpringbigUser,
      variables: {
        dispensaryId,
        userId,
      },
    });
    return true;
  } catch (_error) {
    return false;
  }
}

export async function createAlpineIQUser({ Cart, User, apolloClient, alpineiqV2Flag }) {
  try {
    const mutation = alpineiqV2Flag ? createAlpineUserV2 : createAlpineUser;
    const { data } = await apolloClient.mutate({
      mutation,
      variables: {
        dispensaryId: Cart.order.dispensary.id,
        phoneNumber: Cart.order.guestCustomer ? Cart.order.guestCustomer.phone : User.profile.phone,
        firstName: Cart.order.guestCustomer ? Cart.order.guestCustomer.firstName || '' : User.profile.firstName || '',
        lastName: Cart.order.guestCustomer ? Cart.order.guestCustomer.lastName || '' : User.profile.lastName || '',
        email: User.email || '',
        favoriteStore: Cart.order.dispensary.name,
        address: formatAddress(User.profile?.address || {}) || '',
        program: 'alpineiq',
        acceptedTerms: true,
      },
    });

    if (data) {
      if (alpineiqV2Flag) {
        Cart.hasRewardsWallet = data.createAlpineUserV2.wallet.userHasWallet;
        Cart.rewardsBalance = data.createAlpineUserV2.wallet.balance;
        Cart.rewardAuth = data.createAlpineUserV2.wallet.auth || {};
        Cart.availableRewards =
          data.createAlpineUserV2.wallet.rewards.map((reward) => ({
            ...reward,
            brand: data.createAlpineUserV2.wallet.rewardBrand,
          })) ?? [];
        Cart.userHasWallet = true;
      } else {
        Cart.hasRewardsWallet = data.createAlpineUser.wallet.userHasWallet;
        Cart.rewardsBalance = data.createAlpineUser.wallet.balance;
        Cart.rewardAuth = data.createAlpineUser.wallet.auth || {};
        Cart.availableRewards =
          data.createAlpineUser.wallet.rewards.map((reward) => ({
            ...reward,
            brand: data.createAlpineUser.wallet.rewardBrand,
          })) ?? [];
        Cart.userHasWallet = true;
      }
    }

    return true;
  } catch (error) {
    return false;
  }
}

export async function submitOrRefreshRewardsPin({ Cart, User, apolloClient, isShowLoyaltyFlagEnabled = false }) {
  try {
    const pin = Cart.rewardPin || '';

    const { data } = await apolloClient.query({
      fetchPolicy: 'no-cache',
      query: getRewardsV2,
      variables: {
        dispensaryId: Cart.order.dispensary.id,
        phoneNumber: Cart.order.guestCustomer ? Cart.order.guestCustomer.phone : User.profile.phone,
        dob: formatBirthdayForRewards(User.profile?.birthday || ''),
        pin,
      },
    });

    transaction(() => {
      Cart.order.appliedRewards = [];
      Cart.availableRewards = _.map(data.getRewardsV2?.rewards ?? [], (reward) => ({
        ...reward,
        brand: data.getRewardsV2?.rewardBrand,
      }));
      Cart.hasRewardsWallet = data.getRewardsV2?.userHasWallet;
      Cart.rewardsBalance = data.getRewardsV2?.balance;
      Cart.rewardBrandName = data.getRewardsV2?.rewardBrand;
      Cart.rewardAuth = data.getRewardsV2?.auth || {};

      if (isShowLoyaltyFlagEnabled && pin) {
        User.setLoyaltyPin(Cart.order.dispensary.id, pin);
        User.hasLoyaltyAccount = true;
      }
    });
    return true;
  } catch (error) {
    return false;
  }
}

export async function updateDispensaryDeliveryInfo(Cart, apolloClient, { city, lat, lng, state, zipcode }) {
  try {
    const result = await apolloClient.query({
      fetchPolicy: `no-cache`,
      query: consumerDispensaries,
      variables: {
        dispensaryFilter: {
          cNameOrID: Cart.dispensary?.id,
          city,
          nearLat: lat,
          nearLng: lng,
          destinationTaxState: state,
          destinationTaxZipcode: zipcode,
        },
      },
    });

    const updatedDispensary = _.get(result, `data.filteredDispensaries[0]`);

    if (updatedDispensary) {
      Cart.dispensary = updatedDispensary;
    }
  } catch (error) {
    console.error(`Error setting delivery info for address`, error);
  }
}
