import React, { useCallback, useEffect } from 'react';
import {
  PlaidEmbeddedLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  PlaidLinkStableEvent,
  PlaidLinkOnEventMetadata,
} from 'react-plaid-link';
import { useObserver } from 'mobx-react-lite';

import { useStores } from 'contexts/stores';
import { useStartAggregatorLinkQuery } from 'types/graphql';

import {
  AGGREGATORS,
  DUTCHIE_PAY_QUERY_PARMAS_KEY,
  PLAID_STORAGE_KEY,
  REDIRECT_URL,
  PlaidLinkCustomizationNames,
} from 'src/payments/constants';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { usePlaidLinkUtils } from 'src/payments/hooks/use-plaid-link-utils/use-plaid-link-utils';

import { useDutchiePayAnalytics } from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';

type PlaidEmbeddedSearchProps = {
  handleEmbeddedDutchiePayLink?: () => void;
  handleOnError?: () => void;
  handleOnLinkExit?: () => void;
  linkCustomizationName?: PlaidLinkCustomizationNames;
  height?: number | string;
  width?: number | string;
  onLoadingStateChange?: (state: { isLoading: boolean; hasToken: boolean }) => void;
};

export const PlaidEmbeddedSearch = ({
  handleEmbeddedDutchiePayLink,
  handleOnError,
  handleOnLinkExit,
  linkCustomizationName = PlaidLinkCustomizationNames.DEFAULT,
  width,
  height = 350,
  onLoadingStateChange,
}: PlaidEmbeddedSearchProps): JSX.Element => {
  const { User } = useStores();
  const userFullName = useObserver(() => User.fullName);
  const { trackPlaidOnEvent } = useDutchiePayAnalytics();
  const { plaidLinkExited } = useDutchiePayEnrollment();

  const userEmail = useObserver(() => User.email);
  const userPhone = useObserver(() => User.profile.phone);

  const { handleSuccess } = usePlaidLinkUtils({ handleEmbeddedDutchiePayLink, handleOnError });

  const { data: startAggregatorLinkData, loading: startAggregatorLinkLoading } = useStartAggregatorLinkQuery({
    variables: {
      aggregator: AGGREGATORS.PLAID,
      redirectUrl: REDIRECT_URL,
      entityName: userFullName,
      paymentMethodId: '',
      email: userEmail,
      phone: userPhone,
      linkCustomizationName,
    },
    skip: !userFullName,
    fetchPolicy: 'network-only',
  });

  const plaidLinkToken = startAggregatorLinkData?.startAggregatorLink.plaid.linkToken ?? '';
  /*
   * TODO:
   * Just rely on passing in whatever is passed for handleOnLinkExit
   * and remove the call to useDutchiePayEnrollment and plaidLinkExited
   */
  const onLinkExit = handleOnLinkExit ?? plaidLinkExited;

  const clearPlaidData = (): void => {
    localStorage.removeItem(DUTCHIE_PAY_QUERY_PARMAS_KEY);
    localStorage.removeItem(PLAID_STORAGE_KEY);
  };

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken, metadata) => {
      void handleSuccess({ publicToken, metadata });
    },
    [handleSuccess]
  );

  const onEvent = useCallback<PlaidLinkOnEvent>(
    (eventName: PlaidLinkStableEvent, metadata: PlaidLinkOnEventMetadata) => {
      trackPlaidOnEvent(eventName, metadata);
    },
    [trackPlaidOnEvent]
  );

  const onExit = useCallback<PlaidLinkOnExit>(() => {
    clearPlaidData();
    onLinkExit();
  }, [onLinkExit]);

  const config = {
    token: plaidLinkToken,
    onSuccess,
    onEvent,
    onExit,
  };

  useEffect(() => {
    onLoadingStateChange?.({
      isLoading: startAggregatorLinkLoading,
      hasToken: Boolean(plaidLinkToken),
    });
  }, [startAggregatorLinkLoading, plaidLinkToken, onLoadingStateChange]);

  return (
    <PlaidEmbeddedLink
      {...config}
      style={{
        ...(width && { width }),
        height,
      }}
    />
  );
};

export const MemoizedPlaidEmbeddedSearch = React.memo(PlaidEmbeddedSearch);
