/* eslint-disable camelcase */
import { useRouter } from 'next/router';
import { CategoryPotencyRanges } from 'shared/constants';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import useProductQueryParams from 'src/hooks/use-product-query-params';
import { GqlGetSponsoredResourceFilters } from 'types/graphql';
import { useStores } from 'src/contexts/stores';
import { isCategoryPotencyRangeDefault } from '../utils/is-category-potency-range-default';

type FiltersEnabled =
  | {
      brands?: boolean;
      cbd?: boolean;
      effects?: boolean;
      strain_type?: boolean;
      subcategory?: boolean;
      thc_in_category?: boolean;
      thc_in_review_count?: boolean;
      weight?: boolean;
    }
  | undefined;

export function useFiltersForSponsoredResources(): { filters?: GqlGetSponsoredResourceFilters } {
  const { FeatureFlags } = useStores();
  const filtersEnabled: FiltersEnabled = FeatureFlags?.flags?.['growth.ads.moloco-filters.rollout'];

  const { queryParams: qp } = useProductQueryParams();
  const dispensaryCategory = useDispensaryCategory();
  const {
    query: { subcategory: subcategoryFromPath },
  } = useRouter();

  // if no filters are present, return an empty object
  const filters: GqlGetSponsoredResourceFilters = {};

  if (qp.brands?.length > 0 && filtersEnabled?.brands) {
    filters.brands = qp.brands;
  }

  if (qp.category) {
    filters.category = qp.category;
  }

  if (qp.effects?.length > 0 && filtersEnabled?.effects) {
    filters.effects = qp.effects;
  }

  // if we have subcategories in the query params, use them and don't check the path;
  // we know we're not going to have a category/subcategory?subcategories=foo URL structure
  if (filtersEnabled?.subcategory) {
    if (qp.subcategories?.length > 0) {
      filters.subCategories = qp.subcategories;
      // else, if we have a subcategory in the path, use it
      // subcategoryFromPath could theoretically be an array although it likely won't be in practice
    } else if (subcategoryFromPath) {
      if (Array.isArray(subcategoryFromPath)) {
        filters.subCategories = subcategoryFromPath;
      } else {
        filters.subCategories = [subcategoryFromPath];
      }
    }
  }

  if (qp.straintypes?.length > 0 && filtersEnabled?.strain_type) {
    filters.strainTypes = qp.straintypes;
  }

  if (qp.weight && filtersEnabled?.weight) {
    filters.weight = qp.weight;
  }

  if (qp.potencythc?.length > 1 && (filtersEnabled?.thc_in_category || filtersEnabled?.thc_in_review_count)) {
    const isThcPotencyRangeDefault = isCategoryPotencyRangeDefault({
      category: dispensaryCategory?.key.toLowerCase() ?? ('' as keyof typeof CategoryPotencyRanges | ''),
      potencyRange: qp.potencythc,
    });
    if (!isThcPotencyRangeDefault) {
      filters.potencyThc = {
        min: qp.potencythc[0],
        max: qp.potencythc[1],
      };
    }
  }

  if (qp.potencycbd?.length > 1 && filtersEnabled?.cbd) {
    const isCbdPotencyRangeDefault = isCategoryPotencyRangeDefault({
      category: dispensaryCategory?.key.toLowerCase() ?? ('' as keyof typeof CategoryPotencyRanges | ''),
      potencyRange: qp.potencycbd,
    });
    if (!isCbdPotencyRangeDefault) {
      filters.potencyCbd = {
        min: qp.potencycbd[0],
        max: qp.potencycbd[1],
      };
    }
  }

  return { filters };
}
