import { observable, action, computed, reaction, toJS, makeObservable } from 'mobx';
import moment from 'moment';
import _ from 'lodash';
import { getPersistedValue, setPersistedValue, removePersistedValue } from 'shared/utils/persisted-values';

import { formatName } from 'shared/helpers/users';

// store data about the current user, guest or authed, in this store
// also see the controller inside of hooks/use-user-controller

// there are distinctly 3 logged in states, not 2
// - Not logged in, guest experience
// - Logged in, has profile and stuff
// - *Logging in*, has no user data but isn't a guest
export default class UserStore {
  @observable id = null;
  @observable profile = {};
  @observable email = null;
  @observable emails = [];
  @observable token = undefined;
  @observable createdAt = null;
  @observable viewOrderId = null;
  @observable refetchRequest = false;
  @observable ageOverride = null;
  @observable loading = null;
  @observable userDetailsFromExternalSource = {};
  @observable hasLoyaltyAccount = false;

  // Account Verification
  @observable hasAttemptedVerification = false;
  @observable isVerificationTokenSent = false;
  @observable activeVerificationToken = undefined;
  @observable lastVerifiedAt = null;

  constructor() {
    makeObservable(this);
    reaction(
      () => (this.emails.length > 0 ? this.emails[0].address : null),
      (email) => {
        if (email) {
          this.email = email;
        }
      }
    );

    this.ageOverride = getPersistedValue('ageOverride', null);
    this.token = getPersistedValue('access-token', null);
    this.activeVerificationToken = getPersistedValue('verification-token', null);
  }

  @action
  setAgeOverride(age, rememberMe) {
    const expireDays = rememberMe ? 30 : 1;
    this.ageOverride = {
      expires: moment().add(expireDays, 'days').toISOString(),
      age,
    };

    setPersistedValue('ageOverride', this.ageOverride, !rememberMe);
  }

  @computed
  get isLoggedIn() {
    return !!this.id;
  }

  @computed
  get exists() {
    return this.isLoggedIn;
  }

  @action
  login(loginData) {
    this.setUser(loginData.user);
    this.updateToken(loginData.accessToken);
  }

  @action
  updateToken(token) {
    this.token = token;
    setPersistedValue(`access-token`, token);
  }

  @action
  setActiveVerificationToken(token) {
    this.activeVerificationToken = token;
    if (token) {
      setPersistedValue('verification-token', token);
    } else {
      removePersistedValue('verification-token');
    }
  }

  @action
  setHasAttemptedVerification(hasAttemptedVerification) {
    this.hasAttemptedVerification = hasAttemptedVerification;
  }

  @action setIsVerificationTokenSent = (isSent) => {
    this.isVerificationTokenSent = isSent;
  };

  // Because of the way the user store was built and integrated, we have to
  // manually update the user object manually after mutations
  @action
  refetch() {
    this.refetchRequest = true;
  }

  @action
  logout() {
    this.token = null;
    this.id = null;
    this.createdAt = null;
    this.profile = {};
    this.emails = [];
    this.activeVerificationToken = null;
    removePersistedValue(`loyaltyPins`);
    removePersistedValue(`verification-token`);
    this.hasLoyaltyAccount = false;
  }

  @action
  setUser(userData) {
    this.id = userData._id;
    this.profile = userData.profile;
    this.emails = userData.emails;
    this.createdAt = userData.createdAt;
    this.lastVerifiedAt = userData.emails[0].lastVerifiedAt ?? null;
  }

  @action
  setUserDetailsFromExternalSource(userDetails) {
    // TODO: we should probably validate the user details in some way?
    this.userDetailsFromExternalSource = userDetails;
  }

  @action
  removeUserDetailsFromExternalSource() {
    this.userDetailsFromExternalSource = {};
  }

  @action
  setMedicalCard = (medicalCard) =>
    _.set(this.profile, `medicalCard`, {
      ...(this.profile.medicalCard || {}),
      ..._.omitBy(medicalCard, _.isEmpty),
    });

  @action
  setLoyaltyPin = (dispensaryId, pin) => {
    const existingLoyaltyPins = getPersistedValue(`loyaltyPins`, {});

    existingLoyaltyPins[dispensaryId] = pin;

    setPersistedValue(`loyaltyPins`, existingLoyaltyPins);
  };

  getLoyaltyPin = (dispensaryId) => {
    const existingLoyalty = getPersistedValue(`loyaltyPins`, {});

    return existingLoyalty[dispensaryId] ?? null;
  };

  @computed get hasExternalUser() {
    return !_.isEmpty(this.userDetailsFromExternalSource);
  }

  @computed get formatedBirthdate() {
    if (!this.isLoggedIn) {
      return '';
    }
    return moment(this.profile.birthday, 'M/D/YYYY', 'MM/DD/YYYY').format('MM/DD/YYYY');
  }

  @computed get birthday() {
    return this.profile.birthday;
  }

  @computed get phone() {
    return this.profile.phone;
  }

  @computed get firstName() {
    if (!this.exists) {
      return '';
    }
    return _.get(this.profile, 'firstName', _.split(_.get(this.profile, 'fullName', ''), ' ')[0]);
  }

  @computed get lastName() {
    if (!this.exists) {
      return '';
    }
    return _.get(this.profile, 'lastName', _.split(_.get(this.profile, 'fullName', ''), ' ')[1]);
  }

  @computed get fullName() {
    if (!this.exists) {
      return '';
    }
    return formatName(this.profile);
  }

  @computed get user() {
    return toJS(this);
  }

  @computed get isSsoUser() {
    return this.profile?.isExternalUser ?? false;
  }

  decodeJWT(token) {
    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('Invalid JWT token');
    }

    // Decode the payload part (2nd part) from base64 URL encoding
    const payload = atob(parts[1].replace(/-/g, '+').replace(/_/g, '/'));
    return JSON.parse(payload);
  }

  @computed get expiredToken() {
    if (!this.token) {
      return true;
    }

    const decodedToken = this.decodeJWT(this.token);
    const now = Math.floor(Date.now() / 1000);
    return now > decodedToken.exp;
  }
}
