import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { getDispensaryEnabledForPersonalization } from 'shared/helpers/personalization/settings';

/**
 * Hook to check if product urgency messaging is enabled for the dispensary
 * This checks both the store settings and personalization settings
 */
export function useProductUrgencyEnabled(): boolean {
  const { dispensary } = useDispensary();

  if (!dispensary) {
    return false;
  }

  const personalizationEnabled = getDispensaryEnabledForPersonalization(dispensary);
  const storeSettingEnabled = dispensary?.storeSettings?.enableProductUrgencyMessaging ?? false;

  return personalizationEnabled && storeSettingEnabled;
}
