import _toConsumableArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _ from 'lodash';
import { AdvancedConditionType, AdvancedRewardType, BasicConditionOrRewardType } from 'shared/types/specials.enums';

// https://github.com/pmmmwh/react-refresh-webpack-plugin/blob/main/docs/TROUBLESHOOTING.md#usage-with-indirection-like-workers-and-js-templates
/* eslint-disable no-unused-vars */
var $RefreshReg$ = _.noop;
var $RefreshSig$ = function $RefreshSig$() {
  return _.noop;
};
/* eslint-enable no-unused-vars */

export var bogoLogicOperators = [{
  key: 'and',
  label: 'AND',
  value: 'and'
}, {
  key: 'or',
  label: 'OR',
  value: 'or'
}];
export var comparisonOperators = [{
  key: 'equalTo',
  label: 'Equal to'
}, {
  key: 'greaterThan',
  label: 'Greater than'
}];
export var saleComparisonOperators = [{
  key: 'equalTo',
  label: 'Equal to'
}, {
  key: 'greaterThan',
  label: 'Greater than'
}, {
  key: 'greaterThanEqualTo',
  label: 'Greater than or equal to'
}];
export var advancedTotalSpendComparisonOperators = [{
  key: 'equalTo',
  label: 'Equal to'
}, {
  key: 'greaterThan',
  label: 'Greater than'
}, {
  key: 'greaterThanEqualTo',
  label: 'Greater than or equal to'
}, {
  key: 'between',
  label: 'Between'
}];
export var discountTypes = [{
  key: 'dollarDiscount',
  label: 'Dollar',
  labelShort: '$'
}, {
  key: 'percentDiscount',
  label: 'Percent',
  labelShort: '%'
}];
export var productGroupBogoKeys = {
  brands: 'brandName',
  categories: 'categoryName',
  individual: 'productId'
};
export var productGroupLabels = {
  brands: 'Brand',
  categories: 'Category',
  individual: 'Item'
};
export var specialsMenuTypes = [{
  key: 'medical',
  label: 'Medical'
}, {
  key: 'recreational',
  label: 'Recreational'
}, {
  key: 'both',
  label: 'Both'
}];
export var specialsBogoProductGroups = [{
  key: 'individual',
  label: 'Specific items'
}, {
  key: 'categories',
  label: 'Items in a category'
}, {
  key: 'brands',
  label: 'Items from a brand'
}];
export var specialsBogoProductGroupRewards = [].concat(specialsBogoProductGroups, [{
  key: AdvancedRewardType.itemsForADiscount,
  label: 'Bundled discount'
}, {
  key: AdvancedRewardType.itemsForAPrice,
  label: 'Bundled price'
}]);
export var specialsBogoCustomerReceives = [].concat(_toConsumableArray(specialsBogoProductGroupRewards), [{
  key: AdvancedRewardType.discountToCart,
  label: 'Discount to cart'
}]);
export var specialsSaleProductGroups = [{
  key: 'individual',
  label: 'Individual Items'
}, {
  key: 'categories',
  label: 'Categories'
}, {
  key: 'brands',
  label: 'Brands'
}];
export var specialsTypes = [{
  description: 'Place certain menu items on sale.',
  key: 'sale',
  title: 'Sale'
}, {
  description: "Customer buys X and gets Y (ex: BOGO's)",
  key: 'bogo',
  title: 'Offer'
}
// Note: to add a "Coming Soon" badge to a type tile, add `comingSoon: true` to the type definition object
];
export var DEFAULT_SPECIALS_CARD_IMG = '/images/default-special-card.jpg';
export var totalWeightOptions = ['.5g', '.75g', '1g', '1.25g', '1.5g', '1.75g', '2g', '2.25g', '2.5g', '2.75g', '3g', '3.25g', '3.5g', '3.75g', '4g', '1/8oz', '1/4oz', '1/2oz', '1oz', '1 1/8oz', '1 1/4oz', '1 1/2oz', '2oz'];
export var OFFERS_DATA = {
  conditions: "bogoConditions",
  rewards: "bogoRewards",
  sale: "products"
};
export var OFFERS_DATA_SELECTED = {
  conditions: "selectedConditionsProducts",
  rewards: "selectedRewardsProducts",
  sale: "selectedSaleProducts"
};

// TODO rename these to something more informative
export var pluralToSingular = {
  conditions: 'condition',
  rewards: 'reward',
  sale: 'item',
  brands: 'brand',
  categories: 'category',
  individual: 'individual'
};
export var plural = {
  brand: 'brands',
  category: 'categories',
  individual: 'individual',
  sale: 'items'
};
export var STEP_RULES = {
  conditions: 'conditionRules',
  sale: 'saleSpecialRules',
  rewards: 'rewardRules'
};
export var priorConditions = {
  individual: 'Individual Items',
  brands: 'Items from a Brand',
  categories: 'Items from a Category'
};
export var conditionToolTipCopy = {
  totalQuantity: "Total Quantity has already been applied to this offer.",
  totalWeight: "Total Spend or Total Weight has already been applied to this offer.",
  totalSpend: "Total Spend or Total Weight has already been applied to this offer."
};
export var CONDITION_TYPES = function CONDITION_TYPES() {
  var disabledStates = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return [{
    disabled: !!(disabledStates !== null && disabledStates !== void 0 && disabledStates.individual),
    label: 'Individual Items',
    rowType: BasicConditionOrRewardType.individual,
    uniqueStep: null
  }, {
    disabled: !!(disabledStates !== null && disabledStates !== void 0 && disabledStates.brand),
    label: 'Items from a Brand',
    rowType: BasicConditionOrRewardType.brand,
    uniqueStep: null
  }, {
    disabled: !!(disabledStates !== null && disabledStates !== void 0 && disabledStates.category),
    label: 'Items from a Category',
    rowType: BasicConditionOrRewardType.category,
    uniqueStep: null
  }, {
    disabled: !!(disabledStates !== null && disabledStates !== void 0 && disabledStates.totalWeight),
    label: 'Total Weight',
    rowType: AdvancedConditionType.totalWeight,
    uniqueStep: 'conditions'
  }, {
    disabled: !!(disabledStates !== null && disabledStates !== void 0 && disabledStates.totalQuantity),
    label: 'Total Quantity',
    rowType: AdvancedConditionType.totalQuantity,
    uniqueStep: 'conditions'
  }, {
    disabled: !!(disabledStates !== null && disabledStates !== void 0 && disabledStates.totalSpend),
    label: 'Total Spend',
    rowType: AdvancedConditionType.totalSpend,
    uniqueStep: 'conditions'
  }, {
    disabled: !!(disabledStates !== null && disabledStates !== void 0 && disabledStates.itemsForADiscount),
    label: 'Bundled Discount',
    rowType: AdvancedRewardType.itemsForADiscount,
    uniqueStep: 'rewards'
  }, {
    disabled: !!(disabledStates !== null && disabledStates !== void 0 && disabledStates.itemsForAPrice),
    label: 'Bundled Price',
    rowType: AdvancedRewardType.itemsForAPrice,
    uniqueStep: 'rewards'
  }, {
    disabled: !!(disabledStates !== null && disabledStates !== void 0 && disabledStates.discountToCart),
    label: 'Discount to Cart',
    rowType: AdvancedRewardType.discountToCart,
    uniqueStep: 'rewards'
  }];
};
export var DISCOUNT_SYNC_HOVER_TEXT_V2 = 'This special was created in Dutchie Point of Sale.';
export var DISCOUNTS_SYNC_INFO_TEXT =
// eslint-disable-next-line max-len
'This setting will ingest any Sale discount that is marked as available for online use. You will no longer have to build Sale discounts in Dutchie for your menu and pricing to display as expected.';
export var defaultConditionStates = {
  conditions: {
    individual: false,
    brand: false,
    category: false,
    totalWeight: false,
    totalQuantity: false,
    totalSpend: false
  },
  rewards: {
    individual: false,
    brand: false,
    category: false,
    discountToCart: false,
    itemsForADiscount: false,
    itemsForAPrice: false
  },
  sale: {
    individual: false,
    brand: false,
    category: false
  }
};
export var advancedConditionsArray = _.keys(AdvancedConditionType);
export var defaultErrorMessage = function defaultErrorMessage(isCreate) {
  return "Unable to ".concat(isCreate ? 'create' : 'update', " special. Please contact support.");
};
export var DISCOUNT_RESTRICTION_LIMITS = {
  conditions: 350,
  rewards: 350,
  sale: 700
};