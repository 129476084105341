import React, { ReactNode, forwardRef } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { Product } from 'types/graphql-overrides';
import { ProductPlacement } from 'src/types';
import useUI from 'src/hooks/use-ui';
import { useQuickAddToCart } from 'src/components/product-carousel/use-quick-add-to-cart';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import { tracker } from 'src/utils/analytics';
import {
  ItemListName,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import EmptyLoadingState from 'src/components/empty-loading-state';
import { Card } from 'src/components/product-card-v2';
import { CarouselV2 } from '../carousel-v2';

type ProductCarouselProps = {
  isLoading: boolean;
  products: Product[];
  href?: string;
  hideTitle?: boolean;
  hideLink?: boolean;
  title?: string;
  subTitle?: string;
  icon?: ReactNode;
  header?: ReactNode;
  beforeProducts?: ReactNode;
  afterProducts?: ReactNode;
  mt?: string;
  gradientColor?: string;
  hideSponsoredTag?: boolean;
  placement?: ProductPlacement;
  source?: ProductRecommendationSource | null;
  isSmallTitle?: boolean;
  isBottomSubTitle?: boolean;
  onViewAllClick?: () => void;
  onProductClick?: (product: Product, index: number, trackerSourceOverride?: ItemListName) => void;
  onQuickAddToCart?: (product: Product, index: number, trackerSourceOverride?: ItemListName) => void;
  onProductView?: (product: Product, index: number) => void;
};

export const ProductCarousel = forwardRef<HTMLDivElement, ProductCarouselProps>(
  (
    {
      isLoading,
      products,
      href,
      hideTitle,
      title,
      subTitle,
      icon,
      hideLink,
      header,
      beforeProducts,
      afterProducts,
      onViewAllClick,
      onProductClick,
      onQuickAddToCart,
      onProductView,
      mt,
      gradientColor,
      hideSponsoredTag,
      placement,
      source = null,
      isSmallTitle = false,
      isBottomSubTitle = false,
    },
    ref
  ): JSX.Element => {
    const router = useRouter();
    const UI = useUI();
    const { handleAddToCart } = useQuickAddToCart();
    const trackerSource = getItemListName({
      router,
      source: source ?? ProductRecommendationSource.native,
      section: title ?? '',
    });

    const handleClick = (product: Product, index: number): void => {
      tracker.setContext({ activeProductPosition: index });
      tracker.productClicked({ product, position: index, trackerSource, placement });

      UI.activeProductSource = trackerSource;
      onProductClick?.(product, index, trackerSource);
    };

    const handleQuickAddClick = (product: Product, index: number): void => {
      tracker.setContext({ activeProductPosition: index });
      tracker.productClicked({ product, position: index, trackerSource, placement });

      UI.activeProductSource = trackerSource;

      void handleAddToCart(product, trackerSource);
      onQuickAddToCart?.(product, index, trackerSource);
    };

    if (isLoading) {
      return <EmptyLoadingState isLoading={isLoading} showBorder={false} page='productCarousel' />;
    }

    return (
      <>
        <CarouselV2
          ref={ref}
          href={href}
          hideTitle={hideTitle}
          title={title}
          subTitle={subTitle}
          icon={icon}
          hideLink={hideLink}
          header={header}
          mt={UI.isEmbeddedCarousel ? '0' : mt}
          gradientColor={gradientColor}
          isSmallTitle={isSmallTitle}
          isBottomSubTitle={isBottomSubTitle}
          onLinkClick={onViewAllClick}
        >
          {beforeProducts}

          {products.map((product, index) => (
            <Wrapper key={product.id} role='group' aria-roledescription='slide' aria-label={product.name}>
              <Card
                data-testid='carousel-product-card'
                product={product}
                productIndex={index}
                hideSponsoredTag={hideSponsoredTag}
                onClick={() => handleClick(product, index)}
                onQuickAddClick={() => handleQuickAddClick(product, index)}
                onView={() => onProductView?.(product, index)}
              />
            </Wrapper>
          ))}

          {afterProducts}
        </CarouselV2>
      </>
    );
  }
);

const Wrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  width: 184px;

  @media screen and (min-width: 1110px) {
    width: 220px;
  }
`;
