/* eslint func-names: 0 */

export function phone(message) {
  return this.test({
    message: message,
    test: function test(value) {
      if (!value) {
        return false;
      }
      var digitsOnlyValue = value.replace(/\D/g, '');
      // eslint-disable-next-line lodash/prefer-lodash-method
      if (digitsOnlyValue.startsWith('1')) {
        return false;
      }
      if (digitsOnlyValue.length !== 10) {
        return false;
      }
      return true;
    }
  });
}