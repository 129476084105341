import {
  ConnectionStatus,
  GqlBankAccountPaymentMethod,
  GqlListBankAccountsQuery,
  GqlMxBankAccount,
  GqlShowEnrollmentQuery,
  GqlListRetailerPaymentTypesStatusQuery,
  StatusTypes,
  useListRetailerPaymentTypesStatusQuery,
  useShowEnrollmentQuery,
} from 'types/graphql';
import useDispensaryPaymentMethods from 'shared/hooks/use-dispensary-payment-methods';
import useCart from 'src/hooks/use-cart';
import useFeatureFlags from 'hooks/use-feature-flags';
import useUI from 'src/hooks/use-ui';
import useUser from 'shared/hooks/use-user';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { ApolloQueryResult, WatchQueryFetchPolicy } from 'apollo-client';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { relinkBankAccount, editBankAccount } from 'src/payments/constants';
import { ApolloError } from '@apollo/client';
import { InterfaceVariants } from 'src/constants/interface-variants';

export type EnrollmentBankAccount = Pick<
  GqlMxBankAccount,
  'accountAlias' | 'bankName' | 'billingAddressId' | 'id' | 'lastDigits'
>;

export type PlaidBankAccount = {
  accountAlias: string;
  bankName: string;
  connectionStatus: ConnectionStatus;
  id: string;
  lastDigits: string;
  status: string;
  financialInstitutionLogo: string;
};

export type WhiteLabelConfig = {
  useWhiteLabel: boolean;
  brandedDutchiePayName: string | undefined;
  brandedDutchiePayLogoLight: string | undefined;
  brandedDutchiePayLogoDark: string | undefined;
};

export type UseDutchiePayUtils = {
  aggregator: string | undefined;
  allowDutchiePayEnrollment: boolean;
  bankAccounts: EnrollmentBankAccount[] | PlaidBankAccount[];
  connectionStatus: string | undefined;
  enrollmentStatusPending: boolean;
  error: ApolloError | undefined;
  hasEnrollmentData: boolean;
  isDutchiePayEnabledForDispo: boolean;
  isEnrolledDutchiePay: boolean;
  loading: boolean;
  paymentMethodId: string | undefined;
  refetchEnrollment: () => Promise<ApolloQueryResult<GqlListBankAccountsQuery | GqlShowEnrollmentQuery>>;
  refetchRetailerPaymentData: () => Promise<ApolloQueryResult<GqlListRetailerPaymentTypesStatusQuery>>;
  requiresAggregatorMigration: boolean | null | undefined;
  whiteLabelConfig: WhiteLabelConfig;
};

type UseDutchiPayParams =
  | {
      enrollmentFetchPolicy?: WatchQueryFetchPolicy;
    }
  | undefined;

export const useDutchiePay = ({
  enrollmentFetchPolicy = 'network-only',
}: UseDutchiPayParams = {}): UseDutchiePayUtils => {
  const User = useUser();
  const router = useRouter();
  const { variant } = useUI();
  const Cart = useCart();
  const FeatureFlags = useFeatureFlags();

  const { dispensary } = Cart.order;
  const { brandedDutchiePayLogoDark, brandedDutchiePayLogoLight, brandedDutchiePayName } = dispensary || {};
  const { plus, embedded } = InterfaceVariants;

  const hasWhiteLabelAssets =
    !!dispensary?.brandedDutchiePayName &&
    !!dispensary?.brandedDutchiePayLogoLight &&
    !!dispensary?.brandedDutchiePayLogoDark;

  const isWhiteLabeled = Boolean(
    dispensary?.brandedDutchiePayEnabled && hasWhiteLabelAssets && [embedded, plus].includes(variant)
  );

  const whiteLabelConfig = {
    useWhiteLabel: isWhiteLabeled,
    brandedDutchiePayName: isWhiteLabeled ? brandedDutchiePayName : '',
    brandedDutchiePayLogoLight: isWhiteLabeled ? brandedDutchiePayLogoLight : '',
    brandedDutchiePayLogoDark: isWhiteLabeled ? brandedDutchiePayLogoDark : '',
  };

  const relinkOrEditBankAccountEnabled =
    router.query.step === relinkBankAccount || router.query.step === editBankAccount || router.query.oauth_state_id;

  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const isGuestUser = !User?.exists;

  const { data: data, error, loading: loading, refetch: refetchEnrollment } = useShowEnrollmentQuery({
    skip: isGuestUser,
    fetchPolicy: enrollmentFetchPolicy,
  });

  const internalSwitchesEnabled = FeatureFlags.flags['fintech.payment-processing.use-internal-feature-switches'];
  const { data: retailerPaymentData, refetch: refetchRetailerPaymentData } = useListRetailerPaymentTypesStatusQuery({
    variables: { ecommLocationId: dispensary?.id },
    skip: !internalSwitchesEnabled || !dispensary?.id,
  });
  const retailerPaymentTypes = retailerPaymentData?.listRetailerPaymentTypesStatus?.paymentTypes ?? [];
  const retailerIsEnabled = retailerPaymentTypes.some(
    (pmtType) => pmtType?.type === 'PAYMENT_TYPE_DUTCHIE_PAY' && pmtType.enabled === true
  );

  const connectedBankAccounts = data?.showEnrollment.paymentMethods ?? [];

  const paymentMethodId = connectedBankAccounts[0]?.id;

  const hasConnectedBankAcct = connectedBankAccounts.filter((acct) => acct?.status === 'active').length === 1;

  const enrollmentStatus = data?.showEnrollment.enrollmentStatus;

  const requiresAggregatorMigration = data?.showEnrollment.requiresAggregatorMigration;

  const hasEnrollmentData = !!data;
  const isEnrolledDutchiePay = Boolean(
    enrollmentStatus === StatusTypes.statusEnrolled && (hasConnectedBankAcct || requiresAggregatorMigration)
  );

  const enrollmentStatusPending = enrollmentStatus === StatusTypes.statusAccountPending;

  // TODO: move this mapping into enrollmentStatusReceived action handler https://dutchie.atlassian.net/browse/ENG-46917
  const bankAccounts = connectedBankAccounts.map((acct: GqlBankAccountPaymentMethod) => ({
    accountAlias: acct.bankAccountDetails.accountAlias,
    aggregator: acct.bankAccountDetails.aggregator,
    bankName: acct.bankAccountDetails.bankName,
    billingAddressId: acct.bankAccountDetails.billingAddressId ?? '',
    connectionStatus: acct.bankAccountDetails.status,
    id: acct.id,
    lastDigits: acct.bankAccountDetails.lastDigits,
    status: acct.status,
    financialInstitutionLogo: acct.bankAccountDetails.financialInstitutionLogo,
  }));

  const aggregator = bankAccounts[0]?.aggregator;

  // Current dispo settings

  const paymentOptions = useDispensaryPaymentMethods();
  const payByBankActive = paymentOptions?.some(({ value, disabled }) => value === 'dutchiePay' && !disabled);
  const isDutchiePayEnabledForDispo = internalSwitchesEnabled
    ? retailerIsEnabled && !!payByBankActive
    : !!payByBankActive;

  const allowDutchiePayEnrollment = isDutchiePayEnabledForDispo && !isEnrolledDutchiePay;
  const connectionStatus = bankAccounts[0]?.connectionStatus;

  useEffect(() => {
    if (data) {
      DutchiePayEnrollment.enrollmentStatusReceived({
        enrollmentStatus,
        bankAccounts,
        relinkOrEditBankAccountEnabled,
        requiresAggregatorMigration,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, enrollmentStatus, relinkOrEditBankAccountEnabled]);

  return {
    aggregator,
    allowDutchiePayEnrollment,
    bankAccounts,
    connectionStatus,
    enrollmentStatusPending,
    error,
    hasEnrollmentData,
    isDutchiePayEnabledForDispo,
    isEnrolledDutchiePay,
    loading,
    paymentMethodId,
    refetchEnrollment,
    refetchRetailerPaymentData,
    requiresAggregatorMigration,
    whiteLabelConfig,
  };
};
