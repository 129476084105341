import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
import { v4 } from 'uuid';
import { mapComparisonOperator, mapExclusions, mapInclusions, mapThresholds, mapWeightUnit } from './other-mappers';
import { getWeightAndUnits } from '../../products';
export var defaultCondition = function defaultCondition() {
  return {
    id: v4(),
    exclusions: [],
    inclusions: [],
    thresholds: []
  };
};
export var mapCondition = function mapCondition(condition) {
  return _objectSpread(_objectSpread({}, defaultCondition()), {}, {
    id: condition._id,
    exclusions: mapExclusions(condition),
    inclusions: mapInclusions(condition),
    requiresSingleSku: condition.requiresSingleSku,
    thresholds: mapConditionThresholds(condition)
  });
};
export var mapConditions = function mapConditions(special) {
  return _.map(special.bogoConditions, function (condition) {
    return mapCondition(condition);
  });
};
var mapConditionThresholds = function mapConditionThresholds(condition) {
  return mapThresholds(condition);
};
export var mapGlobalConditionThresholds = function mapGlobalConditionThresholds(_ref) {
  var totalQuantity = _ref.totalQuantity,
    totalSpend = _ref.totalSpend,
    totalWeight = _ref.totalWeight;
  return mapThresholds({
    totalQuantity: totalQuantity,
    totalSpend: totalSpend,
    totalWeight: totalWeight
  });
};
var mapTotalQuantityCondition = function mapTotalQuantityCondition(special) {
  var thresholds = [];
  var _special$totalQuantit = special.totalQuantity,
    quantity = _special$totalQuantit.quantity,
    maxQuantity = _special$totalQuantit.maxQuantity,
    quantityOperator = _special$totalQuantit.quantityOperator;
  var betweenOperatorUsed = quantityOperator === 'between';
  var minThreshold = {
    comparisonOperator: betweenOperatorUsed ? 'GREATER_THAN_OR_EQUAL_TO' : mapComparisonOperator(quantityOperator),
    quantity: {
      amount: parseFloat(quantity)
    },
    type: 'TOTAL_QUANTITY'
  };
  thresholds.push(minThreshold);
  if (!_.isNil(maxQuantity) || betweenOperatorUsed) {
    var maxThreshold = {
      comparisonOperator: 'LESS_THAN_OR_EQUAL_TO',
      quantity: {
        amount: parseFloat(maxQuantity)
      },
      type: 'TOTAL_QUANTITY'
    };
    thresholds.push(maxThreshold);
  }
  return thresholds;
};
var mapTotalSpendCondition = function mapTotalSpendCondition(special) {
  var thresholds = [];
  var _special$totalSpend = special.totalSpend,
    minimumSpend = _special$totalSpend.minimumSpend,
    maximumSpend = _special$totalSpend.maximumSpend,
    spendOperator = _special$totalSpend.spendOperator;
  var betweenOperatorUsed = spendOperator === 'between';
  var minThreshold = {
    comparisonOperator: betweenOperatorUsed ? 'GREATER_THAN_OR_EQUAL_TO' : mapComparisonOperator(spendOperator),
    price: parseFloat(minimumSpend) * 100,
    type: 'TOTAL_PRICE'
  };
  thresholds.push(minThreshold);
  if (betweenOperatorUsed) {
    var maxThreshold = {
      comparisonOperator: 'LESS_THAN_OR_EQUAL_TO',
      price: parseFloat(maximumSpend) * 100,
      type: 'TOTAL_PRICE'
    };
    thresholds.push(maxThreshold);
  }
  return thresholds;
};
var mapTotalWeightCondition = function mapTotalWeightCondition(special) {
  var thresholds = [];
  var _special$totalWeight = special.totalWeight,
    weight = _special$totalWeight.weight,
    maxWeight = _special$totalWeight.maxWeight,
    weightOperator = _special$totalWeight.weightOperator;
  var betweenOperatorUsed = weightOperator === 'between';
  var weightRestriction = getWeightAndUnits(weight);
  var minThreshold = {
    comparisonOperator: betweenOperatorUsed ? 'GREATER_THAN_OR_EQUAL_TO' : mapComparisonOperator(weightOperator),
    type: 'TOTAL_WEIGHT',
    weight: {
      amount: weightRestriction.amount,
      weightUnit: mapWeightUnit(weightRestriction.unit)
    }
  };
  thresholds.push(minThreshold);
  if (betweenOperatorUsed) {
    var maxRestriction = getWeightAndUnits(maxWeight);
    var maxThreshold = {
      comparisonOperator: 'LESS_THAN_OR_EQUAL_TO',
      type: 'TOTAL_WEIGHT',
      weight: {
        amount: maxRestriction.amount,
        weightUnit: mapWeightUnit(weightRestriction.unit)
      }
    };
    thresholds.push(maxThreshold);
  }
  return thresholds;
};