import { action, observable, makeObservable } from 'mobx';
import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';

const STORAGE_KEY = 'instoreEnrollmentStatus';
const SEEN_SUCCESS_KEY = 'instoreEnrollmentSeenSuccess';
const DECLINED_PAY_BY_BANK_KEY = 'instoreEnrollmentDeclinedPayByBank';
const FULLY_ENROLLED_KEY = 'instoreEnrollmentFullyEnrolled';
export enum InstoreEnrollmentStatus {
  ecommLogin = 'ECOMM_LOGIN',
  payByBankEnrollment = 'PAY_BY_BANK_ENROLLMENT',
  connectBankAccount = 'CONNECT_BANK_ACCOUNT',
  loyaltyEnrollment = 'LOYALTY_ENROLLMENT',
  finishedEnrollment = 'FINISHED_ENROLLMENT',
}

export class InstoreEnrollmentStore {
  @observable currentStatus: InstoreEnrollmentStatus | null = null;
  @observable declinedPayByBank = false;
  @observable hasSeenSuccess = false;
  @observable fullyEnrolled = false;

  constructor() {
    makeObservable(this);

    this.currentStatus =
      this.loadPersistedValue(STORAGE_KEY, Object.values(InstoreEnrollmentStatus)) ?? this.currentStatus;
    this.hasSeenSuccess = this.loadPersistedValue(SEEN_SUCCESS_KEY) ?? this.hasSeenSuccess;
    this.declinedPayByBank = this.loadPersistedValue(DECLINED_PAY_BY_BANK_KEY) ?? this.declinedPayByBank;
  }

  private loadPersistedValue<T>(key: string, validValues?: T[]): T | null {
    const savedValue = getPersistedValue(key);

    if (!savedValue) {
      return null;
    }

    if (validValues && !validValues.includes(savedValue)) {
      return null;
    }

    return savedValue;
  }

  @action
  setCurrentStatus(status: InstoreEnrollmentStatus): void {
    this.currentStatus = status;
    setPersistedValue(STORAGE_KEY, status, true);
  }

  @action
  reset(): void {
    this.currentStatus = InstoreEnrollmentStatus.ecommLogin;
    setPersistedValue(STORAGE_KEY, InstoreEnrollmentStatus.ecommLogin, true);
  }

  @action
  setDeclinedPayByBank(declined: boolean): void {
    this.declinedPayByBank = declined;
    setPersistedValue(DECLINED_PAY_BY_BANK_KEY, declined, true);
  }

  @action
  setHasSeenSuccess(): void {
    this.hasSeenSuccess = true;
    setPersistedValue(SEEN_SUCCESS_KEY, true, true);
  }

  @action
  setDidDeclinePayByBank(): void {
    this.declinedPayByBank = true;
    setPersistedValue(DECLINED_PAY_BY_BANK_KEY, true, true);
  }

  @action
  setDidNotDeclinePayByBank(): void {
    this.declinedPayByBank = false;
    setPersistedValue(DECLINED_PAY_BY_BANK_KEY, false, true);
  }

  @action
  setIsFullyEnrolled(): void {
    this.fullyEnrolled = true;
    setPersistedValue(FULLY_ENROLLED_KEY, true, true);
  }
}
