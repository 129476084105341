import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["name", "label", "onChange", "onClick", "labelButton", "labelButtonOnClick", "tooltip", "inputId", "tooltipCopy", "className", "register", "hidden"];
var __jsx = React.createElement;
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Label, Checkbox } from 'shared/components/legacy-rebass';
import { width } from 'styled-system';
import { Tooltip } from 'shared/components';
var CheckboxLabel = styled(Label).withConfig({
  displayName: "checkbox-old__CheckboxLabel",
  componentId: "sc-1v47syr-0"
})(["position:relative;line-height:1.5;margin-top:", ";margin-bottom:", ";display:", ";", ";"], function (props) {
  return props.mt || 0;
}, function (props) {
  return props.mb || 0;
}, function (props) {
  return props.hidden ? 'none' : '';
}, width);
export var StyledCheckbox = styled(Checkbox).withConfig({
  displayName: "checkbox-old__StyledCheckbox",
  componentId: "sc-1v47syr-1"
})(["height:17px;width:17px;opacity:0;position:absolute;top:0;left:0;cursor:pointer;display:", ";+ span{min-height:17px;line-height:17px;cursor:", ";color:", ";font-size:13px;padding-left:30px;position:relative;> span{height:17px;width:17px;position:absolute;left:0;top:0;opacity:", ";background:url(/icons/unchecked-icon.svg) no-repeat;}}&:checked + span > span{opacity:", ";background:url(/icons/checked-icon.svg) no-repeat;}&.focus-visible + span > span{box-shadow:0 0 0pt 2pt rgba(11,153,230,0.4) !important;}"], function (_ref) {
  var hidden = _ref.hidden;
  return hidden ? 'none' : '';
}, function (_ref2) {
  var disabled = _ref2.disabled;
  return disabled && 'not-allowed' || 'pointer';
}, function (_ref3) {
  var disabled = _ref3.disabled,
    color = _ref3.color;
  return disabled && '#b3b3b3 !important' || color || '#575e64';
}, function (_ref4) {
  var disabled = _ref4.disabled;
  return disabled ? 0.5 : 1;
}, function (_ref5) {
  var disabled = _ref5.disabled;
  return disabled ? 0.5 : 1;
});
var CheckBox = function CheckBox(/** @type any */_ref6) {
  var name = _ref6.name,
    label = _ref6.label,
    _ref6$onChange = _ref6.onChange,
    onChange = _ref6$onChange === void 0 ? _.noop : _ref6$onChange,
    _ref6$onClick = _ref6.onClick,
    onClick = _ref6$onClick === void 0 ? _.noop : _ref6$onClick,
    _ref6$labelButton = _ref6.labelButton,
    labelButton = _ref6$labelButton === void 0 ? false : _ref6$labelButton,
    _ref6$labelButtonOnCl = _ref6.labelButtonOnClick,
    labelButtonOnClick = _ref6$labelButtonOnCl === void 0 ? false : _ref6$labelButtonOnCl,
    _ref6$tooltip = _ref6.tooltip,
    tooltip = _ref6$tooltip === void 0 ? false : _ref6$tooltip,
    inputId = _ref6.inputId,
    tooltipCopy = _ref6.tooltipCopy,
    className = _ref6.className,
    register = _ref6.register,
    hidden = _ref6.hidden,
    props = _objectWithoutProperties(_ref6, _excluded);
  return __jsx(CheckboxLabel, _extends({}, props, {
    className: "".concat(className, " checkbox-old"),
    hidden: hidden
  }), __jsx(StyledCheckbox, _extends({
    inputId: inputId,
    "data-testid": inputId,
    name: name,
    onChange: onChange,
    onClick: onClick,
    register: register,
    hidden: hidden
  }, props)), __jsx("span", null, __jsx("span", null), label, labelButton && __jsx("a", {
    onClick: labelButtonOnClick
  }, labelButton) // eslint-disable-line
  ), tooltip && __jsx(Tooltip, {
    tooltipCopy: tooltipCopy,
    grey: true,
    left: "auto",
    bottom: "30px",
    right: "-61px",
    width: "239px"
  }));
};
export default CheckBox;