import React, { useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import styled, { useTheme } from 'styled-components';
import Imgix, { buildImgixSrc, buildImgixSrcSet } from 'shared/components/imgix';

import type { Product as ProductType } from 'types/graphql-overrides';
import { Maybe } from 'types/graphql';

import { useFlags } from 'launchdarkly-react-client-sdk';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DefaultImages, ImgixDefaultSettings } from 'shared/constants';
import { imageToUse } from 'shared/helpers/products';

// eslint-disable-next-line import/no-unresolved
import type { InnerImageZoomProps } from 'vendor/types/react-inner-image-zoom';

const ZoomableImage = dynamic<InnerImageZoomProps>(() => import('components/zoomable-image'), { ssr: false });

type ImgixParams = typeof ImgixDefaultSettings & {
  w?: number;
  h?: number;
  fit?: string;
};

type ProductImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  height?: number;
  isZoomable?: boolean;
  product?: ProductType;
  src?: Maybe<string>;
  width?: number;
};

export default function ProductImage({
  src: passedSrc,
  product,
  isZoomable = false,
  ...imageProps
}: ProductImageProps): JSX.Element {
  const SCtheme = useTheme();
  const flags = useFlags();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down(`sm`));
  const isZoomableAvailable = flags['growth.ecomm.product-image-zoomable.rollout'] ?? false;

  const [imageHasError, setImageHasError] = useState<boolean>(false);

  const onImageError = useCallback((): void => {
    setImageHasError(true);
    console.log(`Image failed to load.`, {
      imgSrc: imageToUse(product ?? {}),
      product: { id: product?._id },
    });
  }, [product]);

  const defaultImage = product ? DefaultImages[product.type ?? 'Flower'] : DefaultImages.Flower;
  let resolvedImgSrc = passedSrc ?? imageToUse(product ?? {}) ?? defaultImage;
  if (imageHasError) {
    resolvedImgSrc = defaultImage;
  }

  if (isZoomableAvailable && isZoomable) {
    const zoomScale = 1.75;
    const imageSrcImgixParams: ImgixParams = { ...ImgixDefaultSettings, fit: 'max' };
    const zoomSrcImgixParams: ImgixParams = { ...ImgixDefaultSettings, fit: 'max' };
    if (imageProps.width) {
      imageSrcImgixParams.w = imageProps.width;
      zoomSrcImgixParams.w = imageProps.width * zoomScale;
    }
    if (imageProps.height) {
      imageSrcImgixParams.h = imageProps.height;
      zoomSrcImgixParams.h = imageProps.height * zoomScale;
    }
    const src = buildImgixSrc(resolvedImgSrc, imageSrcImgixParams);
    const srcSet = buildImgixSrcSet(resolvedImgSrc, imageSrcImgixParams);
    const zoomSrc = buildImgixSrc(resolvedImgSrc, zoomSrcImgixParams);
    const zoomSrcSet = buildImgixSrcSet(resolvedImgSrc, zoomSrcImgixParams);

    return (
      <ZoomableImage
        {...imageProps}
        src={src}
        zoomSrc={zoomSrc}
        sources={[{ srcSet }]}
        hideHint={!isMobile}
        mobileBreakpoint={SCtheme.breakpoints.mobile}
        zoomScale={zoomScale}
        zoomPreload
        imgAttributes={{
          alt: product?.description ?? '',
          loading: 'lazy',
          decoding: 'async',
          onError: onImageError,
        }}
        zoomImgAttributes={{
          alt: product?.description ?? '',
          loading: 'lazy',
          decoding: 'async',
          srcSet: zoomSrcSet,
          onError: onImageError,
        }}
      />
    );
  }

  return (
    <StyledImgix
      {...imageProps}
      type='img'
      imgixParams={{ ...ImgixDefaultSettings, fit: 'max' }}
      sizes='auto'
      width={imageProps.width}
      height={imageProps.width}
      htmlAttributes={{
        alt: product?.description ?? '',
        loading: 'lazy',
        decoding: 'async',
        onError: onImageError,
      }}
      src={resolvedImgSrc}
    />
  );
}

const StyledImgix = styled(Imgix)`
  object-fit: contain;
`;
