/* eslint-disable consistent-return */
import _ from 'lodash';
import { orderProductsForDiscountPrecedence } from '../utilities';
import { mergeSatisfiers } from './common';
var checkStandardConditions = function checkStandardConditions(_ref) {
  var conditions = _ref.conditions,
    continueCheckingConditionsAfterSatisfied = _ref.continueCheckingConditionsAfterSatisfied,
    itemToConsiderLast = _ref.itemToConsiderLast,
    logicOperator = _ref.logicOperator,
    pendingRewardsSatisfiers = _ref.pendingRewardsSatisfiers,
    items = _ref.items,
    specialsSettings = _ref.specialsSettings,
    itemsForAPrice = _ref.itemsForAPrice;
  // no conditions present, exit early
  if (conditions.length < 1) {
    return {
      satisfied: false,
      satisfiers: {}
    };
  }

  // sort out items by discount precedence, because our conditions will always be in alphabetical order
  var sortedItems = orderProductsForDiscountPrecedence(items, specialsSettings, itemsForAPrice.enabled ? 'rewards' : 'conditions', true, itemToConsiderLast);
  var adjustedSortedItems = sortedItems;
  if (itemToConsiderLast) {
    adjustedSortedItems = _.reject(sortedItems, ['key', itemToConsiderLast.key]);
    adjustedSortedItems.push(itemToConsiderLast);
  }
  var satisfiers = {};

  // clear out prior conditions
  _.forEach(conditions, function (condition) {
    condition.reset();
  });
  _.forEach(adjustedSortedItems, function (item) {
    // eslint-disable-next-line lodash/prefer-filter
    _.forEach(conditions, function (condition) {
      // as long as we haven't used satisfied this condition...
      if ((condition === null || condition === void 0 ? void 0 : condition.satisfied) === false) {
        // check if this item is a.) eligible for this condition, and if so, b.) whether it satisfies this condition
        var _condition$evaluateIt = condition.evaluateItem({
            item: item,
            pendingConditionsSatisfiers: satisfiers,
            pendingRewardsSatisfiers: pendingRewardsSatisfiers
          }),
          conditionSatisfied = _condition$evaluateIt.satisfied,
          conditionSatisfiers = _condition$evaluateIt.satisfiers;
        if (conditionSatisfied) {
          var _condition$condition;
          // add this condition to our running list of satisfiers
          satisfiers = mergeSatisfiers(satisfiers, conditionSatisfiers);

          // break out of our condition loop early if we have our singular OR satisfier and we're not continuing to check past satisfaction
          if (logicOperator === 'or' && !continueCheckingConditionsAfterSatisfied) {
            return false;
          }

          // remove any half applied satisfiers that might have been used in this satisfied condition
          var otherConditions = _.reject(conditions, ['condition._id', condition === null || condition === void 0 ? void 0 : (_condition$condition = condition.condition) === null || _condition$condition === void 0 ? void 0 : _condition$condition._id]);
          _.forEach(otherConditions, function (other) {
            other.removeSatisfiers(condition);
          });
        }
      }
    });

    // break out of item loop early if we have our singular OR satisfier and we're not continuing to check past satisfaction
    if (logicOperator === 'or' && _.some(conditions, 'satisfied') && !continueCheckingConditionsAfterSatisfied) {
      return false;
    }

    // break out of item loop early if every AND condition is satisfied
    if (logicOperator === 'and' && _.every(conditions, 'satisfied')) {
      return false;
    }
  });

  // reset previous conditions
  _.forEach(conditions, function (condition) {
    condition.setPrevious();
  });
  if (logicOperator === 'or' && _.some(conditions, 'satisfied')) {
    return {
      satisfied: true,
      satisfiers: satisfiers
    };
  }
  if (logicOperator === 'and' && _.every(conditions, 'satisfied')) {
    return {
      satisfied: true,
      satisfiers: satisfiers
    };
  }
  return {
    satisfied: false,
    satisfiers: {}
  };
};
export default checkStandardConditions;