import { useEffect } from 'react';

// log rocket needs to extend event handlers and does so in strict mode
// that means if they're not extendable (ebmla carousels) then it'll throw
// and exceptions are bad!
export default function useFixLogRocket() {
  useEffect(function () {
    var oldAddListener = HTMLDivElement.prototype.addEventListener;
    HTMLDivElement.prototype.addEventListener = function addEventListener(name, fn) {
      for (var _len = arguments.length, options = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        options[_key - 2] = arguments[_key];
      }
      if (Object.isExtensible(fn)) {
        return oldAddListener.apply(this, [name, fn].concat(options));
      }
      function wrappedFn() {
        for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }
        return fn.call.apply(fn, [this].concat(args));
      }
      Object.keys(fn).forEach(function (key) {
        wrappedFn[key] = fn[key];
      });
      return oldAddListener.apply(this, [name, wrappedFn].concat(options));
    };
    return function () {
      HTMLDivElement.prototype.addEventListener = oldAddListener;
    };
  }, []);
}