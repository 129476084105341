import { datadogLogs } from '@datadog/browser-logs';

type LogLevel = 'debug' | 'error' | 'info' | 'warn';
type LoggerFunction = (message: string, context?: Record<string, unknown>) => void;

type Logger = {
  debug: LoggerFunction;
  info: LoggerFunction;
  warn: LoggerFunction;
  error: LoggerFunction;
};

/**
 * Creates a namespaced logger using Datadog's browser logs
 * @param namespace - The namespace to use for the logger
 * @returns A function that logs messages with the given namespace
 */
export const getLogger = (namespace: string): Logger => {
  const createLogMethod = (level: LogLevel): LoggerFunction => (message: string, context?: Record<string, unknown>) =>
    datadogLogs.logger[level](`[${namespace}] ${message}`, { namespace, ...context });

  return {
    debug: createLogMethod('debug'),
    info: createLogMethod('info'),
    warn: createLogMethod('warn'),
    error: createLogMethod('error'),
  };
};
