var __jsx = React.createElement;
/* eslint max-len: 0 */
import React from 'react';

// font-awesome "info"
export default function InfoIcon() {
  return __jsx("svg", {
    width: "20",
    height: "20",
    viewBox: "0 0 1792 1792",
    xmlns: "http://www.w3.org/2000/svg"
  }, __jsx("path", {
    d: "M1216 1344v128q0 26-19 45t-45 19h-512q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h64v-384h-64q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h384q26 0 45 19t19 45v576h64q26 0 45 19t19 45zm-128-1152v192q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-192q0-26 19-45t45-19h256q26 0 45 19t19 45z",
    fill: "#fff"
  }));
}