import React, { RefObject } from 'react';
import styled from 'styled-components';
import { Clickable } from 'shared/components';
import { EmbeddedAccountIcon } from 'src/assets/embedded-account-icon';
import { MyAccountPopover } from './my-account-popover';

export type MyAccountLinkProps = {
  showDropdown: boolean;
  toggleDropdown: () => void;
  isLoggedIn: boolean;
  anchorEl: HTMLButtonElement | null;
  myAccountRef: RefObject<HTMLButtonElement> | null;
  isMobile: boolean;
};

export function MyAccountLink(props: MyAccountLinkProps): JSX.Element {
  const { showDropdown, toggleDropdown, isLoggedIn, anchorEl, myAccountRef, isMobile } = props;

  return (
    <Account
      aria-label='my-account-dropdown'
      data-testid='my-account-link'
      ref={myAccountRef}
      onClick={toggleDropdown}
      isMobile={isMobile}
    >
      <EmbeddedAccountIcon fill='#b2b8c3' />
      {showDropdown && <MyAccountPopover isLoggedIn={isLoggedIn} anchorEl={anchorEl} />}
    </Account>
  );
}

const Account = styled(Clickable)<{ isMobile: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 100%;
  margin: ${({ isMobile }) => (isMobile ? '0px 18px 0px 0px' : '0px 22px 0px 22px')};
`;
