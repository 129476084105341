import useUI from 'src/hooks/use-ui';
import { useCreateAlpineUserV2Mutation, GetRewardsV2Document } from 'types/graphql';

import { useInstoreEnrollment } from 'src/hooks/use-instore-enrollment';
import { InstoreEnrollmentStatus } from 'src/state/instore-enrollment';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUser from 'src/hooks/use-user';
import { formatAddress } from 'shared/helpers/address';
import { tracker } from 'src/utils/analytics';
import { detectLoyaltyIntegration } from 'src/components/loyalty/use-loyalty';
import { Integrations } from 'src/components/loyalty/use-loyalty/types';

type UseLoyaltyReturn = {
  loading: boolean;
  dispensaryName: string;
  pointsBoostValue: number;
  rewardsProgramUID: string;
  userFirstName: string;
  rewardsProgram: string;
  handleCreateRewardsAccount: (() => Promise<void>) | null;
};

export function useLoyalty(): UseLoyaltyReturn {
  const { dispensary, loading: dispensaryLoading } = useDispensary();
  const instoreEnrollment = useInstoreEnrollment();
  const UI = useUI();
  const user = useUser();
  const userPhone = user.phone;
  const userFirstName = user.firstName;
  const userLastName = user.lastName;
  const userEmail = user.email;
  const rewardsProgram = detectLoyaltyIntegration(dispensary);
  const [createAlpineUser, { loading: createAlpineUserLoading }] = useCreateAlpineUserV2Mutation();

  const handleCreateAlpineUser = async (): Promise<void> => {
    if (!dispensary) {
      console.error('Missing dispensary for Alpine user creation');
      return;
    }

    const variables = {
      dispensaryId: dispensary.id,
      phoneNumber: userPhone,
      firstName: userFirstName,
      lastName: userLastName,
      email: userEmail,
      favoriteStore: dispensary.name,
      address: formatAddress(user.profile?.address ?? {}),
      program: 'alpineiq',
      acceptedTerms: true,
    };

    await createAlpineUser({
      variables,
      refetchQueries: [
        {
          query: GetRewardsV2Document,
          variables: {
            phoneNumber: userPhone,
            dispensaryId: dispensary.id,
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  const loading = dispensaryLoading || createAlpineUserLoading;

  const trackLoyaltySignup = (currentDispensary: typeof dispensary, analyticsEventLabel: string): void => {
    tracker.setContext({ activeDispensary: currentDispensary });
    tracker.setContext({ activeUser: user });
    tracker.loyaltySignup({ analyticsEventLabel });
  };

  const handleCreateRewardsAccount = async (): Promise<void> => {
    if (!dispensary?.id || !userPhone || !userFirstName) {
      console.error('Missing required fields for Alpine user creation');
      return;
    }

    if (rewardsProgram === Integrations.alpineiq) {
      try {
        await handleCreateAlpineUser();
        trackLoyaltySignup(dispensary, 'instore-enrollment');
        const loginSuccessMessage = `You've successfully created a loyalty account!`;

        UI.showErnie(loginSuccessMessage, `success`);
        instoreEnrollment.setCurrentStatus(InstoreEnrollmentStatus.payByBankEnrollment);
      } catch (error) {
        UI.showErnie('Unable to create loyalty account. Please try again later.', 'danger');
        console.error('Alpine user creation error:', error);
      }
    }
  };

  return {
    loading,
    dispensaryName: dispensary?.name,
    pointsBoostValue:
      dispensary?.consumerDispensaryIntegrations[rewardsProgram]?.credentials?.rewardsProgramPointsBoost || 100,
    rewardsProgramUID: dispensary?.consumerDispensaryIntegrations[rewardsProgram]?.credentials?.rewardsProgramUID || '',
    userFirstName,
    rewardsProgram,
    handleCreateRewardsAccount,
  };
}
