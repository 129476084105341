import { useDutchiePay } from 'src/payments/hooks';
import useUser from 'hooks/use-user';
import { EntityTypes, useUnenrollPayByBankUserMutation } from 'types/graphql';

export type UseUnenrollPayByBankUserReturn = {
  handleUnenrollPayByBank: () => Promise<{ success: boolean }>;
  isEnrolledDutchiePay: boolean;
};

export const useUnenrollPayByBankUser = (): UseUnenrollPayByBankUserReturn => {
  const user = useUser();
  const { isEnrolledDutchiePay, refetchEnrollment } = useDutchiePay({ enrollmentFetchPolicy: 'cache-and-network' });
  const [unenrollPayByBankUser] = useUnenrollPayByBankUserMutation();

  const handleUnenrollPayByBank = async (): Promise<{ success: boolean }> => {
    try {
      const { data } = await unenrollPayByBankUser({
        variables: { entityId: user?.id, entityType: EntityTypes.user },
      });
      if (!data?.unenrollPayByBankUser.success) {
        throw new Error('Unenrollment failed');
      }
      await refetchEnrollment();
      return { success: true };
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  };

  return { handleUnenrollPayByBank, isEnrolledDutchiePay };
};
