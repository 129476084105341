import React from 'react';

type SvgGenericCheckmarkProps = {
  color?: string;
  width?: string;
  height?: string;
  className?: string;
};

function SvgGenericCheckmark({
  color = '#0b99e6',
  width = '16.25',
  height = '13',
  className,
}: SvgGenericCheckmarkProps): JSX.Element {
  return (
    <svg viewBox='0 0 11 9' width={width} height={height} className={className}>
      <path d='M0 4.677l2.177-2.3 1.96 2.25L9.421 0 11 1.694 4.089 9z' fill={color} fillRule='evenodd' />
    </svg>
  );
}

export default SvgGenericCheckmark;
