import React, { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useObserver } from 'mobx-react-lite';

import { useModals, ModalNames } from 'src/components/modals';

import useUser from 'hooks/use-user';
import useUI from 'hooks/use-ui';
import useCart from 'hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useDispensaryChains } from 'src/dispensary/hooks/use-dispensary-chain';
import { useCustomizationProvider } from './use-customization-provider';

import useLazyRemoteModule from 'src/utils/module-federation/use-lazy-remote-module';
import { prepareDataForRemote } from './data-interface';
import { DataBridgeVersion } from './types';

const withProps = (Component, partialProps) => {
  return (props) => <Component {...partialProps} {...props} />;
};

const remoteComponentIsValid = (Component) => {
  if (!Component) {
    return false;
  }

  if (Component.DataBridgeVersion !== DataBridgeVersion) {
    console.warn(
      `Remote component (${Component.name} v${Component.DataBridgeVersion}) is not compatible with Dutchie Commerce Components data bridge host version: ${DataBridgeVersion}`
    );
    return false;
  }
  return true;
};

export function useRemoteCommerceComponents() {
  const { dispensary } = useDispensary();
  const router = useRouter();
  const { openModal } = useModals();
  const User = useUser();
  const UI = useUI();
  const Cart = useCart();

  const userDataMapped = useObserver(() => ({
    email: User?.email,
    firstName: User?.firstName,
    lastName: User?.lastName,
    birthday: User?.birthday,
  }));

  const mapCartItem = (item) => ({
    productId: item?.id,
    name: item?.product?.name,
    price: item?.price,
    quantity: item?.quantity,
  });

  const cartDataMapped = useObserver(() => ({
    items: Object.values(Cart?.items || []).map(mapCartItem),
    total: Cart?.costBreakdown?.total,
    subtotal: Cart?.costBreakdown?.subtotal,
    tax: Cart?.costBreakdown?.tax,
    discount: Cart?.costBreakdown?.credit,
  }));

  const { customizationProvider = {} } = useCustomizationProvider({
    dispensaryId: dispensary?.id,
    chainId: dispensary?.chain,
  });

  const remoteThemeModule = useLazyRemoteModule({
    manifestURL: customizationProvider?.manifestURL,
    scope: customizationProvider?.scope,
    module: customizationProvider?.themeName,
    fallback: { default: () => {} },
    skip: !customizationProvider?.themeName,
  });

  const [shouldFetchLocations, setShouldFetchLocations] = React.useState(false);

  const { dispensaryChainLocations } = useDispensaryChains({
    activeOnly: true,
    includeStealthMode: true,
    skip: !customizationProvider?.themeName || !shouldFetchLocations,
  });

  const getAllLocations = useCallback(async () => {
    setShouldFetchLocations(true);
    return dispensaryChainLocations ?? [];
  }, [dispensaryChainLocations]);

  const remoteData = prepareDataForRemote({
    menuContext: UI.isKiosk ? 'kiosk' : 'store-front',
    location: dispensary,
    user: userDataMapped,
    cart: cartDataMapped,
    dataLoaders: {
      getAllLocations,
    },
    actions: {
      goToInfoPage: () => router.push(`/stores/${dispensary?.cName}/info`),
      goToStoreLocator: () => router.push(`/stores/${dispensary?.cName}/locations`),
      goToStoreBrowser: () => router.push(`/stores/${dispensary?.cName}/locations/browse`),
      goToStoreFront: () => router.push(`/stores/${dispensary?.cName}`),
      goToLogin: () => openModal(ModalNames.login),
      goToRegister: () => openModal(ModalNames.signUp),
    },
  });

  const getCommerceComponentsTheme = (styledTheme) => ({
    components: {
      storeFront: {
        header: ({ fallback }) =>
          remoteComponentIsValid(remoteThemeModule.StoreFrontHeader)
            ? withProps(remoteThemeModule.StoreFrontHeader, { theme: styledTheme, data: remoteData })
            : fallback,
        footer: ({ fallback }) =>
          remoteComponentIsValid(remoteThemeModule.StoreFrontFooter)
            ? withProps(remoteThemeModule.StoreFrontFooter, { theme: styledTheme, data: remoteData })
            : fallback,
      },
    },
  });

  return {
    getCommerceComponentsTheme,
    remoteThemeModule,
    themeNameSpace: customizationProvider?.themeName,
  };
}
