import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _invert from 'lodash/invert';
export var countryMap = {
  US: 'United States',
  CA: 'Canada',
  JM: 'Jamaica',
  VC: 'Saint Vincent and the Grenadines',
  PR: 'Puerto Rico'
};
export var JamaicanParishesMap = {
  '01': 'Kingston Parish',
  '02': 'St. Andrew Parish',
  '03': 'St. Thomas Parish',
  '04': 'Portland Parish',
  '05': 'St. Mary Parish',
  '06': 'St. Ann Parish',
  '07': 'Trelawny Parish',
  '08': 'St. James Parish',
  '09': 'Hanover Parish',
  10: 'Westmoreland Parish',
  11: 'St. Elizabeth Parish',
  12: 'Manchester Parish',
  13: 'Clarendon',
  14: 'St. Catherine Parish'
};
export var saintVincentAndTheGrenadinesParishesMap = {
  VC: 'St. Vincent and the Grenadines' // VC is a single compliance area.  Using the ISO country code for 'state'.
};
export var countryMapToShortened = _invert(countryMap);
export var canadianProvincesMap = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Québec',
  SK: 'Saskatchewan',
  YT: 'Yukon'
};
export var puertoRicoMap = {
  Humacao: 'Humacao',
  Naguabo: 'Naguabo'
};
export var canadianProvincesMapToShortened = _objectSpread(_objectSpread({}, _invert(canadianProvincesMap)), {}, {
  Quebec: 'QC'
});
export var USStatesMap = {
  AL: 'Alabama',
  AK: 'Alaska',
  AR: 'Arkansas',
  AS: 'American Samoa',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  'San Juan': 'San Juan',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming'
};
export var USStatesMapToShortened = _objectSpread(_objectSpread({}, _invert(USStatesMap)), {}, {
  DC: 'DC'
});
export var statesMap = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, USStatesMap), canadianProvincesMap), saintVincentAndTheGrenadinesParishesMap), puertoRicoMap);
export var supportedCountries = ['US', 'PR', 'CA', 'JM', 'VC'];