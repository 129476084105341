import { getComplianceConfig } from 'shared/core/helpers/compliance';

type UseGetRetailerLabelReturn = {
  retailerLabel: string;
  pluralRetailerLabel: string;
};

export const useGetRetailerLabel = (dispensaryState: string | null): UseGetRetailerLabelReturn => {
  const retailerLabel = getComplianceConfig(dispensaryState, 'retailerLabel');
  const pluralRetailerLabel = getComplianceConfig(dispensaryState, 'pluralRetailerLabel');

  return { retailerLabel, pluralRetailerLabel };
};
