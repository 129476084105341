import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
import { CANNABIS_TYPES } from 'shared/core/constants/product';
import { getComplianceConfig } from 'shared/core/helpers/compliance';
import { MG_TO_G, POTENCY_TYPES } from 'shared/constants';
import { safeToBig } from 'shared/helpers/utils_math';
import { getKeyFromTAC, getProductWeight, getEquivalent, getProductNetWeightInGrams, getProductVolumeInGrams } from 'shared/helpers/products';
import { CONCENTRATE_TYPES, EDIBLE_TYPES, TINCTURE_TYPES, TOPICAL_TYPES, GRAMS_PER_OUNCE } from './constants';
import { getStateLimits, getTypeLimits, getLimitMessage, getLimitForType, isFlower, isConcentrate, isEdible, isTincture, isTopical, isWeightedProduct, isLiquidTypeProduct, isSolidEdibleTypeProduct, formatDisplayedType } from './utils';
import { checkSolidEdibleNetWeight } from './check-solid-edible-net-weight';
import { checkLiquidEdibleVolume } from './check-liquid-edible-volume';
export var shouldCombine = function shouldCombine(limits, medical) {
  if (medical) {
    return limits.medUsesEquivalencyCalculator;
  }
  return limits.usesEquivalencyCalculator;
};

/**
 * This function is used to check the current product type and its subcategory
 * against any type maps that exist in a state/region's compliance configuration
 */
var isTypeMapType = function isTypeMapType() {
  var typeFromCartProduct = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var subcategoryFromCartProduct = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var limits = arguments.length > 2 ? arguments[2] : undefined;
  var category = arguments.length > 3 ? arguments[3] : undefined;
  var categories = _.get(limits, "typeMap.".concat(category, ".categories"), []);
  var subcategories = _.get(limits, "typeMap.".concat(category, ".subcategories"), []);
  var allCategoriesForType = [].concat(_toConsumableArray(categories), _toConsumableArray(subcategories));
  return _.includes(allCategoriesForType, typeFromCartProduct) || _.includes(allCategoriesForType, subcategoryFromCartProduct);
};
var isIgnoreTypeMapType = function isIgnoreTypeMapType(typeFromCartProduct, subcategoryFromCartProduct, limits, category) {
  var ignoredCategories = _.get(limits, "ignoreTypeMap.".concat(category, ".categories"), []);
  var ignoredSubcategories = _.get(limits, "ignoreTypeMap.".concat(category, ".subcategories"), []);
  var allIgnoredCategoriesForType = [].concat(_toConsumableArray(ignoredCategories), _toConsumableArray(ignoredSubcategories));
  return _.includes(allIgnoredCategoriesForType, typeFromCartProduct) || _.includes(allIgnoredCategoriesForType, subcategoryFromCartProduct);
};
export var getMultiplier = function getMultiplier(productType, limits, medical) {
  var subcategory = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  if (shouldCombine(limits, medical)) {
    var multType = medical ? 'med' : 'rec';
    // Some states have nested multipliers specific for rec/med
    // Use the nested multiplier if it exists
    // If not, then use the non-nested multiplier
    if (isFlower(productType) && !isIgnoreTypeMapType(productType, subcategory, limits, 'Flower')) {
      return 1;
    }
    if (limits.usesSolidEdibleTypes && isSolidEdibleTypeProduct(productType, subcategory)) {
      return _.get(limits.solidNetWeightMultiplier, multType, limits.solidNetWeightMultiplier) || 0;
    }
    if (limits.usesLiquidTypes && isLiquidTypeProduct(limits, productType, subcategory)) {
      return _.get(limits.liquidNetWeightMultiplier, multType, limits.liquidNetWeightMultiplier) || 0;
    }
    if (_.includes(CONCENTRATE_TYPES, productType) || isTypeMapType(productType, subcategory, limits, 'Concentrate')) {
      return _.get(limits.concentrateMultiplier, multType, limits.concentrateMultiplier) || 0;
    }
    if (_.includes(EDIBLE_TYPES, productType) || isTypeMapType(productType, subcategory, limits, 'Edible')) {
      return _.get(limits.edibleMultiplier, multType, limits.edibleMultiplier) || 0;
    }
    if (_.includes(TINCTURE_TYPES, productType) || isTypeMapType(productType, subcategory, limits, 'Tincture')) {
      return _.get(limits.tinctureMultiplier, multType, limits.tinctureMultiplier) || 0;
    }
    if (_.includes(TOPICAL_TYPES, productType) || isTypeMapType(productType, subcategory, limits, 'Topicals')) {
      return _.get(limits.topicalMultiplier, multType, limits.topicalMultiplier) || 0;
    }
  }
  if (isFlower(productType)) {
    return 1;
  }
  return 0;
};
export var cartHasMix = function cartHasMix(cart) {
  var limits = cart.limits;
  var productTypes = _.uniq(_.map(cart.orders, function (_ref) {
    var product = _ref.product;
    return product.type;
  }));
  var productSubcategories = _.uniq(_.map(cart.orders, function (_ref2) {
    var product = _ref2.product;
    return product.subcategory;
  }));
  var allTypes = [].concat(_toConsumableArray(productTypes), _toConsumableArray(productSubcategories));
  var hasConcentrates = !!_.find(allTypes, isConcentrate) || !!_.find(allTypes, function (type) {
    return isTypeMapType(type, type, limits, 'Concentrate');
  });
  var hasFlower = !!_.find(allTypes, isFlower) || !!_.find(allTypes, function (type) {
    return isTypeMapType(type, type, limits, 'Flower');
  });
  var hasEdibles = !!_.find(allTypes, isEdible) || !!_.find(allTypes, function (type) {
    return isTypeMapType(type, type, limits, 'Edible');
  });
  var hasTinctures = !!_.find(allTypes, isTincture) || !!_.find(allTypes, function (type) {
    return isTypeMapType(type, type, limits, 'Tincture');
  });
  var hasTopicals = !!_.find(allTypes, isTopical) || !!_.find(allTypes, function (type) {
    return isTypeMapType(type, type, limits, 'Topicals');
  });
  return _.compact([hasFlower, hasConcentrates, hasEdibles, hasTinctures, hasTopicals]).length > 1;
};
var getPotencyRanges = function getPotencyRanges(product) {
  var _product$THCContent, _product$CBDContent, _ref3;
  if (!product) {
    return {};
  }
  var cannabinoids = _.get(product, 'cannabinoidsV2', []);
  var tacPotencyValue = getKeyFromTAC(cannabinoids, "value", 0);
  var tacPotencyRangeV2 = [tacPotencyValue, tacPotencyValue];
  var thcPotencyUnit = _.get(product, 'THCContent.unit');
  var thcPotencyRange;
  var netWeightInMg = _.get(product, 'measurements.netWeight.values[0]');
  if (thcPotencyUnit === 'PERCENTAGE' && _.isArray(product === null || product === void 0 ? void 0 : (_product$THCContent = product.THCContent) === null || _product$THCContent === void 0 ? void 0 : _product$THCContent.range) && netWeightInMg) {
    var range = product.THCContent.range.map(function (percentage) {
      return parseFloat(percentage) / 100 * netWeightInMg;
    });
    thcPotencyRange = range;
  } else {
    var _product$THCContent2;
    thcPotencyRange = _.isArray(product === null || product === void 0 ? void 0 : (_product$THCContent2 = product.THCContent) === null || _product$THCContent2 === void 0 ? void 0 : _product$THCContent2.range) ? product.THCContent.range : [0, 0];
  }
  var cbdPotencyRange;
  var cbdPotencyUnit = _.get(product, 'CBDContent.unit');
  if (cbdPotencyUnit === 'PERCENTAGE' && _.isArray(product === null || product === void 0 ? void 0 : (_product$CBDContent = product.CBDContent) === null || _product$CBDContent === void 0 ? void 0 : _product$CBDContent.range) && netWeightInMg) {
    var _range = product.CBDContent.range.map(function (percentage) {
      return parseFloat(percentage) / 100 * netWeightInMg;
    });
    cbdPotencyRange = _range;
  } else {
    var _product$CBDContent2;
    cbdPotencyRange = _.isArray(product === null || product === void 0 ? void 0 : (_product$CBDContent2 = product.CBDContent) === null || _product$CBDContent2 === void 0 ? void 0 : _product$CBDContent2.range) ? product.CBDContent.range : [0, 0];
  }

  // for each cannabinoid, if range does not have two values, give it two values
  if (cbdPotencyRange.length !== 2) {
    cbdPotencyRange = [0].concat(_toConsumableArray(cbdPotencyRange));
  }
  if (thcPotencyRange.length !== 2) {
    thcPotencyRange = [0].concat(_toConsumableArray(thcPotencyRange));
  }
  return _ref3 = {}, _defineProperty(_ref3, POTENCY_TYPES.CBD, cbdPotencyRange), _defineProperty(_ref3, POTENCY_TYPES.THC, thcPotencyRange), _defineProperty(_ref3, POTENCY_TYPES.TAC, tacPotencyRangeV2), _ref3;
};
var getPotencyValues = function getPotencyValues(product) {
  var _ref4;
  if (!product) {
    return {};
  }
  var ranges = getPotencyRanges(product);
  return _ref4 = {}, _defineProperty(_ref4, POTENCY_TYPES.CBD, _.max(ranges[POTENCY_TYPES.CBD])), _defineProperty(_ref4, POTENCY_TYPES.THC, _.max(ranges[POTENCY_TYPES.THC])), _defineProperty(_ref4, POTENCY_TYPES.TAC, _.max(ranges[POTENCY_TYPES.TAC])), _ref4;
};
export var getProductTHCInGrams = function getProductTHCInGrams(_ref5) {
  var product = _ref5.product,
    quantity = _ref5.quantity;
  var defaultWeight = product.defaultWeight;
  return safeToBig(quantity).times(Number(getPotencyValues(product).THC) || defaultWeight).div(MG_TO_G);
};
export var getProductTHCBasedOnWeight = function getProductTHCBasedOnWeight(_ref6) {
  var product = _ref6.product,
    quantity = _ref6.quantity;
  var defaultWeight = product.defaultWeight,
    Options = product.Options;
  var weight = getProductWeight(Options, defaultWeight, quantity);
  return weight;
};
export var getFlowerEquivalent = function getFlowerEquivalent(order, medical) {
  var _product$multiplier;
  var limits = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var product = order.product,
    option = order.option,
    quantity = order.quantity;
  var defaultWeight = product.defaultWeight,
    type = product.type,
    subcategory = product.subcategory;
  var _limits$baseConcentra = limits.baseConcentrateOffWeight,
    baseConcentrateOffWeight = _limits$baseConcentra === void 0 ? true : _limits$baseConcentra,
    _limits$baseTinctures = limits.baseTincturesOffWeight,
    baseTincturesOffWeight = _limits$baseTinctures === void 0 ? false : _limits$baseTinctures;
  var multiplier = (_product$multiplier = product.multiplier) !== null && _product$multiplier !== void 0 ? _product$multiplier : 1;
  var standardEquivalent = getEquivalent(product, option, quantity);
  var recEquivalent = getEquivalent(product, option, quantity, 'recEquivalent');
  /*
   * This logic is just to allow IL dispensaries to pass the standardEquivalent field on products
   * even though it shouldn't be used due to their config using equivalency for medical but not recreational
   * orders. IL is the only state config that includes the limits.ignoreStandardEquivalentField
   * - Mack H. 11/5/21
   */
  var allowStandardEquivalentFieldUse = !(limits !== null && limits !== void 0 && limits.ignoreStandardEquivalentField);
  if (_.isFinite(recEquivalent) && !medical) {
    return recEquivalent;
  }
  if (_.isFinite(standardEquivalent) && allowStandardEquivalentFieldUse) {
    return standardEquivalent;
  }
  var weightInGrams;
  if (limits !== null && limits !== void 0 && limits.solidNetWeightMultiplier && isSolidEdibleTypeProduct(type, subcategory)) {
    weightInGrams = getProductNetWeightInGrams(product) * quantity;
    return parseFloat(safeToBig(weightInGrams).times(multiplier));
  }
  if (limits !== null && limits !== void 0 && limits.liquidNetWeightMultiplier && isLiquidTypeProduct(limits, type, subcategory)) {
    weightInGrams = getProductVolumeInGrams(product) * quantity;
    /*
     * Sometimes users of TechPOS will still use the netWeight in measurements rather than volume as suggested
     * so this just acts as a double check for that scenario
     */

    if (weightInGrams === 0) {
      weightInGrams = getProductNetWeightInGrams(product) * quantity;
    }
    return parseFloat(safeToBig(weightInGrams).times(multiplier));
  }
  weightInGrams = isEdible(type) || isTincture(type) && !baseTincturesOffWeight || isTopical(type) || isConcentrate(type) && !baseConcentrateOffWeight ? getProductTHCInGrams(order) : getProductWeight(option, defaultWeight, quantity);
  return parseFloat(safeToBig(weightInGrams).times(multiplier));
};
export var getEdibleTHCInGrams = function getEdibleTHCInGrams(order) {
  var type = order.product.type;
  return isEdible(type) ? getProductTHCInGrams(order) : 0;
};
export var getTinctureTHCInGrams = function getTinctureTHCInGrams(order) {
  var _ref7 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    _ref7$baseTincturesOf = _ref7.baseTincturesOffWeight,
    baseTincturesOffWeight = _ref7$baseTincturesOf === void 0 ? false : _ref7$baseTincturesOf;
  var type = order.product.type;
  if (baseTincturesOffWeight) {
    return isTincture(type) ? getProductTHCBasedOnWeight(order) : 0;
  }
  return isTincture(type) ? getProductTHCInGrams(order) : 0;
};
export var getTopicalTHCInGrams = function getTopicalTHCInGrams(order) {
  var type = order.product.type;
  return isTopical(type) ? getProductTHCInGrams(order) : 0;
};
export var getTotalFlowerInGrams = function getTotalFlowerInGrams(_ref8) {
  var limits = _ref8.limits,
    orders = _ref8.orders,
    medical = _ref8.medical;
  return Number(_.reduce(orders, function (previous, order) {
    var _limits$ignoreTypeMap, _limits$ignoreTypeMap2;
    /**
     * Prevent any excluded subcategories from triggering state flower limits unless
     * we still need to check equivalency. For example in NV infused pre-rolls are counted as
     * concentrates but need to still count to the overall flower limit
     */
    var subcategory = order.product.subcategory;
    if (_.includes(limits === null || limits === void 0 ? void 0 : (_limits$ignoreTypeMap = limits.ignoreTypeMap) === null || _limits$ignoreTypeMap === void 0 ? void 0 : (_limits$ignoreTypeMap2 = _limits$ignoreTypeMap.Flower) === null || _limits$ignoreTypeMap2 === void 0 ? void 0 : _limits$ignoreTypeMap2.subcategories, subcategory) && !shouldCombine(limits, medical)) {
      return safeToBig(previous);
    }
    return safeToBig(previous).plus(safeToBig(getFlowerEquivalent(order, medical, limits)));
  }, 0));
};
export var getTotalTinctureTHCInGrams = function getTotalTinctureTHCInGrams(_ref9) {
  var orders = _ref9.orders,
    limits = _ref9.limits;
  return Number(_.reduce(orders, function (previous, order) {
    return safeToBig(previous).plus(safeToBig(getTinctureTHCInGrams(order, limits)));
  }, 0.0));
};
export var getTotalTopicalTHCInGrams = function getTotalTopicalTHCInGrams(_ref10) {
  var orders = _ref10.orders,
    _ref10$limits = _ref10.limits,
    limits = _ref10$limits === void 0 ? {} : _ref10$limits;
  return Number(_.reduce(orders, function (previous, order) {
    var _limits$ignoreTypeMap3, _limits$ignoreTypeMap4, _limits$ignoreTypeMap5, _limits$ignoreTypeMap6;
    var product = order.product;
    var type = product.type,
      subcategory = product.subcategory;
    var categoryIgnored = _.includes(limits === null || limits === void 0 ? void 0 : (_limits$ignoreTypeMap3 = limits.ignoreTypeMap) === null || _limits$ignoreTypeMap3 === void 0 ? void 0 : (_limits$ignoreTypeMap4 = _limits$ignoreTypeMap3.Topicals) === null || _limits$ignoreTypeMap4 === void 0 ? void 0 : _limits$ignoreTypeMap4.categories, type);
    var subcategoryIgnored = _.includes(limits === null || limits === void 0 ? void 0 : (_limits$ignoreTypeMap5 = limits.ignoreTypeMap) === null || _limits$ignoreTypeMap5 === void 0 ? void 0 : (_limits$ignoreTypeMap6 = _limits$ignoreTypeMap5.Topicals) === null || _limits$ignoreTypeMap6 === void 0 ? void 0 : _limits$ignoreTypeMap6.subcategories, subcategory);
    var shouldCheckWeightForType = !categoryIgnored && !subcategoryIgnored;
    return shouldCheckWeightForType ? safeToBig(previous).plus(safeToBig(getTopicalTHCInGrams(order))) : safeToBig(previous);
  }, 0.0));
};
export var getTotalEdibleTHCInGrams = function getTotalEdibleTHCInGrams(_ref11) {
  var orders = _ref11.orders,
    _ref11$limits = _ref11.limits,
    limits = _ref11$limits === void 0 ? {} : _ref11$limits;
  return Number(_.reduce(orders, function (previous, order) {
    var product = order.product;
    var type = product.type,
      subcategory = product.subcategory;
    var _limits$baseEdiblesOf = limits.baseEdiblesOffWeight,
      baseEdiblesOffWeight = _limits$baseEdiblesOf === void 0 ? false : _limits$baseEdiblesOf;
    var edibleCategories = _.get(limits, 'typeMap.Edible.categories', EDIBLE_TYPES);
    var edibleSubcategories = _.get(limits, 'typeMap.Edible.subcategories', []);
    if (baseEdiblesOffWeight) {
      return _.includes(edibleCategories, type) || _.includes(edibleSubcategories, subcategory) ? safeToBig(previous).plus(safeToBig(getProductTHCBasedOnWeight(order))) : safeToBig(previous);
    }
    return _.includes(edibleCategories, type) || _.includes(edibleSubcategories, subcategory) ? safeToBig(previous).plus(safeToBig(getProductTHCInGrams(order))) : safeToBig(previous);
  }, 0.0));
};
export var getTotalConcentrateInGrams = function getTotalConcentrateInGrams(_ref12) {
  var orders = _ref12.orders,
    _ref12$limits = _ref12.limits,
    limits = _ref12$limits === void 0 ? {} : _ref12$limits,
    medical = _ref12.medical,
    state = _ref12.state;
  return Number(_.reduce(orders, function (previous, order) {
    var _limits$ignoreTypeMap7, _limits$ignoreTypeMap8, _limits$ignoreTypeMap9, _limits$ignoreTypeMap10;
    var product = order.product,
      quantity = order.quantity,
      option = order.option;
    var defaultWeight = product.defaultWeight,
      type = product.type,
      subcategory = product.subcategory;
    var _limits$baseConcentra2 = limits.baseConcentrateOffWeight,
      baseConcentrateOffWeight = _limits$baseConcentra2 === void 0 ? true : _limits$baseConcentra2;
    var isWeighted = isWeightedProduct(product);
    var concentrateCategories = _.get(limits, 'typeMap.Concentrate.categories', CONCENTRATE_TYPES);
    var concentrateSubcategories = _.get(limits, 'typeMap.Concentrate.subcategories', []);
    var categoryIgnored = _.includes(limits === null || limits === void 0 ? void 0 : (_limits$ignoreTypeMap7 = limits.ignoreTypeMap) === null || _limits$ignoreTypeMap7 === void 0 ? void 0 : (_limits$ignoreTypeMap8 = _limits$ignoreTypeMap7.Concentrate) === null || _limits$ignoreTypeMap8 === void 0 ? void 0 : _limits$ignoreTypeMap8.categories, subcategory);
    var subcategoryIgnored = _.includes(limits === null || limits === void 0 ? void 0 : (_limits$ignoreTypeMap9 = limits.ignoreTypeMap) === null || _limits$ignoreTypeMap9 === void 0 ? void 0 : (_limits$ignoreTypeMap10 = _limits$ignoreTypeMap9.Concentrate) === null || _limits$ignoreTypeMap10 === void 0 ? void 0 : _limits$ignoreTypeMap10.subcategories, subcategory);
    var shouldCheckWeightForType = !categoryIgnored && !subcategoryIgnored;
    var isBritishColumbiaDispensary = state === 'BC';
    var flowerEquivalent = getFlowerEquivalent(order, medical, limits);
    var useEquivalent = isBritishColumbiaDispensary && !!flowerEquivalent;
    var productWeight = baseConcentrateOffWeight && isWeighted ? getProductWeight(option, defaultWeight, quantity) : getProductTHCInGrams(order);
    return shouldCheckWeightForType && _.includes(concentrateCategories, type) || _.includes(concentrateSubcategories, subcategory) ? safeToBig(previous).plus(safeToBig(useEquivalent ? flowerEquivalent : productWeight)) : safeToBig(previous);
  }, 0.0));
};
export var getSerializedOrders = function getSerializedOrders(_ref13) {
  var cart = _ref13.cart,
    limits = _ref13.limits,
    medical = _ref13.medical,
    state = _ref13.state;
  var defaultOptionWeights = getComplianceConfig(state, 'defaults');
  var applicableOrders = _.filter(cart, function (_ref14) {
    var product = _ref14.product;
    return _.includes(CANNABIS_TYPES, product.type);
  });
  return _.map(applicableOrders, function (order) {
    var product = order.product,
      quantity = order.quantity,
      option = order.option;
    var type = product.type,
      subcategory = product.subcategory;
    var serializedProduct = _objectSpread(_objectSpread({}, product), {}, {
      multiplier: getMultiplier(type, limits, medical, subcategory),
      defaultWeight: defaultOptionWeights[type]
    });
    return {
      product: serializedProduct,
      quantity: quantity,
      option: option
    };
  });
};
export var getSerializedCart = function getSerializedCart(_ref15) {
  var state = _ref15.state,
    cart = _ref15.cart,
    purchaseState = _ref15.purchaseState,
    medical = _ref15.medical;
  var limits = getStateLimits(state, purchaseState);
  var orders = getSerializedOrders({
    cart: cart,
    limits: limits,
    medical: medical,
    state: state
  });
  return {
    orders: orders,
    state: state,
    limits: limits,
    medical: medical
  };
};
export var checkFlowerLimit = function checkFlowerLimit(cart) {
  var limits = cart.limits,
    medical = cart.medical,
    state = cart.state;
  var _getTypeLimits = getTypeLimits(cart),
    flowerLimit = _getTypeLimits.flowerLimit;
  var mixedTypesInCart = cartHasMix(cart);
  var shouldCombineAmounts = shouldCombine(limits, medical);
  var isMixOrder = mixedTypesInCart && shouldCombineAmounts;
  // This was added to allow for product specific limit messages even when equivalency is triggered for
  // orders that are of a single product to improve compliance messaging in Canada - Mack H. 3/15/2021
  var isEquivalencyWithSingleType = !mixedTypesInCart && shouldCombineAmounts;
  var limitMessage = getLimitMessage("".concat(isMixOrder ? 'cannabis' : 'flower'), flowerLimit, state);
  if (isEquivalencyWithSingleType) {
    var productTypeAndSubcategory = _.head(_.map(cart.orders, function (_ref16) {
      var product = _ref16.product;
      return {
        type: product.type,
        subcategory: product.subcategory
      };
    }));
    var productLimit = getLimitForType(productTypeAndSubcategory, cart);
    var typeToDisplay = productTypeAndSubcategory.type;
    if (limits.usesLiquidTypes && isLiquidTypeProduct(limits, typeToDisplay, productTypeAndSubcategory.subcategory)) {
      typeToDisplay = 'liquids';
      productLimit *= GRAMS_PER_OUNCE;
    }
    if (productTypeAndSubcategory.type !== 'Seeds') {
      limitMessage = getLimitMessage(formatDisplayedType(typeToDisplay), productLimit, state);
    } else {
      limitMessage = getLimitMessage('cannabis', flowerLimit, state);
    }
  }
  var totalFlowerInGrams = getTotalFlowerInGrams(cart);
  return totalFlowerInGrams > flowerLimit ? {
    type: 'Flower',
    total: totalFlowerInGrams,
    message: limitMessage,
    withinLimits: false
  } : {
    withinLimits: true
  };
};
export var checkEdibleTHCLimit = function checkEdibleTHCLimit(cart) {
  var state = cart.state;
  var _getTypeLimits2 = getTypeLimits(cart),
    edibleTHCLimit = _getTypeLimits2.edibleTHCLimit;
  var totalEdibleTHCInGrams = getTotalEdibleTHCInGrams(cart);
  return totalEdibleTHCInGrams > edibleTHCLimit ? {
    type: 'Edible',
    total: totalEdibleTHCInGrams,
    message: getLimitMessage('edible THC', edibleTHCLimit, state, 'mg'),
    withinLimits: false
  } : {
    withinLimits: true
  };
};
export var checkConcentrateLimit = function checkConcentrateLimit(cart) {
  var state = cart.state;
  var _getTypeLimits3 = getTypeLimits(cart),
    concentrateLimit = _getTypeLimits3.concentrateLimit;
  var totalConcentrateInGrams = getTotalConcentrateInGrams(cart);
  return totalConcentrateInGrams > concentrateLimit ? {
    type: 'Concentrate',
    total: totalConcentrateInGrams,
    message: getLimitMessage('concentrates', concentrateLimit, state),
    withinLimits: false
  } : {
    withinLimits: true
  };
};
export var checkTinctureLimit = function checkTinctureLimit(cart) {
  var state = cart.state;
  var _getTypeLimits4 = getTypeLimits(cart),
    tinctureLimit = _getTypeLimits4.tinctureLimit;
  var totalTinctureTHCInGrams = getTotalTinctureTHCInGrams(cart);
  return totalTinctureTHCInGrams > tinctureLimit ? {
    type: 'Tincture',
    total: totalTinctureTHCInGrams,
    message: getLimitMessage('tinctures', tinctureLimit, state),
    withinLimits: false
  } : {
    withinLimits: true
  };
};
export var checkTopicalLimit = function checkTopicalLimit(cart) {
  var state = cart.state;
  var _getTypeLimits5 = getTypeLimits(cart),
    topicalLimit = _getTypeLimits5.topicalLimit;
  var totalTopicalTHCInGrams = getTotalTopicalTHCInGrams(cart);
  return totalTopicalTHCInGrams > topicalLimit ? {
    type: 'Topicals',
    total: totalTopicalTHCInGrams,
    message: getLimitMessage('topicals', topicalLimit, state),
    withinLimits: false
  } : {
    withinLimits: true
  };
};
export var checkNonFlowerLimit = function checkNonFlowerLimit(cart) {
  var state = cart.state;
  var _getTypeLimits6 = getTypeLimits(cart),
    nonFlowerLimit = _getTypeLimits6.nonFlowerLimit;
  if (nonFlowerLimit) {
    // choose any of these non-flower limits as our baseline for comparison
    var totalTopicalTHCInGrams = getTotalTopicalTHCInGrams(cart);
    var totalTinctureTHCInGrams = getTotalTinctureTHCInGrams(cart);
    var totalConcentrateInGrams = getTotalConcentrateInGrams(cart);
    var totalEdibleTHCInGrams = getTotalEdibleTHCInGrams(cart);
    var nonFlowerTHCSum = totalTopicalTHCInGrams + totalTinctureTHCInGrams + totalConcentrateInGrams + totalEdibleTHCInGrams;
    return nonFlowerTHCSum > nonFlowerLimit ? {
      type: 'Non-Flower',
      total: nonFlowerTHCSum,
      message: getLimitMessage('non-flower items', nonFlowerLimit, state),
      withinLimits: false
    } : {
      withinLimits: true
    };
  }
  return {
    withinLimits: true
  };
};
var updateLimits = function updateLimits(cart, dispensary) {
  var usePotencyForConcentrateLimits = _.get(dispensary, 'storeSettings.usePotencyForConcentrateLimits', false);
  var state = cart.state;
  if (state === 'MA') {
    cart.limits.baseConcentrateOffWeight = !usePotencyForConcentrateLimits;
  }
  return cart;
};
export var checkLimits = function checkLimits(orderData) {
  var cart = getSerializedCart(orderData);
  var _orderData$dispensary = orderData.dispensary,
    dispensary = _orderData$dispensary === void 0 ? {} : _orderData$dispensary;
  cart = updateLimits(cart, dispensary);
  var messageOverride = _.get(cart, 'limits.message', null);
  if (_.isEmpty(cart.orders)) {
    return {
      withinLimits: true
    };
  }
  return _.reduce([checkFlowerLimit, checkNonFlowerLimit, checkEdibleTHCLimit, checkConcentrateLimit, checkTinctureLimit, checkTopicalLimit, checkSolidEdibleNetWeight, checkLiquidEdibleVolume], function (previous, limitChecker) {
    var result = limitChecker(cart);
    if (messageOverride) {
      result.message = messageOverride;
    }
    if (!result.withinLimits && result.type) {
      result.typesOverLimit = previous.typesOverLimit ? [].concat(_toConsumableArray(previous.typesOverLimit), [result.type]) : [result.type];
    }
    return !result.withinLimits ? result : previous;
  }, {
    withinLimits: true
  });
};