import _ from 'lodash';
import { activeProductsCheck } from 'shared/graphql/product/queries';
import {
  afterHoursEnabledForDelivery,
  afterHoursEnabledForPickup,
  scheduledOrderingEnabledForDelivery,
} from 'shared/helpers/dispensaries';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import { getQuantityRemaining } from 'shared/helpers/products';
import { checkAllLimits } from 'shared/helpers/purchase-limits';

function mapCartToPrices(cart) {
  return _.map(cart, (item, key) => [key, item.price, item.product.Name]);
}

export const ERR_EMPTY_CART = 1;
export const ERR_CLOSED = 2;
export const ERR_MISSING_ITEM = 3;
export const ERR_PRICE_CHANGE = 4;
export const ERR_OVER_QUANTITY = 5;

/*
 * This function should alert the user directly. It should not add errors to checkout context.
 *
 * called via src/checkout/actions/validateSubmit.js 25
 * called via src/cart/index.jsx 25 <- called via popup & cart slideout
 */
export default async function validateCart({ Cart, UI, apolloClient, showErnie, isVariant, checkOnDuty }) {
  const { cart: cartItems, dispensary, customerState } = Cart.order;
  const openInfo = openInfoForDispensary(dispensary, { previewMode: UI.previewMode });

  if (!cartItems) {
    showErnie('No items in cart!');
    return {
      proceed: false,
      code: ERR_EMPTY_CART,
    };
  }

  const { inStorePickup, curbsidePickup, driveThruPickup, delivery } = openInfo;

  const enableAfterHoursOrderingForPickup = afterHoursEnabledForPickup(dispensary);
  const enableAfterHoursOrderingForDelivery = afterHoursEnabledForDelivery(dispensary);
  const enableScheduledOrderingForDelivery = scheduledOrderingEnabledForDelivery(dispensary);

  const pickupIsClosed = inStorePickup.isClosed && curbsidePickup.isClosed && driveThruPickup.isClosed;

  const deliveryIsClosed = delivery.isClosed;

  const closeCart = (err) => {
    UI.viewingCart = false;
    Cart.viewingClosedModal = true;
    showErnie(err);
  };

  if (checkOnDuty && dispensary?.temporalLocation?.onDuty === false) {
    showErnie(`We're sorry, the dispensaries in your area are currently closed for online ordering.`);
    return {
      proceed: false,
      code: ERR_CLOSED,
    };
  }

  if (
    pickupIsClosed &&
    deliveryIsClosed &&
    !enableAfterHoursOrderingForPickup &&
    !(enableAfterHoursOrderingForDelivery && enableScheduledOrderingForDelivery)
  ) {
    closeCart(`This dispensary is closed`);
    return {
      proceed: false,
      code: ERR_CLOSED,
    };
  }

  const isDeliveryAndClosed = Cart.orderType === 'delivery' && deliveryIsClosed;

  const isPickupAndClosed = Cart.orderType === 'pickup' && inStorePickup.isClosed;

  const isCurbsideAndClosed = Cart.orderType === 'curbsidePickup' && curbsidePickup.isClosed;

  const isDriveThruAndClosed = Cart.orderType === 'driveThruPickup' && driveThruPickup.isClosed;

  // If the ASAP fulfillment method is closed when trying to place an ASAP order, show the closed modal
  if (
    Cart.reservationType === 'asap' &&
    (isPickupAndClosed || isDeliveryAndClosed || isCurbsideAndClosed || isDriveThruAndClosed)
  ) {
    const errorMsg = {
      curbsidePickup: 'Curbside Pickup',
      driveThruPickup: 'Drive-Thru Pickup',
      pickup: 'In-store Pickup',
      delivery: 'Delivery',
    };
    closeCart(
      `This dispensary is closed for ASAP ${errorMsg[Cart.orderType]} Orders. Please try a different fulfillment type.`
    );
    return {
      proceed: false,
      code: ERR_CLOSED,
    };
  }

  const isKiosk = isVariant(`kiosk`);

  const ids = _.uniq(_.map(_.keys(cartItems), (key) => cartItems[key].product._id));
  const {
    data: { activeProductsCheck: products },
  } = await apolloClient.query({
    fetchPolicy: `no-cache`,
    query: activeProductsCheck,
    variables: {
      productsFilter: {
        ids,
        dispensaryId: dispensary.id,
        isKiosk,
      },
    },
  });

  /** This handles removing inactive products and products from the
   * wrong dispo (which shouldn't happen anyway)
   */
  let validItems = _.omitBy(cartItems, (item, key) => {
    item.key = key;
    return !_.find(products, (p) => p._id === item.product._id && _.includes(p.Options, item.option));
  });

  validItems = _.uniqBy(_.values(validItems), (item) => item.product._id);
  if (_.size(validItems) !== ids.length) {
    if (products.length === 0) {
      Cart.clearOrder();
      showErnie('The only item in your cart has sold out and been removed.', `info`, 9000);
      return {
        proceed: false,
        code: ERR_MISSING_ITEM,
        outOfStockItem: true,
        returnToMenu: true,
        dispensaryCName: dispensary.cName,
      };
    }

    _.forEach(_.keys(cartItems), (key) => {
      const validItem = _.find(validItems, [`key`, key]);
      if (!validItem) {
        Cart.removeItem(key);
      }
    });

    showErnie('An item in your cart has sold out and been removed.');
    return {
      proceed: false,
      code: ERR_MISSING_ITEM,
      outOfStockItem: true,
    };
  }

  /** This handles a dispo updating the price of an item
   * when a user has it in their cart already
   */
  const localPrices = mapCartToPrices(cartItems);
  const localCostBreakdown = { ...Cart.costBreakdown };
  _.forEach(cartItems, (localProduct, key) => {
    const dbProduct = _.find(products, [`_id`, localProduct.product._id]);
    Cart.updateProduct(key, {
      ...localProduct,
      product: { ...localProduct.product, ...dbProduct, specialData: localProduct.product.specialData },
    });
  });
  const dbPrices = mapCartToPrices(cartItems);
  const dbCostBreakdown = { ...Cart.costBreakdown };

  if (localCostBreakdown.subtotal !== dbCostBreakdown.subtotal) {
    let priceDiscrepancy = 'Cart subtotal has changed';

    _.forEach(localPrices, (entry) => {
      const [_dbKey, dbPrice, dbName] = _.find(dbPrices, (e) => e[0] === entry[0]);
      priceDiscrepancy = `${dbName} price has changed to $${dbPrice.toFixed(2)}`;
    });

    showErnie(`${priceDiscrepancy}. Please confirm your new cart total and try again.`, `info`, 9000);
    return {
      proceed: false,
      code: ERR_PRICE_CHANGE,
    };
  }

  /** This handles a change in cart total
   * and surfaces specific changes to the user
   */
  if (localCostBreakdown.total !== dbCostBreakdown.total) {
    if (localCostBreakdown.bottleDepositTaxCents !== dbCostBreakdown.bottleDepositTaxCents) {
      const updatedBottleDepositTax = (dbCostBreakdown.bottleDepositTaxCents / 100).toFixed(2);

      showErnie(
        `Bottle deposit tax has changed to $${updatedBottleDepositTax}. Please confirm your new cart total and try again.`,
        `info`,
        9000
      );
      return {
        proceed: false,
        code: ERR_PRICE_CHANGE,
      };
    }
  }

  /** This handles a product quantity changing
   * when a user has it in their cart already
   */
  let quantityDiscrepancy = null;
  _.forEach(cartItems, (localProduct, key) => {
    const dbProduct = _.find(products, [`_id`, localProduct.product._id]);
    const localQuantity = localProduct.quantity;
    const dbQuantity = getQuantityRemaining(dbProduct, localProduct.option, { isKiosk });
    if (localQuantity > dbQuantity) {
      Cart.changeItemQuantity(key, dbQuantity, showErnie, customerState);
      if (!_.isEmpty(cartItems)) {
        quantityDiscrepancy = `${dbProduct.Name} availability has changed to ${dbQuantity}`;
      }
    }
  });

  if (_.isEmpty(cartItems)) {
    showErnie('The only item in your cart has sold out and been removed.', `info`, 9000);
    return {
      proceed: false,
      skipAlert: true,
      stop: true,
      code: ERR_OVER_QUANTITY,
      outOfStockItem: true,
      returnToMenu: true,
      dispensaryCName: dispensary.cName,
    };
  }

  if (_.isString(quantityDiscrepancy)) {
    showErnie(`${quantityDiscrepancy}. Please confirm your cart changes and try again.`, `info`, 9000);
    return {
      proceed: false,
      code: ERR_OVER_QUANTITY,
    };
  }

  const limitsCheck = checkAllLimits({
    existingItems: cartItems,
    medical: Cart.isMedical ?? Cart.order?.medicalOrder,
    dispensary,
    customerState,
  });

  if (!limitsCheck.withinLimits) {
    showErnie(limitsCheck.message);
    return { proceed: false, code: limitsCheck.code };
  }

  return { proceed: true };
}
