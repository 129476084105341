import { ModalNames, useModals } from 'components/modals';
import {
  ENROLLMENT_EVENTS,
  useDutchiePayAnalytics,
} from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import { useOpenDutchiePay } from 'src/payments/hooks/use-open-dutchie-pay/use-open-dutchie-pay';
import { useDutchiePay } from 'src/payments/hooks';
import useUI from 'src/hooks/use-ui';
import { formatPaymentsError } from 'src/payments/hooks/use-payments-error/use-payments-error';
import useErnie from 'shared/hooks/use-ernie';
import { PaySignUpButtonProps } from './pay-sign-up-button';

type UseDutchiePayResponse = ReturnType<typeof useDutchiePay>;
type UsePaySignUpButtonProps = {
  whiteLabelConfig: UseDutchiePayResponse['whiteLabelConfig'];
  error: UseDutchiePayResponse['error'];
  goToCheckout?: () => void;
  refHref?: string;
  cname?: string;
  enrollmentSource?: string;
  location: PaySignUpButtonProps['location'];
};

type UsePaySignUpButtonResponse = {
  handleClick: () => void;
};

export const usePaySignUpButton = ({
  whiteLabelConfig,
  error,
  goToCheckout,
  cname,
  refHref,
  enrollmentSource,
  location,
}: UsePaySignUpButtonProps): UsePaySignUpButtonResponse => {
  const { isMobileEcommApp } = useUI();
  const { dutchiePayEventTracker } = useDutchiePayAnalytics();
  const { openDutchiePay } = useOpenDutchiePay({ cname: cname ?? '', refHref, enrollmentSource });
  const { openModal } = useModals();
  const showErnie = useErnie();

  const handleClick = (): void => {
    if (error) {
      const { errorMarkdown, ernieType, ernieTimeout } = formatPaymentsError(
        error,
        whiteLabelConfig.brandedDutchiePayName
      );
      showErnie(errorMarkdown, ernieType, ernieTimeout);
      return;
    }

    if (isMobileEcommApp) {
      void openModal(ModalNames.dutchiePayVisitOnDesktop);
    } else if (goToCheckout) {
      goToCheckout();
    } else {
      openDutchiePay();
      dutchiePayEventTracker(ENROLLMENT_EVENTS.ENROLLMENT_BUTTON_CLICKED, { buttonLocation: location });
    }
  };

  return {
    handleClick,
  };
};
