import styled from 'styled-components';
import { Typography } from 'src/typography';
import { Button } from 'src/components/core';

export const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 600px;
  padding: 24px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Heading = styled(Typography.Heading)`
  font-weight: 600;
  text-align: center;
  font-size: 20px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ConfettiContainer = styled.div``;

export const Image = styled.img`
  height: auto;
  object-fit: contain;
  width: 100%;
`;

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const TextContentContainer = styled.div`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  background-color: #f1f8f7;
  border-radius: 8px;
`;

export const TextContentHeading = styled(Typography.Heading)`
  font-weight: 600;
  text-align: center;
  font-size: 24px;
`;

export const GreenText = styled.span`
  color: ${({ theme }) => theme.colors.primaryGreen};
`;

export const LineItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const LineItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LineItemText = styled(Typography.Body)`
  font-size: 16px;
  font-weight: 600;
  color: #00624a;
`;

export const BackButton = styled(Button)`
  background-color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  width: 100%;
`;

export const CloseText = styled(Typography.Heading)`
  font-size: 14px;
  font-weight: 400;
  color: #464f53;
`;
