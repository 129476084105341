/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { LDEvaluationReason, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { ORDER_TYPE_DELIVERY, ORDER_TYPE_PICKUP } from 'shared/constants';
import { GqlDispensaries, GqlOrders_Customer_Emails, GqlUsers, ProductsProvider } from 'types/graphql';
import { ProductPlacement } from 'src/types';
import { AnalyticsCart, AnalyticsOrder, AnalyticsProduct } from '../../events';
import { PageTypes } from './helpers/get-page-type';

export type CheckoutContext = {
  checkout_token?: string;
  delivery_method?: typeof ORDER_TYPE_DELIVERY | typeof ORDER_TYPE_PICKUP;
  dutchie_pay_enabled?: boolean;
  email?: GqlOrders_Customer_Emails | string | null;
  payment_type?: string | null;
  purchase_type?: string;
  logged_in?: boolean;
  user_enrolled_dutchie_pay?: boolean;
};

export type Payload = CheckoutContext &
  DispensaryContext & {
    button_location?: string;
    click_url?: string;
    customer_id?: string;
    data_source?: string | null;
    dutchie_pay_enrollment_source?: string;
    dutchie_pay_session_token?: string | null;
    ecommerce?: any;
    event: string;
    event_action?: string;
    event_category?: string;
    event_label?: string;
    instore_cart_id?: string;
    instore_cart_token?: string;
    login_step?: string;
    page_path?: string | undefined;
    page_title?: string | undefined;
    page_type?: string | null | undefined;
    page_url?: string | undefined;
    search_term?: string;
    source?: 'mx' | 'plaid' | undefined;
    variation_id?: number | undefined;
    variation_name?: LDFlagValue;
    variation_reason?: LDEvaluationReason;
  };

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events
// we are sending GA4 events to the GTM container.
export enum GA4_EVENTS {
  addToCart = 'add_to_cart',
  beginCheckout = 'begin_checkout',
  checkoutSession = 'checkout_session',
  gtmEvent = 'gtm_event',
  purchase = 'purchase',
  removeFromCart = 'remove_from_cart',
  search = 'search',
  selectItem = 'select_item',
  viewCart = 'view_cart',
  viewItem = 'view_item',
  viewListItem = 'view_list_item',
  viewPromotion = 'view_promotion',
  selectPromotion = 'select_promotion',
  viewItemList = 'view_item_list',
  experimentImpression = 'experiment_impression',
}

export type ActivePath = {
  concrete: string;
  normalized: string;
};

export type ContextCache = {
  activeCart: AnalyticsCart | null;
  activeDispensary: (GqlDispensaries & { isDutchiePayEnabledForDispo?: boolean }) | null;
  activePath: ActivePath | null;
  activeUser: (GqlUsers & { id?: string; isLoggedIn?: boolean; isEnrolledDutchiePay?: boolean }) | null;
  activeMenuVariant: string;
  activeOrder: AnalyticsOrder | null;
  activePathHasChanged: boolean;
};

export enum ProductRecommendationSource {
  native = 'Native',
  crossingMinds = 'Crossing Minds',
  moloco = 'Moloco',
}

export enum ProductRecommendationSection {
  brand = 'Brand',
  buyItAgain = 'Personalized Recommendations > Buy It Again',
  cartRecommendations = 'Cart Recommendations',
  custom = 'Custom',
  guestRecommendations = 'Guest Recommendations',
  itemsOnSale = 'Items on Sale',
  menu = `Menu`,
  offers = 'Offers',
  oftenPurchasedWith = 'Often Purchased With',
  orderReceipt = 'Order Receipt',
  personalizedRecommendations = 'Personalized Recommendations',
  popularCategory = 'Popular Category',
  popularSubcategory = 'Popular Subcategory',
  recentlyViewed = 'Recently Viewed',
  relatedItems = 'Related Items',
  sponsoredBrand = 'Sponsored Brand',
  sponsoredProduct = 'Sponsored Product',
  staffPicks = 'Staff Picks',
  topSellers = 'Top Sellers',
  collectionItems = 'Collection Products',
}

export type ItemListName = `${PageTypes} > ${ProductRecommendationSource} > ${ProductRecommendationSection | string}`;

export type GTMCartItem = {
  item_id: string;
  item_name: string;
  item_category: string;
  item_list_name?: string | null;
  index?: number | null;
  item_category_2?: string;
  item_variant?: string;
  item_additional_variant?: string;
  item_brand?: string;
  quantity?: string;
  price?: string;
  item_list_id?: string | null;
  creative_slot?: string | null;
  placement?: ProductPlacement;
  provider?: ProductsProvider;
};

export type EcommGTMContext = {
  affiliation?: string;
  currency?: string;
  value?: string;
  item_list_name?: string | null;
};

export type DispensaryContext = {
  dispensary_id?: string;
  dispensary_name?: string;
  dutchie_pay_enabled?: boolean;
};

export type UserContext = {
  customer_id?: string;
  logged_in?: boolean;
  user_enrolled_dutchie_pay?: boolean;
};

export type CartContext = {
  checkout_token?: string;
};

export type CartItem = {
  quantity: number;
  product: AnalyticsProduct;
  option?: string | null | undefined;
};

export type PaymentsError = {
  reasonCode: string;
  meta?: Record<string, unknown>;
};
