import { useEffect } from 'react';
import { useObserver } from 'mobx-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUser from 'src/hooks/use-user';
import useUI from 'src/hooks/use-ui';
import { useLoyaltyModal } from 'src/components/modals/loyalty';
import { useGetRewards } from 'src/components/loyalty/use-get-rewards';
import { detectLoyaltyIntegration, Integrations } from '../loyalty/use-loyalty';

export const LoyaltyAccountDetection = ({ children }): JSX.Element => {
  const { isEmbedded, isStoreFront, isEmbeddedCarousel } = useUI();
  const isEmbeddedOrStoreFront = (isEmbedded || isStoreFront) && !isEmbeddedCarousel;
  const User = useUser();
  const isUserLoading = useObserver(() => User.loading);
  const isLoggedIn = useObserver(() => User.isLoggedIn);
  const isUserLoadedAndLoggedIn = !isUserLoading && isLoggedIn;
  const userId = useObserver(() => User.id);
  const phone = useObserver(() => User.phone);
  const birthday = useObserver(() => User.birthday);
  const { dispensary } = useDispensary();
  const loyaltyProgram = detectLoyaltyIntegration(dispensary);
  const dispensaryId = dispensary?.id;
  const pin = User.getLoyaltyPin(dispensaryId);
  const flags = useFlags();
  const isShowLoyaltyFlagEnabled = flags['growth.ecomm.show-loyalty-rewards.rollout'] ?? false;
  const isShowSpringbigLoyaltyFlagEnabled = flags['growth.ecomm.springbig-member-signup.rollout'] ?? false;
  const showSpringbigLoyalty = loyaltyProgram === Integrations.springbig ? isShowSpringbigLoyaltyFlagEnabled : true;
  const { open: showLoyaltyModal } = useLoyaltyModal();
  const skip = loyaltyProgram === Integrations.alpineiq || !phone || !birthday || !dispensaryId || !userId;

  const { data, loading } = useGetRewards({
    phone,
    dispensaryId,
    birthday,
    pin,
    skip,
    customerId: userId,
  });

  useEffect(() => {
    const showForAlpine = loyaltyProgram === Integrations.alpineiq && !pin;
    const showForSpringbig =
      loyaltyProgram === Integrations.springbig && !loading && !data?.getRewardsV2?.userHasWallet;

    if (
      isEmbeddedOrStoreFront &&
      isUserLoadedAndLoggedIn &&
      [Integrations.alpineiq, Integrations.springbig].includes(loyaltyProgram) &&
      showSpringbigLoyalty &&
      dispensaryId &&
      !pin &&
      isShowLoyaltyFlagEnabled &&
      phone &&
      birthday &&
      userId &&
      (showForAlpine || showForSpringbig)
    ) {
      void showLoyaltyModal();
    }
  }, [
    dispensaryId,
    loyaltyProgram,
    showSpringbigLoyalty,
    isEmbeddedOrStoreFront,
    isShowLoyaltyFlagEnabled,
    isUserLoadedAndLoggedIn,
    phone,
    birthday,
    userId,
    pin,
    showLoyaltyModal,
    data,
    loading,
  ]);

  return children;
};
