import _ from 'lodash';

import useApolloClient from 'shared/hooks/use-apollo-client';
import useStores from 'shared/hooks/use-stores';
import { updateCustomerSubscription } from 'shared/graphql/customer/mutations';
import { removeTypename } from 'shared/helpers/utils';
import {
  updateEmailMutation,
  updateEmailV2Mutation,
  updateProfile,
  sendVerificationTokenMutation,
  validateVerificationTokenMutation,
} from 'shared/graphql/user/mutations';
import consumerSignupMutation from 'shared/graphql/user/mutations/consumer-signup.gql';
import loginConsumerMutation from 'shared/graphql/user/mutations/login-consumer.gql';
import updatePasswordV2 from 'shared/graphql/password/mutations/update-password-v2.gql';

import miscUtils from 'shared/utils/misc-utils';
import PublicEnv from 'shared/utils/public-env';

import { isAdminAccessible } from 'shared/helpers/users';

// Helper function that uses an object for parameters
// maps the consumerLogin which uses positional parameters
export function consumerLoginV2({
  apolloClient,
  User,
  email,
  password,
  DutchiePayEnrollment = null,
  showEnrollmentQuery = null,
  isEmbedded = false,
  turnstileToken = null,
}) {
  return consumerLogin(
    apolloClient,
    User,
    email,
    password,
    DutchiePayEnrollment,
    showEnrollmentQuery,
    isEmbedded,
    turnstileToken
  );
}

/**
 * @typedef {Object} ActionReturnValue
 * @property {boolean} success
 * @property {string} [msg]
 * @property {Object} [error]
 * @property {*} [data]
 */

/**
 * Update user profile
 * @returns {Promise<ActionReturnValue>}
 */
export async function consumerLogin(
  apolloClient,
  User,
  email,
  password,
  DutchiePayEnrollment,
  showEnrollmentQuery,
  isEmbedded = false,
  turnstileToken
) {
  try {
    const response = await apolloClient.mutate({
      mutation: loginConsumerMutation,
      variables: {
        email,
        password,
        turnstileToken,
      },
    });

    const { accessToken, user, transferToken } = response.data.loginConsumer;

    if (user && isAdminAccessible(user)) {
      window.location.href = `${PublicEnv.adminUrl}/transfer?transferToken=${transferToken}`;
      return { success: true };
    }
    if (User.handleSetupAuthorizationToken) {
      User.handleSetupAuthorizationToken(accessToken);
      await User.fetchUser();
    } else {
      User.login({ accessToken, user });
    }

    try {
      const { data: showEnrollmentResponse } = await apolloClient.query({
        query: showEnrollmentQuery,
      });

      const { enrollmentStatus, paymentMethods } = showEnrollmentResponse.showEnrollment;

      // TODO: move this mapping into enrollmentStatusReceived action handler https://dutchie.atlassian.net/browse/ENG-46917
      const bankAccounts = paymentMethods.map((acct) => ({
        accountAlias: acct.bankAccountDetails.accountAlias,
        aggregator: acct.bankAccountDetails.aggregator,
        bankName: acct.bankAccountDetails.bankName,
        billingAddressId: acct.bankAccountDetails.billingAddressId ?? '',
        connectionStatus: acct.bankAccountDetails.status,
        id: acct.id,
        lastDigits: acct.bankAccountDetails.lastDigits,
        status: acct.status,
        financialInstitutionLogo: acct.bankAccountDetails.financialInstitutionLogo,
      }));

      DutchiePayEnrollment.enrollmentStatusReceived({
        enrollmentStatus,
        bankAccounts,
        billingAddress: null,
      });
    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    // Handle account locked error
    if (error.message.includes('Password update required')) {
      return {
        error: {
          graphQLErrors: [
            {
              extensions: {
                errors: [
                  {
                    detail: error.message.replace('GraphQL error: ', ''),
                  },
                ],
              },
            },
          ],
        },
        success: false,
        keepModalOpen: true,
      };
    }

    // Force a user that now has a social SSO account into the social SSO flow
    if (error.message.includes('Password authentication is disabled.')) {
      const ssoMapping = {
        Apple: 'apple',
        Google: 'google',
      };

      const matchedKey = Object.keys(ssoMapping).find((key) => error.message.includes(key));

      if (!matchedKey) {
        console.error('No SSO provider found in error message');
        return { error, success: false };
      }

      const provider = ssoMapping[matchedKey];

      if (isEmbedded) {
        window.open(
          `/oauth/auto-login?provider=${provider}&redirectUrl=${window.location.href}`,
          '_blank',
          'width=400,height=600,toolbar=0,location=0,menubar=0'
        );
      } else {
        window.location.href = `/oauth/auto-login?provider=${provider}&redirectUrl=${window.location.href}`;
      }

      return { success: true, sso: true };
    }

    console.error(error);
    return { error, success: false };
  }
  return { success: true };
}

// A helper function for partial application when using objects for parameters.
function partialObject(fn, fixedParams) {
  return (newParams) => fn({ ...fixedParams, ...newParams });
}

export function useConsumerLogin() {
  const { User } = useStores();
  const apolloClient = useApolloClient();
  return _.partial(consumerLogin, apolloClient, User);
}

export function useConsumerLoginV2() {
  const { User } = useStores();
  const apolloClient = useApolloClient();

  // Instead of using _.partial, we use our custom helper to merge object parameters.
  return partialObject(consumerLoginV2, { apolloClient, User });
}

export async function updateSubscription(apolloClient, id, newValue, options) {
  const variables = {
    customerId: id,
    subscribed: newValue,
  };

  try {
    const response = await apolloClient.mutate({ mutation: updateCustomerSubscription, variables, ...options });
    const { success } = response.data.updateCustomerSubscription;
    return { success };
  } catch (error) {
    console.error(error);
    return { error, success: false };
  }
}

export function useUpdateSubscription() {
  const apolloClient = useApolloClient();
  return _.partial(updateSubscription, apolloClient);
}

// Helper function that uses an object for parameters
// maps the consumerSignup which uses positional parameters
export function consumerSignupV2({ apolloClient, User, Cart, UI, profile, userCreationData, turnstileToken = null }) {
  return consumerSignup(apolloClient, User, Cart, UI, profile, userCreationData, turnstileToken);
}

/**
 * @typedef {ActionReturnValue} SignupActionReturnValue
 * @property {*} user
 */

/**
 * Consumer side account creation
 * @returns {Promise<SignupActionReturnValue>}
 */
export async function consumerSignup(apolloClient, User, Cart, UI, profile, userCreationData, turnstileToken) {
  userCreationData = userCreationData || {
    dispensaryId: Cart?.dispensary?.id,
    embedded: UI.isVariant(`embedded`) || UI.isVariant(`store-front`),
  };
  try {
    const response = await apolloClient.mutate({
      mutation: consumerSignupMutation,
      variables: { input: { ...profile, data: userCreationData }, turnstileToken },
    });

    if (User.handleSetupAuthorizationToken) {
      // v2
      User.handleSetupAuthorizationToken(response.data.consumerSignup.accessToken);
      await User.fetchUser();
    } else {
      // v3
      User.updateToken(response.data.consumerSignup.accessToken);
    }

    return { success: true, user: response.data.consumerSignup.user };
  } catch (e) {
    let error = { message: `We are unable to create your account. Please contact support.` };
    if (e.graphQLErrors?.length && e.graphQLErrors[0].extensions?.errors?.length) {
      error = { message: e.graphQLErrors[0].extensions.errors?.[0]?.detail };
    }
    return { success: false, error };
  }
}

export function useConsumerSignup() {
  const { User, Cart, UI } = useStores();
  const apolloClient = useApolloClient();
  return _.partial(consumerSignup, apolloClient, User, Cart, UI);
}

export function useConsumerSignupV2() {
  const { User, Cart, UI } = useStores();
  const apolloClient = useApolloClient();

  // Instead of using _.partial, we use our custom helper to merge object parameters.
  // _.partial is for partial application of a functions arguments (currying)
  // we need partial application of a function for object destructured arguments which _.partial is not suitable for.
  return partialObject(consumerSignupV2, { apolloClient, User, Cart, UI });
}

/**
 * Update user proile
 * @returns {Promise<ActionReturnValue>}
 */
export async function updateUser(apolloClient, { profile, ...options }) {
  const pickedProfile = _.pick(profile, [
    `audioNotificationsOnNewArrivalsDisabled`,
    `audioNotificationsOnNewOrdersDisabled`,
    `birthday`,
    `driversLicense`,
    `emailNotifications`,
    `emailOptIn`,
    `firstName`,
    `lastName`,
    `inStoreEnrollmentCompleted`,
    `medicalCard`,
    `phone`,
    `photoId`,
    `textNotifications`,
  ]);

  const pickedAddress = _.pick(profile?.address, [`city`, `lat`, `ln1`, `ln2`, `lng`, `state`, `zipcode`]);

  const variables = removeTypename({
    profile: { ...pickedProfile, address: pickedAddress },
  });

  try {
    const response = await apolloClient.mutate({ mutation: updateProfile, variables, ...options });
    return { data: response.data.updateProfileV2, success: response.data.updateProfileV2.success };
  } catch (error) {
    return { error, success: false };
  }
}

export function useUpdateUser() {
  const apolloClient = useApolloClient();
  return _.partial(updateUser, apolloClient);
}

/**
 * Update user password
 * @returns {Promise<ActionReturnValue>}
 */
export async function updatePassword(apolloClient, User, { oldPassword, newPassword }) {
  try {
    const response = await apolloClient.mutate({
      mutation: updatePasswordV2,
      variables: {
        oldPassword,
        newPassword,
      },
    });

    const { accessToken } = response.data.updatePasswordV2;
    User.updateToken(accessToken);

    return { success: true, token: accessToken };
  } catch (error) {
    let message = null;
    const validationErrors = miscUtils.extractValidationErrors(error.graphQLErrors);

    if (!_.isEmpty(validationErrors)) {
      console.error(validationErrors);
      message = validationErrors;
    }

    return { error, msg: message, success: false };
  }
}

export function useChangePassword() {
  const { User } = useStores();
  const apolloClient = useApolloClient();
  return _.partial(updatePassword, apolloClient, User);
}

/**
 * Changes email using password verification
 * @returns {Promise<ActionReturnValue>}
 */
export async function updateEmail(apolloClient, User, email, password) {
  const variables = {
    email,
    password,
  };
  if (!password) {
    return { success: false, msg: `Error updating email: Missing password.` };
  }
  try {
    const response = await apolloClient.mutate({ mutation: updateEmailMutation, variables });
    User.email = email;
    return { data: response.data.updateEmail, success: response.data.updateEmail.success };
  } catch (error) {
    let message = null;
    const validationErrors = miscUtils.extractValidationErrors(error.graphQLErrors);

    if (!_.isEmpty(validationErrors)) {
      console.error(validationErrors);
      message = validationErrors;
    }

    return { error, msg: message, success: false };
  }
}

export function useUpdateEmail() {
  const { User } = useStores();
  const apolloClient = useApolloClient();
  return _.partial(updateEmail, apolloClient, User);
}

/**
 * Changes email using active verification
 * @returns {Promise<ActionReturnValue>}
 */
export async function updateEmailV2(apolloClient, User, email) {
  const variables = { email };
  try {
    const response = await apolloClient.mutate({ mutation: updateEmailV2Mutation, variables });
    User.email = email;
    return { data: response.data.updateEmailV2, success: response.data.updateEmailV2.success };
  } catch (error) {
    let message = null;
    const validationErrors = miscUtils.extractValidationErrors(error.graphQLErrors);

    if (!_.isEmpty(validationErrors)) {
      console.error(validationErrors);
      message = validationErrors;
    }

    return { error, msg: message, success: false };
  }
}

export function useUpdateEmailV2() {
  const { User } = useStores();
  const apolloClient = useApolloClient();
  return _.partial(updateEmailV2, apolloClient, User);
}

/**
 * Sends a verification link to the user's email address
 * @returns {Promise<ActionReturnValue>}
 */
export async function sendVerificationToken(apolloClient, { redirectUrl, dispensaryId }) {
  const variables = {
    redirectUrl,
    dispensaryId,
  };
  try {
    const response = await apolloClient.mutate({ mutation: sendVerificationTokenMutation, variables });
    return { data: response.data.sendVerificationToken, success: response.data.sendVerificationToken.success };
  } catch (error) {
    return { error, success: false };
  }
}

export function useSendVerificationToken() {
  const apolloClient = useApolloClient();
  return _.partial(sendVerificationToken, apolloClient);
}

/**
 * Validates a verification token
 * @returns {Promise<ActionReturnValue>}
 */
export async function validateVerificationToken(apolloClient, { token }) {
  const variables = { token };
  try {
    const response = await apolloClient.mutate({ mutation: validateVerificationTokenMutation, variables });
    return { data: response.data.validateVerificationToken, success: response.data.validateVerificationToken.success };
  } catch (error) {
    return { error, success: false };
  }
}

export function useValidateVerificationToken() {
  const apolloClient = useApolloClient();
  return _.partial(validateVerificationToken, apolloClient);
}
