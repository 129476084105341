import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
function SvgExitIcon(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 44 44"
  }, props), __jsx("path", {
    d: "M29.071 11.393a2.5 2.5 0 013.536 3.536L14.929 32.607a2.5 2.5 0 01-3.536-3.536z"
  }), __jsx("path", {
    d: "M32.607 29.071a2.5 2.5 0 01-3.536 3.536L11.393 14.929a2.5 2.5 0 013.536-3.536z"
  }));
}
export default SvgExitIcon;