import React from 'react';
import styled from 'styled-components';
import { margin } from 'styled-system';
import { MAIN_CONTENT_ID } from './constants';

export default function MainContent({ children, ...props }) {
  return (
    <Main id={MAIN_CONTENT_ID} {...props}>
      {children}
    </Main>
  );
}

const Main = styled.main`
  padding: 0 16px;
  ${margin};
`;
