import React, { useRef, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { Button } from 'shared/components';
import useCart from 'shared/hooks/use-cart';
import { useCheckout } from 'checkout/context';
import { useCheckoutAutoSave } from 'checkout/hooks/use-checkout-auto-save';
import { DefaultInput, ExpirationDateInput, CvvInput } from 'shared/components/text-input-with-label';
import {
  AmplitudeCategory,
  AmplitudeEvents,
  useAmplitude,
  AmplitudePaymentFormEventDescriptions,
} from 'shared/hooks/use-amplitude';
import useTranslation from 'hooks/use-translation';
import { useCheckoutCredentials } from 'checkout/components/sections/payment/use-checkout-credentials';
import { useMonerisController } from 'checkout/components/sections/payment/moneris/controller';

import {
  baseMonerisIframeUrl,
  styleParams,
  messagesForMonerisErrorCodes,
  errorMessage,
  validatePostalCode,
  validateExpiry,
  validateCvv,
} from './constants';

export function HostedTokenizationForm(props) {
  const amplitude = useAmplitude();
  const Cart = useCart();
  const Checkout = useCheckout();
  const iframeRef = useRef();
  const { onSave, handleError, applyCartStyles = false } = props;
  const [credentials] = useCheckoutCredentials(`moneris`);
  const htProfileId = credentials?.profileId;
  const [errorMessages, setErrorMessages] = useState(new Set());
  const { t } = useTranslation();

  const monerisController = useMonerisController(iframeRef);

  // this could be cleaned up
  // the control flow on it is weird, and it's unclear the desired behavior of handleError
  const handleMonerisResponse = (responseData) => {
    const newErrorMessages = new Set(errorMessages);
    if (responseData?.responseCode === '942') {
      newErrorMessages.add(942);
    } else {
      newErrorMessages.delete(942);

      if (validatePostalCode(Cart.monerisInput.postalCode)) {
        newErrorMessages.delete(888);
      } else {
        newErrorMessages.add(888);
      }

      if (validateCvv(Cart.monerisInput.cvv)) {
        newErrorMessages.delete(945);
      } else {
        newErrorMessages.add(945);
      }

      if (validateExpiry(Cart.monerisInput.expiryDate)) {
        newErrorMessages.delete(944);
      } else {
        newErrorMessages.add(944);
      }

      // check Moneris field for errors
      if (Checkout.getError({ path: `payment.monerisPAN` }).error) {
        newErrorMessages.add(943);
      } else if (!Checkout.getError({ path: `payment.monerisPAN` }).error && responseData?.responseCode === '001') {
        newErrorMessages.delete(943);
      }
    }

    if (!_.isEqual(newErrorMessages, errorMessages)) {
      setErrorMessages(newErrorMessages);
      handleError(errorMessage(newErrorMessages));
    }

    if (
      !newErrorMessages.length &&
      responseData?.responseCode === '001' &&
      validatePostalCode(Cart.monerisInput.postalCode) &&
      validateCvv(Cart.monerisInput.cvv) &&
      validateExpiry(Cart.monerisInput.expiryDate)
    ) {
      amplitude.log(AmplitudeEvents.payments.moneris.onSave, {
        description: AmplitudePaymentFormEventDescriptions.moneris.onSaveSuccess,
        category: AmplitudeCategory.paymentsMoneris,
      });

      onSave();
    } else if (newErrorMessages.length) {
      amplitude.log(AmplitudeEvents.payments.moneris.onError, {
        description: `${AmplitudePaymentFormEventDescriptions.moneris.onError}${_.join(
          _.map(newErrorMessages, (v) => messagesForMonerisErrorCodes[v]),
          ', '
        )}`,
        category: AmplitudeCategory.paymentsMoneris,
      });
    }
  };

  const doMonerisSubmit = async () => {
    amplitude.log(AmplitudeEvents.payments.moneris.onSave, {
      category: AmplitudeCategory.paymentsMoneris,
    });

    const result = await monerisController.tokenize(baseMonerisIframeUrl);
    handleMonerisResponse(result, true);
  };

  // register our save function to get called on checkout
  useCheckoutAutoSave(doMonerisSubmit);
  if (!htProfileId) {
    return <StyledButton loading>{t('moneris.loading', 'Loading Moneris Payments Configuration')}</StyledButton>;
  }

  const monerisIframeUrl = `${baseMonerisIframeUrl}?id=${htProfileId}&${styleParams}`;

  const updatePostalCode = (event) => {
    const value = event.target.value.replace(/\s+/g, '');
    Cart.monerisInput.postalCode = value;
  };

  const updateCvv = (event) => {
    const value = event.target.value.replace(/\s+/g, '');
    Cart.monerisInput.cvv = value;
  };

  const updateExpiration = (event) => {
    const value = event.target.value.replace(/\s+/g, '');
    Cart.monerisInput.expiryDate = value;
  };

  return (
    <div
      data-testid='hosted-tokenization-form'
      style={{
        background: applyCartStyles ? 'none' : '#f0f3f6',
        borderRadius: 8,
        padding: applyCartStyles ? 0 : 18,
        border: 'none',
        marginTop: applyCartStyles ? 2 : 12,
      }}
    >
      {applyCartStyles ? (
        <CardHeaderText>Credit Card</CardHeaderText>
      ) : (
        <HeaderText>{t('checkout.enter-card-information', 'Enter credit card information')}</HeaderText>
      )}

      <InputsContainer>
        <iframe
          title='Card Payment Information Form'
          ref={iframeRef}
          id='monerisFrame'
          frameBorder='0'
          width='100%'
          src={monerisIframeUrl}
        />
      </InputsContainer>
      <InputsContainer>
        <ExpirationDateInput
          label={t('checkout.exp-date', 'Exp Date')}
          containerClassName='expirationDate'
          aria-label='expiration date input'
          data-cy='expiration-date-input'
          data-test='expiration-date-input'
          mr='8px'
          width='calc(100% - 8px)'
          format='##/##'
          placeholder={t('checkout.exp-date-placeholder', 'mm/yy')}
          onValueAfterError={() => Checkout.removeError({ path: `payment.monerisExpirationDate` })}
          error={Checkout.getError({ path: `payment.monerisExpirationDate` }).error}
          errorMessage={Checkout.getError({ path: `payment.monerisExpirationDate` }).message}
          onBlur={updateExpiration}
        />
        <CvvInput
          containerClassName='cvvInput'
          placeholder={t('checkout.ccv', 'CVV')}
          label={t('checkout.ccv', 'CVV')}
          aria-label='cvv input'
          data-cy='cvv-input'
          data-test='cvv-input'
          ml='8px'
          width='calc(100% - 8px)'
          onBlur={updateCvv}
          onValueAfterError={() => Checkout.removeError({ path: `payment.monerisCvv` })}
          error={Checkout.getError({ path: `payment.monerisCvv` }).error}
          errorMessage={Checkout.getError({ path: `payment.monerisCvv` }).message}
        />
      </InputsContainer>
      <InputsContainer>
        <FullDefaultInput
          id='monerisPostalCode'
          name='monerisPostalCode'
          label={t('checkout.postal-code', 'Postal Code')}
          aria-label='Postal code input'
          onValueAfterError={() => Checkout.removeError({ path: `payment.monerisPostalCode` })}
          error={Checkout.getError({ path: `payment.monerisPostalCode` }).error}
          errorMessage={Checkout.getError({ path: `payment.monerisPostalCode` }).message}
          onBlur={updatePostalCode}
        />
      </InputsContainer>
      <StyledButton applyCartStyles={applyCartStyles} onClick={doMonerisSubmit}>
        {t('common.save', 'Save')}
      </StyledButton>
    </div>
  );
}

const StyledButton = styled(Button)`
  margin-top: ${({ applyCartStyles }) => (applyCartStyles ? '16px' : '25px')};
  width: 73px;
  height: 33px;
  margin-right: 15px;
`;

const HeaderText = styled.div`
  font-size: 13px;
  line-height: 15px;
  color: ${(props) => (props.error ? `#e25241` : `#5d666d`)};
`;

const CardHeaderText = styled.div`
  color: #242526;
  font: 600 16px/20px Matter;
`;

const InputsContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & iframe {
    height: 53px;
    @media only screen and (min-width: 768px) {
      height: 45px;
    }
  }
`;

const FullDefaultInput = styled(DefaultInput)`
  &.MuiFormControl-root {
    width: 100%;
  }
`;
