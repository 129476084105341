import React, { forwardRef } from 'react';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'hooks/use-ui';
import useCart from 'hooks/use-cart';

import { useModals, ModalNames } from 'components/modals';
import { useCheckAllAddressFlags } from 'src/state/actions/address';
import { mediaSizes } from 'theme';
import { ORDER_TYPE_PICKUP, ORDER_TYPE_DELIVERY } from 'shared/constants';
import {
  afterHoursEnabledForDelivery,
  afterHoursEnabledForPickup,
  scheduledOrderingEnabledForDelivery,
  shouldHideOrderEstimates,
  openInfoForPickup,
  getFirstAvailablePickupOption,
} from 'shared/helpers/dispensaries';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import { ModalContainer, ModalExitIcon } from 'components/core';
import { bold } from 'components/core/typography';
import { formatEstimate } from 'shared/helpers/order-estimates';
import { PickupButton, DeliveryButton } from './order-type-buttons';

export const OrderTypeModal = forwardRef(({ onClose, containerProps }, ref) => {
  const { t } = useTranslation();
  const { dispensary } = useDispensary();
  const UI = useUI();
  const { openModal } = useModals();
  const Cart = useCart();
  const { durationEstimates, hasDeliveryAddress } = Cart;
  const { previewMode, timezone } = UI;
  const checkAllAddressFlags = useCheckAllAddressFlags();

  const openInfo = openInfoForDispensary(dispensary, {
    userTimezone: timezone,
    previewMode,
  });

  const { type: nextOpenPickupType, info: nextOpenPickupInfo } = openInfoForPickup(openInfo);
  const { delivery } = openInfo;

  const hideOrderEstimates = shouldHideOrderEstimates(dispensary, Cart, true);
  const { pickup: pickupEstimate, delivery: deliveryEstimate } = durationEstimates;

  const formattedPickupEstimate = !hideOrderEstimates && formatEstimate(pickupEstimate, ORDER_TYPE_PICKUP);
  const formattedDeliveryEstimate = !hideOrderEstimates && formatEstimate(deliveryEstimate, ORDER_TYPE_DELIVERY);

  const offersAfterHoursDelivery =
    afterHoursEnabledForDelivery(dispensary) && scheduledOrderingEnabledForDelivery(dispensary);

  const offersAfterHoursPickup = nextOpenPickupType
    ? dispensary.orderTypesConfigV2[nextOpenPickupType].enableAfterHoursOrdering
    : afterHoursEnabledForPickup(dispensary);

  const handleChooseOrderType = async (type) => {
    if (type === ORDER_TYPE_PICKUP) {
      Cart.setOrderType(getFirstAvailablePickupOption(dispensary));
    } else {
      Cart.setOrderType(type);

      const goodAddress = await checkAllAddressFlags();

      if (!goodAddress.success) {
        await openModal(ModalNames.deliveryAddress);
      }
    }

    return onClose();
  };

  const handleCloseWithoutChoosing = () => {
    const firstAvailableOrderType = dispensary.offerAnyPickupService
      ? getFirstAvailablePickupOption(dispensary)
      : ORDER_TYPE_DELIVERY;

    Cart.setOrderType(firstAvailableOrderType);
    onClose();
  };

  return (
    <ModalContainer data-testid='order-type-modal' ref={ref} height={327} width={502} {...containerProps}>
      <Header>{t('orderType.selectOrderType', 'select an order type')}</Header>
      <ModalExitIcon data-testid='close-order-type-modal' onClick={handleCloseWithoutChoosing} />

      <ButtonContainer>
        <PickupButton
          tabindex='0'
          mb={17}
          pickupInfo={nextOpenPickupInfo}
          dispensary={dispensary}
          formattedPickupEstimate={formattedPickupEstimate}
          offersAfterHours={offersAfterHoursPickup}
          onClick={() => {
            if (nextOpenPickupInfo.isClosed && !offersAfterHoursPickup) {
              return;
            }
            handleChooseOrderType(ORDER_TYPE_PICKUP);
          }}
        />
        <DeliveryButton
          deliveryInfo={delivery}
          dispensary={dispensary}
          formattedDeliveryEstimate={formattedDeliveryEstimate}
          hasDeliveryAddress={hasDeliveryAddress()}
          offersAfterHours={offersAfterHoursDelivery}
          onClick={() => {
            if (delivery.isClosed && !offersAfterHoursDelivery) {
              return;
            }
            handleChooseOrderType(ORDER_TYPE_DELIVERY);
          }}
        />
      </ButtonContainer>
    </ModalContainer>
  );
});

const Header = styled.h3`
  margin-top: 8px;
  margin-bottom: 25px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[35]};
  ${bold};
  text-align: center;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  padding: 0 26px 13px 26px;

  @media (max-width: ${mediaSizes.phone}px) {
    padding: 0;
  }
`;
