import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useQuery } from '@apollo/react-hooks';
import useStores from 'shared/hooks/use-stores';
export default function useApolloQuery(query, variables) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var _useStores = useStores(),
    apolloClient = _useStores.apolloClient;
  return useQuery(query, _objectSpread(_objectSpread({
    fetchPolicy: 'cache-and-network',
    client: apolloClient
  }, options), {}, {
    variables: variables
  }));
}