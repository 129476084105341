import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
import { checkAllLimits } from 'shared/helpers/purchase-limits';
import { productIncludedInType } from 'shared/helpers/check-limits/utils';
export var maxQuantity = function maxQuantity(key, cart, isMedical, order) {
  var dispensary = order.dispensary,
    customerState = order.customerState;
  var _cart$key = cart[key],
    quantity = _cart$key.quantity,
    product = _cart$key.product;
  var currentMaxQuantity = quantity;
  var withinLimits = false;
  while (!withinLimits) {
    var limitsCheck = checkAllLimits({
      newItemId: key,
      itemBeingAdded: _objectSpread(_objectSpread({}, _.clone(cart[key])), {}, {
        quantity: currentMaxQuantity
      }),
      existingItems: cart,
      medical: isMedical,
      dispensary: dispensary,
      changingQuantity: true,
      customerState: customerState,
      complianceOnly: true
    });

    // Make sure we're not inadvertently removing products whose types
    // are still within the limits when switching from med -> rec
    var typesOverLimit = limitsCheck.typesOverLimit;
    var productExceedsLimits = void 0;
    if ((typesOverLimit === null || typesOverLimit === void 0 ? void 0 : typesOverLimit.length) > 0) {
      // eslint-disable-next-line max-len
      productExceedsLimits = _.some(typesOverLimit, function (exceededType) {
        return productIncludedInType(product, exceededType, order);
      });
    }
    if (limitsCheck.withinLimits || !productExceedsLimits || currentMaxQuantity === 0) {
      withinLimits = true;
    } else {
      currentMaxQuantity -= 1;
    }
  }
  return currentMaxQuantity;
};