/* eslint-disable @typescript-eslint/naming-convention */
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { SpaceProps } from 'styled-system';
import { useObserver } from 'mobx-react-lite';

import { ModalContainer, ModalExitIcon } from 'shared/modals/v2-container';
import { PayByBankLogo as _PayByBankLogo } from 'src/assets/pay-by-bank-logo';
import { WhiteLabelLogo } from 'src/payments/components';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import useViewport from 'hooks/use-viewport';
import useUI from 'hooks/use-ui';
import { mediaSizes } from 'shared/styles';

export type DutchiePayBaseModalProps = SpaceProps & {
  children: React.ReactNode;
  centerLogo?: boolean;
  heading?: string;
  subHeading?: string;
  hideLogo?: boolean;
  hideExitIcon?: boolean;
  onCloseModal?: () => void;
};

export const BaseModal = forwardRef<JSX.Element, DutchiePayBaseModalProps>(
  ({ children, centerLogo, heading, subHeading, hideExitIcon, hideLogo, onCloseModal, ...restOfProps }, ref) => {
    const UI = useUI();
    const viewport = useViewport();
    const DutchiePayEnrollment = useDutchiePayEnrollment();
    const useWhiteLabel = useObserver(() => DutchiePayEnrollment.dispensary?.useWhiteLabel);

    const Logo = (): JSX.Element =>
      useWhiteLabel ? (
        <WhiteLabelLogo variant='dark' />
      ) : (
        <PayByBankLogo data-testid='logo' width='130' height='25' $centerLogo={centerLogo} />
      );

    const containerProps = {
      isIframe: UI.isIframe,
      iframeOffset: viewport.offset,
      parentOffset: viewport.parentOffset,
      viewportHeight: viewport.height,
      viewportWidth: viewport.width,
      iframeHeight: UI.iframeHeight,
    };

    return (
      <StyledModalContainer ref={ref} {...containerProps} {...restOfProps}>
        <ModalBody>
          <Header>
            {!hideExitIcon && <ModalExitIcon onClick={onCloseModal} />}
            {!hideLogo && <Logo />}
            {heading && <ModalHeading>{heading}</ModalHeading>}
            {subHeading && <ModalSubheading>{subHeading}</ModalSubheading>}
          </Header>
          {children}
        </ModalBody>
      </StyledModalContainer>
    );
  }
);

const PayByBankLogo = styled(_PayByBankLogo)`
  ${({ theme: { colors } }) => css`
    color: ${colors.dutchiePayGreen};
  `}
  align-self: flex-start;
`;

const StyledModalContainer = styled(ModalContainer)`
  max-width: 450px;
  padding: 0;
  text-align: left;
  ${({ theme }) => theme?.breakpoints?.down('xs')} {
    width: 90%;
  }
`;

const Header = styled.header`
  display: flex;
  margin-bottom: 32px;
`;

const ModalHeading = styled.h3`
  color: ${({ theme }) => theme.colors.grey[25]};
`;

const ModalSubheading = styled.p`
  color: ${({ theme }) => theme.colors.grey[45]};
`;

const ModalBody = styled.main`
  padding: 30px;

  @media only screen and (min-width: ${mediaSizes.phone - 1}px) {
    min-width: 450px;
`;
