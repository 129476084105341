import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _slicedToArray from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
import { isBlank } from 'underscore.string';
import { canadianProvincesMapToShortened, countryMapToShortened, USStatesMapToShortened } from 'shared/core/constants/geography';
import { getCityToDisplay } from './address';
var parseStateShortCode = function parseStateShortCode(option1, option2) {
  var splitByDash = _.split(option1 || option2, '-');
  return splitByDash[splitByDash.length - 1];
};
var formatAddressForLocationSearch = function formatAddressForLocationSearch(location) {
  var ln1 = location.ln1,
    ln2 = location.ln2,
    state = location.state,
    stateLong = location.stateLong,
    zipcode = location.zipcode,
    country = location.country,
    countryLong = location.countryLong,
    rootType = location.rootType;
  var countryPretty = rootType === 'region' || rootType === 'country' ? countryLong : "".concat(country === 'US' ? 'USA' : country);
  var statePretty = rootType === 'place' || rootType === 'region' ? stateLong : state;
  var cityPretty = getCityToDisplay(location);
  return _.reject([_.reject([ln1, ln2], isBlank).join(' '), cityPretty, _.reject([statePretty, zipcode], isBlank).join(' '), countryPretty], isBlank).join(', ');
};
export function buildLocationObjectFromMapboxFeature(data) {
  var id = data.id,
    center = data.center,
    addressLong = data.place_name;
  var _center = _slicedToArray(center, 2),
    lng = _center[0],
    lat = _center[1];
  var locationDetails = _.reduce([_objectSpread(_objectSpread({}, data), {}, {
    isRoot: true
  })].concat(_toConsumableArray(data.context || [])), function (accumulator, current) {
    // eslint-disable-next-line camelcase
    var address = current.address,
      currentId = current.id,
      text = current.text,
      short_code = current.short_code,
      properties = current.properties;
    var _$split = _.split(currentId, '.'),
      _$split2 = _slicedToArray(_$split, 1),
      type = _$split2[0];
    if (current.isRoot) {
      accumulator.rootType = type;
    }
    if (type === 'country') {
      accumulator.country = countryMapToShortened[text];
      accumulator.countryLong = text;
    } else if (type === 'region') {
      var _ref, _USStatesMapToShorten;
      accumulator.state = (_ref = (_USStatesMapToShorten = USStatesMapToShortened[text]) !== null && _USStatesMapToShorten !== void 0 ? _USStatesMapToShorten : canadianProvincesMapToShortened[text]) !== null && _ref !== void 0 ? _ref : parseStateShortCode(short_code, properties === null || properties === void 0 ? void 0 : properties.short_code);
      accumulator.stateLong = text;
    } else if (type === 'postcode') {
      accumulator.zipcode = text;
    } else if (type === 'district') {
      accumulator.county = text;
    } else if (type === 'locality') {
      accumulator.locality = text;
    } else if (type === 'place') {
      accumulator.city = text;
    } else if (type === 'neighborhood') {
      accumulator.neighborhood = text;
    } else if (type === 'address') {
      // TODO: see if there's a way to get ln2 (Ex: Apt 111) in Mapbox
      accumulator.ln1 = "".concat(address ? "".concat(address, " ") : '').concat(text);
    }
    return accumulator;
  }, {});
  return _objectSpread(_objectSpread({
    id: id
  }, locationDetails), {}, {
    lat: lat,
    lng: lng,
    address: formatAddressForLocationSearch(locationDetails),
    addressLong: addressLong
  });
}