import React from 'react';
import styled from 'styled-components';

import { PlaidEmbeddedSearch } from 'src/payments/components';
import { Typography } from 'src/typography';
import { CompleteAccount } from 'src/payments/dutchie-pay/signup/views/complete-account';
import { Loading, TermsView } from 'src/payments/dutchie-pay/signup/views';
import { LoadingSkeleton } from 'src/components/core';
import { EnrollmentSteps, connectingBankSteps, tosSteps } from 'src/payments/constants';
import { useConnectBank } from './use-connect-bank';

export function ConnectBank(): JSX.Element {
  const { currentStep, isPlaidLoading, onLoadingStateChange, handleOnError, handleOnLinkExit } = useConnectBank();

  return (
    <MainContainer data-testid='connect-bank'>
      {currentStep === EnrollmentSteps.connectBank && (
        <HeadingContainer data-testid='connect-bank-heading'>
          <Heading size='large' tag='h2'>
            Link your bank via Plaid
          </Heading>
          <SubHeading size='small' tag='h3'>
            You'll only be charged after you place an order.
          </SubHeading>
        </HeadingContainer>
      )}
      <MainContentContainer>
        {currentStep === EnrollmentSteps.connectBank && (
          <PlaidEmbeddedSearch
            height={450}
            onLoadingStateChange={onLoadingStateChange}
            handleOnError={handleOnError}
            handleOnLinkExit={handleOnLinkExit}
          />
        )}
        {isPlaidLoading && <LoadingSkeleton height={450} width={300} data-testid='plaid-loading-skeleton' />}
        {currentStep === EnrollmentSteps.completeAccount && <CompleteAccount />}
        {currentStep === EnrollmentSteps.bankAccountsLoading && <Loading steps={connectingBankSteps} />}
        {currentStep === EnrollmentSteps.tosLoading && <Loading steps={tosSteps} />}
        {currentStep === EnrollmentSteps.terms && <TermsView continueTextOverride='Continue' />}
      </MainContentContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px 20px;
  gap: 24px;
`;

const MainContentContainer = styled.div`
  display: flex;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

const Heading = styled(Typography.Heading)`
  font-size: 20px;
  text-align: center;
  font-weight: 600;
`;

const SubHeading = styled(Typography.Heading)`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[30]};
`;
