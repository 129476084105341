import Big from 'big.js';
import _ from 'lodash';
import { useCallback } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import { UseCartPricingReturns, Discount } from 'hooks/use-cart-pricing/use-cart-pricing.types';
import CartItem from 'state/cart-item';

export const useCartItemPricing = ({ priceCartPricing }): UseCartPricingReturns => {
  const getItemPrice = useCallback(
    (product: CartItem) => {
      const receiptItem = product.getReceiptItem();

      if (!receiptItem && priceCartPricing) {
        datadogLogs.logger.error('No receipt item found for product', { productId: product.id });
      }

      const useReceiptItem = priceCartPricing && receiptItem;

      const basePrice = useReceiptItem ? receiptItem.basePrice / 100 : product.basePrice;
      const bogoDiscount = useReceiptItem
        ? sumDiscountValues(receiptItem.discounts?.bogo || []) / 100
        : product.bogoDiscount;
      const price = useReceiptItem ? Number(Big(receiptItem.total).div(100)) : product.price;
      const itemSaleAdjustment = useReceiptItem
        ? sumDiscountValues(receiptItem.discounts?.sale || []) / 100
        : Big(product.saleAdjustment || 0).times(-1);

      const originalPrice = Big(basePrice).times(product.quantity).toFixed(2);
      const saleAdjustment = !_.isNil(itemSaleAdjustment) && itemSaleAdjustment !== 0 ? itemSaleAdjustment : null;

      const adjustedPrice = Big(price)
        .minus(saleAdjustment ?? 0)
        .toFixed(2);
      const bogoDiscountedPrice =
        !_.isNil(bogoDiscount) && bogoDiscount > 0 ? Big(adjustedPrice).minus(bogoDiscount).toFixed(2) : null;

      return {
        originalPrice,
        adjustedPrice,
        bogoDiscountedPrice,
      };
    },
    [priceCartPricing]
  );

  return {
    getItemPrice,
  };
};

const sumDiscountValues = (discounts: Discount[]): number =>
  _.reduce(discounts, (total, discount) => total + discount.value, 0);
