import React, { useEffect, useState, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { useRouter } from 'next/router';
import ReactMapGL, { Marker, NavigationControl, FlyToInterpolator } from 'react-map-gl';
import ClipLoader from 'react-spinners/ClipLoader';
import { sanitizeUrl } from '@braintree/sanitize-url';

import ChevronIcon from 'assets/chevron-icon';
import MarkerIcon from 'src/assets/marker-icon';
import { mediaQueries } from 'shared/styles';
import Imgix from 'shared/components/imgix';
import PublicEnv from 'shared/utils/public-env';

import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';

import { useDispensaryChains } from 'src/dispensary/hooks/use-dispensary-chain';
import { MAPBOX_TOKEN } from 'shared/constants';

import { statesMap } from 'shared/core/constants/geography';
import DispensaryChainCard from 'src/dispensary/core-menu/locations/dispensary-chain-card';
import { ModalClose } from 'shared/modals';
import { GqlDispensaries } from 'types/graphql';

type Props = {
  onClose: () => void;
  hideCloseButton?: boolean;
};

type Bounds = {
  minLng: number;
  maxLng: number;
  minLat: number;
  maxLat: number;
};

const calculateMaximalMapViewport = (dispensaryChainLocations: GqlDispensaries[] | null | undefined): Bounds => {
  if (!dispensaryChainLocations) {
    return {
      minLng: 180,
      maxLng: -180,
      minLat: 90,
      maxLat: -90,
    };
  }
  return dispensaryChainLocations.reduce<Bounds>(
    (acc, d) => {
      if (!d.location?.geometry?.coordinates) {
        return acc;
      }
      const [lng, lat] = d.location.geometry.coordinates;
      if (typeof lng !== 'number' || typeof lat !== 'number') {
        return acc;
      }
      return {
        minLng: Math.min(acc.minLng, lng),
        maxLng: Math.max(acc.maxLng, lng),
        minLat: Math.min(acc.minLat, lat),
        maxLat: Math.max(acc.maxLat, lat),
      };
    },
    {
      minLng: 180,
      maxLng: -180,
      minLat: 90,
      maxLat: -90,
    }
  );
};

const isValidBackToUrl = (url: string): boolean => {
  // Handle relative URLs that start with a forward slash
  if (url.startsWith('/')) {
    return true;
  }

  try {
    const sanitizedUrl = sanitizeUrl(url);
    // If an absolute URL is provided, we don't want to allow it
    if (sanitizedUrl.startsWith('http://') || sanitizedUrl.startsWith('https://')) {
      return false;
    }
    const urlObj = new URL(sanitizedUrl);
    const consumerUrlObj = new URL(PublicEnv.consumerUrl);
    // Only allow URLs from our consumer domain for absolute URLs
    return urlObj.hostname === consumerUrlObj.hostname;
  } catch {
    return false;
  }
};

export const ChainLocationBrowserContent = ({ onClose, hideCloseButton }: Props): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation();
  const UI = useUI();
  const theme = useTheme();
  const locationsContainerRef = useRef<HTMLDivElement>(null);
  const [hoveredDispensaryId, setHoveredDispensaryId] = useState<string | null>(null);
  const [mapViewport, setMapViewport] = useState({
    latitude: 39.8283,
    longitude: -98.5795,
    zoom: 3,
  });

  const { dispensaryChainLocations, loading: dispensaryChainLocationsLoading } = useDispensaryChains({
    address: '',
    activeOnly: true,
    includeStealthMode: true,
  });

  const { backTo } = router.query;
  const showBackButton = typeof backTo === 'string' && backTo.length > 0;

  const handleLocationClick = (dispensary: GqlDispensaries): void => {
    if (!dispensary.cName) {
      return;
    }
    const { cName } = dispensary;
    const { path } = router.query;
    const decodedPath = typeof path === 'string' ? decodeURIComponent(path) : '/menu';
    const pathSuffix = decodedPath.startsWith('/') ? decodedPath : `/${decodedPath}`;

    onClose();
    if (UI.isStoreFront) {
      void router.push(`/stores/${cName}${pathSuffix}`).catch(console.error);
    } else {
      void router.push(`/embedded-menu/${cName}${pathSuffix}`).catch(console.error);
    }
  };

  const handleLocationHover = (dispensary: GqlDispensaries, isEntering: boolean): void => {
    setHoveredDispensaryId(isEntering ? dispensary.id : null);

    if (isEntering && dispensary.location?.geometry?.coordinates) {
      const [lng, lat] = dispensary.location.geometry.coordinates;
      if (typeof lng === 'number' && typeof lat === 'number') {
        setMapViewport((prev) => ({
          ...prev,
          latitude: lat,
          longitude: lng,
          zoom: 13,
          transitionDuration: 1000,
          transitionInterpolator: new FlyToInterpolator(),
        }));
      }
    } else {
      const bounds = calculateMaximalMapViewport(dispensaryChainLocations as GqlDispensaries[]);

      // Ensure we have valid bounds before updating viewport
      if (bounds.minLng === 180 || bounds.maxLng === -180) {
        return;
      }

      setMapViewport((prev) => ({
        ...prev,
        latitude: (bounds.minLat + bounds.maxLat) / 2,
        longitude: (bounds.minLng + bounds.maxLng) / 2,
        zoom: 3,
        transitionDuration: 100,
        transitionInterpolator: new FlyToInterpolator(),
      }));
    }
  };

  const handleMarkerHover = (dispensary: GqlDispensaries, isEntering: boolean): void => {
    setHoveredDispensaryId(isEntering ? dispensary.id : null);

    if (isEntering) {
      // Scroll to the location card when hovering over the marker
      const locationCard = document.getElementById(`location-${dispensary.id}`);
      if (locationCard && locationsContainerRef.current) {
        locationsContainerRef.current.scrollTo({
          top: locationCard.offsetTop - locationsContainerRef.current.offsetTop - 24,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    if (dispensaryChainLocations && dispensaryChainLocations.length > 0) {
      // Calculate bounds to fit all markers
      const bounds = calculateMaximalMapViewport(dispensaryChainLocations as GqlDispensaries[]);

      // Ensure we have valid bounds before updating viewport
      if (bounds.minLng === 180 || bounds.maxLng === -180) {
        return;
      }

      // Center the viewport on the middle of the bounds
      setMapViewport({
        latitude: (bounds.minLat + bounds.maxLat) / 2,
        longitude: (bounds.minLng + bounds.maxLng) / 2,
        zoom: 3,
      });
    }
  }, [dispensaryChainLocations]);

  if (!dispensaryChainLocations) {
    return <></>;
  }

  const groupedByState = dispensaryChainLocations.reduce<Record<string, GqlDispensaries[]>>(
    (acc: Record<string, GqlDispensaries[]>, dispensary: GqlDispensaries) => {
      const { state } = dispensary.location ?? {};
      if (!state) {
        return acc;
      }
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!acc[state]) {
        acc[state] = [];
      }
      acc[state].push(dispensary);
      return acc;
    },
    {}
  );

  const sortedStates = Object.entries(groupedByState).sort(([stateA], [stateB]) => {
    const stateNameA = statesMap[stateA] || stateA;
    const stateNameB = statesMap[stateB] || stateB;
    return stateNameA.localeCompare(stateNameB);
  });

  const handleGoBack = (): void => {
    onClose();
    if (showBackButton && typeof backTo === 'string') {
      const sanitizedBackTo = sanitizeUrl(backTo);
      if (isValidBackToUrl(sanitizedBackTo)) {
        void router.push(sanitizedBackTo).catch(console.error);
      } else {
        router.back();
      }
    } else {
      router.back();
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent, callback: () => void): void => {
    if (e.key === 'Enter' || e.key === ' ') {
      callback();
    }
  };

  if (dispensaryChainLocationsLoading) {
    return (
      <LoadingContainer>
        <ClipLoader color={theme.customized.colors.buttonsLinks} size={100} />
        <LoadingText>Loading locations...</LoadingText>
      </LoadingContainer>
    );
  }

  return (
    <SplitContainer>
      <LocationListSection>
        <HeaderContainer>
          {showBackButton && (
            <BackArrowContainer
              onClick={handleGoBack}
              onKeyPress={(e) => handleKeyPress(e, handleGoBack)}
              role='button'
              tabIndex={0}
              aria-label='Back'
            >
              <BackArrow />
            </BackArrowContainer>
          )}
          <AllLocations>{t('browse.allLocations', 'All Locations')}</AllLocations>
          <Instructions>Click a location below to view the menu.</Instructions>
        </HeaderContainer>
        <LocationsContainer ref={locationsContainerRef}>
          {sortedStates.map(([state, locations]: [string, GqlDispensaries[]]) => (
            <StateSection key={state}>
              <StateName>{statesMap[state]}</StateName>
              {locations.map((location) => (
                <DispensaryChainCard
                  key={location.id}
                  id={`location-${location.id}`}
                  dispensary={location}
                  loading={false}
                  onMouseEnter={() => handleLocationHover(location, true)}
                  onMouseLeave={() => handleLocationHover(location, false)}
                  onClick={() => handleLocationClick(location)}
                  isHighlighted={hoveredDispensaryId === location.id}
                />
              ))}
            </StateSection>
          ))}
        </LocationsContainer>
      </LocationListSection>

      <MapContainer>
        {!hideCloseButton && <StyledModalClose top='24px' right='24px' onClick={onClose} />}
        <ReactMapGL
          {...mapViewport}
          width='100%'
          height='100%'
          mapStyle='mapbox://styles/mapbox/streets-v11'
          mapboxApiAccessToken={MAPBOX_TOKEN}
          onViewportChange={setMapViewport}
          attributionControl={false}
        >
          {dispensaryChainLocations.map((dispensary) => {
            if (!dispensary?.location?.geometry?.coordinates) {
              return null;
            }

            const [lng, lat] = dispensary.location.geometry.coordinates;
            if (typeof lng !== 'number' || typeof lat !== 'number') {
              return null;
            }

            return (
              <Marker key={dispensary.id} latitude={lat} longitude={lng} offsetLeft={-13} offsetTop={-37}>
                <div>
                  <MarkerContainer
                    onClick={() => handleLocationClick(dispensary as GqlDispensaries)}
                    onMouseEnter={() => handleMarkerHover(dispensary as GqlDispensaries, true)}
                    onMouseLeave={() => handleMarkerHover(dispensary as GqlDispensaries, false)}
                    onKeyPress={(e) => handleKeyPress(e, () => handleLocationClick(dispensary as GqlDispensaries))}
                    role='button'
                    tabIndex={0}
                    isHighlighted={hoveredDispensaryId === dispensary.id}
                  >
                    <PinIconContainer>
                      <PinIcon isHighlighted={hoveredDispensaryId === dispensary.id} />
                      <LogoContainer>
                        <LocationLogo
                          src={dispensary.logoImage ?? ''}
                          alt={dispensary.name ?? ''}
                          width={14}
                          height={14}
                        />
                      </LogoContainer>
                    </PinIconContainer>
                  </MarkerContainer>
                  <MarkerLabel isVisible={hoveredDispensaryId === dispensary.id}>
                    <MarkerLabelContent>
                      <MarkerLabelLogo
                        src={dispensary.logoImage ?? ''}
                        alt={dispensary.name ?? ''}
                        width={32}
                        height={32}
                      />
                      <div>
                        <MarkerName>{dispensary.name}</MarkerName>
                        <MarkerAddress>
                          {dispensary.location.city}, {dispensary.location.state}
                        </MarkerAddress>
                      </div>
                    </MarkerLabelContent>
                  </MarkerLabel>
                </div>
              </Marker>
            );
          })}
          <MapControls showCompass />
        </ReactMapGL>
      </MapContainer>
    </SplitContainer>
  );
};

const SplitContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  height: 100%;
  width: 100%;

  @media ${mediaQueries.largePhone} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

const LocationListSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 24px 24px 0;
`;

const MapContainer = styled.div`
  height: 100%;
  overflow: hidden;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;

  @media ${mediaQueries.largePhone} {
    border-radius: 0;
  }

  .mapboxgl-ctrl-logo {
    display: none !important;
  }
`;

const StateSection = styled.div`
  margin-bottom: 20px;
`;

const LocationsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-right: 16px;
  margin-bottom: 24px;

  ::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey[45]};
    border-radius: 4px;
  }
`;

const HeaderContainer = styled.div`
  padding: 24px 0;
  color: ${({ theme }) => theme.colors.v2TextColor1};
  position: relative;
`;

const BackArrowContainer = styled.div`
  padding-left: 10px;
  position: absolute;
`;

const BackArrow = styled(ChevronIcon)`
  fill: ${({ theme }) => theme.customized.colors.buttonsLinks};
  transform: rotate(90deg);
  height: 8px;
  margin-right: 5px;
  cursor: pointer;
`;

const AllLocations = styled.div`
  font-size: 21px;
  font-weight: bold;
  text-align: center;
`;

const StateName = styled.h1`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.v2TextColor1};
  margin: 20px 0 18px;
  &:first-of-type {
    margin-top: 0;
  }
`;

const Instructions = styled.div`
  margin-top: 5px;
  font-size: 13px;
  text-align: center;
`;

const PinIconContainer = styled.div`
  position: relative;
  width: 26px;
  height: 37px;
`;

const PinIcon = styled(MarkerIcon)<{ isHighlighted: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  path {
    fill: ${({ theme, isHighlighted }) =>
      isHighlighted ? theme.colors.blue[55] : theme.customized.colors.buttonsLinks};
  }
  transition: all 0.2s ease;
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LocationLogo = styled(Imgix)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const MapControls = styled(NavigationControl)`
  position: absolute;
  bottom: 30px;
  right: 30px;
`;

const MarkerLabel = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 8px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: all 0.2s ease;
  pointer-events: none;
  min-width: 150px;
  text-align: left;
`;

const MarkerLabelContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px;
  min-width: 200px;
`;

const MarkerLabelLogo = styled(Imgix)`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
`;

const MarkerName = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.v2TextColor1};
  margin-bottom: 4px;
`;

const MarkerAddress = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[45]};
`;

const MarkerContainer = styled.div<{ isHighlighted: boolean }>`
  cursor: pointer;
  transition: transform 0.2s ease;
  position: relative;

  &:hover {
    transform: scale(1.1);
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    `
    transform: scale(1.1);
  `}
`;

// Update the StyledModalClose styled component
const StyledModalClose = styled(ModalClose)`
  z-index: 1000;
  position: absolute;
  background: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background: ${({ theme }) => theme.colors.grey[45]};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 20px;
`;

const LoadingText = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.v2TextColor1};
`;
