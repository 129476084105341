import React from 'react';
import _ from 'lodash';
import { useApolloClient } from '@apollo/react-hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Box } from 'rebass/styled-components';

import useCart from 'hooks/use-cart';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useErnie from 'shared/hooks/use-ernie';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import useUser from 'hooks/use-user';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { useTurnstile } from 'src/components/turnstile';
import { TURNSTILE_ACTIONS, TurnstileAction } from 'shared/constants/turnstile';
import { consumerSignup } from 'src/state/actions/users';
import { DutchiePayHeader, DutchiePayTextBody } from 'src/payments/components';
import { SignUpForm, SignUpFormProps } from './sign-up-form';

export type SignUpFormWrapperProps = {
  titleText: string;
  subtitleText: string;
};

export function SignUpFormWrapper({ titleText, subtitleText }: SignUpFormWrapperProps): JSX.Element {
  const UI = useUI();
  const Cart = useCart();
  const apolloClient = useApolloClient();
  const { dispensary } = useDispensary();
  const showErnie = useErnie();
  const User = useUser();
  const { t } = useTranslation();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const flags = useFlags();
  const isTurnstileEnabled = flags['growth.ecomm.signup-turnstile-enable.rollout'] ?? false;

  const { TurnstileComponent, executeAndWaitForToken } = useTurnstile({
    size: 'flexible',
    action: TURNSTILE_ACTIONS.SIGNUP as TurnstileAction,
  });

  const handleFormSubmit = async ({
    firstName,
    lastName,
    password,
    phoneNumber,
    birthday,
    medicalCardNumber,
    expirationDate,
    state,
  }: SignUpFormProps): Promise<void> => {
    try {
      let turnstileToken = '';
      if (isTurnstileEnabled) {
        try {
          turnstileToken = await executeAndWaitForToken();
        } catch (error) {
          console.error(error);
          showErnie(t('signUp.turnstileError', 'Error validating your request. Please try again.'), 'danger');
          return;
        }
      }

      const profile = {
        email: DutchiePayEnrollment.userEmail,
        password,
        profile: {
          firstName: _.trim(firstName),
          lastName: _.trim(lastName),
          phone: phoneNumber,
          birthday,
          textNotifications: true,
          emailNotifications: true,
          medicalCard: state
            ? {
                number: state !== 'CO' ? medicalCardNumber : '',
                expirationDate,
                state,
              }
            : null,
        },
      };

      const userCreationData = {
        dispensaryId: dispensary?.id,
        embedded: UI.isEmbedded || UI.isStoreFront,
      };
      const { success, error } = await consumerSignup(
        apolloClient,
        User,
        Cart,
        UI,
        profile,
        userCreationData,
        turnstileToken
      );

      if (success) {
        DutchiePayEnrollment.signUpStepCompleted();
      } else if (error.message) {
        showErnie(error.message, 'danger');
      } else {
        showErnie(t('signUp.fail', 'We are unable to create your account. Please contact support.'), 'danger');
      }
    } catch (err) {
      console.error(err.message);
      showErnie(err.message, 'danger');
    }
  };

  const handleAgeCheck = (): void => undefined;

  return (
    <>
      <DutchiePayHeader>{titleText}</DutchiePayHeader>
      <DutchiePayTextBody>{subtitleText}</DutchiePayTextBody>
      <SignUpForm
        onFormSubmit={handleFormSubmit}
        showMedicalCardForm={false}
        userEmail={DutchiePayEnrollment.userEmail}
        ageCheck={handleAgeCheck}
      />
      {isTurnstileEnabled && (
        <Box mt='20px'>
          <TurnstileComponent />
        </Box>
      )}
    </>
  );
}
