import { init, registerPlugins, registerRemotes } from '@module-federation/runtime';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as styled from 'styled-components';
import * as mui from '@material-ui/core';

import reactPkg from 'react/package.json';
import reactDomPkg from 'react-dom/package.json';
import styledPkg from 'styled-components/package.json';
import muiPkg from '@material-ui/core/package.json';

import PluginOfflineRemotes from './plugin-offline-remotes';

type Remote = {
  name: string;
  alias: string;
  entry: string;
};

type SharedDependency = {
  version: string;
  lib: () => unknown;
  shareConfig: {
    singleton: boolean;
    requiredVersion: string;
  };
};

type SharedDependencies = Record<string, SharedDependency>;

type ModuleFederationConfig = {
  name: string;
  remotes: Remote[];
  shareStrategy: 'loaded-first';
  shared: SharedDependencies;
};

const sharedDependenciesConfigFactory = (
  pkgs: Array<[{ name: string; version: string }, unknown]>
): SharedDependencies => {
  const configObj: SharedDependencies = {};
  pkgs.forEach(([pkg, lib]) => {
    configObj[pkg.name] = {
      version: pkg.version,
      lib: () => lib,
      shareConfig: {
        singleton: true,
        requiredVersion: `^${String(pkg.version.split('.')[0])}`,
      },
    };
  });
  return configObj;
};

/**
 * Initializes Module Federation with the configured remotes and shared dependencies
 */
export const initializeModuleFederation = (): void => {
  // This is not an async function, it's just a one-time initialization
  // That sets the RUNTIME remotes for the host app
  // The shared packages are loaded at build time in next.config.js
  const config: ModuleFederationConfig = {
    name: '@dutchie/host',
    remotes: [],
    shareStrategy: 'loaded-first',
    shared: sharedDependenciesConfigFactory([
      [reactPkg, React],
      [reactDomPkg, ReactDOM],
      [styledPkg, styled],
      [muiPkg, mui],
    ]),
  };

  init(config);
  registerPlugins([PluginOfflineRemotes()]);

  try {
    if (typeof window !== 'undefined') {
      (window as Window & { __MF_INITIALIZED?: boolean }).__MF_INITIALIZED = true;
    }
  } catch (e) {
    console.error('Error setting __MF_INITIALIZED', e);
  }
};

export const ensureRemoteInitialized = ({ manifestURL, scope }: { manifestURL: string; scope: string }): boolean => {
  if (!manifestURL || !scope) {
    console.error('manifestURL and scope are required to register a remote', { manifestURL, scope });
    return false;
  }
  registerRemotes([
    {
      name: scope,
      alias: scope,
      entry: manifestURL,
    },
  ]);
  return true;
};
