import React from 'react';

import { ModalWrapper } from 'shared/modals/v2-wrapper';
import { ModalObserver } from '../modal-observer';
import { AccountChangeVerificationModal } from './account-change-verification-modal';

type ModalObserverRenderProps = { email: string; onResend: () => Promise<void> };

export const AccountChangeVerification = ({ name }: { name: string }): JSX.Element => (
  <ModalObserver name={name}>
    {(props) => {
      const { data } = props;
      const { email, onResend } = data as ModalObserverRenderProps;

      return (
        <ModalWrapper {...props}>
          <AccountChangeVerificationModal {...props} email={email} onResend={onResend} />
        </ModalWrapper>
      );
    }}
  </ModalObserver>
);
