import styled from 'styled-components';
import { fontSize } from 'styled-system';

/**
 * @typedef {import('styled-system').FontSizeProps} FontSizeProps
 */

var StyledHeader = /** @type {import('styled-components').ThemeStyledFunction<'h2', FontSizeProps>} */styled.h2.withConfig({
  displayName: "header__StyledHeader",
  componentId: "sc-mfk0p4-0"
})(["color:#46494c;font-size:15px;font-weight:bold;line-height:1;margin-bottom:", ";text-transform:", ";", ";"], function (props) {
  return props.mb ? props.mb : '21px';
}, function (props) {
  return props.lowercase ? 'none' : 'uppercase';
}, fontSize);
export default StyledHeader;