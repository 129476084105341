/**
 * Creates a fallback plugin for Webpack Module Federation to handle remote loading failures.
 * This plugin provides graceful degradation when remote modules fail to load or when network
 * requests to fetch remote modules fail.
 */

export default function createFallbackPlugin() {
  return {
    name: 'fallback-plugin',
    errorLoadRemote(args) {
      console.error('errorLoadRemote', args);

      if (args.lifecycle === 'onLoad') {
        return 'fallback';
      }

      if (args.lifecycle === 'beforeRequest' || args.lifecycle === 'afterLoad') {
        return args;
      }

      // Handle unexpected lifecycle values
      console.warn(`Unexpected lifecycle value encountered: ${String(args.lifecycle)}`);
      return args;
    },
  };
}
