import React from 'react';
import styled from 'styled-components';
import { object, SchemaOf, string } from 'yup';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FormTextInput } from 'components/form-inputs';
import Form from 'components/forms/form';
import useTranslation, { ExtendedUseTranslationResponse } from 'hooks/use-translation';
import SvgQuestionCircleIcon from 'assets/question-circle-icon';
import { useDutchiePayAnalytics } from 'src/payments/hooks/use-dutchie-pay-analytics/use-dutchie-pay-analytics';
import useDutchiePayEnrollment from 'hooks/use-dutchiePay-enrollment';
import { useTurnstile } from 'src/components/turnstile';
import { TURNSTILE_ACTIONS, TurnstileAction } from 'shared/constants/turnstile';

import { EnrollmentSteps } from 'src/payments/constants';
import {
  DutchiePayButtonContainer,
  DutchiePayHeader,
  DutchiePayTextBody,
  DutchiePayTextButton,
  EmailDisplay,
} from 'src/payments/components';

export type DutchiePayPasswordFormProps = {
  onSubmit: ({ password }: PasswordInput) => Promise<void>;
  onForgotPassword: () => void;
  onLearnMore: () => void;
  userEmail: string;
  onBackClick?: () => void;
  showToolTip?: boolean;
};

export type PasswordInput = {
  password: string;
};

const loginFormValidation = (t: ExtendedUseTranslationResponse['t']): SchemaOf<PasswordInput> =>
  object().shape({
    password: string().required(t(`form.passwordRequired`, `password is required`)),
  });

const VOWEL_FIRST = /^[aeiouAEIOU]/;
const addArticle = (word: string): string => (VOWEL_FIRST.test(word) ? `an ${word}` : `a ${word}`);

export function PasswordForm({
  onSubmit,
  onForgotPassword,
  onLearnMore,
  userEmail,
  showToolTip,
  onBackClick,
}: DutchiePayPasswordFormProps): JSX.Element {
  const { t } = useTranslation();
  const { trackEnrollmentStepSubmitted } = useDutchiePayAnalytics();
  const { dispensary } = useDutchiePayEnrollment();
  const flags = useFlags();

  const { TurnstileComponent, executeAndWaitForToken } = useTurnstile({
    size: 'flexible',
    action: TURNSTILE_ACTIONS.LOGIN as TurnstileAction,
  });

  const handleSubmit = async (vals): Promise<void> => {
    trackEnrollmentStepSubmitted(EnrollmentSteps.passwordForm);

    let token = '';
    if (flags['growth.ecomm.login-turnstile-enable.rollout']) {
      try {
        token = await executeAndWaitForToken();
      } catch (error) {
        return;
      }
    }

    void onSubmit({ ...vals, turnstileToken: token });
  };

  const brandedDPNameWithArticle = dispensary.useWhiteLabel
    ? addArticle(dispensary.brandedDutchiePayName)
    : `a Dutchie`;

  return (
    <>
      <StyledDutchiePayHeader>
        {t('dutchiePay.youHaveAccount', 'You have {{company}} account!', {
          company: dispensary.useWhiteLabel ? brandedDPNameWithArticle : `a Dutchie`,
        })}
        {showToolTip && <SvgQuestionCircleIcon onClick={onLearnMore} />}
      </StyledDutchiePayHeader>
      <DutchiePayTextBody>{t('dutchiePay.enterPassword', `Enter your password to continue.`)}</DutchiePayTextBody>

      <EmailDisplay>{userEmail}</EmailDisplay>

      <Form onSubmit={handleSubmit} mode='onTouched' validationSchema={loginFormValidation(t)}>
        <FormTextInput label='Password' type='password' name='password' variant='filled' />

        <DutchiePayTextButton type='button' onClick={onForgotPassword} mt={18} mb={20}>
          {t('common.forgotPassword', 'Forgot password?')}
        </DutchiePayTextButton>

        {flags['growth.ecomm.login-turnstile-enable.rollout'] && <TurnstileComponent />}

        <DutchiePayButtonContainer
          showBackLink
          onBackLinkClick={onBackClick}
          requiredFieldKeys={[`password`]}
          disabled={false}
        >
          {t('common.continue', 'Continue')}
        </DutchiePayButtonContainer>
      </Form>
    </>
  );
}

const StyledDutchiePayHeader = styled(DutchiePayHeader)`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    margin-left: 8px;
  }
`;
