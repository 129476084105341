import React from 'react';
import styled, { css } from 'styled-components';
import PopupState from 'material-ui-popup-state';
import { bindHover, bindPopover, bindTrigger, PopupState as PopupStateType } from 'material-ui-popup-state/hooks';
import { useObserver } from 'mobx-react-lite';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useSwitchMenuType from 'src/hooks/use-switch-menu-type';
import useCart from 'src/hooks/use-cart';
import { useHeader } from 'src/dispensary/core-menu/components/header/use-header';
import SvgChevronIcon from 'src/assets/chevron-icon';
import {
  buttonResetStyles,
  PopoverNavButton,
  StyledHoverPopover,
} from 'src/dispensary/core-menu/components/header/header.styles';

const menuTypes = [
  { key: `rec`, label: `Recreational` },
  { key: `med`, label: `Medical` },
];

export const MenuType = (): JSX.Element => {
  const { dispensary } = useDispensary();
  const { recDispensary, medicalDispensary } = dispensary;
  const showMenuTypeDropdown = recDispensary && medicalDispensary;
  const switchMenuType = useSwitchMenuType();
  const Cart = useCart();
  const currentMenuType = useObserver(() => Cart.menuType);
  const { trackEvent } = useHeader();

  // No dropdown, just need to display the type
  if (!showMenuTypeDropdown) {
    return (
      <div>
        <MenuTypeLabel>Menu</MenuTypeLabel>
        <SingleMenuType>{recDispensary ? 'Recreational' : 'Medical'}</SingleMenuType>
      </div>
    );
  }

  const handleClick = (key: string, popupState: PopupStateType): void => {
    void switchMenuType(key);
    trackEvent(`menu type > ${key}`);
    popupState.close();
  };

  return (
    <PopupState variant='popover' popupId='menuTypePopover'>
      {(popupState) => (
        <>
          <div>
            <MenuTypeLabel>Menu</MenuTypeLabel>
            {/* Also bindTrigger for keyboard and touch device support */}
            <MenuTypeButton
              {...bindHover(popupState)}
              {...bindTrigger(popupState)}
              data-testid='menu-type-popover-toggle'
            >
              <div>{currentMenuType === 'rec' ? 'Recreational' : 'Medical'}</div>
              <SvgChevronIcon height='7px' width='12px' />
            </MenuTypeButton>
          </div>

          <MenuTypePopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            data-testid='menu-type-popover'
            $offsetTop
          >
            <nav>
              {menuTypes.map(({ key, label }) => (
                <PopoverNavButton
                  key={key}
                  onClick={() => handleClick(key, popupState)}
                  $isActive={currentMenuType === key}
                  data-testid={`menu-type-${key}`}
                >
                  {label}
                </PopoverNavButton>
              ))}
            </nav>
          </MenuTypePopover>
        </>
      )}
    </PopupState>
  );
};

const MenuTypeLabel = styled.div`
  color: #a0a7ab;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 13px;
  font-weight: 700;
  line-height: ${18 / 13};
  text-transform: uppercase;

  @media (max-width: 1019px) {
    display: none;
  }
`;

const menuTypeSelectionStyles = css`
  color: #485055;
  font-size: 13px;
  font-weight: 600;
  line-height: ${18 / 13};

  @media (max-width: 1019px) {
    font-family: ${({ theme }) => theme.customized.fonts.secondary};
  }

  @media (min-width: 1020px) {
    color: ${({ theme }) => (theme.customized.utils.isNavBarDark ? '#fff' : '#485055')};
  }
`;

const SingleMenuType = styled.div`
  ${menuTypeSelectionStyles}
`;

const MenuTypeButton = styled.button`
  ${buttonResetStyles}
  ${menuTypeSelectionStyles}

  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  position: relative;

  /* Space between popover to maintain mouseenter state */
  &::after {
    content: '';
    height: 8px;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
  }
`;

const MenuTypePopover = styled(StyledHoverPopover)`
  @media (max-width: 1019px) {
    font-family: ${({ theme }) => theme.customized.fonts.secondary};
  }
`;
