import { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useGetRewardsV2Query } from 'types/graphql';
import { useInstoreEnrollment } from 'src/hooks/use-instore-enrollment';
import { InstoreEnrollmentStatus } from 'src/state/instore-enrollment';
import useUser from 'src/hooks/use-user';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useDutchiePay } from 'src/payments/hooks/use-dutchie-pay/use-dutchie-pay';

type UseInstoreEnrollmentReturn = {
  currentStatus: InstoreEnrollmentStatus;
};

export function useInstoreEnrollmentPage(): UseInstoreEnrollmentReturn {
  const instoreEnrollment = useInstoreEnrollment();
  const currentStatus = useObserver(() => instoreEnrollment.currentStatus);
  const user = useUser();
  const userIsLoggedIn = useObserver(() => user.isLoggedIn) ?? false;
  const userIsLoading = useObserver(() => user.loading) ?? true;
  const { dispensary } = useDispensary();
  const { data: rewardsV2Data, loading: rewardsV2DataLoading } = useGetRewardsV2Query({
    skip: !user.phone || !dispensary?.id,
    variables: {
      phoneNumber: user.phone,
      dispensaryId: dispensary?.id,
    },
  });

  const { isEnrolledDutchiePay, loading: dutchiePayLoading } = useDutchiePay();

  const customerHasRewardsAccount = rewardsV2Data?.getRewardsV2?.userHasWallet;

  useEffect(() => {
    // If user is not logged in and not loading, show login
    if (!userIsLoggedIn && !userIsLoading) {
      instoreEnrollment.setCurrentStatus(InstoreEnrollmentStatus.ecommLogin);
      return;
    }

    // Keep current status while loading rewards data
    if (rewardsV2DataLoading || !rewardsV2Data || dutchiePayLoading) {
      return;
    }

    // If user has a wallet, skip loyalty enrollment
    if (customerHasRewardsAccount && !isEnrolledDutchiePay) {
      instoreEnrollment.setCurrentStatus(InstoreEnrollmentStatus.payByBankEnrollment);
      return;
    }

    if (customerHasRewardsAccount && isEnrolledDutchiePay) {
      instoreEnrollment.setCurrentStatus(InstoreEnrollmentStatus.finishedEnrollment);
      instoreEnrollment.setIsFullyEnrolled();
      return;
    }

    // Only show loyalty enrollment if user is logged in and doesn't have a wallet
    instoreEnrollment.setCurrentStatus(InstoreEnrollmentStatus.loyaltyEnrollment);
  }, [
    userIsLoggedIn,
    userIsLoading,
    rewardsV2DataLoading,
    customerHasRewardsAccount,
    instoreEnrollment,
    rewardsV2Data,
    dutchiePayLoading,
    isEnrolledDutchiePay,
  ]);

  return { currentStatus };
}
