import { NormalizedCacheObject } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';
import { ApolloClient, ApolloError } from 'apollo-client';
import gql from 'graphql-tag';

const GET_CUSTOMIZATION_PROVIDER = gql`
  query GetCustomizationProvider($dispensaryId: String, $chainId: String) {
    findCustomizationProvider(dispensaryId: $dispensaryId, chainId: $chainId) {
      manifestURL
      scope
      themeName
    }
  }
`;

type CustomizationProvider = {
  manifestURL: string;
  scope: string;
  themeName: string;
} | null;

type CustomizationProviderVariables = {
  dispensaryId?: string;
  chainId?: string;
  client: ApolloClient<NormalizedCacheObject>;
};

export function useCustomizationProvider(
  variables: CustomizationProviderVariables
): {
  customizationProvider: CustomizationProvider;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { data, loading, error } = useQuery(GET_CUSTOMIZATION_PROVIDER, {
    variables,
    client: variables.client,
    skip: !variables.dispensaryId && !variables.chainId,
  });

  return {
    customizationProvider: data?.findCustomizationProvider ?? null,
    loading,
    error,
  };
}
