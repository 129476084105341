import ApolloClient from 'apollo-client';
import { GqlDispensaries } from 'types/graphql';
import Cart from 'src/hooks/use-cart';
import { ERNIE_TYPES } from 'shared/constants';
import CreateSpringbigUser from 'shared/graphql/reward/mutations/create-springbig-user.gql';
import {
  getDefaultRewardsProgramDisplayName,
  getDefaultRewardsProgramDisplayDescription,
} from 'shared/core/helpers/dispensaries';
import getRewardsV2 from 'shared/graphql/reward/queries/get-rewards-V2.gql';
import { formatBirthdayForRewards } from 'shared/helpers/rewards';
import { setLoyaltyData } from '../loyalty.helpers';
import { Statuses, UseLoyaltyReturn, ShowErnie } from './types';

type User = {
  id: string;
  phone: string;
  birthday: string;
};

type Dependencies = {
  dispensary: GqlDispensaries;
  cart: ReturnType<typeof Cart>;
  user: User;
  apolloClient: ApolloClient<object>;
  showErnie: ShowErnie;
};

type SpringbigLoyaltyProps = {
  status: Statuses;
  setStatus: (status: Statuses) => void;
  deps: Dependencies;
};

export const createSpringbigLoyalty = ({ status, setStatus, deps }: SpringbigLoyaltyProps): UseLoyaltyReturn => {
  const { dispensary, cart, user: User, apolloClient, showErnie } = deps;
  const programName =
    dispensary.storeSettings.rewardsIntegrationConfiguration?.rewardsProgramDisplayName ??
    getDefaultRewardsProgramDisplayName({ location: dispensary.location ?? {} });
  const programDescription =
    dispensary.storeSettings.rewardsIntegrationConfiguration?.rewardsProgramDisplayDescription ??
    getDefaultRewardsProgramDisplayDescription({ location: dispensary.location ?? {} });

  const createUserAndPin = async (): Promise<void> => {
    setStatus(Statuses.loading);

    try {
      const { data: createdUserData } = await apolloClient.mutate({
        mutation: CreateSpringbigUser,
        variables: {
          dispensaryId: dispensary.id,
          userId: User.id,
        },
      });

      if (!createdUserData) {
        showErnie('Error creating account, please try again.', ERNIE_TYPES.DANGER);
        setStatus(Statuses.terms);
        return;
      }

      const fetchStatus = await fetchWallet(cart, User, dispensary.id, apolloClient);

      if (fetchStatus === Statuses.signup) {
        showErnie('Error creating account, please try again.', ERNIE_TYPES.DANGER);
      }

      setStatus(fetchStatus);
    } catch (error) {
      console.error(error);

      showErnie('Error creating account, please try again.', ERNIE_TYPES.DANGER);
      setStatus(Statuses.terms);
    }
  };

  return {
    createUserAndPin,
    status,
    setStatus,
    programName,
    programDescription,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    processPin: async (_pin) => Promise.resolve(),
    resendPin: async () => Promise.resolve(),
  };
};

const fetchWallet = async (
  cart: ReturnType<typeof Cart>,
  user: User,
  dispensaryId: string,
  apolloClient: ApolloClient<object>
): Promise<Statuses> => {
  const { loading, data } = await apolloClient.query({
    query: getRewardsV2,
    variables: {
      dispensaryId,
      phoneNumber: user.phone,
      dob: formatBirthdayForRewards(user.birthday),
      pin: '',
      customerId: user.id,
    },
  });

  if (loading) {
    return Statuses.loading;
  }

  if (!data?.getRewardsV2) {
    return Statuses.signup;
  }

  setLoyaltyData({
    user,
    cart,
    data,
    dispensaryId,
  });

  return Statuses.success;
};
