import React, { forwardRef } from 'react';
import { useObserver } from 'mobx-react-lite';

import useUser from 'hooks/use-user';
import useTranslation from 'hooks/use-translation';

import { ModalContainer, ModalExitIcon } from 'components/core';
import { ModalContent } from 'components/modals/components';
import { ModalObserverRenderProps } from 'components/modals/modal-observer';
import { StyledHeading, Message, StyledButton, StyledLink } from './account-change-verification.styles';
import { DeviceNotice } from './device-notice';
import { EmailSent } from './email-sent';

type AccountChangeVerificationResultModalProps = ModalObserverRenderProps & {
  email?: string;
  result: 'failure' | 'success';
  onResend?: () => Promise<void>;
  customOnClose?: () => void;
};

export const AccountChangeVerificationResultModal = forwardRef<HTMLElement, AccountChangeVerificationResultModalProps>(
  ({ containerProps, onClose, email, result, onResend, customOnClose }, ref) => {
    const { t } = useTranslation();
    const User = useUser();
    const isVerificationTokenSent = useObserver(() => User.isVerificationTokenSent);

    const handleClose = (): void => {
      onClose();
      customOnClose?.();
    };

    return (
      <ModalContainer ref={ref} width={420} padding={0} {...containerProps}>
        <ModalExitIcon onClick={handleClose} />
        <ModalContent pt={24} pb={37}>
          {result === 'success' && (
            <>
              <StyledHeading mb={24}>{t('account.verificationSuccess', 'Email verified')}</StyledHeading>
              <Message size='medium'>
                {t(
                  'account.verificationSuccess',
                  'Your email address has been successfully verified. You can now make changes to your account.'
                )}
              </Message>
            </>
          )}
          {result === 'failure' && (
            <>
              <StyledHeading mb={24}>{t('account.verificationFailure', 'Invalid Link')}</StyledHeading>
              <Message size='medium'>
                {t(
                  'account.verificationFailure',
                  "It looks like your email verification link is invalid or has expired. But don't worry—you can request a new one by clicking the link below:"
                )}
              </Message>
              <Message size='medium'>
                <StyledLink onClick={onResend}>Resend verification email</StyledLink>
                <EmailSent isVisible={isVerificationTokenSent} />
              </Message>
              {email && (
                <Message size='medium'>
                  Email will be sent to:
                  <br />
                  <strong>{email}</strong>
                </Message>
              )}
              <DeviceNotice />
            </>
          )}
          <StyledButton variant='primary' fullWidth onClick={handleClose}>
            {t('common.ok', 'OK')}
          </StyledButton>
        </ModalContent>
      </ModalContainer>
    );
  }
);
