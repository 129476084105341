// From https://github.com/juliencrn/usehooks-ts package, but requires node 16

import { RefObject, useEffect, useState, useMemo } from 'react';

/**
 * Extended IntersectionObserver configuration options
 * @typedef {Object} Args
 * @property {boolean} [freezeOnceVisible] - If true, stops observing once element becomes visible
 */
type Args = IntersectionObserverInit & {
  freezeOnceVisible?: boolean;
};

/**
 * A React hook that tracks the intersection of a DOM element with its viewport or a specified root element
 * using the IntersectionObserver API.
 *
 * @param elementRef - React ref object pointing to the DOM element to observe
 * @param options - Configuration options for IntersectionObserver and additional hook behavior
 * @returns The latest IntersectionObserverEntry or undefined if not yet observed
 *
 * @example
 * const elementRef = useRef(null);
 * const entry = useIntersectionObserver(elementRef, { threshold: 0.5 });
 * const isVisible = entry?.isIntersecting;
 */
export function useIntersectionObserver(
  elementRef: RefObject<Element>,
  options?: Args
): IntersectionObserverEntry | undefined {
  // Track the latest intersection entry
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  // Destructure options with defaults
  const { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false } = options ?? {};

  // Determine if observation should be frozen (element has become visible and freezeOnceVisible is true)
  const frozen = entry?.isIntersecting && freezeOnceVisible;

  // Callback to update the entry state when intersection changes
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  // Memoize observer parameters to prevent unnecessary observer recreations
  const observerParams = useMemo(() => ({ threshold, root, rootMargin }), [threshold, root, rootMargin]);

  useEffect(() => {
    const node = elementRef.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    // Skip observation if:
    // - Browser doesn't support IntersectionObserver
    // - Element is already visible and freezeOnceVisible is true
    // - Target element ref is not available
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!hasIOSupport || frozen || !node) {
      return;
    }

    // Create and start observing with configured parameters
    const observer = new IntersectionObserver(updateEntry, observerParams);
    observer.observe(node);

    // Cleanup: stop observing when component unmounts or dependencies change
    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  }, [elementRef, observerParams, frozen]);

  return entry;
}
