import { Flex, Text, Box } from 'rebass';
import React from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import { width } from 'styled-system';
import { useObserver } from 'mobx-react-lite';
import { useApolloClient } from '@apollo/react-hooks';
import { updateTextSetting as UPDATE_TEXT_SETTING } from 'shared/graphql/user/mutations';

import { ResetButton } from 'shared/components';
import { Receipt } from 'shared/components/receipt';
import { mediaQueries } from 'shared/styles';
import { useDutchiePay } from 'src/payments/hooks';
import useStyledTheme from 'shared/hooks/use-styled-theme';

import { formatEstimate, getAfterHoursWindow, formatAfterHoursEstimate } from 'shared/helpers/order-estimates';
import { getDispensaryHomePageUrl } from 'shared/helpers/dispensaries';
import { openInfoForDispensary } from 'shared/core/helpers/dispensaries';
import { getScheduledOrderTime, willSendOrderReadySMS } from 'shared/helpers/orders';
import useUI from 'hooks/use-ui';
import useUser from 'hooks/use-user';
import useTranslation from 'hooks/use-translation';
import { changeNativeTabToShop } from 'src/mobile-ecomm/hooks/use-mobile-native-bridge';
import { InProgress, Incompleted, Completed } from 'assets/order-status-steps';
import { SMSUpdateBanner } from 'src/components/modals/components/sms-update-banner';
import DutchiePayCheckoutSuccessCTA from 'src/payments/components/checkout-success-cta';
import { CreditAuthNotification } from 'src/payments/components';

const getSteps = (order, t) => {
  const all = [{ copy: t('orderStatus.orderSent', 'Your order has been sent to the dispensary.'), status: `open` }];
  let pickup, delivery;

  if (order.autoClosed) {
    pickup = [
      {
        copy: t(`orderStatus.confirmedPickup.${order.dispensary.name}`, {
          defaultValue: `Your order has been confirmed by {{dispensaryName}} and will be ready in approximately 15-25 mins.`,
          dispensaryName: order.dispensary.name,
        }),
        status: `closed`,
      },
    ];
    delivery = [
      {
        copy: t(`orderStatus.confirmedDelivery.${order.dispensary.name}`, {
          defaultValue: `Your order has been confirmed by {{dispensaryName}} and will arrive in approximately {{deliveryEstimate}}.`,
          dispensaryName: order.dispensary.name,
          deliveryEstimate: formatEstimate(order.durationEstimates.delivery, `delivery`),
        }),
        status: `closed`,
      },
    ];
  } else {
    all.push({ copy: t('orderStatus.orderBeingPrepared', 'Your order is being prepared.'), status: `confirmed` });
    delivery = [
      { copy: t('orderStatus.orderOnItsWay', 'Your order is on its way!'), status: `inTransit` },
      { copy: t('orderStatus.orderArrived', 'Your order has arrived! Time to enjoy!'), status: `closed` },
    ];
    pickup = [{ copy: t(`orderStatus.orderReady`, `Your order is ready. Time to go pick it up!`), status: `closed` }];
  }

  const copyArray = [...all, ...(order.deliveryInfo?.deliveryOption ? delivery : pickup)];

  let pastCurrent = false;
  return copyArray.map((copyObject) => {
    const el = copyObject;
    if (el.status === order.status) {
      el.bold = true;
      pastCurrent = true;
    } else if (pastCurrent) {
      el.undone = true;
    } else {
      el.completed = true;
    }
    return el;
  });
};

const getDetailedServiceType = (order) => {
  const { isCurbsidePickupOrder, isDriveThruPickupOrder, deliveryInfo } = order;
  if (deliveryInfo.deliveryOption) {
    return `delivery`;
  }
  if (isCurbsidePickupOrder) {
    return `curbsidePickup`;
  }
  if (isDriveThruPickupOrder) {
    return `driveThruPickup`;
  }
  return `inStorePickup`;
};

export default function OrderStatus({ order, dispensary, onClose }) {
  const UI = useUI();
  const { isDutchieMain, isMobileEcommApp } = useUI();
  const user = useUser();
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const { isEnrolledDutchiePay } = useDutchiePay();
  const isDutchiePayEnabledForDispo = dispensary.paymentTypesAccepted.dutchiePay;

  const theme = useStyledTheme();

  const dispensaryUrl = UI.isKiosk
    ? null
    : getDispensaryHomePageUrl(order.dispensary, UI.isEmbedded, UI.isMobileEcommApp);

  const textNotifications = useObserver(() => user.profile.textNotifications);
  const showOrderReadySMSNotice = willSendOrderReadySMS(dispensary, order, textNotifications);

  const openInfo = openInfoForDispensary(dispensary, {
    userTimezone: UI.timezone,
    previewMode: UI.previewMode,
  });

  const { isAfterHoursOrder, deliveryInfo, reservation } = order;
  const isDelivery = deliveryInfo.deliveryOption === true;
  const serviceType = isDelivery ? `delivery` : `pickup`;

  const afterHoursServiceType = getDetailedServiceType(order);

  let orderTimeTypeText, orderTime;
  if (reservation?.startTimeISO) {
    const scheduledOrderTime = getScheduledOrderTime({ reservation, timezone: order.dispensary.timezone });

    orderTimeTypeText = `${_.startCase(serviceType)} time: `;
    orderTime = `${scheduledOrderTime.prefix}, ${scheduledOrderTime.startTime} - ${scheduledOrderTime.endTime}`;
  } else if (isAfterHoursOrder) {
    orderTimeTypeText = `${_.startCase(serviceType)} time: `;
    orderTime = _.upperFirst(formatAfterHoursEstimate(getAfterHoursWindow(openInfo, afterHoursServiceType)));
  } else {
    orderTimeTypeText = `Est. ${_.startCase(serviceType)} time: `;
    orderTime = formatEstimate(_.get(order, `durationEstimates[${serviceType}]`), serviceType);
  }

  const shouldNotShowDeliveryTime = !(
    isDelivery &&
    order.durationEstimates?.delivery &&
    !order.dispensary.featureFlags?.hideDeliveryEstimate
  );

  let OrderText = `Order #: ${order.orderId} | ${orderTimeTypeText} ${orderTime}`;
  if (serviceType === 'delivery' && shouldNotShowDeliveryTime) {
    [OrderText] = OrderText.split('|'); // remove estimate
  }

  const toggleTextStatus = async () => {
    let result = {};

    try {
      result = await apolloClient.mutate({
        mutation: UPDATE_TEXT_SETTING,
        variables: {
          enabled: !textNotifications,
        },
      });
    } catch (error) {
      console.error(error);
      return { error, success: false };
    }

    if (result?.data?.updateTextSetting.success) {
      user.profile.textNotifications = !textNotifications;
    } else {
      UI.showErnie(
        t('orderStatus.problemWithPreferences', 'There was a problem updating your preferences. Please try again.'),
        `danger`
      );
    }
  };

  return (
    <>
      <OrderStatusContainer
        data-cy='thanks-order-modal'
        data-test='thanks-order-modal'
        data-testid='thanks-order-modal'
      >
        <Text
          fontSize='18px'
          fontWeight='bold'
          color='#46494c'
          mb='20px'
          data-test='thanks-modal-title'
          data-testid='thanks-modal-title'
        >
          {t('orderStatus.thankYou', 'Thank you for your order!')}
        </Text>
        <Text
          fontSize='14px'
          color='#4a4a4a'
          data-test='thanks-modal-order-details'
          data-testid='thanks-modal-order-details'
        >
          {OrderText}
        </Text>
        <Divider width={['100%', '100%', '400px', '100%']} />

        {order.paymentMethod === 'rethinkPay' ? (
          <Box mb={theme.spaces[6]}>
            <CreditAuthNotification />
          </Box>
        ) : null}

        <StatusHeaderContainer width={['100%', '100%', '400px', '100%']} mb={showOrderReadySMSNotice ? '20px' : '34px'}>
          <Text fontSize='16px' fontWeight='bold' color='#45484b'>
            {t('orderStatus.status', 'Status')}
          </Text>
          <Text fontSize='13px' color='#4a4a4a' lineHeight='16px'>
            {t('orderStatus.textUpdates', 'Text Updates:')}&nbsp;
            <TextStatus data-cy='text-update-switch' data-test='text-update-switch' onClick={toggleTextStatus}>
              {textNotifications ? t('common.on', 'On') : t('common.off', 'Off')}
            </TextStatus>
          </Text>
        </StatusHeaderContainer>

        {showOrderReadySMSNotice && <SMSUpdateBanner orderType={serviceType} />}

        <Flex width={['100%', '100%', '400px', '100%']} flexDirection='column' justifyContent='flex-start'>
          {getSteps(order, t).map((step) => (
            <StepContainer key={step.copy}>
              <StepIcon
                data-cy='order-success-steps'
                data-test='order-success-steps'
                data-testid='order-success-steps'
                height='34px'
                width='34px'
                step={step}
              />{' '}
              <Text
                fontSize='14px'
                color='#4a4a4a'
                ml='18px'
                fontWeight={step.bold ? 'bold' : 'regular'}
                lineHeight='1.3'
              >
                {step.copy}
              </Text>
            </StepContainer>
          ))}
        </Flex>
        {isDutchiePayEnabledForDispo && !isEnrolledDutchiePay && (
          <DutchiePayCheckoutSuccessCTA dispensary={dispensary} onClose={onClose} />
        )}
      </OrderStatusContainer>
      <ReceiptContainer>
        <Receipt
          order={order}
          dispensaryUrl={dispensaryUrl}
          isDutchieMain={isDutchieMain}
          onLogoClick={
            isMobileEcommApp
              ? (e) => {
                  e.preventDefault();
                  changeNativeTabToShop();
                }
              : undefined
          }
        />
      </ReceiptContainer>
    </>
  );
}

const StepIcon = ({ step, ...props }) => {
  let Icon = InProgress;
  if (step.completed) {
    Icon = Completed;
  } else if (step.undone) {
    Icon = Incompleted;
  }
  return (
    <StepIconContainer>
      <Icon {...props} />
    </StepIconContainer>
  );
};

const StepIconContainer = styled.div`
  flex-shrink: 0;
  z-index: 1;
`;

const containerCss = css`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @media ${mediaQueries.largePhone} {
    width: 100%;
    align-items: center;
    border-radius: 0px;
    flex-shrink: 0;
  }
`;

const OrderStatusContainer = styled(Flex)`
  ${containerCss}
  padding: 39px 44px 44px;
  width: 57.8%;
  border-radius: 28px 0 0 28px;
`;

const ReceiptContainer = styled(Box)`
  ${containerCss}
  padding: 39px 74px 44px 44px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  background-color: #edf1f3;
  width: 42.3%;
  border-radius: 0 28px 28px 0;

  @media ${mediaQueries.largePhone} {
    padding: 39px 44px 44px;
  }

  ${({ theme }) => theme?.breakpoints?.down('sm')} {
    max-height: unset;
  }

  @-moz-document url-prefix() {
    &:after {
      content: '';
      height: 44px;
      display: block;
    }
  }
`;

const Divider = styled.div`
  ${({ theme: { spaces } }) => css`
    ${width}
    margin: ${spaces[5]} 0 ${spaces[6]} 0;
    min-height: 1px;
    background-color: #d3dadf;
    @media ${mediaQueries.tablet} {
      margin: ${spaces[8]} 0 ${spaces[7]};
    }
  `}
`;

const StatusHeaderContainer = styled(Flex)`
  max-width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

const TextStatus = styled(ResetButton)`
  font-size: 13px;
  color: #4597e0;
  font-weight: bolder;
  display: inline-block;
  cursor: pointer;
  vertical-align: top;
  line-height: 16px;
  &:active,
  &:focus,
  &:hover {
    color: #4597e0;
  }
  &&&:focus-visible {
    box-shadow: 0 0 0pt 2pt rgb(11 153 230 / 40%);
  }
`;

const StepContainer = styled(Flex)`
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-bottom: 52px;
  &:last-of-type {
    padding-bottom: 0;
  }
  img {
    z-index: 2;
  }
  &:not(:last-child):before {
    z-index: 1;
    background-color: #d3dadf;
    content: ' ';
    display: block;
    height: calc(100% + 2px); /* account for top -1 of img height */
    left: 17px;
    position: absolute;
    top: 1px;
    width: 1px;
  }
  @media ${mediaQueries.tablet} {
    &:last-of-type {
      padding-bottom: 0px;
    }
  }
`;
