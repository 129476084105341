import Big from 'big.js';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useObserver } from 'mobx-react-lite';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { mediaSizes } from 'theme';
import { makeStyles } from '@material-ui/core/styles';

import usePaths from 'hooks/use-paths';
import { useProductDemandCountsQuery } from 'types/graphql';
import useTranslation from 'hooks/use-translation';
import { isWeightedProduct, getRangeForQuantityDropdown, getQuantityRemaining } from 'shared/helpers/products';
import { hasSaleSpecialForMenuType, productSatisfiesSaleWeight } from 'shared/helpers/specials';
import { groupOptionsWithPrices } from 'utils/helpers/product';

import { Link, Option, Select, TextButton } from 'components/core';
import ProductImage from 'components/product-image';
import TrashCanIcon from 'src/assets/trash-can-icon';
import DiscountBadge from 'shared/components/discount-badge/discount-badge';

import { Typography } from 'src/typography';
import { Quantity } from 'src/components/quantity';
import { QuantitySelect } from 'src/components/quantity-select';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';
import { SkeletonLoader } from 'shared/components';
import { usePriceCartCalcs } from 'src/hooks/use-price-cart-calcs';
import { useCartPricing } from 'hooks/use-cart-pricing/use-cart-pricing';
import { useProductUrgencyEnabled } from 'src/hooks/use-product-urgency-enabled';

const useWeightSelectStyles = makeStyles({
  paper: {
    width: 100,
  },
  list: {
    '& li': {
      minHeight: `0`,
    },
  },
});

export default function CartItem(props) {
  const {
    closeCheckoutModal,
    item,
    onWeightChange,
    onQuantityChange,
    onRemoveItem,
    isKiosk,
    menuType,
    dispensary,
    onProductDemandChange,
    showDemandCount,
    isCalculating,
  } = props;

  const { t } = useTranslation();

  const priceCartEnabled = usePriceCartCalcs();
  const { getItemPrice } = useCartPricing();
  const { originalPrice, adjustedPrice, bogoDiscountedPrice } = getItemPrice(item);

  const isMedical = useObserver(() => item.cart?.isMedical);
  const option = useObserver(() => item.option);
  const quantity = useObserver(() => item.quantity);
  const product = useObserver(() => item.product);
  const { href, route } = usePaths({ product, dispensary });

  const name = useObserver(() => product.name);
  const brand = useObserver(() => product.brand);
  const weightedProduct = useObserver(() => isWeightedProduct(product));

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`xs`));
  const isProductDemandEnabled = useProductUrgencyEnabled();

  const { data: productDemandCountsData, loading } = useProductDemandCountsQuery({
    variables: {
      dispensaryId: dispensary?.id,
      productIds: [item.id],
    },
    fetchPolicy: 'network-only',
    skip: !dispensary?.id || !item.id || !isProductDemandEnabled,
  });

  const productDemandCount = productDemandCountsData?.getProductDemandCounts[0];

  useEffect(() => {
    if (!loading && productDemandCount) {
      onProductDemandChange(item.id, productDemandCount);
    }
  }, [productDemandCount, onProductDemandChange, item.id, loading]);

  const weight = option?.value || option || `N/A`;
  const weightOptions = groupOptionsWithPrices({ product, menuType, isKiosk });
  const weightSelectClasses = useWeightSelectStyles();

  const isOnSale =
    Big(adjustedPrice).lt(Big(originalPrice)) &&
    hasSaleSpecialForMenuType({ product, medicalOrder: isMedical || false });
  const isWeightOnSale =
    isOnSale && product?.specialData?.saleSpecials
      ? productSatisfiesSaleWeight(product, product.specialData.saleSpecials, weight)
      : false;

  const quantitiesAvailableForSelection = getRangeForQuantityDropdown({
    product,
    option,
    quantityInCart: quantity,
    isKiosk,
  });
  const totalQuantityAvailable = getQuantityRemaining(product, weight, { isKiosk });
  const isQuantityLimitMaxAvailableEnabled = dispensary?.storeSettings?.quantityLimitMaxAvailable ?? false;
  const [hasQuantityError, setHasQuantityError] = useState(false);
  const hideDiscountsEnabled = useHideDiscountFromConsumer(dispensary);
  const hideDiscounts = isCalculating || hideDiscountsEnabled;

  return (
    <Container data-testid='cart-item-container'>
      <InnerContainer>
        <Link href={href} route={route}>
          <a href={href} onClick={closeCheckoutModal}>
            <StyledProductImage product={product} width={isMobile ? 55 : 81} height={isMobile ? 55 : 81} />
          </a>
        </Link>
        <DetailContainer>
          {!hideDiscounts && (
            <>
              {bogoDiscountedPrice && (
                <DiscountBadge
                  type='bogo'
                  originalPrice={originalPrice}
                  discountedPrice={bogoDiscountedPrice}
                  item={item}
                  isMobile={isMobile}
                  forceDollarFormat={priceCartEnabled}
                />
              )}
              {!bogoDiscountedPrice && isWeightOnSale && (
                <DiscountBadge
                  type='sale'
                  originalPrice={originalPrice}
                  discountedPrice={adjustedPrice}
                  item={item}
                  option={option.value || option}
                  forceDollarFormat={priceCartEnabled}
                />
              )}
            </>
          )}
          <Link href={href} route={route}>
            <a href={href} onClick={closeCheckoutModal}>
              <Name>{name}</Name>
            </a>
          </Link>
          {brand?.name && <Brand>{brand?.name}</Brand>}
          {item.additionalOption && <AdditionalOptionLabel>{item.additionalOption}</AdditionalOptionLabel>}
          <WeightedRemove>
            {weightedProduct && (
              <WeightSelect
                SelectDisplayProps={{
                  'aria-label': t('common.weight', 'Weight'),
                }}
                onChange={onWeightChange}
                value={option}
                mr='12px'
                disabled={weightOptions.length < 2}
                MenuProps={{ classes: { paper: weightSelectClasses.paper, list: weightSelectClasses.list } }}
              >
                {_.map(weightOptions, ({ label, value }) => (
                  <Option key={value} value={value}>
                    <OptionText>{label}</OptionText>
                  </Option>
                ))}
              </WeightSelect>
            )}
            {weightedProduct && <Separator />}
            <RemoveButton data-testid='remove-cart-item' onClick={onRemoveItem}>
              <TrashCanIcon />
              {t('common.remove', 'Remove')}
            </RemoveButton>
          </WeightedRemove>
        </DetailContainer>

        <OptionWrapper>
          <OptionContainer>
            {isQuantityLimitMaxAvailableEnabled ? (
              <Quantity
                onQuantityChange={onQuantityChange}
                quantity={quantity}
                totalQuantityAvailable={totalQuantityAvailable}
                setHasQuantityError={setHasQuantityError}
              />
            ) : (
              <QuantitySelect
                onQuantityChange={onQuantityChange}
                quantity={quantity}
                quantitiesAvailableForSelection={quantitiesAvailableForSelection}
              />
            )}
            {isCalculating ? (
              <SkeletonLoader height='40' width='80' />
            ) : (
              <Price showPriceDiscount={(!!bogoDiscountedPrice || !!isWeightOnSale) && !hideDiscounts}>
                {/* If the bogoDiscountedPrice is null and the item is not selected to be on sale with the hideDiscount flag enabled we still want to see  <div>${originalPrice}</div> */}
                {(!hideDiscounts || (!bogoDiscountedPrice && !isWeightOnSale)) && <div>${originalPrice}</div>}
                {bogoDiscountedPrice && <div>${bogoDiscountedPrice}</div>}
                {isWeightOnSale && !bogoDiscountedPrice && <div>${adjustedPrice}</div>}
              </Price>
            )}
          </OptionContainer>

          {hasQuantityError && (
            <QuantityError tag='div' size='medium'>
              {totalQuantityAvailable} max quantity available
            </QuantityError>
          )}
        </OptionWrapper>
      </InnerContainer>
      {showDemandCount && productDemandCount && (
        <ProductDemand>
          <p>High demand: {productDemandCount.count} others have this in their cart.</p>
        </ProductDemand>
      )}
    </Container>
  );
}

const AdditionalOptionLabel = styled.p`
  color: ${({ theme }) => theme.colors.grey[30]};
  font-size: 12px;
  line-height: 165%;

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    margin-top: 3px;
    font-size: 13px;
  }
`;

const WeightedRemove = styled.div`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  margin-top: 8px;
`;

const OptionText = styled.div`
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveButton = styled(TextButton)`
  color: ${({ theme }) => theme.colors.grey[45]};
  font-size: 12px;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 6px;
    position: relative;
  }
`;

const Separator = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  content: '';
  height: 19px;
  width: 1px;
  margin: 0 10px;
`;

const WeightSelect = styled(Select)`
  border: 0 !important;

  > div {
    font-size: 13px !important;
    padding: 0 !important;
    height: auto !important;
    padding-right: 18px !important;
  }

  > svg {
    right: -7px;
    height: 7px;
    width: 11px;
    visibility: ${({ disabled }) => (disabled ? `hidden` : `inherit`)};
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 7px 25px;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};

  a,
  img {
    cursor: pointer;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  @media (min-width: 450px) {
    margin: 7px 25px;
  }

  @media (min-width: 600px) {
    margin: 7px 30px;
  }

  @media (max-width: ${mediaSizes.phone}px) {
    padding: 6px 0 10px 0;
    img {
      margin-top: 5px;
    }
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
  margin: 12px 9px;

  @media (max-width: ${mediaSizes.phone}px) {
    margin: 9px;
  }
`;

const Name = styled.strong`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-size: 14px;
  line-height: 14px;
  margin-top: 4px;
`;

const Brand = styled.p`
  color: ${({ theme }) => theme.colors.grey[30]};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 12px;
  line-height: 165%;

  @media (max-width: ${mediaSizes.phone}px) {
    font-size: 13px;
    margin-top: 3px;
  }
`;

const ProductDemand = styled.div`
  margin: 0 0 0 90px;

  p {
    color: ${({ theme }) => theme.customized.colors.discountTag};
    font-family: ${({ theme }) => theme.customized.fonts.secondary};
    font-size: 12px;

    @media (max-width: ${mediaSizes.phone}px) {
      font-size: 12px;
      margin-top: 3px;
    }
  }

  @media (max-width: ${mediaSizes.phone}px) {
    margin: 0 0 0 64px;
  }

  @media (max-width: ${mediaSizes.smallPhone}px) {
    margin: 0 0 0 9px;
  }
`;

const OptionWrapper = styled.div`
  flex-basis: 0;
  margin: 12px 0;
`;

const OptionContainer = styled.div`
  align-items: flex-start;
  display: flex;
`;

const Price = styled.div`
  color: ${({ theme }) => theme.colors.grey[30]};
  flex-grow: 1;
  font-size: 14px;
  line-height: 175%;
  margin-left: 15px;
  margin-top: 4px;
  min-width: 60px;
  text-align: right;

  ${({ showPriceDiscount, theme }) =>
    showPriceDiscount &&
    `
    padding: 0 8px;

    > div:first-child {
      color: ${theme.colors.grey[60]};
      margin-top: 2px;
      text-decoration: line-through;
    }
  `};

  @media (min-width: 600px) {
    margin-left: 18px;
  }

  @media (max-width: ${mediaSizes.phone}px) {
    min-width: 50px;
  }
`;

const QuantityError = styled(Typography.Label)`
  color: ${({ theme }) => theme.colors.red[45]};
  margin: 4px 8px 0 0;
  text-align: right;
`;

const StyledProductImage = styled(ProductImage)`
  @media (max-width: ${mediaSizes.smallPhone}px) {
    display: none;
  }
`;
