import styled, { css } from 'styled-components';
import React, { forwardRef } from 'react';
import _ from 'lodash';
import { Box } from 'rebass';

import { formatEstimate, getAfterHoursWindow, formatAfterHoursEstimate } from 'shared/helpers/order-estimates';
import { getScheduledOrderTime, willSendOrderReadySMS } from 'shared/helpers/orders';

import CheckoutSuccessIcon from 'assets/checkout-success-icon';
import { ModalContainer } from 'shared/modals/v2-container';
import useCart from 'src/hooks/use-cart';
import useUI from 'src/hooks/use-ui';
import useUser from 'src/hooks/use-user';
import useTranslation from 'src/hooks/use-translation';
import useStyledTheme from 'shared/hooks/use-styled-theme';
import { CreditAuthNotification } from 'src/payments/components';
import { SMSUpdateBanner } from 'src/components/modals/components/sms-update-banner';
import GuestCheckoutSuccessSignupForm from './signup-form';

const GuestCheckoutSuccess = forwardRef(({ containerProps }, ref) => {
  const cart = useCart();
  const UI = useUI();
  const User = useUser();
  const { t } = useTranslation();

  const { stashedOrder } = cart;

  const {
    deliveryOption: serviceType,
    dispensary,
    isAfterHoursOrder,
    isInStorePickup,
    isCurbsidePickup,
    isDriveThruPickup,
    isDelivery,
    reservation,
    openInfo,
  } = stashedOrder;
  const { orderTypesConfigV2 } = dispensary;

  const showOrderReadySMSNotice = willSendOrderReadySMS(dispensary, stashedOrder);

  const serviceTypeToTextMap = {
    curbsidePickup: `curbside pickup`,
    driveThruPickup: `drive thru pickup`,
  };

  const scheduledOrderingForPickupV2 =
    (isInStorePickup && orderTypesConfigV2?.inStorePickup.enableScheduledOrdering) ||
    (isCurbsidePickup && orderTypesConfigV2?.curbsidePickup.enableScheduledOrdering) ||
    (isDriveThruPickup && orderTypesConfigV2?.driveThruPickup.enableScheduledOrdering);
  const scheduledOrderingForDeliveryV2 = isDelivery && orderTypesConfigV2?.delivery.enableScheduledOrdering;

  const scheduledOrderingEnabled = scheduledOrderingForPickupV2 || scheduledOrderingForDeliveryV2;

  const shouldShowEstimate =
    !(serviceType === 'delivery' && dispensary.featureFlags?.hideDeliveryEstimate) && !scheduledOrderingEnabled;

  const detailedServiceType = serviceType === 'pickup' ? `inStorePickup` : serviceType;

  const estimateTypeText =
    isAfterHoursOrder || detailedServiceType === 'inStorePickup'
      ? `Pickup time: `
      : `Estimated ${serviceTypeToTextMap[detailedServiceType] || detailedServiceType} time: `;

  const estimateTime = isAfterHoursOrder
    ? _.upperFirst(formatAfterHoursEstimate(getAfterHoursWindow(openInfo, detailedServiceType)))
    : formatEstimate(_.get(cart, `durationEstimates[${serviceType}]`), serviceType);

  let scheduledOrderTime;
  if (reservation) {
    scheduledOrderTime = getScheduledOrderTime({ reservation, timezone: dispensary.timezone });
  }

  function onClose() {
    UI.viewingGuestOrderSuccess = false;
  }

  return (
    <StyledModalContainer
      ref={ref}
      height={User.hasExternalUser ? 216 : 'auto'}
      width={541}
      p='0'
      data-cy='guest-checkout-success'
      data-test='guest-checkout-success'
      data-testid='guest-checkout-success'
      {...containerProps}
    >
      <SuccessContainer data-testid='guest-checkout-success-modal'>
        <StyledSuccessIcon />
        <OrderSubmitted data-testid='guest-checkout-success-header'>
          {t('checkout.order-submitted', 'Order Submitted!')}
        </OrderSubmitted>
        {shouldShowEstimate && (
          <>
            <StyledEstimateText fontSize='13px' color='#47494c' mt='20px'>
              {estimateTypeText}
              <strong>{estimateTime}</strong>
            </StyledEstimateText>
          </>
        )}
        {scheduledOrderTime && (
          <>
            <StyledEstimateText fontSize='13px' color='#47494c' mt='20px'>
              {`${_.capitalize(serviceTypeToTextMap[serviceType] || serviceType)} time: `}
              <strong>
                {scheduledOrderTime.prefix} | {scheduledOrderTime.startTime} - {scheduledOrderTime.endTime}
              </strong>
            </StyledEstimateText>
          </>
        )}

        {showOrderReadySMSNotice && <SMSUpdateBanner orderType={serviceType} mt='20px' mb='0px' />}
      </SuccessContainer>
      {!User.hasExternalUser && (
        <>
          <GuestCheckoutSuccessSignupForm onClose={onClose} />
        </>
      )}
    </StyledModalContainer>
  );
});

const StyledModalContainer = styled(ModalContainer)`
  overflow: hidden;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0;
    &&& {
      top: 28px;
      ${({ isIframe }) =>
        isIframe
          ? css`
              width: ${({ viewportWidth }) => viewportWidth * 0.85}px;
            `
          : css`
              transform: none;
              width: 85vw;
            `}
    }
  }
`;

const OrderSubmitted = styled.span`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.v2Green};
  font-weight: bold;
  margin-top: 16px;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 47px 42px 47px;
  img {
    margin-left: 9px;
  }
`;

const StyledSuccessIcon = styled(CheckoutSuccessIcon)`
  margin-left: 9px;
`;

const StyledEstimateText = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[30]};
  margin-top: 20px;
  span {
    font-weight: bold;
  }
`;

export default GuestCheckoutSuccess;
