import React from 'react';

export function LockIconSimple(): JSX.Element {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.84408 8.28125H15.1566C15.1566 8.28125 16.3024 8.28125 16.3024 9.42708V17.4479C16.3024 17.4479 16.3024 18.5937 15.1566 18.5937H4.84408C4.84408 18.5937 3.69824 18.5937 3.69824 17.4479V9.42708C3.69824 9.42708 3.69824 8.28125 4.84408 8.28125Z'
        stroke='#5D666D'
        strokeWidth='1.5'
      />
      <path
        d='M5.98926 8.28125V5.41667C5.98926 4.35304 6.41178 3.33297 7.16388 2.58087C7.91598 1.82877 8.93605 1.40625 9.99967 1.40625C11.0633 1.40625 12.0834 1.82877 12.8355 2.58087C13.5876 3.33297 14.0101 4.35304 14.0101 5.41667V8.28125'
        stroke='#5D666D'
        strokeWidth='1.5'
      />
      <path
        d='M8.28125 13.4375C8.28125 13.8933 8.46233 14.3305 8.78466 14.6528C9.10699 14.9752 9.54416 15.1562 10 15.1562C10.4558 15.1562 10.893 14.9752 11.2153 14.6528C11.5377 14.3305 11.7187 13.8933 11.7187 13.4375C11.7187 12.9817 11.5377 12.5445 11.2153 12.2222C10.893 11.8998 10.4558 11.7187 10 11.7188C9.54416 11.7187 9.10699 11.8998 8.78466 12.2222C8.46233 12.5445 8.28125 12.9817 8.28125 13.4375Z'
        stroke='#5D666D'
        strokeWidth='1.5'
      />
    </svg>
  );
}
