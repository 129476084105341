import { CategoryPotencyRanges } from 'shared/constants';

export function isCategoryPotencyRangeDefault({
  category,
  potencyRange,
}: {
  category: keyof typeof CategoryPotencyRanges | '';
  potencyRange: [number, number];
}): boolean {
  if (category === '') {
    return true;
  }
  const min = CategoryPotencyRanges[category]?.[0];
  const max = CategoryPotencyRanges[category]?.[1];
  if (min === undefined || max === undefined) {
    return true;
  }
  return min === potencyRange[0] && max === potencyRange[1];
}
