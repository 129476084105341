import uuid from 'uuid';
import moment from 'moment';

import { getPersistedValue, setPersistedValue } from 'shared/utils/persisted-values';

const sessionKey = 'dutchie-session';
const molocoAttributionWindow = 7;
const autoRefreshDuration = moment.duration({ hours: 1 }).asMilliseconds();

type Data = {
  id: string;
  expires: number;
};

function encode(data: any): string {
  return Buffer.from(JSON.stringify(data)).toString('base64');
}

function decode(str: string): any {
  return JSON.parse(Buffer.from(str, 'base64').toString('utf8'));
}

function hasValidData(data: any): data is Data {
  try {
    return typeof data.id === 'string' && typeof data.expires === 'number';
  } catch (err) {
    return false;
  }
}

function hasExpired(data: Data): boolean {
  return Date.now() > data.expires;
}

function generate(): Data {
  console.debug('session: generated');

  return {
    id: uuid.v4(),
    expires: moment().add(molocoAttributionWindow, 'days').valueOf(),
  };
}

function load(): Data | null {
  try {
    const cached = getPersistedValue(sessionKey);

    if (!cached) {
      console.debug('session: cached session is missing');
      return null;
    }

    const data = decode(cached);

    if (!hasValidData(data)) {
      console.debug('session: cached session is invalid');
      return null;
    }

    if (hasExpired(data)) {
      console.debug(`session: cached session expired`);
      return null;
    }

    return data;
  } catch (err) {
    console.debug('session: failed loading cached session');
    console.error(err);
    return null;
  }
}

export class Session {
  private static instance?: Session;
  readonly data: Data;
  readonly encoded: string;
  readonly autoRefreshInterval: any;

  private constructor() {
    this.data = load() ?? generate();
    this.encoded = encode(this.data);

    setPersistedValue(sessionKey, this.encoded);

    this.autoRefreshInterval = setInterval(() => this.autoRefresh(), autoRefreshDuration);
  }

  static getInstance(): Session {
    if (!Session.instance) {
      Session.instance = new Session();
    }

    return Session.instance;
  }

  static destroy(): void {
    clearInterval(Session.instance?.autoRefreshInterval);

    setPersistedValue(sessionKey, null);
    Session.instance = undefined;
  }

  private autoRefresh(): void {
    if (hasExpired(this.data)) {
      console.debug('session: expired');
      this.reset();
    }
  }

  public reset(): void {
    console.debug('session: reset');
    Session.destroy();
    Session.getInstance();
  }

  public get header(): { 'x-dutchie-session': string } {
    return { 'x-dutchie-session': this.encoded };
  }
}
