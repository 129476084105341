import React, { forwardRef } from 'react';
import { ModalContainer } from 'components/core';
import { toJS } from 'mobx';
import type { ContainerProps } from 'src/components/modals/with-modal-controls-hook/utils/use-container-props';
import { ChainLocationBrowserContent } from './chain-location-browser-content';

type Props = {
  onClose: () => void;
  containerProps: ContainerProps;
  data?: unknown;
};

export const ChainLocationBrowserContainer = forwardRef<HTMLDivElement, Props>(
  ({ onClose, containerProps, data }, ref) => {
    const { hideCloseButton = false } = toJS(data ?? {}) as { hideCloseButton?: boolean };

    return (
      <ModalContainer
        data-testid='chain-location-browser-modal-container'
        width='90vw'
        height='60vh'
        maxHeight='800px'
        maxWidth='1200px'
        padding={0}
        ref={ref}
        {...containerProps}
      >
        <ChainLocationBrowserContent onClose={onClose} hideCloseButton={hideCloseButton} />
      </ModalContainer>
    );
  }
);
