import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { color, height, justifyContent, width, space, borderRadius } from 'styled-system';
var Card = function Card(_ref) {
  var children = _ref.children,
    rest = _objectWithoutProperties(_ref, _excluded);
  return __jsx(StyledCard, rest, children);
};
export default Card;
var StyledCard = styled.div.withConfig({
  displayName: "card__StyledCard",
  componentId: "sc-1m4f3gk-0"
})(["cursor:pointer;align-items:center;display:flex;flex-direction:column;justify-content:center;transition:box-shadow 0.2s;border-radius:13px;position:relative;box-shadow:", ";height:187px;max-width:unset;width:auto;", " ", " ", " ", " ", " ", " user-select:none;user-drag:none;@media (min-width:600px) and (hover:hover){&:hover,&:active{box-shadow:0 5px 14px rgba(0,0,0,0.1);}}"], function (_ref2) {
  var alwaysShowShadow = _ref2.alwaysShowShadow;
  return alwaysShowShadow ? '0px 4px 10px rgba(0, 0, 0, 0.10)' : 'none';
}, borderRadius, color, height, justifyContent, space, width);