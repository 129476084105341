import React from 'react';

import { getComponentDisplayName } from 'utils/get-component-display-name';

export function withCompositeHook<ControllerProps extends Record<string, unknown>, CompositeHookReturn>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WrappedComponent: (props: CompositeHookReturn & ControllerProps) => JSX.Element | null,
  useCompositeHook: (props: ControllerProps) => CompositeHookReturn
): (props: ControllerProps) => JSX.Element | null {
  const WithCompositeHook = (props: ControllerProps): JSX.Element | null => (
    <WrappedComponent {...props} {...useCompositeHook(props)} />
  );

  WithCompositeHook.displayName = `WithCompositeHook(${getComponentDisplayName(WrappedComponent)})`;

  return WithCompositeHook;
}
