import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["id", "name", "label", "onChange", "value", "error", "errorMessage", "onValueAfterError", "disableEmptyOption", "customIcon", "children"];
var __jsx = React.createElement;
import React from 'react';
import styled, { css } from 'styled-components';
import { space, width } from 'styled-system';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
export var ArrowCss = css(["border:1px solid #46494c;border-left:4px solid transparent !important;border-right:4px solid transparent !important;border-top:5px solid #797e83 !important;border-bottom:none !important;transform:none;content:' ';display:block;pointer-events:none;position:absolute;z-index:4;"]);
var Arrow = styled.div.withConfig({
  displayName: "select-with-label__Arrow",
  componentId: "sc-1bwwxde-0"
})(["position:absolute;cursor:pointer;top:46%;right:26px;&:after{", "}"], ArrowCss);
export var ArrowImage = function ArrowImage() {
  return __jsx(Arrow, null);
};

/* eslint-disable import/prefer-default-export */
export var DefaultSelect = function DefaultSelect(_ref) {
  var id = _ref.id,
    name = _ref.name,
    label = _ref.label,
    _onChange = _ref.onChange,
    value = _ref.value,
    error = _ref.error,
    errorMessage = _ref.errorMessage,
    onValueAfterError = _ref.onValueAfterError,
    _ref$disableEmptyOpti = _ref.disableEmptyOption,
    disableEmptyOption = _ref$disableEmptyOpti === void 0 ? false : _ref$disableEmptyOpti,
    _ref$customIcon = _ref.customIcon,
    customIcon = _ref$customIcon === void 0 ? null : _ref$customIcon,
    children = _ref.children,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(StyledSelectWithLabel, props, __jsx(FormControl, {
    variant: "filled",
    classes: {
      root: 'mui-dt-root'
    }
  }, __jsx(InputLabel, {
    htmlFor: id,
    classes: {
      root: 'mui-dt-label-root',
      focused: 'mui-dt-label-focused',
      error: 'mui-dt-label-error',
      shrink: 'mui-dt-label-shrink'
    },
    className: error ? 'mui-dt-label-error' : ''
  }, error && errorMessage ? errorMessage : label), __jsx(Select, _extends({
    "native": true,
    id: id,
    error: !!error,
    value: value,
    onChange: function onChange(e) {
      _onChange(e);
      if (error) {
        onValueAfterError(e);
      }
    },
    input: __jsx(FilledInput, {
      name: name,
      id: id,
      classes: {
        root: 'mui-dt-input-root',
        input: 'mui-dt-input-input',
        error: 'mui-dt-input-error',
        underline: 'mui-dt-input-underline',
        inputAdornedEnd: 'mui-dt-input-icon'
      }
    }),
    IconComponent: customIcon || ArrowImage
  }, props), __jsx("option", {
    value: "",
    disabled: disableEmptyOption
  }), children)));
};
var StyledSelectWithLabel = styled.div.withConfig({
  displayName: "select-with-label__StyledSelectWithLabel",
  componentId: "sc-1bwwxde-1"
})([".mui-dt-root{", ";", ";}.mui-dt-input-root{cursor:pointer;background-color:#fcfdfe !important;border:1px solid #caced4;border-radius:4px;&.mui-dt-input-error{border:1px solid #e25241;}}.mui-dt-input-input{font-size:16px;padding:22px 5px 6px 20px;caret-color:#6fa48e;color:#5d666d;height:23px;width:calc(100% - 18px - 34px);@media only screen and (min-width:768px){font-size:13px;height:19px;padding:19px 34px 5px 18px;}&:focus{background-color:transparent;}}.mui-dt-label-root{color:#858788;font-size:16px;transform:translate(20px,19px) scale(1);transition:color 200ms cubic-bezier(0,0,0.2,1) 0ms,transform 200ms cubic-bezier(0,0,0.2,1) 0ms,font-size 200ms cubic-bezier(0,0,0.2,1) 0ms;&.mui-dt-label-shrink{transform:translate(20px,10px);font-size:10px;@media only screen and (min-width:768px){transform:translate(18px,8px);font-size:10px;}}&.mui-dt-label-error{color:#e25241 !important;}&.mui-dt-label-focused{color:#858788;}@media only screen and (min-width:768px){font-size:13px;transform:translate(18px,17px) scale(1);}}.mui-dt-input-underline:before{display:none;}.mui-dt-input-underline:after{transform:scale(0) !important;}"], space, width);