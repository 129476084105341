/* eslint-disable no-use-before-define */
import _ from 'lodash';
import { safeToBig } from 'shared/helpers/utils_math';
import { getProductVolumeInFluidOunces } from 'shared/helpers/products';
import { getFlowerEquivalent } from 'shared/helpers/check-limits';
import { getTypeLimits, getLimitMessage } from '../utils';
export function isLiquidEdible(product, limits) {
  var type = product.type,
    subcategory = product.subcategory;
  var liquidEdibleTypes = limits.liquidEdibleTypes;
  return _.includes(liquidEdibleTypes === null || liquidEdibleTypes === void 0 ? void 0 : liquidEdibleTypes.subcategories, subcategory) || _.includes(liquidEdibleTypes === null || liquidEdibleTypes === void 0 ? void 0 : liquidEdibleTypes.categories, type);
}
export function checkLiquidEdibleVolume(cart) {
  var state = cart.state;
  var _getTypeLimits = getTypeLimits(cart),
    liquidEdibleVolumeLimitInOunces = _getTypeLimits.liquidEdibleVolumeLimitInOunces;
  var totalVolumeInOunces = getTotalVolumeInOuncesForCart(cart);
  var message = getLimitMessage('liquid edibles', liquidEdibleVolumeLimitInOunces, state, 'oz');
  return totalVolumeInOunces > liquidEdibleVolumeLimitInOunces ? {
    total: totalVolumeInOunces,
    message: message,
    withinLimits: false
  } : {
    withinLimits: true
  };
}
export function getTotalVolumeInOuncesForCart(_ref) {
  var orders = _ref.orders,
    limits = _ref.limits,
    state = _ref.state,
    medical = _ref.medical;
  return Number(_.reduce(orders, function (previous, order) {
    var product = order.product,
      quantity = order.quantity;
    if (!isLiquidEdible(product, limits)) {
      return previous;
    }
    var volume = getProductVolumeInFluidOunces(product);
    var totalVolumeForOrder = safeToBig(volume).times(quantity).round(5);
    var isBritishColumbiaDispensary = state === 'BC';
    var flowerEquivalent = getFlowerEquivalent(order, medical, limits);
    var useEquivalent = isBritishColumbiaDispensary && !!flowerEquivalent;
    return safeToBig(previous).plus(useEquivalent ? flowerEquivalent : totalVolumeForOrder);
  }, 0.0));
}