import { useEffect, useState, useCallback } from 'react';
import { useObserver } from 'mobx-react-lite';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUser from 'src/hooks/use-user';
import { InstoreEnrollmentStatus } from 'src/state/instore-enrollment';
import { useInstoreEnrollment } from 'src/hooks/use-instore-enrollment';
import useDutchiePayEnrollment from 'src/hooks/use-dutchiePay-enrollment';

import { useDutchiePay } from 'src/payments/hooks/use-dutchie-pay/use-dutchie-pay';
import { EnrollmentSteps } from 'src/payments/constants';

export type UseConnectBankReturn = {
  currentStep: EnrollmentSteps;
  handleOnError: () => void;
  isPlaidLoading: boolean;
  onLoadingStateChange: ({ isLoading, hasToken }: { isLoading: boolean; hasToken: boolean }) => void;
  handleOnLinkExit: () => void;
};

export function useConnectBank(): UseConnectBankReturn {
  const { dispensary, loading: dispensaryLoading } = useDispensary();
  const user = useUser();
  const [startedEnrollment, setStartedEnrollment] = useState(false);
  const [plaidState, setPlaidState] = useState({
    isLoading: true,
    hasToken: false,
  });
  const instoreEnrollment = useInstoreEnrollment();
  const userExists = useObserver(() => user.exists);
  const userPhoneNumber = useObserver(() => user.phone);
  const userBirthday = useObserver(() => user.birthday);
  const userLastName = useObserver(() => user.lastName);
  const isMissingAccountInfo = !userPhoneNumber || !userBirthday || !userLastName;

  const { isEnrolledDutchiePay } = useDutchiePay();
  const DutchiePayEnrollment = useDutchiePayEnrollment();
  const currentStep = useObserver(() => DutchiePayEnrollment.currentStep);
  const handleOnError = useCallback(() => {
    // Go back to the pay by bank enrollment page for now until we have a better error handling strategy
    instoreEnrollment.setCurrentStatus(InstoreEnrollmentStatus.payByBankEnrollment);
  }, [instoreEnrollment]);

  const handleOnLinkExit = useCallback(() => {
    DutchiePayEnrollment.setCurrentStep(EnrollmentSteps.connectBank);
  }, [DutchiePayEnrollment]);

  const onLoadingStateChange = useCallback(
    ({ isLoading, hasToken }: { isLoading: boolean; hasToken: boolean }): void => {
      setPlaidState({ isLoading, hasToken });
    },
    []
  );

  useEffect(() => {
    if (isEnrolledDutchiePay || currentStep === EnrollmentSteps.success) {
      DutchiePayEnrollment.userAlreadySignedUp();
      instoreEnrollment.setCurrentStatus(InstoreEnrollmentStatus.finishedEnrollment);
      return;
    }

    if (userExists && isMissingAccountInfo) {
      DutchiePayEnrollment.setCurrentStep(EnrollmentSteps.completeAccount);
      return;
    }

    if (userExists && !startedEnrollment) {
      DutchiePayEnrollment.beginEnrollment(userExists);
      if (dispensary && !dispensaryLoading) {
        DutchiePayEnrollment.setDispensary(dispensary);
      }
      setStartedEnrollment(true);
    }
  }, [
    currentStep,
    dispensary,
    dispensaryLoading,
    DutchiePayEnrollment,
    instoreEnrollment,
    isEnrolledDutchiePay,
    isMissingAccountInfo,
    userExists,
    startedEnrollment,
  ]);

  return {
    currentStep,
    handleOnError,
    handleOnLinkExit,
    isPlaidLoading: plaidState.isLoading || !plaidState.hasToken,
    onLoadingStateChange,
  };
}
