// TODO: Move this into /marketplace/src/checkout/actions, on-click-submit.test.js is located there
import _ from 'lodash';
import LogRocket from 'shared/logrocket';

import { persistCartData } from 'src/state/actions/cart';

import validateSubmit from './validate-submit';
import validateCart from './validate-cart';
import buildOrder from './build-order';

// called via src/checkout/components/confirmation/submit 48
// called via src/modals/kiosk-checkout/modal.jsx 90
export default async function onClickSubmit(
  submitOrder,
  {
    Cart,
    UI,
    User,
    apolloClient,
    showErnie,
    Checkout,
    checkOnDuty = false,
    isAnonymousCheckout = false,
    isEmailRequiredForCurbsideArrival = false,
    isExternalProviderEnabled = false,
    paymentId = '',
    rethinkToken = '',
    isRethinkEnabled = false,
    getRethinkUrl,
    Payments,
    refetchRetailerPaymentData,
    tracker,
    flags,
    turnstileToken = '',
  }
) {
  const { isVariant, addErrors, saveMethods } = Checkout;
  // attempt to auto save specific sections
  try {
    // attempt to auto save sections that have registered themselves for saving
    await Promise.all(saveMethods.map((method) => method()));

    // old manually coded ones
    await Cart.medicalComponentRef?.current?.onSave();
    await Cart.orderTypeComponentRef?.current?.onSave();
    await Cart.scheduledOrderingRef?.current?.handleSave();
    // if we don't have a checkout token, persist cart to get one
    if (!Cart.checkoutToken) {
      await persistCartData(apolloClient, Cart, UI, User, flags);
    }
  } catch (e) {
    // log error to console--ernie logging is handled in respective onSave() calls above
    console.error(e);
    return { error: true, msg: e };
  }

  // validate the cart
  const { proceed: cartIsValid, outOfStockItem, returnToMenu } = await validateCart({
    Cart,
    UI,
    apolloClient,
    showErnie,
    isVariant,
    checkOnDuty,
  });
  if (!cartIsValid) {
    return { error: true, outOfStockItem, returnToMenu };
  }

  // validate the order
  const { proceed: orderIsValid } = await validateSubmit({
    Cart,
    UI,
    User,
    showErnie,
    Checkout,
    isEmailRequiredForCurbsideArrival,
    isExternalProviderEnabled,
    turnstileToken,
  });

  if (!orderIsValid) {
    if (isRethinkEnabled && !!Payments.rethinkWindow) {
      Payments.rethinkWindow.close();
    }
    return { error: true };
  }
  // build the order
  const { order } = buildOrder({
    Cart,
    UI,
    User,
    Checkout,
    paymentId,
    flags,
  });
  order.isAnonymous = isAnonymousCheckout;

  // For Rethink Pay, we need to redirect to their payment page after we've
  // validated the Cart and Order without submiting the order. Once they complete with Rethink,
  // they will be redirected back to our checkout page where we will then complete the order.
  if (isRethinkEnabled && order.paymentMethod === 'rethinkPay' && !rethinkToken) {
    getRethinkUrl();
    return { order };
  }
  // server side validations & attempt to submit the order
  const response = await submitOrder({ variables: { input: order, turnstileToken } });
  const valid = _.get(response, 'data.createOrderV2.valid', false);
  const serverErrors = _.get(response, 'data.createOrderV2.errors', false);
  if (!valid && !_.isEmpty(serverErrors)) {
    const dutchiePayError = serverErrors[0].extensions?.path === 'dutchiePay';
    const reasonCode = serverErrors[0].extensions?.reason_code;
    const dutchiePayIsDown = serverErrors[0].extensions?.reason_code === 'S010';

    if (dutchiePayError) {
      tracker.paymentsError({ reasonCode: reasonCode ?? 'NO_REASON_CODE', meta: serverErrors[0]?.extensions });
    }

    if (dutchiePayError && dutchiePayIsDown) {
      refetchRetailerPaymentData();
    }

    addErrors(serverErrors);
    console.error({ 'Checkout Errors': serverErrors, Order: order });
  } else if (!valid) {
    console.error('CheckoutError', response);
    UI.showErnie('Error submitting order. Please contact customer support.');
    LogRocket?.track('CheckoutError');
  }
  if (valid) {
    Payments?.resetPaymentData();
  }
  return response;
}
