var __jsx = React.createElement;
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { formatDiscountForDisplay } from 'shared/helpers/specials';
export default function DiscountBadge(_ref) {
  var className = _ref.className,
    displayText = _ref.displayText,
    type = _ref.type,
    _ref$isMobile = _ref.isMobile,
    isMobile = _ref$isMobile === void 0 ? false : _ref$isMobile,
    originalPrice = _ref.originalPrice,
    discountedPrice = _ref.discountedPrice,
    item = _ref.item,
    option = _ref.option,
    value = _ref.value,
    _ref$forceDollarForma = _ref.forceDollarFormat,
    forceDollarFormat = _ref$forceDollarForma === void 0 ? false : _ref$forceDollarForma;
  var specialsText;

  // Display overrides
  if (displayText) {
    specialsText = displayText;
    // Sales
  } else if (type === 'sale') {
    // value is a deprecated attribute
    if (!_.isNil(value)) {
      specialsText = "".concat(value, "% off");
    } else {
      specialsText = formatDiscountForDisplay(originalPrice, discountedPrice, item, type, option, false, forceDollarFormat);
    }
    // Offers
  } else if (!_.isNil(value)) {
    // value is a deprecated attribute
    specialsText = isMobile ? "$".concat(value, " discount!") : "$".concat(value, " discount applied!");
  } else {
    specialsText = formatDiscountForDisplay(originalPrice, discountedPrice, item, type, option, isMobile, forceDollarFormat);
  }
  return __jsx(Container, {
    className: className,
    type: type
  }, __jsx("span", null, specialsText));
}
var Container = styled.div.withConfig({
  displayName: "discount-badge__Container",
  componentId: "sc-1mcu7tp-0"
})(["display:block;width:fit-content;height:19px;line-height:157%;border-radius:34px;color:#fff;font-weight:bold;font-size:12px;padding:0px 10px;background-color:", ";margin-bottom:9px;margin-right:9px;font-style:normal;"], function (props) {
  return props.type === 'sale' ? '#f3583a' : '#4ca667';
});