/**
 * @fileoverview
 *
 * @deprecated - transitioning to new analytics framework (see {@link utils/analytics/README.md})
 */

/** this comment keeps the above deprecated from thinking it applies to the import react line that's next **/
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import Router from 'next/router';
import { useObserver } from 'mobx-react-lite';
import _once from 'lodash/once';
import _omitBy from 'lodash/omitBy';
import _isUndefined from 'lodash/isUndefined';
import _isEmpty from 'lodash/isEmpty';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'hooks/use-ui';
import useFeatureFlags from 'hooks/use-feature-flags';
import { isBrowser } from 'utils/misc-utils';
import PublicEnv from 'shared/utils/public-env';
import { createGaV4Client, createGaV4DelayedCallClient } from 'utils/analytics/clients/ga-v4-client';
import { isGaV4Id } from 'utils/analytics/helpers/is-ga-v4-id';

// Start with a delayed call client until we have access to the dispensary's GA ID
let dispensaryGaV4Client = createGaV4DelayedCallClient();

// a (read-only) accessor for the `let` above.
export const getDispensaryGaV4Client = () => dispensaryGaV4Client;

export const initGlobalTracker = _once(() => {
  if (PublicEnv.appEnv !== 'development') {
    ReactGA.initialize(PublicEnv.googleAnalyticsID, {
      gaOptions: {
        cookieFlags: 'max-age=7200;secure;samesite=none',
      },
    });
    ReactGA.plugin.require('ec');
  }
});

const initDispensaryTracker = _once((id) => {
  if (isGaV4Id(id)) {
    const delayedCallClient = dispensaryGaV4Client;
    dispensaryGaV4Client = createGaV4Client(id);

    // replay all the events that were captured before the client
    // was initialized
    delayedCallClient?.updateClient(dispensaryGaV4Client);
  } else {
    ReactGA.ga('create', id, 'auto', 'dispensaryTracker', {
      allowLinker: true,
      cookieFlags: 'max-age=7200;secure;samesite=none',
    });
    ReactGA.ga('dispensaryTracker.require', 'ec');
  }
});

function embeddedEventDispatcher(type, event, data) {
  if (window.parent === window) {
    return true;
  }

  window.parent.postMessage(
    JSON.stringify({
      event: `analytics:${type}`,
      // Note: Playload was unfortunate typo that some stores may be using :(. Need to leave intact.
      payload: { event, playload: data, payload: data },
    }),
    '*'
  );
  return true;
}

export function dispatchOnMostTrackers(event, ...payload) {
  ReactGA.ga(event, ...payload);
  ReactGA.ga(`dispensaryTracker.${event}`, ...payload);
  embeddedEventDispatcher('ga_event', event, payload);
}

export function setDimensionInfo(dispensary, variant) {
  ReactGA.ga('set', 'dimension1', dispensary?.id);
  ReactGA.ga('set', 'dimension2', variant);
  ReactGA.ga('set', 'dimension3', dispensary?.name);
  embeddedEventDispatcher('ga_dimensions', [dispensary.id, variant, dispensary.name]);
}

export function useDispensaryGA() {
  const UI = useUI();
  const { dispensary } = useDispensary();
  const shouldUseDispensaryGA = useObserver(() => UI.isEmbedded || UI.isStoreFront || UI.isPlus);
  const variant = useObserver(() => UI.variant);
  const flagsStore = useFeatureFlags();

  return useEffect(() => {
    if (!isBrowser()) {
      return;
    }
    if (dispensary) {
      const disableInternalGaTracker = flagsStore?.flags?.['deprecate-internal-ga-tracker'];

      if (!disableInternalGaTracker) {
        initGlobalTracker();
      }

      setDimensionInfo(dispensary, variant);
      if (dispensary.googleAnalyticsID && shouldUseDispensaryGA) {
        initDispensaryTracker(dispensary.googleAnalyticsID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispensary?.googleAnalyticsID, shouldUseDispensaryGA, variant, flagsStore]);
}

export function useTrackRouteChanges() {
  useEffect(() => {
    const handleRouteChange = (url) => ReactGA.pageview(url, ['dispensaryTracker']);
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);
}

export function useInitGlobalGA(FeatureFlags) {
  const flags = useObserver(() => FeatureFlags.flags);

  return useEffect(() => {
    const disableInternalGaTracker = flags?.['deprecate-internal-ga-tracker'] || _isEmpty(flags);
    if (!disableInternalGaTracker) {
      initGlobalTracker();
    }
  }, [flags]);
}

export function cartItemToUAObject(item) {
  return _omitBy(
    {
      id: item.product.id,
      name: item.product.name,
      brand: item.product.brand?.name || item.product.brandName,
      category: item.product.type,
      variant: item.option,
      additional_variant: item.additionalOption,
      quantity: item.quantity,
      price: item.unitPrice,
      dimension1: item.product.DispensaryID,
    },
    _isUndefined
  );
}
