import React from 'react';
import { Button } from 'shared/components';
import { RibbonStarV2 } from 'src/assets/ribbon-star-v2';
import { Typography } from 'src/typography';

import styled from 'styled-components';
import { FullPageLoader } from 'shared/components/loading';
import { useLoyalty } from './use-loyalty';

export function Loyalty(): JSX.Element {
  const { dispensaryName, userFirstName, rewardsProgram, handleCreateRewardsAccount, loading } = useLoyalty();

  const handleStartEarningPoints = async (): Promise<void> => {
    if (handleCreateRewardsAccount) {
      await handleCreateRewardsAccount();
    }
  };

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <LoyaltyContainer data-testid='loyalty-main-container'>
      <HeadingText size='large' tag='h2' data-testid='heading-text'>
        <span role='img' aria-label='wave'>
          👋
        </span>{' '}
        Hi, {userFirstName}!
      </HeadingText>
      <RibbonStarV2 />
      <ContentContainer>
        <BodyTextContainer>
          <SubHeadingText size='small'>Enroll in {`${dispensaryName} Rewards`}</SubHeadingText>
          <BodyText size='small'>Unlock exclusive rewards as you shop with us.</BodyText>
        </BodyTextContainer>
      </ContentContainer>
      <SignUpButton size='large' onClick={handleStartEarningPoints} loading={loading}>
        Start Earning Points
      </SignUpButton>
      <TermsText size='small' data-testid='terms-text'>
        By clicking “Start Earning Points” I consent to signup for the {dispensaryName} {rewardsProgram} program where I
        will receive marketing communications. By doing so, I understand that I am allowing {dispensaryName} and its
        technology provider(s) to retain my personal contact details/engagement history for use in personalized
        marketing. Opt out at any time by replying "STOP" to messages. Standard message and calling rates may apply.
      </TermsText>
    </LoyaltyContainer>
  );
}

const SignUpButton = styled(Button)`
  background-color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  height: 52px;
  width: 100%;
`;

const BodyTextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const HeadingText = styled(Typography.Heading)`
  font-size: 20px;
  font-weight: 600;
`;

const SubHeadingText = styled(Typography.Body)`
  font-size: 16px;
  font-weight: 700;
  text-align: center;
`;

const BodyText = styled(Typography.Body)`
  color: ${({ theme }) => theme.colors.grey[30]};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;

const LoyaltyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 24px 20px;
`;

const TermsText = styled(Typography.Body)`
  color: #646d72;
  font-size: 12px;
  text-align: center;
`;
