// This is old, do not use it
// This causes problems.

import type { LDContext, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { action, computed, observable, toJS, makeObservable } from 'mobx';
import PublicEnv from 'shared/utils/public-env';

export default class FeatureFlagsStore {
  @observable _flags: LDFlagSet = {};
  @observable loading = true;
  @observable targets = new Set<string>();

  constructor() {
    makeObservable(this);
  }

  @action
  setFlags(newFlags: LDFlagSet): void {
    if (Object.keys(newFlags).length) {
      this.loading = false;
    }
    this._flags = newFlags;
  }

  @action
  identify(context: LDContext): void {
    Object.keys(context).forEach((target) => {
      this.targets.add(target);
    });
  }

  @computed
  get flags(): LDFlagSet {
    if (PublicEnv.appEnv === 'development') {
      console.warn('Warning! Do not use the FeatureFlagsStore, it is deprecated and causes issues.');
    }
    return toJS(this._flags);
  }

  @computed
  get hasTargetUser(): boolean {
    return this.targets.has('user');
  }

  @computed
  get hasTargetDispensary(): boolean {
    return this.targets.has('ecomm-dispensary');
  }

  @computed
  get hasTargetEnterprise(): boolean {
    return this.targets.has('ecomm-enterprise');
  }
}
