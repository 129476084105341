import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getAvailableQuantity } from 'shared/order/bogo/common';
var issueDiscountBundleRewards = function issueDiscountBundleRewards(bogoCalcsData, bogoSpecial, conditionsManager) {
  var discountBundle = bogoSpecial.discountBundle,
    specialId = bogoSpecial.specialId,
    source = bogoSpecial.source;
  var discountType = discountBundle.discountType,
    value = discountBundle.value;
  var rewardsSatisfiers = {};
  _.forEach(conditionsManager.satisfiers, function (_ref) {
    var item = _ref.item,
      quantity = _ref.quantity;
    var quantityToDiscount = quantity;
    // if we have a limit we want to use that as the quantityToDiscount for this pass
    // unless the limit is greater than the availableQuantity
    // if there is a leftover that doesn't divide evenly across the limit
    // we don't want to include that remainder quantity
    // limits are only available on POS specials per the field "per cart limit"
    if (discountBundle !== null && discountBundle !== void 0 && discountBundle.limit && source === 'POS') {
      var availableQuantity = getAvailableQuantity({
        item: item,
        bogoCalcsData: bogoCalcsData,
        pendingConditionsSatisfiers: {},
        pendingRewardsSatisfiers: rewardsSatisfiers,
        specialId: bogoSpecial.specialId
      });
      var lesserOfAvailableAndLimit = Math.min(availableQuantity, discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.limit);
      if (availableQuantity >= (discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.limit)) {
        quantityToDiscount = lesserOfAvailableAndLimit;
      } else {
        quantityToDiscount = lesserOfAvailableAndLimit % (discountBundle === null || discountBundle === void 0 ? void 0 : discountBundle.limit) === 0 ? lesserOfAvailableAndLimit : 0;
      }
    }
    if (quantityToDiscount > 0) {
      bogoCalcsData.ignoreConditionsForDiscountBundle = true;
      rewardsSatisfiers[item.key] = {
        item: item,
        quantity: quantityToDiscount,
        rewards: _defineProperty({}, discountBundle._id, {
          quantity: quantityToDiscount,
          reward: _defineProperty({
            _id: discountBundle._id,
            quantity: quantityToDiscount
          }, discountType, value),
          discountBundle: _objectSpread({}, discountBundle),
          specialId: specialId,
          discountStacking: bogoSpecial.discountStacking,
          discountPrecedence: bogoSpecial.discountPrecedence,
          stackingBehavior: bogoSpecial.stackingBehavior,
          nonStackingBehavior: bogoSpecial.nonStackingBehavior,
          settingsDerivedFrom: bogoSpecial.settingsDerivedFrom
        })
      };
    }
  });
  return rewardsSatisfiers;
};
export default issueDiscountBundleRewards;