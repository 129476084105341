import _ from 'lodash';
import geodist from 'geodist';
import { toJS } from 'mobx';

import { getDeliveryInfo } from 'shared/graphql/dispensary/queries';
import addressResidentialCheck from 'shared/graphql/misc/address-residential-check.gql';
import useStores from 'shared/hooks/use-stores';

export async function checkResidential(UI, apolloClient, locationObj) {
  try {
    const { data } = await apolloClient.query({
      query: addressResidentialCheck,
      variables: { input: _.pick(locationObj, ['ln1', 'city', 'state']) },
    });
    return data.addressResidentialCheck.isValid ? 'VERIFIED' : 'UNVERIFIED';
  } catch (err) {
    console.error(err);
    return 'UNVERIFIED';
  }
}

export function useCheckResidential() {
  const { apolloClient, UI } = useStores();
  return _.partial(checkResidential, UI, apolloClient);
}

export async function validateAddressLimits(Cart, apolloClient, address, dispensary) {
  const dispo = dispensary || toJS(Cart?.order?.dispensary);
  const maxDeliveryDistance = _.get(dispo, 'maxDeliveryDistance');

  let withinDistance = true;
  if (maxDeliveryDistance) {
    const { 0: lng, 1: lat } = _.get(dispo, 'location.geometry.coordinates');
    const distance = geodist({ lat, lng }, _.pick(address, ['lat', 'lng']), {
      unit: 'miles',
      exact: true,
    });
    withinDistance = parseFloat(maxDeliveryDistance) > distance;
  }

  const { city, lat, lng, state, zipcode } = address;
  const { data } = await apolloClient.query({
    query: getDeliveryInfo,
    variables: {
      input: {
        city,
        dispensaryId: dispo.id,
        lat,
        lng,
        state,
        zipcode,
      },
    },
  });

  const deliveryInfo = data.getDeliveryInfo;

  if (withinDistance && deliveryInfo?.withinBounds && deliveryInfo?.deliveryAreaId) {
    Cart.deliveryAreaId = deliveryInfo?.deliveryAreaId;

    return { success: true };
  }

  return { success: withinDistance && deliveryInfo?.withinBounds };
}

export function useValidateAddressLimits() {
  const { Cart, apolloClient } = useStores();
  return _.partial(validateAddressLimits, Cart, apolloClient);
}

export async function checkAllAddressFlags(
  UI,
  Cart,
  apolloClient,
  { location = UI.currentAddress, alert = false, getMessage = false } = {},
  dispensary = null
) {
  const deliveryAddress = UI.hasDeliveryAddress({ location });

  if (!deliveryAddress) {
    const msg = 'Please enter a delivery address';
    if (alert) {
      UI.showErnie(msg, 'danger');
    }
    if (getMessage) {
      return { success: false, msg };
    }
    return { success: false };
  }

  const validLimits = await validateAddressLimits(Cart, apolloClient, location, dispensary);
  if (!validLimits.success) {
    const msg = 'The address you entered is outside the delivery area.';
    if (alert) {
      UI.showErnie(msg, 'danger');
    }
    if (getMessage) {
      return { success: false, msg };
    }
    return { success: false };
  }

  if (UI.currentAddress?.residentialStatus !== 'VERIFIED') {
    const residentialCheck = await checkResidential(UI, apolloClient, location);
    if (residentialCheck === 'UNVERIFIED') {
      if (alert || getMessage) {
        UI.showResidentialStatusModal();
        return { success: false, checkingResidentialStatus: true };
      }

      return { success: false };
    }
  }

  return { success: true };
}

export function useCheckAllAddressFlags() {
  const { UI, Cart, apolloClient } = useStores();
  return _.partial(checkAllAddressFlags, UI, Cart, apolloClient);
}
