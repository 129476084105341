import _defineProperty from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import _ from 'lodash';
import moment from 'moment-timezone';
export var getSpecialHoursDays = function getSpecialHoursDays(specialHours, timezone) {
  var upcomingSpecialHours = _.filter(specialHours, function (specialHoursItem) {
    var specialHoursItemEndDate = moment.tz(specialHoursItem.endDate, timezone);
    var today = moment().tz(timezone).startOf('day');
    return today.isSameOrBefore(specialHoursItemEndDate);
  });
  var upcomingSpecialHoursPerDay = _.map(upcomingSpecialHours, 'hoursPerDay');
  var flattenedSpecialHoursPerDay = _.flattenDeep(upcomingSpecialHoursPerDay);
  return _.compact(flattenedSpecialHoursPerDay);
};
export var getEffectiveHours = function getEffectiveHours(pickupHours, deliveryHours, specialHours, timezone) {
  /**
   * When using updated ordering/hours settings, the top-level effectiveHours
   * object is irrelevant, as effectiveHours object nested in hoursSettings
   * are used.
   * It would be possible to wrangle the data to make this work, but the field
   * isn't used anyway. -- RTL, 8/6/2021
   */
  var usingUpdatedHoursSettings = _.some(specialHours, function (specialHoursSet) {
    return _.has(specialHoursSet, 'specialOperatingHours') && !_.has(specialHoursSet, 'hoursPerDay');
  });
  if (!specialHours || usingUpdatedHoursSettings) {
    return {
      pickupHours: pickupHours,
      deliveryHours: deliveryHours
    };
  }
  var effectivePickupHours = {};
  var effectiveDeliveryHours = {};
  var specialHoursDays = getSpecialHoursDays(specialHours, timezone);
  var specialHoursThisWeek = _.filter(_.compact(specialHoursDays), function (_ref) {
    var date = _ref.date;
    return moment.tz(date, timezone).isSame(moment().tz(timezone), 'week');
  });
  if (specialHoursThisWeek) {
    _.forEach(specialHoursThisWeek, function (day) {
      _.set(effectivePickupHours, moment.tz(day.date, timezone).format('dddd'), day.pickupHours);
      _.set(effectiveDeliveryHours, moment.tz(day.date, timezone).format('dddd'), day.deliveryHours);
    });
  }
  return {
    pickupHours: _objectSpread(_objectSpread({}, pickupHours), effectivePickupHours),
    deliveryHours: _objectSpread(_objectSpread({}, deliveryHours), effectiveDeliveryHours)
  };
};